import React, { useEffect, useState } from "react";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";

// axios
import { PatientConfig } from "../../../../axiosConfig";

function PrivacyPolicy() {
  // const [data, setData] = useState([]);
  // const [contents, setContents] = useState([]);
  // const [titles, setTitles] = useState([]);

  // const termsAndCondition = () => {
  //   PatientConfig.get("api/v1/patients/patient/privacy-policy/")
  //     .then((response) => {
  //       setData(response.data.data);
  //       setContents(response.data.data.contents);
  //       setTitles(response.data.data.contents);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  // useEffect(() => {
  //   termsAndCondition();
  // }, []);

  return (
    <>
      {/* <MainContainer>
        <Wrapper>
          <Heading>{data.introduction_title}</Heading>
          <Date>Last Updated {data.date_updated}</Date>
          <MainDiv>
            <Left>
              <SubHeading>
                This privacy policy will help you better under stand how we
                collect , use and share your personal information
              </SubHeading>
              <ContentDiv>
                {contents.map((item, index) => (
                  <Contents key={index}>
                    <SmallHeading>{item.title}</SmallHeading>
                    <Para>{item.descriptions}</Para>
                  </Contents>
                ))}
              </ContentDiv>
            </Left>
            <Right>
              <Label>Updates to the privacy policy</Label>
              <Line />

              {titles.map((item, index) => (
                <Points key={index}>{item.title}</Points>
              ))}
            </Right>
          </MainDiv>
        </Wrapper>
      </MainContainer> */}

      <MainContainer>
        <Wrapper>
          <Heading>Privacy Policy</Heading>
          <Date>Last Updated December 15, 2023</Date>
          <MainDiv>
            <Left>
              <SubHeading>
                This privacy policy will help you better understand how we
                collect, use and share your personal information
              </SubHeading>
              <ContentDiv>
                <Contents>
                  <SmallHeading>
                    About Your Privacy and This Privacy Policy
                  </SmallHeading>
                  <Para>
                    We place a great deal of importance on your privacy. We are
                    always putting a lot of consideration, work, resources,
                    technologies, and processes in place to make sure your
                    privacy is protected. Our "Privacy Policy," which is
                    available here, provides information on matters pertaining
                    to your privacy when utilizing our services. Its purpose is
                    to enlighten you about our methods, policies, and processes
                    about the gathering, utilizing, and disclosing of any data
                    you provide on the Platform. Our website contains our Terms
                    and Conditions, which include the Privacy Policy. Terms like
                    "we," "our," "us," "Platform," "Counselor," "Counselor
                    Services," “Website”, “expert” and so forth that are used in
                    the Privacy Policy have the same meanings as those found in
                    our Terms and Conditions document. You acknowledge and agree
                    to the Terms & Conditions and the Privacy Policy when you
                    use our platform. You should cease using the Platform right
                    away if you disagree with the terms and conditions stated in
                    the privacy statement. You confirm that you have read the
                    Terms & Conditions and the Privacy Policy and that you
                    comprehend, agree with, and recognize all of the terms
                    stated in both documents by accessing and using our
                    platform.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>
                    Information Collection, Use, and Disclosure
                  </SmallHeading>
                  <Para>
                    By deciding to provide the Information you agree to our
                    methods of collections and use, as well to other terms and
                    provisions of this Privacy Policy. Protecting this
                    Information is a top priority for us. We will never sell or
                    rent any Information you shared in the Platform. Other than
                    in the limited ways detailed in this Privacy Policy, we will
                    never use or disclose any Information unless you
                    specifically and explicitly requested or approved us to do
                    so. <br /> The Information may be used for the following
                    purposes:
                    <Purposes>
                      <Purpose>
                        To contact you or provide you with information, alerts
                        and suggestions that are related to the service.
                      </Purpose>
                      <Purpose>Billing-related purposes.</Purpose>
                      <Purpose>
                        To reach out to you, either ourselves or using the
                        appropriate authorities, if either we or a Counselor
                        have a good reason to believe that you or any other
                        person may be in danger or may be either the cause or
                        the victim of a criminal act.
                      </Purpose>
                      <Purpose>To match you with a Counselor.</Purpose>
                      <Purpose>
                        To enable and facilitate the Counselor Services.
                      </Purpose>
                      <Purpose>
                        To supervise, administer and monitor the service.
                      </Purpose>
                      <Purpose>
                        To measure and improve the quality, the effectiveness
                        and the delivery of our services.
                      </Purpose>
                    </Purposes>
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>Cookies</SmallHeading>
                  <Para>
                    Like many websites, we use “cookies” to collect information.
                    A “cookie” is a small data file that is transferred to your
                    computer’s hard disk for record-keeping purposes. You can
                    change your browser’s settings so it will stop accepting
                    cookies or to prompt you before accepting a cookie. However,
                    if you do not accept cookies you may not be able to use the
                    Platform.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>Phishing</SmallHeading>
                  <Para>
                    Online identity theft and account hacking, including the
                    practice currently known as “phishing”, are of great
                    concern. You should always be diligent when you are being
                    asked for your account information and you must always make
                    sure you do that in our secure system. We will never request
                    your login information or your credit card information in
                    any non-secure or unsolicited communication (email, phone or
                    otherwise).
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>Links</SmallHeading>
                  <Para>
                    The Platform may contain links to other websites, services
                    or offers which are owned, operated or maintained by third
                    parties. If you click on a third-party link, you will be
                    directed to that third website or service. The fact that we
                    link to a website or service is not an endorsement,
                    authorization or representation of our affiliation with that
                    third party, nor is it an endorsement of their privacy or
                    information security policies or practices. We do not have
                    control over third party websites and services and we do not
                    have control over their privacy policies and terms of use.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>Security</SmallHeading>
                  <Para>
                    While using any Internet-based service carries inherent
                    security risks that cannot be 100% prevented, our systems,
                    infrastructure, encryption technology, operation and
                    processes are all designed, built and maintained with your
                    security and privacy in mind. We apply industry standards
                    and best practices to prevent any unauthorized access, use,
                    and disclosure. We comply with or exceed all applicable
                    federal laws, state laws, and regulations regarding data
                    privacy.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>Service Providers</SmallHeading>
                  <Para>
                    We may employ third party companies and individuals to
                    facilitate our Platform, to perform certain tasks which are
                    related to the Platform, or to provide audit, legal,
                    operational or other services for us. These tasks include,
                    but not limited to, customer service, technical maintenance,
                    monitoring, email management and communication, database
                    management, billing and payment processing, reporting and
                    analytics. We will share with them only the minimum
                    necessary information to perform their task for us and only
                    after entering into appropriate confidentiality agreements.
                    We use google meet platform to facilitate video conferencing
                    and your email id will be used to set up the online meeting.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>Children’s Privacy</SmallHeading>
                  <Para>
                    The services for the minors (below 18 years age) from the
                    platform is intended to be availed under supervision of
                    parent or legal guardian. If you are a minor and registered
                    as an adult in the platform by mistake; please let us know.
                    We will delete your account details.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>International Transfer</SmallHeading>
                  <Para>
                    Your information may be transferred to — and maintained on —
                    computers located outside of your state, province, country
                    or other governmental jurisdiction. Regardless of where your
                    data is stored, it will be maintained securely as outlined
                    in this policy. Your consent to our Terms and Conditions
                    followed by your submission of such information represents
                    your agreement to such transfers.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>
                    Compliance with Laws and Law Enforcement
                  </SmallHeading>
                  <Para>
                    We cooperate with government and law enforcement officials
                    to enforce and comply with the law. We may disclose
                    information necessary or appropriate to protect the safety
                    of the public or any person, to respond to claims and legal
                    process (including but not limited to subpoenas), and to
                    prevent or stop activity that may be illegal or dangerous.
                    You should also be aware that Counselors may be obliged to
                    disclose information to law enforcement or other authorities
                    to conform to their professional and legal responsibilities.
                    Specifically, and without limitation, you should be aware
                    that the law requires mental health professionals to
                    disclose information and/or take action in the following
                    cases: (a) reported or suspected abuse of a child or
                    vulnerable adult; (b) serious suicidal potential; (c)
                    threatened harm to another person; (d) court-ordered
                    presentation of treatment.
                  </Para>
                </Contents>
                <Contents>
                  <SmallHeading>Changes to the Privacy Policy</SmallHeading>
                  <Para>
                    We may update this privacy statement at our sole discretion.
                    The date of the last revision of this policy appears at the
                    end of this page. We encourage you to periodically review
                    this page for the latest information on our Privacy Policy
                    and practices. Regardless of changes to our Privacy Policy,
                    we will never use the information you submit under our
                    current privacy notice in a new way without first notifying
                    you and giving you the option to opt out.
                  </Para>
                </Contents>
              </ContentDiv>
            </Left>
            {/* <Right>
              <Label>Updates to the privacy policy</Label>
              <Line />

              <Points></Points>
            </Right> */}
          </MainDiv>
        </Wrapper>
      </MainContainer>
      <BottomNavigation />
    </>
  );
}

export default PrivacyPolicy;

const MainContainer = styled.div`
  width: 100%;
  background: ${THEME_COLORS.light_200};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 20px 0;
  @media all and (max-width: 768px) {
    width: 100%;
    padding-bottom: 60px;
  }
`;
const Wrapper = styled.div`
  width: 57%;
  display: flex;
  flex-direction: column;
  @media all and (max-width: 1350px) {
    width: 65%;
  }
  @media all and (max-width: 1250px) {
    width: 70%;
  }
  @media all and (max-width: 1150px) {
    width: 75%;
  }
  @media all and (max-width: 1050px) {
    width: 80%;
  }
  @media all and (max-width: 950px) {
    width: 85%;
  }
  @media all and (max-width: 900px) {
    width: 90%;
  }
  @media all and (max-width: 780px) {
    padding-bottom: 50px;
  }
`;
const Heading = styled.h3`
  font-size: 20px;
  text-align: start;
  font-family: "DM_sans_medium";
  color: ${THEME_COLORS.black};
  line-height: 26px;
  margin-bottom: 1px;
`;
const Date = styled.label`
  display: inline-block;
  font-size: 12px;
  text-align: start;
  font-family: "DM_sans";
  color: ${THEME_COLORS.text_label};
  margin-bottom: 30px;
`;
const MainDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 56px;
  @media all and (max-width: 1280px) {
    justify-content: space-between;
  }
  @media all and (max-width: 980px) {
    flex-direction: column;
    gap: 22px;
  }
`;
const Left = styled.div`
  width: 100%;
  /* width: 56%;
  @media all and (max-width: 1280px) {
    width: 66%;
  }
  @media all and (max-width: 980px) {
    width: 100%;
  } */
`;
const SubHeading = styled.h4`
  font-size: 20px;
  text-align: start;
  font-family: "DM_sans_bold";
  color: ${THEME_COLORS.text_title};
  line-height: 26px;
  margin-bottom: 20px;
`;
const ContentDiv = styled.div``;
const Contents = styled.div`
  margin-bottom: 18px;
`;
const SmallHeading = styled.h5`
  font-size: 17px;
  text-align: start;
  font-family: "DM_sans_bold";
  color: ${THEME_COLORS.text_title};
  line-height: 26px;
  margin-bottom: 6px;
`;
const Para = styled.p`
  margin-bottom: 10px;
  font-size: 14px;
  text-align: start;
  font-family: "DM_sans";
  color: ${THEME_COLORS.text_para};
`;
const Purposes = styled.ul`
  list-style: disc;
  list-style-position: inside;
`;
const Purpose = styled.li``;
const Right = styled.div`
  width: 30%;
  border: 1px solid ${THEME_COLORS.light_400};
  filter: drop-shadow(18px 18px 24px rgba(7, 11, 36, 0.08));
  border-radius: 10px;
  padding: 12px 10px;
  @media all and (max-width: 980px) {
    width: 70%;
  }
  @media all and (max-width: 480px) {
    width: 100%;
  }
`;
const Label = styled.h6`
  font-size: 15px;
  font-family: "DM_sans";
  color: ${THEME_COLORS.black};
  margin-bottom: 6px;
`;
const Points = styled.label`
  display: inline-block;
  font-size: 14px;
  font-family: "DM_sans";
  color: ${THEME_COLORS.dark_300};
  margin-bottom: 14px;
  line-height: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #2c13eb;
  margin-bottom: 16px;
`;

import React, { useState } from "react";

// components
import BottomNavigation from "../../general/BottomNavigation";
import Waveform from "../../includes/chip/Waveform";
import VideoPlayer from "../../includes/chip/VideoPlayer";

// modal
import CategoryModal from "../../includes/modals/CategoryModal";

// package
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function Explore() {
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState("All");
  const [option, setOption] = useState("All");
  const [duration, setDuration] = useState(0);
  const [VideoDuration, setVideoDuration] = useState(0);

  // initializing subcategories data
  const Subcategories = [
    {
      id: 1,
      title: "All",
    },
    {
      id: 2,
      title: "Motivation",
    },
    {
      id: 3,
      title: "Concentration",
    },
    {
      id: 4,
      title: "Mental Wellness",
    },
    {
      id: 5,
      title: "Stress Relief",
    },
    {
      id: 6,
      title: "Addiction Therapy",
    },
    {
      id: 7,
      title: "Relationship",
    },
  ];
  const Data = [
    {
      id: 1,
      thumbnail: require("../../../assets/images/video-thumnail-1.png"),
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: "../../../assets/video/kannur.mp4",
      title:
        "CHANGE YOUR MINDSET | Powerful Motivational Speeches About Life | WAKE UP POSITIVE",
      creator: "Dr Sulthana Khan",
      duration: "00:35:24",
      view_count: "450 plays",
      category: "video",
      subcategory: "motivation",
    },
    {
      id: 2,
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: "../../../assets/mp3/music.mp3",
      title:
        "Successful people do what unsuccessful people are not willing to do",
      creator: "Dr Sulthana Khan",
      duration: "00:35:24",
      view_count: "450 plays",
      category: "podcast",
      subcategory: "motivation",
    },
    {
      id: 3,
      thumbnail: require("../../../assets/images/video-thumbnail-2.png"),
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: "../../../assets/video/video-1.mov",
      title:
        "Don’t be afraid to take risk in life | Entrepreneur talk with Sundar Pichai",
      creator: "Dr Arman Khan",
      duration: "00:30:24",
      view_count: "4500 plays",
      category: "video",
      subcategory: "Mental wellness",
    },

    {
      id: 4,
      thumbnail: require("../../../assets/images/video-thumnail-1.png"),
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: "../../../assets/video/video-1.mov",
      title:
        "CHANGE YOUR MINDSET | Powerful Motivational Speeches About Life | WAKE UP POSITIVE",
      creator: "Dr Sulthana Khan",
      duration: "10:30:24",
      view_count: "1050 plays",
      category: "video",
      subcategory: "relationship",
    },
    {
      id: 5,
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: "../../../assets/mp3/music.mp3",
      title:
        "The only limit to our realization of tomorrow will be our doubts of today.",
      creator: "Dr Arman Khan",
      duration: "00:30:24",
      view_count: "4500 plays",
      category: "podcast",
      subcategory: "motivation",
    },
    {
      id: 6,
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: "../../../assets/mp3/loon.mp3",
      title: "How to overcome Anxiety | Mental health video",
      creator: "Dr Jazzi Khan",
      duration: "00:30:24",
      view_count: "1050 plays",
      category: "podcast",
      subcategory: "relationship",
    },
    {
      id: 7,
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: require("../../../assets/images/article-focus.png"),
      title: "FOCUS ON YOURSELF NOT OTHERS - Tony Robbins",
      creator: "Dr Jacky Khan",
      category: "article",
      time_to_read: "5 mins read",
      subcategory: "stress relief",
    },
    {
      id: 8,
      profile_pic: require("../../../assets/images/dp.svg").default,
      src: require("../../../assets/images/video-thumnail-1.png"),
      title: "Hustle up",
      creator: "Dr James Khan",
      view_count: "1050",
      category: "images",
      subcategory: "addiction therapy",
    },
  ];

  // initializing article data
  const ArticleData = [
    {
      id: 1,
      title:
        "The future belongs to those who believe in the beauty of their dreams.",
      time_to_read: "3 mins to read",
      creator: "Dr Krishnaprasad",
      profile_pic: require("../../../assets/images/dp.svg").default,
      pic: require("../../../assets/images/neon.png"),
    },
    {
      id: 2,
      title: "The only way to do great work is to love what you do.",
      time_to_read: "7 mins to read",
      creator: "Anjali Sivadas",
      profile_pic: require("../../../assets/images/dp.svg").default,
      pic: require("../../../assets/images/cave.png"),
    },
    {
      id: 3,
      title: "Don't watch the clock; do what it does. Keep going.",
      time_to_read: "10 mins to read",
      creator: "George Jose",
      profile_pic: require("../../../assets/images/dp.svg").default,
      pic: require("../../../assets/images/timer.png"),
    },
    {
      id: 4,
      title: "FOCUS ON YOURSELF NOT OTHERS - Tony Robbins",
      time_to_read: "8 mins to read",
      creator: "Dr Jayasurya",
      profile_pic: require("../../../assets/images/dp.svg").default,
      pic: require("../../../assets/images/article-focus.png"),
    },
  ];

  return (
    <>
      {value === "All" && option === "All" && Data.length === 0 && (
        <div>Loading...</div>
      )}
      <MainContainer>
        <Content>
          <ExploreContent>
            <Heading>
              <Title>Explore</Title>
              {isOpen && (
                <CategoryModal
                  onClose={() => setOpen(false)}
                  setValue={setValue}
                />
              )}
              <Menu
                onClick={() => setOpen(true)}
                className={isOpen ? "selected" : ""}
              >
                {value}
                <DropDown>
                  <DropDownImage
                    src={
                      require("../../../assets/images/arrow-drop-down.svg")
                        .default
                    }
                  />
                </DropDown>
              </Menu>
            </Heading>
            <SubcategoriesData>
              {Subcategories.map((item) => {
                return (
                  <Options
                    key={item.id}
                    onClick={() => setOption(item.title)}
                    className={option === item.title && "selected"}
                  >
                    {item.title}
                  </Options>
                );
              })}
            </SubcategoriesData>
            <MainContent>
              {value === "All" &&
                option === "All" &&
                Data.map((item, index) => {
                  if (item.category === "video") {
                    return (
                      <CardContainer key={item.id}>
                        <VideoPlayer
                          item={item}
                          setVideoDuration={setVideoDuration}
                        />
                        <DescriptionContainer>
                          <ProfilePicContainer>
                            <Dp src={item?.profile_pic} alt="Profile picture" />
                          </ProfilePicContainer>
                          <DetailsContainer>
                            <Head>{item?.title}</Head>
                            <AboutContainer>
                              <Author>by {item?.creator}</Author>
                              <Duration>. {VideoDuration} .</Duration>
                              <ViewCount>{item?.view_count}</ViewCount>
                            </AboutContainer>
                          </DetailsContainer>
                        </DescriptionContainer>
                      </CardContainer>
                    );
                  } else if (item?.category === "podcast") {
                    return (
                      <CardContainer key={item.id}>
                        <Waveform item={item} setDuration={setDuration} />
                        <DescriptionContainer>
                          <ProfilePicContainer>
                            <Dp src={item?.profile_pic} alt="Profile picture" />
                          </ProfilePicContainer>
                          <DetailsContainer>
                            <Head>{item?.title}</Head>
                            <AboutContainer>
                              <Author>by {item?.creator}</Author>
                              <Duration>. {duration} .</Duration>
                              <ViewCount>{item?.view_count}</ViewCount>
                            </AboutContainer>
                          </DetailsContainer>
                        </DescriptionContainer>
                      </CardContainer>
                    );
                  } else if (item?.category === "article") {
                    return (
                      <CardContainer key={item?.id}>
                        <ProfileContainer>
                          <ProfilePic>
                            <Dp src={item?.profile_pic} alt="Profile picture" />
                          </ProfilePic>
                          <ArticleAuthor>{item?.creator}</ArticleAuthor>
                        </ProfileContainer>
                        <Detail>
                          <DetailContent>
                            <Head>{item?.title}</Head>
                            <TimeToRead>{item?.time_to_read}</TimeToRead>
                          </DetailContent>
                          <ArticleImageContainer>
                            <ArticleImage
                              src={item?.src}
                              alt="Article picture"
                            />
                          </ArticleImageContainer>
                        </Detail>
                      </CardContainer>
                    );
                  } else if (item?.category === "images") {
                    return (
                      <CardContainer key={item?.id}>
                        <ImageContainer>
                          <Image src={item?.src} alt="image" />
                        </ImageContainer>
                        <DescriptionContainer>
                          <ProfilePicContainer>
                            <Dp src={item?.profile_pic} alt="Profile picture" />
                          </ProfilePicContainer>
                          <DetailsContainer>
                            <Head>{item?.title}</Head>
                            <AboutContainer>
                              <Author>by {item?.creator}</Author>
                              <ViewCount>{item?.view_count} plays</ViewCount>
                            </AboutContainer>
                          </DetailsContainer>
                        </DescriptionContainer>
                      </CardContainer>
                    );
                  }
                })}
            </MainContent>
          </ExploreContent>
          <ArticleContentRight>
            <ArticleContainer>
              <ArticleTitle>Recent Articles</ArticleTitle>
              {ArticleData.map((item) => {
                return (
                  <CardContainer key={item?.id}>
                    <ProfileContainer>
                      <ProfilePic>
                        <Dp src={item?.profile_pic} alt="Profile picture" />
                      </ProfilePic>
                      <ArticleAuthor>{item?.creator}</ArticleAuthor>
                    </ProfileContainer>
                    <Detail>
                      <DetailContent>
                        <HeadText>{item?.title}</HeadText>
                        <TimeToRead>{item?.time_to_read}</TimeToRead>
                      </DetailContent>
                      <ArticleImageContainer className="article-image">
                        <ArticleImage src={item?.pic} alt="Article picture" />
                      </ArticleImageContainer>
                    </Detail>
                  </CardContainer>
                );
              })}
              <ViewButton>View all</ViewButton>
            </ArticleContainer>
          </ArticleContentRight>
        </Content>
      </MainContainer>
      <BottomNavigation />
    </>
  );
}
const MainContainer = styled.div`
  width: 100%;
  background: ${THEME_COLORS.light_200};
  height: calc(100vh - 70px);
  overflow-y: scroll;
`;
const Content = styled.div`
  padding: 50px 0 0px;
  width: 60%;
  margin: 0 auto;
  max-width: 1440px;
  display: flex;
  gap: 50px;
  @media all and (max-width: 1440px) {
    width: 65%;
  }
  @media all and (max-width: 1280px) {
    gap: 40px;
    width: 90%;
  }
  @media all and (max-width: 768px) {
    padding-bottom: 100px;
  }
`;
const ExploreContent = styled.div`
  width: 453px;
  position: relative;
  @media all and (max-width: 640px) {
    width: 100%;
  }
`;
const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  @media all and (max-width: 480px) {
    margin-bottom: 20px;
  }
`;

const Title = styled.h2`
  font-family: "DM_sans";
  font-size: 20px;
  width: 30%;
`;
const DropDown = styled.div`
  width: 10px;
`;
const DropDownImage = styled.img``;
const Menu = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 10px;
  color: ${THEME_COLORS.text_title};
  font-family: "DM_sans";
  padding: 6px 12px;
  width: fit-content;
  background: ${THEME_COLORS.white};
  border-radius: 8px;
  border: 1px solid ${THEME_COLORS.white};
  cursor: pointer;
  &.selected {
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    background: ${THEME_COLORS.light_200};
  }
`;
const SubcategoriesData = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 75%;
  margin-bottom: 50px;
  align-items: center;

  @media all and (max-width: 1440px) {
    width: 100%;
  }
  @media all and (max-width: 480px) {
    flex-wrap: unset;
    overflow-x: scroll;
    margin-bottom: 30px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Options = styled.div`
  font-size: 14px;
  font-family: "DM_sans";
  cursor: pointer;
  border: 2px solid ${THEME_COLORS.light_400};
  color: ${THEME_COLORS.text_title};
  background: ${THEME_COLORS.light_100};
  padding: 6px 12px;
  border-radius: 32px;
  white-space: nowrap;
  &.selected {
    border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    color: #000;
    background: ${THEME_COLORS.chips_blue_container};
  }
`;
const MainContent = styled.div``;
const CardContainer = styled.div`
  padding: 12px;
  border-radius: 16px;
  background: ${THEME_COLORS.white};
  margin-bottom: 20px;
  box-shadow: ${THEME_COLORS.elevation_user_u3};
`;
const DescriptionContainer = styled.div`
  display: flex;
  gap: 15px;
  @media all and (max-width: 360px) {
    gap: 12px;
  }
`;
const ProfilePicContainer = styled.div`
  width: 45px;
`;
const Dp = styled.img``;
const DetailsContainer = styled.div``;
const Head = styled.h2`
  font-family: "DM_sans";
  font-size: 16px;
  color: #000;
  max-width: 90%;
  @media all and (max-width: 480px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
const AboutContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const Author = styled.span`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-family: "DM_sans";
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 11px;
  }
`;
const Duration = styled.span`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-family: "DM_sans";
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 11px;
  }
`;
const ViewCount = styled.span`
  color: ${THEME_COLORS.text_para};
  font-size: 14px;
  font-family: "DM_sans";
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 11px;
  }
`;
const ProfileContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`;
const ProfilePic = styled.div`
  width: 20px;
`;
const ArticleAuthor = styled.div`
  font-family: "DM_sans";
  font-size: 14px;
`;
const ArticleImageContainer = styled.div`
  &.article-image {
    width: 50%;
  }
`;
const ArticleImage = styled.img``;
const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const DetailContent = styled.div``;

const TimeToRead = styled.span`
  font-size: 12px;
  color: ${THEME_COLORS.text_para};
  font-family: "DM_sans";
  display: block;
`;
const ImageContainer = styled.div`
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 20px;
`;
const Image = styled.img``;
const ArticleContentRight = styled.div`
  width: 35%;
  @media all and (max-width: 1440px) {
    width: 38%;
  }
  @media all and (max-width: 1080px) {
    display: none;
  }
`;
const ArticleContainer = styled.div`
  background: ${THEME_COLORS.light_200};
  padding: 12px;
  border-radius: 12px;
`;
const HeadText = styled.div`
  font-family: "DM_sans_medium";
  font-size: 16px;
  color: #000;
  max-width: 90%;
`;
const ArticleTitle = styled.h4`
  font-family: "DM_sans_medium";
  font-size: 16px;
  color: #000;
  margin-bottom: 20px;
`;
const ViewButton = styled.div`
  font-size: 14px;
  color: ${THEME_COLORS.dark_500};
  font-family: "DM_sans_medium";
  text-align: center;
  border: 2px solid ${THEME_COLORS.light_400};
  border-radius: 32px;
  background: ${THEME_COLORS.white};
  padding: 6px 0;
  cursor: pointer;
`;

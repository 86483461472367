import "./assets/css/style.css";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./components/routing/routers/MainRouter";
import Store from "./contexts/Store";

function App() {
    return (
        <BrowserRouter>
            <Store>
                <MainRouter />
            </Store>
        </BrowserRouter>
    );
}

export default App;

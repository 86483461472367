import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { THEME_COLORS } from "../../ThemeConfig";
import Header from "./Header";

export default function ComingSoon() {
  return (
    <MainContainer>
      <Header />
      <Container>
        <TopContent>
          <ImageContainer>
            <Image
              src={require("../../assets/images/coming-soon.svg").default}
            />
          </ImageContainer>
          <Content>
            <Title>We're working on something exciting!</Title>
            <Description>
              Get ready for a powerful addition to Mind Mitra, Designed to
              enhance your therapy experience.
            </Description>
            <Button to="/">
              <img
                src={require("../../assets/images/left-arrow.svg").default}
                alt="left-arrow"
              />
              Back To Home
            </Button>
          </Content>
        </TopContent>
        <BottomContainer>
          <BottomImageContainer className="left">
            <BottomImage
              src={require("../../assets/icons/coming-soon-leaf.svg").default}
              alt="Icon"
            />
          </BottomImageContainer>
          <MiddleContainer>
            <Text>Follow us on social media for updates</Text>
            <SocialIconsContainer>
              <SocialIconsSubContainer>
                <a href="https://www.facebook.com/" target="blank">
                  <SocailIconContainer>
                    <SocialIcon
                      src={require("../../assets/icons/facebook.svg").default}
                      alt="facebook"
                    />
                  </SocailIconContainer>
                </a>
              </SocialIconsSubContainer>
              <SocialIconsSubContainer>
                <a href="https://twitter.com/" target="blank">
                  <SocailIconContainer>
                    <SocialIcon
                      src={require("../../assets/icons/twitter.svg").default}
                      alt="twitter"
                    />
                  </SocailIconContainer>
                </a>
              </SocialIconsSubContainer>
              <SocialIconsSubContainer>
                <a href="https://www.instagram.com/" target="blank">
                  <SocailIconContainer>
                    <SocialIcon
                      src={require("../../assets/icons/instagram.svg").default}
                      alt="instagram"
                    />
                  </SocailIconContainer>
                </a>
              </SocialIconsSubContainer>
              <SocialIconsSubContainer>
                <a href="https://www.youtube.com/" target="blank">
                  <SocailIconContainer>
                    <SocialIcon
                      src={require("../../assets/icons/youtube.svg").default}
                      alt="youtube"
                    />
                  </SocailIconContainer>
                </a>
              </SocialIconsSubContainer>
            </SocialIconsContainer>
          </MiddleContainer>
          <BottomImageContainer className="right">
            <BottomImage
              src={require("../../assets/icons/coming-soon-leaf.svg").default}
              alt="Icon"
            />
          </BottomImageContainer>
        </BottomContainer>
      </Container>
    </MainContainer>
  );
}
const MainContainer = styled.div`
  height: 100vh !important;
`;
const Container = styled.div`
  padding: 100px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 110px;
`;
const TopContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  width: 600px;
  @media all and (max-width: 620px) {
    width: 90%;
  }
`;
const ImageContainer = styled.div``;
const Image = styled.img``;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
const Title = styled.h4`
  color: #3e3e53;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
const Description = styled.p`
  color: #aea8a8;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 80%;
`;
const Button = styled(Link)`
  display: inline-block;
  display: flex;
  gap: 10px;
  border: 2px solid ${THEME_COLORS.light_400};
  padding: 10px 20px;
  border-radius: 32px;
  font-size: 14px;
  font-family: "DM_sans_medium";
  color: ${THEME_COLORS.dark_500};
  @media all and (max-width: 480px) {
    padding: 8px 16px;
  }

  img {
    width: 7px;
  }
`;

const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const BottomImageContainer = styled.div`
  &.right {
    rotate: 270deg;
  }
`;
const BottomImage = styled.img``;
const MiddleContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  @media all and (max-width: 720px) {
    flex-direction: column;
    gap: 12px;
  }
`;
const Text = styled.span`
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  @media all and (max-width: 720px) {
    text-align: center;
  }
`;
const SocialIconsContainer = styled.ul`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  @media all and (max-width: 520px) {
    padding-bottom: 10px;
    gap: 10px;
  }
  @media all and (max-width: 420px) {
    padding-bottom: 50px;
  }
`;
const SocialIconsSubContainer = styled.li`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 500px;
  background: #fff;
  box-shadow: 2px 2px 16px 0px rgba(7, 11, 36, 0.06);
  width: 44px;
  height: 44px;
`;
const SocailIconContainer = styled.div``;
const SocialIcon = styled.img``;

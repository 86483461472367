import React, { useState, useRef } from "react";

// packages
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// Styles //
import styled from "styled-components";

// colors //
import { THEME_COLORS } from "../../../../ThemeConfig";

// Media-Images //
import brain from "../../../../assets/images/tick.svg";
import bird from "../../../../assets/images/birdmp3.svg";
import vibes from "../../../../assets/images/vibes.svg";
import rain from "../../../../assets/images/rain.svg";
import night from "../../../../assets/images/night.png";
import play from "../../../../assets/images/Play.svg";
import pause from "../../../../assets/images/Pause.svg";

// Media-songs //
import birdmp3 from "../../../../assets/mp3/bird.mp3";
import vibemp3 from "../../../../assets/mp3/vibe.mp3";
import rainmp3 from "../../../../assets/mp3/rain.mp3";
import nightmp3 from "../../../../assets/mp3/night.mp3";

// components //
import BottomNavigation from "../../../general/BottomNavigation";
import BreathStatus from "../../../includes/modals/BreathStatus";
import { useEffect } from "react";

function MeditateHome() {
    // breathing Counter //
    const [isPlaying, setIsPlaying] = useState(false);
    const [isduration, setIsDuration] = useState(30);
    const [key, setKey] = useState(0);
    // console.log(isduration, "==duration");

    const handleIsDurationChange = (e) => {
        setIsDuration(parseInt(e.target.value));
    };

    const handleBreathButtonClick = () => {
        setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    };

    // Breating Status Modal open //
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };
    useEffect(() => {
        if (!isPlaying) {
            setIsDuration(30);
        }
    }, [isPlaying, key]);

    // Audio player //
    const audioRef = useRef([]);
    const [Playing, setPlaying] = useState("Hello");

    // mapping for songs
    const [OptionsBox, setOptionsBox] = useState([
        {
            id: 1,
            image: bird,
            title: "Morning Birds",
            name: "By Rawman Jason",
            song: birdmp3,
            time: 0,
            duration: 0,
        },
        {
            id: 2,
            image: vibes,
            title: "Twilight Vibes",
            name: "By Seenej Trimol",
            song: vibemp3,
            time: 0,
            duration: 0,
        },
        {
            id: 3,
            image: rain,
            title: "Lofi Rain",
            name: "By Rawman Jason",
            song: rainmp3,
            time: 0,
            duration: 0,
        },
        {
            id: 4,
            image: night,
            title: "Middle of the Night",
            name: "By Rawman Jason",
            song: nightmp3,
            time: 0,
            duration: 0,
        },
    ]);

    // play and pause
    const handlePlayPause = (id, title) => {
        audioRef.current.map((el, index) => {
            if (index !== id) {
                el.pause();
            }
        });
        const audio = audioRef.current[id];
        if (audio.paused) {
            audio.play();
            setPlaying(title);
        } else {
            audio.pause();
            setPlaying("");
        }
    };

    // time //
    const handleTimeUpdate = (item, id) => {
        item.time = audioRef.current[id].currentTime;
        setOptionsBox([...OptionsBox]);
        if (item.time === item.duration) {
            item.time = 0;
            setOptionsBox([...OptionsBox]);
            setPlaying("");
        }
    };
    const handleDurationChange = (item, id) => {
        item.duration = audioRef.current[id].duration;
        setOptionsBox([...OptionsBox]);
    };
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    const Mp3Box = OptionsBox.map((box, index) => {
        return (
            <Mp3Div key={box.id}>
                <LeftImgDiv>
                    <Mp3Img src={box.image} alt="bird image" />
                </LeftImgDiv>
                <RightMp3Div>
                    <SongName>{box.title}</SongName>
                    <AudioContainer>
                        <audio
                            ref={(el) => (audioRef.current[index] = el)}
                            src={box.song}
                            onTimeUpdate={() => handleTimeUpdate(box, index)}
                            onLoadedMetadata={() =>
                                handleDurationChange(box, index)
                            }
                        />
                        <TimeContainer>
                            <Author>{box.name}</Author>
                            <Duration>{formatTime(box.time)}</Duration>
                        </TimeContainer>
                        <ControlsContainer>
                            <ProgressBar
                                value={(box.time / box.duration) * 100 || 0}
                                onChange={(e) => {
                                    let newTime =
                                        (e.target.value / 100) * box.duration;
                                    setOptionsBox([...OptionsBox]);
                                    audioRef.current[index].currentTime =
                                        newTime;
                                }}
                                onMouseDown={() => (
                                    audioRef.current[index].pause(),
                                    setPlaying("")
                                )}
                                onMouseUp={() => (
                                    audioRef.current[index].play(),
                                    setPlaying(box.title)
                                )}
                            />
                            <ControlButton>
                                {Playing === box.title ? (
                                    <Pause
                                        src={pause}
                                        alt="pause image"
                                        onClick={() => handlePlayPause(index)}
                                    />
                                ) : (
                                    <Play
                                        src={play}
                                        alt="play image"
                                        onClick={() =>
                                            handlePlayPause(index, box.title)
                                        }
                                    />
                                )}
                            </ControlButton>
                        </ControlsContainer>
                    </AudioContainer>
                </RightMp3Div>
            </Mp3Div>
        );
    });
    return (
        <>
            <MainContainer>
                <ContentDiv>
                    <Divleft>
                        <Top>
                            <Heading>Meditate</Heading>
                            <HeadingSpan>
                                <BrainImg src={brain} />
                            </HeadingSpan>
                        </Top>
                        <GreenDiv>
                            <Greenh5>
                                Take a moment to meditate and <br /> calm your
                                mind.
                            </Greenh5>
                            <GreenPara>
                                You are stronger than you think
                            </GreenPara>
                            <GreenBtn onClick={handleButtonClick}>
                                View Benefits
                            </GreenBtn>
                            {isModalOpen && (
                                <BreathStatus
                                    closeModal={() => setIsModalOpen(false)}
                                />
                            )}
                        </GreenDiv>
                        <Counter>
                            <svg
                                style={{
                                    position: "absolute",
                                    left: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient
                                        id="countdown"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="0"
                                    >
                                        <stop
                                            offset="36%"
                                            stopColor="#21A789"
                                        />
                                        <stop
                                            offset="100%"
                                            stopColor="rgba(29, 166, 135, 0.36)"
                                        />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <CountdownCircleTimer
                                isPlaying={isPlaying}
                                duration={isduration}
                                initialRemainingTime={isduration}
                                colors={"url(#countdown)"}
                                strokeWidth={32}
                                size={280}
                                strokeLinecap={"square"}
                                trailColor={THEME_COLORS.white}
                                onComplete={() => {
                                    // console.log("onComplete triggered");
                                    setKey((prevKey) => prevKey + 1); // Increment the key causing the component to remount and reset
                                    setIsPlaying(false); // Update isPlaying to false to pause the timer
                                    setIsDuration(30); // Update isDuration to 30 seconds
                                    return [false, 0]; // Don't auto-restart the timer
                                }}
                            >
                                {({ remainingTime }) => (
                                    <div className="timer">
                                        <div
                                            className="value-container"
                                            style={{
                                                border: "10px solid #ECE7E7",
                                                borderRadius: "50%",
                                                padding: "80px 54px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <div
                                                className="value timer-text"
                                                style={{
                                                    fontFamily: "DM_sans_bold",
                                                    fontSize: "32px",
                                                    minWidth: "100px",
                                                }}
                                            >
                                                00:
                                                {remainingTime < 10
                                                    ? `0${remainingTime}`
                                                    : remainingTime}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </CountdownCircleTimer>
                        </Counter>
                        <BtnDiv>
                            <TimeBtn>
                                <Time>
                                    <select
                                        value={isduration}
                                        onChange={handleIsDurationChange}
                                    >
                                        <option value={30}>30 sec</option>
                                        <option value={50}>50 sec</option>
                                        <option value={60}>60 sec</option>
                                    </select>
                                </Time>
                            </TimeBtn>
                            <BreathButton
                                // onClick={() => setIsPlaying(true)}
                                onClick={handleBreathButtonClick}
                            >
                                {isPlaying ? "Stop" : "Breath In"}
                            </BreathButton>
                        </BtnDiv>
                    </Divleft>
                    <DivRight>
                        <RightHeading>Calm with music</RightHeading>
                        {Mp3Box}
                    </DivRight>
                </ContentDiv>
            </MainContainer>
            <BottomNavigation />
        </>
    );
}
export default MeditateHome;

const MainContainer = styled.div`
    width: 100%;
    height: calc(100vh - 70px);
    background: ${THEME_COLORS.light_200};
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media all and (max-width: 768px) {
        width: 100%;
    }
`;
const ContentDiv = styled.div`
    width: 70%;
    display: flex;
    gap: 30px;
    @media all and (max-width: 1280px) {
        width: 80%;
    }
    @media all and (max-width: 1080px) {
        width: 92%;
        justify-content: center;
    }
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
        overflow-y: auto;
        width: 100%;
        display: flex;
        align-items: center;
    }
`;
const Divleft = styled.div`
    width: 50%;
    @media all and (max-width: 980px) {
        width: 90%;
    }
`;
const Top = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-bottom: 44px;
    @media all and (max-width: 480px) {
        margin-bottom: 22px;
    }
`;
const Heading = styled.h5`
    font-size: 20px;
    font-family: "DM_sans";
`;
const HeadingSpan = styled.span`
    display: inline-block;
`;
const BrainImg = styled.img`
    width: 100%;
    display: block;
`;
const GreenDiv = styled.div`
    display: block;
    padding: 14px;
    background: ${THEME_COLORS.chips_green_container};
    border: 1px solid ${THEME_COLORS.chips_green_on_container};
    border-radius: 13px;
    margin-bottom: 30px;
`;
const Greenh5 = styled.h5`
    text-align: center;
    font-size: 16px;
    font-family: "DM_sans";
    line-height: 18px;
    margin-bottom: 12px;
`;
const GreenPara = styled.p`
    text-align: center;
    font-size: 14px;
    font-family: "DM_sans";
    margin-bottom: 12px;
    color: ${THEME_COLORS.dark_300};
`;
const GreenBtn = styled.button`
    display: block;
    margin: 0 auto;
    border-radius: 16px;
    font-size: 14px;
    font-family: "DM_sans";
    padding: 6px 0px;
    width: 110px;
    cursor: pointer;
    background: ${THEME_COLORS.white};
    color: ${THEME_COLORS.chips_green_on_container};
`;
const Counter = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    @media all and (max-width: 480px) {
        margin-bottom: 30px;
    }
`;
const BtnDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: 980px) {
        justify-content: space-evenly;
    }
    @media all and (max-width: 360px) {
        justify-content: space-between;
    }
`;
const TimeBtn = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 20px;
    width: 200px;
    height: 40px;
    border: 2px solid ${THEME_COLORS.light_400};
    @media all and (max-width: 1440px) {
        width: 180px;
    }
    @media all and (max-width: 1280px) {
        width: 160px;
    }
    @media all and (max-width: 980px) {
        width: 190px;
    }
    @media all and (max-width: 768px) {
        width: 200px;
    }
    @media all and (max-width: 480px) {
        width: 140px;
    }
`;
const Time = styled.label`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 16px;
    line-height: 20px;
`;
const BreathButton = styled.div`
    background: ${THEME_COLORS.secondary_gradient};
    color: ${THEME_COLORS.white};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border-radius: 20px;
    width: 200px;
    height: 40px;
    cursor: pointer;
    @media all and (max-width: 1440px) {
        width: 180px;
    }
    @media all and (max-width: 1280px) {
        width: 160px;
    }
    @media all and (max-width: 980px) {
        width: 190px;
    }
    @media all and (max-width: 768px) {
        width: 200px;
    }
    @media all and (max-width: 480px) {
        width: 140px;
    }
`;
const DivRight = styled.div`
    width: 46%;
    height: fit-content;
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    padding: 12px;
    background: ${THEME_COLORS.light_200};
    border-radius: 12px;

    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 120px;
    }
`;
const RightHeading = styled.h6`
    font-family: "DM_sans";
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
`;
const Mp3Div = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    background: ${THEME_COLORS.light_200};
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    border-radius: 12px;
    padding: 6px;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 30px;
    }
`;
const LeftImgDiv = styled.span`
    display: inline-block;
    width: 80px;
`;
const Mp3Img = styled.img`
    display: block;
    width: 100%;
`;
const RightMp3Div = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
`;
const SongName = styled.h4`
    font-family: "DM_sans";
    font-size: 16px;
    margin-bottom: 6px;
    width: 100%;
    color: ${THEME_COLORS.text_title};
`;
const AudioContainer = styled.div`
    width: 100%;
`;
const ControlsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    gap: 8px;
`;
const ControlButton = styled.button`
    cursor: pointer;
`;
const ProgressBar = styled.input.attrs({
    type: "range",
    min: 0,
    max: 100,
    step: 0.1,
})`
    width: 100%;
    height: 6px;
    border-radius: 5px;
    -webkit-appearance: none;
    background-color: ${THEME_COLORS.chips_green_container};
    outline: none;
    margin: 0;
    overflow: hidden;
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 14px;
        height: 10px;
        border-radius: 50%;
        background-color: ${THEME_COLORS.chips_green_on_container};
    }
    &::-moz-range-thumb {
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
`;
const TimeContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const Duration = styled.label`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.text_label};
`;
const Author = styled.label`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.text_label};
`;
const Pause = styled.img`
    display: block;
    width: 26px;
`;
const Play = styled.img`
    display: block;
    width: 26px;
`;

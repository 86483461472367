import React from "react";

// packages
import styled from "styled-components";
import { Link } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function CancelButton({ onClose, path, style }) {
    const handleClick = () => {
        if (typeof onClose === "function") {
            onClose();
        }
    };

    return (
        <CancelBtn onClick={() => handleClick()} to={path} style={style}>
            <CloseSvg>
                <CloseSvgImg
                    src={require("../../../assets/images/close.svg").default}
                    alt="cancel-button"
                />
            </CloseSvg>
            <Cancel>Cancel</Cancel>
        </CancelBtn>
    );
}
const CancelBtn = styled(Link)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    background: ${THEME_COLORS.white};
    border-radius: 32px;
    cursor: pointer;
    font-family: "DM_sans_medium";
`;
const CloseSvg = styled.div``;
const CloseSvgImg = styled.img``;
const Cancel = styled.span`
    font-size: 14px;
    font-family: "DM_sans_medium";

    color: ${THEME_COLORS.chips_green_on_container};
    margin-left: 8px;
`;

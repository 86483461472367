export const THEME_COLORS = {
  //Main
  primary: "#00A79D",
  secondary: "#2B3990",
  secondary_container: "#484859",
  tertiary: "#F8F9FD",
  container: "#13244E",
  on_container: "#354A80",
  divider: "#F5F5F5",
  white: "#FFFF",
  black: "#000",
  border_blue: "#384bb8",
  menu_blue: "#F0F5FF",

    //text color
    text_title: "#3E3E53",
    text_para: "#818181",
    text_label: "#AEA8A8",
    wireframe_500: "#7A838A",
    wireframe_400: "#B4BCC7",

  //background
  light_100: "#FAFAFA",
  breath_model: "#0000007f",
  //background
  light_200: "#F4F4F4",
  light_300: "#E2E2E2",
  light_400: "#D3D3D3",

    dark_200: "#AAAAAA",
    dark_300: "#969696",
    dark_400: "#686868",
    dark_500: "#333333",

    //button color
    chips_blue_container: "#E8EFFE", //blue
    chips_blue_on_container: "#2B3990", //blue
    chips_blue_on_container_2: "#366EE3", //blue

    chips_green_container: "#E0F8F2", //green
    chips_green_on_container: "#1DA687", //green

    chips_orange_container: "#F8EDE0", //orange
    chips_orange_on_container: "#EB892F", //orange

    chips_red_container: "#FFE9E9", //red
    chips_red_on_container: "#FE6666", //red

    chips_violet_container: "#FFEAFF", //violet
    chips_violet_on_container: "#E24AE2", //violet

    //box shadow
    elevation_user_u1: "10px 12px 48px rgba(7, 11, 40, 0.25)",
    elevation_user_u2: "27px 27px 48px rgba(7, 11, 36, 0.1)",
    elevation_user_u3: "18px 18px 24px rgba(7, 11, 36, 0.08)",
    elevation_user_u4: " 2px 2px 16px rgba(7, 11, 36, 0.06)",
    elevation_expert_e1: "0px 5px 6px rgba(0, 0, 0, 0.03)",
    elevation_expert_e2: "10px 12px 48px rgba(0, 0, 0, 0.25)",
    elevation_expert_e3: "0px 12px 32px rgba(0, 0, 0, 0.08)",
    elevation_expert_e4: "0px 12px 12px rgba(0, 0, 0, 0.04)",
    elevation_expert_e5: "0px 0px 0px 2px rgba(54, 110, 227, 0.19)",
    elevation_expert_e6: "0px 0px 0px 2px rgba(151, 71, 255, 0.2)",
    elevation_expert_e7: "rgba(0, 0, 0, 0.4)",

    //gradient
    gradient_1: "linear-gradient(269.16deg, #366EE3 10.2%, rgba(54, 110, 227, 0.49) 97.43%)",
    secondary_gradient: " linear-gradient(310.87deg, #2B3990 11.44%, #4F67F8 87.61%)",
    gradient_2: " linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #E8EFFE ",

    // modal_background
    modal_bg: "rgba(0, 0, 0, 0.3)",

    // background of sidebar and header
    light_green:"#E6EFF0"
};

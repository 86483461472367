import React, { useContext, useState } from "react";

// package
import styled, { keyframes } from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// store
import { Context } from "../../../contexts/Store";

// axios
import { PatientConfig } from "../../../axiosConfig";

export default function EditProfileModal({ onClose }) {
    const { state, dispatch } = useContext(Context);

    //  The error and phoneError state variables store whether the name or phone number fields are blank.
    const [error, setError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    // The phone state variable stores the user's phone number.
    const [phone, setPhone] = useState(state.user_details.phone_number || "");

    // The isFocused state variable stores the name or phone number field that is currently focused.
    const [isFocused, setIsFocused] = useState("");

    // The name state variable stores the user's name.
    const [name, setName] = useState(state.user_details.user_name || "");

    //   handle alphabetic characters in phone number
    const handleKeyPress = (e) => {
        const charCode = e.which ? e.which : e.keyCode;
        // Allow only numeric characters (0-9) or the backspace key (charCode 8)
        if (charCode < 48 || charCode > 57) {
            e.preventDefault();
        }
    };

    let accessToken = state.user_details.access_token;

    // error  validation, dispatching data and navigation
    const handleSubmit = () => {
        if (name === "") {
            setError(true);
        }
        if (phone === "") {
            setPhoneError(true);
        } else if (phone.length < 10) {
            setPhoneError(true);
        } else {
            setError(false);
            setPhoneError(false);
            PatientConfig.patch(
                "api/v1/patients/patient/profile-edit/",
                {
                    full_name: name,
                    phone: phone,
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
                .then((response) => {
                    // console.log(response.data.data.message);
                    dispatch({
                        type: "UPDATE_USER",
                        user_details: {
                            user_name: name,
                            phone_number: phone,
                        },
                    });
                    // Handle success as needed
                })
                .catch((error) => {
                    // console.error("An error occurred:", error);
                    // Handle errors as needed
                });
            onClose();
        }
    };

    return (
        <Modal onClick={() => onClose()}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <Card>
                    <Head>Edit Profile</Head>
                    <NameContainer
                        className={
                            isFocused === "first-name-focus"
                                ? "input-focus"
                                : ""
                        }
                        onFocus={() => {
                            setIsFocused("first-name-focus");
                        }}
                        onBlur={() => {
                            setIsFocused("");
                            setError(name === "");
                        }}
                    >
                        <NameLabel>Name</NameLabel>
                        <Name
                            type="text"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setError(e.target.value === "");
                            }}
                        />
                        <ErrorMessage className={error ? "active" : ""}>
                            Enter your name
                        </ErrorMessage>
                    </NameContainer>
                    <PhoneContainer
                        className={
                            isFocused === "phone-focus" ? "phone-focus" : ""
                        }
                        onFocus={() => {
                            setIsFocused("phone-focus");
                        }}
                        onBlur={() => {
                            setIsFocused("");
                            setPhoneError(phone === "");
                        }}
                    >
                        <PhoneNumber
                            type="tel"
                            value={phone}
                            maxLength={10}
                            placeholder="Phone Number"
                            onKeyPress={handleKeyPress}
                            onChange={(e) => {
                                setPhone(e.target.value);
                            }}
                        />
                    </PhoneContainer>
                    <PhoneErrorMessage className={phoneError ? "active" : ""}>
                        {phone.length < 10 &&
                            !phone == "" &&
                            "Phone number must be 10 digits."}
                        {phone === "" && "Enter your phone number"}
                    </PhoneErrorMessage>
                    <NavButtons>
                        <Cancel onClick={() => onClose()}>Cancel</Cancel>
                        <SaveButton onClick={handleSubmit}>Save</SaveButton>
                    </NavButtons>
                </Card>
            </ModalContent>
        </Modal>
    );
}
const popup = keyframes`
    0%{
        scale: 0;
    }
    100%{       
        scale: 1;
    }
`;
const Modal = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${THEME_COLORS.modal_bg};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 303;
`;
const ModalContent = styled.div`
    border-radius: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    padding-top: 6px;
    width: 450px;
    animation: ${popup} 0.3s ease-in-out;
    @media all and (max-width: 480px) {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        animation-name: modal;
        animation-duration: 0.3s;
        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
`;
const Card = styled.div`
    border-radius: 24px;
    background: ${THEME_COLORS.white};
    padding: 32px;
    position: relative;
    @media all and (max-width: 480px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const Head = styled.h3`
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    font-size: 20px;
    margin-bottom: 20px;
`;

const NameContainer = styled.div`
    background: ${THEME_COLORS.light_200};
    border-radius: 25px;
    padding: 6px 24px;
    margin-bottom: 30px;
    border: 2px solid ${THEME_COLORS.light_200};
    &.input-focus {
        background: ${THEME_COLORS.white};
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const ErrorMessage = styled.span`
    display: none;
    &.active {
        position: absolute;
        color: red;
        font-size: 12px;
        display: block;
        bottom: 158px;
    }
`;
const NameLabel = styled.span`
    display: block;
    color: ${THEME_COLORS.text_para};
    font-size: 12px;
    font-family: "DM_Sans";
`;
const Name = styled.input`
    font-size: 12px;
    font-family: "DM_sans_medium";
    width: 100%;
`;
const PhoneContainer = styled.div`
    background: ${THEME_COLORS.light_200};
    border-radius: 25px;
    padding: 14px 24px;
    border: 2px solid ${THEME_COLORS.light_200};
    margin-bottom: 30px;
    &.phone-focus {
        background: ${THEME_COLORS.white};
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const PhoneNumber = styled.input`
    font-size: 16px;
    font-family: "DM_sans";
`;
const PhoneErrorMessage = styled.span`
    display: none;
    &.active {
        position: absolute;
        color: red;
        font-size: 12px;
        display: block;
        bottom: 80px;
        left: 60px;
    }
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Cancel = styled.div`
    border-radius: 32px;
    padding: 8px 20px;
    border: 2px solid ${THEME_COLORS.light_400};
    color: ${THEME_COLORS.dark_500};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;
const SaveButton = styled.div`
    border-radius: 32px;
    padding: 10px 20px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.secondary_gradient};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;

import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../../screens/loginscreens/Login";

export default function AuthRouter() {
  return (
    <Routes>
      <Route path="/">
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Login />} />
        <Route path="/enterotp" element={<Login />} />
        <Route path="/getstarted" element={<Login />} />
        <Route path="/emergency" element={<Login />} />
        <Route path="/emergency-contact" element={<Login />} />
      </Route>
    </Routes>
  );
}

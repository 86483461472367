import React, { useState } from "react";

// packages
import styled from "styled-components";
import moment from "moment";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";
import ReactChart from "../../includes/chip/ReactChart";
import BottomNavigation from "../../general/BottomNavigation";

export default function Statistics() {
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(7, "days"));
    const [secondStartDate, setSecondStartDate] = useState(moment());
    const [secondEndDate, setSecondEndDate] = useState(moment().add(7, "days"));

    // rendering next 7 days
    const handleNextWeek = (isSecond) => {
        const dateElement = document.getElementById(
            isSecond ? "MedateElement" : "dateElement"
        );

        dateElement.classList.add("transition-right");

        setTimeout(() => {
            if (isSecond) {
                const newStartDate = secondStartDate.clone().add(7, "days");
                const newEndDate = secondEndDate.clone().add(7, "days");
                setSecondStartDate(newStartDate);
                setSecondEndDate(newEndDate);
            } else {
                const newStartDate = startDate.clone().add(7, "days");
                const newEndDate = endDate.clone().add(7, "days");
                setStartDate(newStartDate);
                setEndDate(newEndDate);
            }
            dateElement.classList.remove("transition-right");
        }, 500);
    };

    // rendering previous 7 days
    const handlePreviousWeek = (isSecond) => {
        const dateElement = document.getElementById(
            isSecond ? "MedateElement" : "dateElement"
        );

        dateElement.classList.add("transition-left");

        setTimeout(() => {
            if (isSecond) {
                const newStartDate = secondStartDate
                    .clone()
                    .subtract(7, "days");
                const newEndDate = secondEndDate.clone().subtract(7, "days");
                setSecondStartDate(newStartDate);
                setSecondEndDate(newEndDate);
            } else {
                const newStartDate = startDate.clone().subtract(7, "days");
                const newEndDate = endDate.clone().subtract(7, "days");
                setStartDate(newStartDate);
                setEndDate(newEndDate);
            }
            dateElement.classList.remove("transition-left");
        }, 500);
    };

    // format date to desired form
    const formatDate = (date) => {
        return (
            date.format("D") + "-" + date.clone().add(6, "days").format("D MMM")
        );
    };

    // emoji images data initialization
    const smiley = [
        {
            id: 1,
            pic: require("../../../assets/images/sad-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            day: "S",
        },
        {
            id: 2,
            pic: require("../../../assets/images/neutral-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            day: "M",
        },
        {
            id: 3,
            pic: require("../../../assets/images/smile-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            day: "T",
        },
        {
            id: 4,
            pic: require("../../../assets/images/happy-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            day: "W",
        },
        {
            id: 5,
            pic: require("../../../assets/images/lol-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            day: "T",
        },
        {
            id: 6,
            pic: require("../../../assets/images/lol-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            day: "F",
        },
        {
            id: 7,
            pic: require("../../../assets/images/lol-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            day: "S",
        },
    ];
    return (
        <>
            <Content>
                <ContentContainer>
                    <Heading>Statistics 👏🏻</Heading>
                    <SessionContainer>
                        <SessionCountContainer>
                            <SessionCountImageContainer>
                                <SessionCountImage
                                    src={
                                        require("../../../assets/images/session-count-img.svg")
                                            .default
                                    }
                                    alt="calender image"
                                />
                            </SessionCountImageContainer>
                            <SessionCountDiv>
                                <CountContainer>
                                    <SessionCount>32</SessionCount>
                                    <RightArrow
                                        src={
                                            require("../../../assets/images/right.svg")
                                                .default
                                        }
                                        alt="right arrow"
                                    />
                                </CountContainer>
                                <Title>Sessions</Title>
                            </SessionCountDiv>
                        </SessionCountContainer>
                        <SessionCountContainer>
                            <SessionCountImageContainer>
                                <SessionCountImage
                                    src={
                                        require("../../../assets/images/case-report.svg")
                                            .default
                                    }
                                    alt="case report image"
                                />
                            </SessionCountImageContainer>
                            <SessionCountDiv>
                                <CountContainer>
                                    <SessionCount>20</SessionCount>
                                    <RightArrow
                                        src={
                                            require("../../../assets/images/right.svg")
                                                .default
                                        }
                                        alt="right arrow"
                                    />
                                </CountContainer>
                                <Title>Case Report</Title>
                            </SessionCountDiv>
                        </SessionCountContainer>
                    </SessionContainer>
                    <EmojiContainer>
                        <EmojiIcon>
                            <Emoji
                                src={
                                    require("../../../assets/images/lol-emoji.svg")
                                        .default
                                }
                            />
                        </EmojiIcon>
                        <EmojiTitle>Blissful Day</EmojiTitle>
                        <EmojiDescription>
                            You are happy most of the week.
                        </EmojiDescription>
                        <WeekEmojiContainer>
                            <SmileyContainer>
                                {smiley.map((item) => {
                                    return (
                                        <>
                                            <SmileyDiv key={item.id}>
                                                <SmileyImageContainer>
                                                    <SmileyImage
                                                        src={item?.pic}
                                                        alt="emoji"
                                                    />
                                                </SmileyImageContainer>
                                                <WeekDay>{item?.day}</WeekDay>
                                            </SmileyDiv>
                                        </>
                                    );
                                })}
                            </SmileyContainer>
                        </WeekEmojiContainer>
                        <WeekDate>
                            <ArrowImageContainer
                                onClick={() => handlePreviousWeek(false)}
                            >
                                <ArrowImage
                                    src={
                                        require("../../../assets/images/left-arrow-green.svg")
                                            .default
                                    }
                                />
                            </ArrowImageContainer>
                            <Date id="dateElement">
                                {formatDate(startDate)}
                            </Date>
                            <ArrowImageContainer
                                onClick={() => handleNextWeek(false)}
                            >
                                <ArrowImage
                                    src={
                                        require("../../../assets/images/right-arrow-green.svg")
                                            .default
                                    }
                                />
                            </ArrowImageContainer>
                        </WeekDate>
                    </EmojiContainer>
                    <MeditationContainer>
                        <MeditationTitle>
                            Average Meditation Time
                        </MeditationTitle>
                        <MeditationStatus>
                            <StatusTitle>Meditation Status</StatusTitle>
                            <WeekGraphDate>
                                <ArrowImageContainer
                                    onClick={() => handlePreviousWeek(true)}
                                >
                                    <ArrowImage
                                        src={
                                            require("../../../assets/images/left-arrow-green.svg")
                                                .default
                                        }
                                    />
                                </ArrowImageContainer>
                                <Date id="MedateElement">
                                    {formatDate(secondStartDate)}
                                </Date>
                                <ArrowImageContainer
                                    onClick={() => handleNextWeek(true)}
                                >
                                    <ArrowImage
                                        src={
                                            require("../../../assets/images/right-arrow-green.svg")
                                                .default
                                        }
                                    />
                                </ArrowImageContainer>
                            </WeekGraphDate>
                        </MeditationStatus>
                        <GraphContainer>
                            <ReactChart />
                        </GraphContainer>
                    </MeditationContainer>
                </ContentContainer>
            <BottomNavigation />
            </Content>
        </>
    );
}

const Content = styled.div`
    background: ${THEME_COLORS.light_100};
    width: 100%;
    height: 100%;
    overflow-y: scroll;

`;
const ContentContainer = styled.div`
    padding: 30px 0;
    width: 30%;
    max-width: 1440px;
    margin: 0 auto;
    @media all and (max-width: 1440px) {
        width: 35%;
    }
    @media all and (max-width: 1280px) {
        width: 45%;
    }
    @media all and (max-width: 1080px) {
        width: 55%;
    }
    @media all and (max-width: 980px) {
        width: 65%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
        padding-bottom: 120px;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;
const Heading = styled.h4`
    font-size: 20px;
    font-family: "DM_sans";
    margin-bottom: 30px;
`;
const SessionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
`;

const SessionCountContainer = styled.div`
    display: flex;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_300};
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    gap: 10px;
    width: 48%;
    cursor: pointer;
`;
const SessionCountImageContainer = styled.div`
    width: 45px;
`;
const SessionCountImage = styled.img``;
const SessionCountDiv = styled.div`
    width: 100%;
`;
const CountContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const SessionCount = styled.span`
    display: inline-block;
    font-size: 16px;
    font-family: "DM_sans_bold";
`;
const RightArrow = styled.img`
    width: 5px;
`;
const Title = styled.h6`
    color: ${THEME_COLORS.text_para};
    font-size: 14px;
`;
const EmojiContainer = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    background: ${THEME_COLORS.white};
    border-radius: 16px;
    padding: 27px 50px;
    margin-bottom: 30px;
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    @media all and (max-width: 480px) {
        padding: 25px;
    }
`;
const EmojiIcon = styled.div`
    width: 84px;
    margin: 0 auto 30px auto;
`;
const Emoji = styled.img``;
const EmojiTitle = styled.h3`
    font-family: "DM_sans_bold";
    color: ${THEME_COLORS.chips_green_on_container};
    text-align: center;
    margin-bottom: 5px;
`;
const EmojiDescription = styled.span`
    display: block;
    color: ${THEME_COLORS.text_title};
    font-size: 14px;
    font-family: "DM_sans";
    text-align: center;
    margin-bottom: 30px;
`;
const WeekEmojiContainer = styled.div``;
const SmileyContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const SmileyImageContainer = styled.div`
    width: 28px;
    margin-bottom: 20px;
`;
const SmileyDiv = styled.div`
    text-align: center;
    margin-bottom: 30px;
`;
const SmileyImage = styled.img``;

const WeekDay = styled.span`
    color: ${THEME_COLORS.text_label};
    font-family: "DM_sans";
    font-size: 14px;
    display: inline-block;
`;
const WeekDate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 0 auto;
`;
const ArrowImageContainer = styled.div`
    width: 7px;
    cursor: pointer;
`;
const ArrowImage = styled.img``;
const Date = styled.span`
    display: inline-block;
    font-size: 14px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_green_on_container};
`;
const MeditationContainer = styled.div`
    border: 1px solid ${THEME_COLORS.light_300};
    background: ${THEME_COLORS.white};
    border-radius: 16px;
    padding: 27px;
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    @media all and (max-width: 480px) {
        padding: 20px;
    }
`;
const MeditationTitle = styled.h4`
    font-size: 20px;
    font-family: "DM_sans_medium";
    text-align: center;
    margin-bottom: 30px;
`;
const MeditationStatus = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const StatusTitle = styled.h4`
    font-size: 14px;
    font-family: "DM_sans_medium";
`;
const WeekGraphDate = styled.div`
    display: flex;
    width: 35%;
    justify-content: space-between;
    align-items: center;
`;

const GraphContainer = styled.div`
    padding: 16px 14px;
    background: ${THEME_COLORS.chips_green_container};
    border-radius: 16px;
`;

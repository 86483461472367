import React, { Suspense, useContext, useEffect, useState } from "react";

// packages
import { Routes, Route } from "react-router-dom";
import Lottie from "lottie-react";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";

// store
import { Context } from "../../../contexts/Store";

// Route
import AuthRoute from "../routes/AuthRoute";
import PrivateRoute from "../routes/PrivateRoute";
import AuthRouter from "./AuthRouter";
import AppRouter from "./AppRouter";

export default function MainRouter() {
  const [isLoading, setLoading] = useState(true);
  const { dispatch } = useContext(Context);

  async function fetchUserData() {
    let user_details = localStorage.getItem("user_details");
    // if (!user_details) {
    // 	localStorage.setItem("user_details", JSON.stringify(user_details));
    // 	user_details = localStorage.getItem("user_details");
    // }
    let user_details_stored = JSON.parse(user_details);
    dispatch({
      type: "UPDATE_USER",
      user_details: user_details_stored,
    });
    setTimeout(() => {
      setLoading(false);
    }, 50);
  }

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading ? (
    <Lottie
      animationData={LoaderLottie}
      style={{
        height: "100vh",
        width: 150,
        margin: "auto",
      }}
    />
  ) : (
    // <h1>loading...</h1>
    <Suspense fallback={"Loading"}>
      <Routes>
        <Route
          path="auth/*"
          element={
            <AuthRoute>
              <AuthRouter />
            </AuthRoute>
          }
        />
        <Route
          path="/*"
          element={
              <AppRouter />
          }
        />
        {/* <Route exact path="*" element={<h1>error...</h1>} /> */}
      </Routes>
    </Suspense>
  );
}

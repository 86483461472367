import React, { useContext, useEffect, useState } from "react";

// Packages //
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// Style //
import styled from "styled-components";

// colors //
import { THEME_COLORS } from "../../ThemeConfig";

// Media-Images //
import more from "../../assets/images/more-green.svg";
import home from "../../assets/images/home-green.svg";
import explore from "../../assets/images/explore-green.svg";
import meditate from "../../assets/images/meditate-green.svg";
import stats from "../../assets/images/stats-green.svg";
import account from "../../assets/images/account-green.svg";
import more_white from "../../assets/images/more-white.svg";
import home_white from "../../assets/images/home-white.svg";
import explore_white from "../../assets/images/explore-white.svg";
import meditate_white from "../../assets/images/meditate-white.svg";
import stats_white from "../../assets/images/stats-white.svg";
import account_white from "../../assets/images/account-white.svg";
import logoutIcon from "../../assets/images/logout-green.svg";
import logoutWhiteIcon from "../../assets/images/logout-white.svg";

// components
import MoreModal from "../includes/modals/MoreModal";
import LogoutModal from "../includes/modals/LogoutModal";
import PrivateRoute from "../routing/routes/PrivateRoute";
import { Context } from "../../contexts/Store";

function Sidebar() {
    // State variables
    const [activeItem, setActiveItem] = useState(""); // Currently active sidebar item
    const { pathname } = useLocation(); // Get the current pathname
    const [isOpen, setOpen] = useState(""); // Open state for the more modal
    const [modalOpen, setModalOpen] = useState(""); // Open state for the logout modal
    const [isHovered, setIsHovered] = useState(""); // State variable to track the hovered item
    const { state, dispatch } = useContext(Context);
    const auth = state.user_details.is_verified;

    const navigate = useNavigate();
    let id = state.session_details.id;

    // Sidebar items
    const SIDEBAR_ITEMS = [
        { id: "home", icon: home, text: "Home", path: "/", image: home_white },
        // {
        //   id: "explore",
        //   icon: explore,
        //   text: "Explore",
        //   path: "/explore",
        //   image: explore_white,
        // },
        // {
        //   id: "explore",
        //   icon: explore,
        //   text: "Explore",
        //   path: "/coming-soon",
        //   image: explore_white,
        // },
        // {
        //   id: "meditate",
        //   icon: meditate,
        //   text: "Meditate",
        //   path: "/meditate",
        //   image: meditate_white,
        // },
        // {
        //   id: "meditate",
        //   icon: meditate,
        //   text: "Meditate",
        //   path: "/coming-soon",
        //   image: meditate_white,
        // },
        // {
        //   id: "stats",
        //   icon: stats,
        //   text: "Stats",
        //   path: "/statistics",
        //   image: stats_white,
        // },
        // {
        //   id: "stats",
        //   icon: stats,
        //   text: "Stats",
        //   path: "/coming-soon",
        //   image: stats_white,
        // },
        {
            id: "account",
            icon: account,
            text: "My Account",
            path: "/account",
            image: account_white,
        },
    ];

    useEffect(() => {
        // Update the active item based on the current pathname
        const match = SIDEBAR_ITEMS.find((item) => {
            if (item.path === "/") {
                return (
                    pathname === "/" ||
                    pathname === "/describe" ||
                    pathname === "/schedule" ||
                    pathname === "/fulfill" ||
                    pathname === "/notifications"
                    // pathname === "/transaction-history"
                );
            }
            return item.path === pathname;
        });
        if (match) {
            setActiveItem(match.id);
        }
    }, [pathname]);

    return (
        <>
            <LogoutModal
                modalOpen={modalOpen}
                onClose={() => setModalOpen("")}
            />
            <Container>
                <MainContainer>
                    <Cover>
                        {/* Render top section of the sidebar */}
                        <Top>
                            {SIDEBAR_ITEMS.map((item) => (
                                <NavItem
                                    key={item.id}
                                    to={item.path}
                                    activeclassname="active"
                                    onClick={() => setActiveItem(item.id)}
                                    // Add onMouseEnter and onMouseLeave event handlers
                                    onMouseEnter={() => setIsHovered(item.id)}
                                    onMouseLeave={() => setIsHovered("")}
                                    className={
                                        // Add "active" class to the home item if on any related pages
                                        (item.path === "/" &&
                                            (pathname === "/" ||
                                                pathname === "/describe" ||
                                                pathname === "/schedule" ||
                                                pathname ===
                                                    "/create-session" ||
                                                // pathname ===
                                                //     "/finding-therapist" ||
                                                pathname ===
                                                    "/session-confirmed" ||
                                                pathname === "/fulfill" ||
                                                pathname === "/notifications" ||
                                                pathname === `/session/${id}` ||
                                                pathname ===
                                                    `/view-summary-report/${id}` ||
                                                pathname ===
                                                    `/cancel-session/${id}`)) ||
                                        // Add "active" class to the account item if on any related pages
                                        (item.path === "/account" &&
                                            (pathname === "/privacy-policy" ||
                                                pathname ===
                                                    "/terms-and-conditions" ||
                                                pathname === "/refund-policy" ||
                                                pathname === "/about-us" ||
                                                pathname === "/faq" ||
                                                pathname ===
                                                    "/transaction-history"))
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <Icon
                                        src={
                                            activeItem === item.id ||
                                            isHovered === item.id
                                                ? item.image
                                                : item.icon
                                        }
                                        active={activeItem === item.id}
                                        isHovered={isHovered === item.id}
                                    />
                                    <Text active={activeItem === item.id}>
                                        {item.text}
                                    </Text>
                                </NavItem>
                            ))}
                        </Top>
                        {/* Render bottom section of the sidebar */}
                        <Bottom>
                            {isOpen === "more" && (
                                <PrivateRoute>
                                    <MoreModal
                                        setOpen={setOpen}
                                        setModalOpen={setModalOpen}
                                    />
                                </PrivateRoute>
                            )}
                            {/* <MoreButton
                                onClick={() => {
                                    setOpen("more");
                                }}
                            >
                                <IconMore src={more} alt="more-image" />
                                <IconMore
                                    className="more-icon"
                                    src={more_white}
                                    alt="more-image"
                                />
                                <Text>More</Text>
                            </MoreButton> */}
                            {auth ? (
                                <MoreButton
                                    onClick={() => {
                                        setModalOpen("Logout");
                                    }}
                                >
                                    <IconMore
                                        src={logoutIcon}
                                        alt="more-image"
                                    />
                                    <IconMore
                                        className="more-icon"
                                        src={logoutWhiteIcon}
                                        alt="more-image"
                                    />
                                    <Text>Logout</Text>
                                </MoreButton>
                            ) : (
                                <MoreButton
                                    onClick={() => {
                                        navigate("/auth/login");
                                    }}
                                >
                                    <IconMore
                                        src={logoutIcon}
                                        alt="more-image"
                                    />
                                    <IconMore
                                        className="more-icon"
                                        src={logoutWhiteIcon}
                                        alt="more-image"
                                    />
                                    <Text>Login</Text>
                                </MoreButton>
                            )}
                        </Bottom>
                    </Cover>
                </MainContainer>
            </Container>
        </>
    );
}

export default Sidebar;

const Container = styled.section`
    border-right: 1px solid ${THEME_COLORS.light_300};
    width: 320px;
    padding: 26px 0px 0px;
    background: ${THEME_COLORS.light_green};
    height: calc(100vh - 70px);

    @media all and (max-width: 768px) {
        display: none;
    }
`;
const MainContainer = styled.div`
    height: calc(100vh - 105px);
    /* border: 1px solid #000; */
`;
const Cover = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 85%;
    margin: 0 auto;
`;
const Top = styled.div`
    width: 100%;
`;
const Bottom = styled.div`
    width: 100%;
    position: relative;
`;
const NavItem = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 10px 16px;
    margin-bottom: 6px;
    border-radius: 6px;
    color: ${(props) =>
        props.active ? THEME_COLORS.black : THEME_COLORS.light_500};
    background-color: ${(props) =>
        props.active ? THEME_COLORS.menu_blue : "transparent"};
    transition: all 0.2s ease-in-out;

    &:hover {
        color: ${THEME_COLORS.white};
        background-color: ${THEME_COLORS.primary};
    }
    &.active {
        color: ${THEME_COLORS.white};
        background-color: ${THEME_COLORS.primary};
    }
`;
const Icon = styled.img`
    width: 22px;
    height: 22px;
    margin-right: 10px;
    /* filter: ${(props) => (props.active ? "none" : "grayscale(100%)")}; */
    filter: ${(props) =>
        props.active || props.isHovered
            ? "none"
            : "brightness(90%) opacity(50%)"};
`;

const MoreButton = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;
    &:hover {
        color: ${THEME_COLORS.white};
        background-color: ${THEME_COLORS.primary};
        border-radius: 6px;
    }
`;
const Text = styled.p`
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: ${(props) =>
        props.active ? THEME_COLORS.white : THEME_COLORS.light_500};
    ${NavItem}:hover & {
        color: ${THEME_COLORS.white};
    }
    ${MoreButton}:hover & {
        color: ${THEME_COLORS.white};
    }
`;
const IconMore = styled.img`
    width: 22px;
    height: 22px;
    margin-right: 10px;
    &.more-icon {
        display: none;
    }
    ${MoreButton}:hover & {
        display: none;
        &.more-icon {
            display: block;
        }
    }
`;

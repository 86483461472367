import React from "react";
import { Link } from "react-router-dom";

// package
import styled, { css } from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function PreviousButton({ url, size, style, onClick }) {
    return (
        <>
            <PreviousContainer to={url} style={style} onClick={onClick}>
                <Parent>
                    <ArrowContainer>
                        <Arrow
                            src={
                                require("../../../assets/images/left-arrow.svg")
                                    .default
                            }
                            alt="arrow-image"
                        />
                    </ArrowContainer>
                    <Previous size={size}>Previous</Previous>
                </Parent>
            </PreviousContainer>
        </>
    );
}
const PreviousContainer = styled(Link)`
    border: 2px solid ${THEME_COLORS.light_400};
    width: 46%;
    border-radius: 32px;
    height: 50px;
    display: flex;
    justify-content: center;
    ${(props) =>
        props.style &&
        css`
            background-color: ${props.style.backgroundColor};
            font-size: ${props.style.fontSize};
        `}

    @media (max-width: 480px) {
        padding: 0 12px !important;
    }
    @media (max-width: 360px) {
        padding: 0 6px !important;
    }
`;
const Parent = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;
const ArrowContainer = styled.div`
    width: 8px;
`;
const Arrow = styled.img``;
const Previous = styled.span`
    font-size: ${(props) => (props.size ? props.size : "16px")};
    font-family: "DM_sans_medium";
    margin-left: 10px;
    display: inline-block;
`;

import React, { useState } from "react";

// packages
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function OnboardingFeelToday() {
    const [changed, setChanged] = useState(true);
    const [emoji, setEmoji] = useState([
        {
            id: 1,
            pic: require("../../../assets/images/sad-emoji.svg").default,
            bw_pic: require("../../../assets/images/sad-emoji-bw.png"),
            selected: false,
        },
        {
            id: 2,
            pic: require("../../../assets/images/neutral-emoji.svg").default,
            bw_pic: require("../../../assets/images/neutral-emoji-bw.png"),
            selected: false,
        },
        {
            id: 3,
            pic: require("../../../assets/images/smile-emoji.svg").default,
            bw_pic: require("../../../assets/images/smile-emoji-bw.png"),
            selected: false,
        },
        {
            id: 4,
            pic: require("../../../assets/images/happy-emoji.svg").default,
            bw_pic: require("../../../assets/images/happy-emoji-bw.png"),
            selected: false,
        },
        {
            id: 5,
            pic: require("../../../assets/images/lol-emoji.svg").default,
            bw_pic: require("../../../assets/images/lol-emoji-bw.png"),
            selected: false,
        },
    ]);
    const handleImageClick = (id) => {
        const updatedEmoji = emoji.map((smiley) =>
            smiley.id === id ? { ...smiley, selected: true } : { ...smiley, selected: false }
        );
        setEmoji(updatedEmoji);
        setChanged(!changed);
    };
    return (
        <MainContainer>
            <ContentContainer>
                <Content>
                    <TopContent>
                        <LogoContainer>
                            <Logo
                                src={require("../../../assets/images/logo.svg").default}
                                alt="logo"
                            />
                        </LogoContainer>
                        <Text>How are you feeling today?</Text>
                        <EmojiParent>
                            {emoji.map((smiley) => {
                                return (
                                    <EmojiContainer
                                        key={smiley.id}
                                        onClick={() => handleImageClick(smiley.id)}
                                    >
                                        <Emoji
                                            src={smiley.selected ? smiley.pic : smiley.bw_pic}
                                            alt={smiley.selected ? "emoji" : "black-n-white-emoji"}
                                        />
                                    </EmojiContainer>
                                );
                            })}
                        </EmojiParent>
                    </TopContent>
                    <BottomContent>
                        <StartButton>Get Started</StartButton>
                    </BottomContent>
                </Content>
            </ContentContainer>
        </MainContainer>
    );
}
const MainContainer = styled.section`
    height: calc(100vh - 70px);
    background: ${THEME_COLORS.light_100};
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        background: ${THEME_COLORS.white};
    }
`;
const ContentContainer = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
    border-radius: 24px;
    border: 2px solid ${THEME_COLORS.chips_green_on_container};
    width: 468px;
    /* @media all and (max-width: 1440px) {
        width: 35%;
    }
    @media all and (max-width: 1280px) {
        width: 42%;
    }
    @media all and (max-width: 1080px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 58%;
    }
    @media all and (max-width: 768px) {
        width: 65%;
    }
    @media all and (max-width: 640px) {
        width: 80%;
    } */
    @media all and (max-width: 480px) {
        width: 100%;
        border: none;
        box-shadow: none;
        height: 100%;
       
    }
`;
const Content = styled.div`
    padding: 60px 50px;
    @media all and (max-width: 768px) {
        padding: 50px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 60% 20px 25px 20px;
    }
`;
const TopContent = styled.div``;
const LogoContainer = styled.div`
    width: 70px;
    margin: 0 auto 60px auto;
    @media all and (max-width: 640px) {
        width: 60px;
        margin: 0 auto 50px auto;
    }
    @media all and (max-width: 480px) {
        width: 56px;
    }
`;
const Logo = styled.img``;
const Text = styled.h3`
    font-family: "DM_sans_bold";
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
`;
const EmojiParent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    @media all and (max-width: 640px) {
        margin-bottom: 60px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 0px;
    }
`;
const EmojiContainer = styled.div`
    width: 42px;
    cursor: pointer;
    transition: all 3s ease-out;
    height: 43px;
`;
const Emoji = styled.img`
    height: 100%;
`;
const BottomContent = styled.div`
    text-align: center;
`;
const StartButton = styled.a`
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 32px;
    padding: 12px 20px;
    color: ${THEME_COLORS.white};
`;

import React, { useState, useEffect } from "react";
import { PatientConfig } from "../../../../axiosConfig";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";

// Media-Images //
import arrowimg from "../../../../assets/images/expand_more.svg";
import arrowimg2 from "../../../../assets/images/expand_less.svg";

function Faq() {
    const [OptionsBox, setOptionsBox] = useState([]);

    useEffect(() => {
        PatientConfig.get("api/v1/general/faq/")
            .then((response) => {
                setOptionsBox(response.data.data);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    }, []);

    // show description for option
    const [isdown, setIsdown] = useState("");

    // Render each FAQ item
    const renderBox = OptionsBox.map((box) => {
        return (
            <ItemDiv key={box.id} className={isdown === box.id ? "active" : ""}>
                <ItemContent>
                    <LeftContainer>
                        <Label>{box.title}</Label>
                    </LeftContainer>
                    <RightContainer onClick={() => toggleDropdown(box.id)}>
                        <ArrowImage
                            src={isdown === box.id ? arrowimg2 : arrowimg}
                            alt="Arrow"
                        />
                    </RightContainer>
                </ItemContent>
                {isdown === box.id && <Para>{box.description}</Para>}
            </ItemDiv>
        );
    });

    const toggleDropdown = (boxId) => {
        setIsdown(isdown === boxId ? "" : boxId);
    };

    return (
        <>
            <MainContainer>
                <Wrapper>
                    <Terms>
                        <Heading>FAQ</Heading>
                        {/* Container for the FAQ items */}
                        <ContentDiv>{renderBox}</ContentDiv>
                    </Terms>
                </Wrapper>
            </MainContainer>
            <BottomNavigation />
        </>
    );
}

export default Faq;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_100};
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 30px 0;
    @media all and (max-width: 768px) {
        width: 100%;
        padding-bottom: 60px;
    }
`;
const Wrapper = styled.div`
    width: 68%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 980px) {
        width: 80%;
    }
`;
const Terms = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
`;
const Heading = styled.h3`
    font-size: 20px;
    text-align: start;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.black};
    line-height: 26px;
    margin-bottom: 34px;
`;
const ContentDiv = styled.div`
    width: 100%;
`;
const Para = styled.p`
    margin-bottom: 10px;
    font-size: 14px;
    text-align: start;
    font-family: "DM_sans";
    width: 96%;
    color: ${THEME_COLORS.text_para};
    @media all and (max-width: 360px) {
        font-size: 12px;
    }
`;
const ItemDiv = styled.div`
    width: 100%;
    padding: 12px 16px;
    margin-bottom: 8px;
    border-radius: 8px;
    &.active {
        background: ${THEME_COLORS.light_200};
        transition: all 0.5s ease;
    }
    @media all and (max-width: 360px) {
        margin-bottom: 1px;
    }
`;
const ItemContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96%;
    margin-bottom: 12px;
`;
const Label = styled.label`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 14px;
    line-height: 20px;
    width: 98%;
    /* @media all and (max-width: 980px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    width: 86%;
  } */
    @media all and (max-width: 360px) {
        font-size: 12px;
        margin-bottom: 8px;
    }
`;
const RightContainer = styled.span`
    cursor: pointer;
    display: inline-block;
    margin-top: 6px;
    width: 12px;
`;
const ArrowImage = styled.img`
    width: 100%;
    display: block;
`;

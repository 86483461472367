import React, { useContext, useEffect, useState } from "react";

// Styles //
import styled from "styled-components";

// Packages //
import { Link } from "react-router-dom";
import $ from "jquery";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";
import DeleteModal from "../../../includes/modals/DeleteModal";
import ConfirmDeleteModal from "../../../includes/modals/ConfirmDeleteModal";
import DeleteOtpVerification from "../../../includes/modals/DeleteOtpVerification";
import ShareAppModal from "../../../includes/modals/ShareAppModal";
import EditProfileModal from "../../../includes/modals/EditProfileModal";
import VideoPlayer from "../../../includes/chip/VideoPlayer";

// store
import { Context } from "../../../../contexts/Store";

// Media-Images //
import playIcon from "../../../../assets/icons/rounded-play-button.svg";
import girl from "../../../../assets/images/girl.svg";
import avathar from "../../../../assets/images/avathar.svg";
import edit from "../../../../assets/images/edit.svg";
import btn_arrow from "../../../../assets/images/btn_arrow.svg";
import ticket from "../../../../assets/images/ticket-green.svg";
import faq from "../../../../assets/images/faq.svg";
import green_arrow from "../../../../assets/images/green_arrow.svg";
import { PatientConfig } from "../../../../axiosConfig";
import LogoutModal from "../../../includes/modals/LogoutModal";

function MyAccount() {
    let { state, dispatch } = useContext(Context);

    // Breating Status Modal open //
    const [isOpen, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(""); // Open state for the logout modal

    const [Duration, setDuration] = useState(0);
    const [deleteModal, setDeleteModal] = useState("");
    const [phone, setPhone] = useState(state.user_details.phone_number || "");
    const [name, setName] = useState(state.user_details.user_name || "");
    const [email, setEmail] = useState(state.user_details.email || "");

    const [videoContents, setVideoContents] = useState([]);

    const windowWidth = window.innerWidth;

    useEffect(() => {
        setName(state.user_details.user_name);
    }, [state.user_details.user_name]);

    useEffect(() => {
        setPhone(state.user_details.phone_number);
    }, [state.user_details.phone_number]);

    // video API
    const getVideo = () => {
        PatientConfig.get("api/v1/general/videos?purpose=2")
            .then((response) => {
                setVideoContents(response.data.data);
            })
            .catch((error) => {
                // console.error("Error", error);
            });
    };

    useEffect(() => {
        getVideo();
    }, []);

    useEffect(() => {
        if (
            deleteModal === "share" ||
            deleteModal === "confirm-delete" ||
            deleteModal === "otp-verification" ||
            deleteModal === "delete"
        ) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [deleteModal]);

    return (
        <>
            {isOpen && <EditProfileModal onClose={() => setOpen(false)} />}
            {deleteModal === "delete" && (
                <DeleteModal
                    onClose={() => setDeleteModal("")}
                    setDeleteModal={setDeleteModal}
                    deleteModal={deleteModal}
                />
            )}
            {deleteModal === "confirm-delete" && (
                <ConfirmDeleteModal
                    onClose={() => setDeleteModal("")}
                    setDeleteModal={setDeleteModal}
                    deleteModal={deleteModal}
                    phone={phone}
                    setPhone={setPhone}
                />
            )}
            {deleteModal === "otp-verification" && (
                <DeleteOtpVerification
                    deleteModal={deleteModal}
                    onClose={() => setDeleteModal("")}
                    phone={phone}
                />
            )}
            {deleteModal === "share" && (
                <ShareAppModal
                    deleteModal={deleteModal}
                    onClose={() => setDeleteModal("")}
                />
            )}
            {modalOpen == "Logout" && (
                <LogoutModal
                    modalOpen={modalOpen}
                    onClose={() => setModalOpen("")}
                />
            )}
            <MainContainer>
                <BG></BG>
                <ContentDiv>
                    <Heading>My Account </Heading>
                    <ProfileDiv>
                        <Top>
                            <Profile>
                                <Avathar>
                                    <img
                                        className="image"
                                        src={avathar}
                                        alt="profile_image"
                                    />
                                </Avathar>
                                <Details>
                                    <h5>{name}</h5>
                                    {phone && <p>+91 {phone}</p>}
                                    {email && <p>{email}</p>}
                                </Details>
                            </Profile>
                            {/* <Edit onClick={() => setOpen(true)}>
                <img className="image" src={edit} alt="edit_button" />
              </Edit> */}
                        </Top>
                        <Middle>
                            {/* not in this release */}
                            {/* <ButtonDiv>
                <BtnLeft>
                  <BtnImg>
                    <img className="image" src={ticket} alt="edit_button" />
                  </BtnImg>
                  <h6>Raise a Ticket </h6>
                </BtnLeft>
                <BtnRight>
                  <img className="image" src={btn_arrow} alt="arrow_button" />
                </BtnRight>
              </ButtonDiv> */}
                            <ButtonDiv to="/faq">
                                <BtnLeft>
                                    <BtnImg>
                                        <img
                                            className="image"
                                            src={faq}
                                            alt="edit_button"
                                        />
                                    </BtnImg>
                                    <h6>Frequently Asked Questions(FAQ's) </h6>
                                </BtnLeft>
                                <BtnRight>
                                    <img
                                        className="image"
                                        src={btn_arrow}
                                        alt="arrow_button"
                                    />
                                </BtnRight>
                            </ButtonDiv>
                        </Middle>
                        <Bottom>
                            <Link to="/about-us">
                                <MapContainer>
                                    <LeftWord>About Us</LeftWord>
                                    <RightArrow>
                                        {" "}
                                        <img
                                            className="image"
                                            src={green_arrow}
                                            alt="green_button"
                                        />
                                    </RightArrow>
                                </MapContainer>
                            </Link>
                            <Link to="/transaction-history">
                                <MapContainer>
                                    <LeftWord>Transactions</LeftWord>
                                    <RightArrow>
                                        {" "}
                                        <img
                                            className="image"
                                            src={green_arrow}
                                            alt="green_button"
                                        />
                                    </RightArrow>
                                </MapContainer>
                            </Link>
                            {/* not in ths release */}
                            {/* <Link to="/">
                <MapContainer>
                  <LeftWord>Provide Feedback</LeftWord>
                  <RightArrow>
                    {" "}
                    <img
                      className="image"
                      src={green_arrow}
                      alt="green_button"
                    />
                  </RightArrow>
                </MapContainer>
              </Link> */}
                            <Link to="/terms-and-conditions">
                                <MapContainer>
                                    <LeftWord>Terms of Service</LeftWord>
                                    <RightArrow>
                                        {" "}
                                        <img
                                            className="image"
                                            src={green_arrow}
                                            alt="green_button"
                                        />
                                    </RightArrow>
                                </MapContainer>
                            </Link>
                            <Link to="/privacy-policy">
                                <MapContainer>
                                    <LeftWord>Privacy Policy</LeftWord>
                                    <RightArrow>
                                        {" "}
                                        <img
                                            className="image"
                                            src={green_arrow}
                                            alt="green_button"
                                        />
                                    </RightArrow>
                                </MapContainer>
                            </Link>
                            <Link to="/refund-policy">
                                <MapContainer>
                                    <LeftWord>Refund Policy</LeftWord>
                                    <RightArrow>
                                        {" "}
                                        <img
                                            className="image"
                                            src={green_arrow}
                                            alt="green_button"
                                        />
                                    </RightArrow>
                                </MapContainer>
                            </Link>
                            <Link onClick={() => setDeleteModal("share")}>
                                <MapContainer>
                                    <LeftWord>Share App</LeftWord>
                                    <RightArrow>
                                        {" "}
                                        <img
                                            className="image"
                                            src={green_arrow}
                                            alt="green_button"
                                        />
                                    </RightArrow>
                                </MapContainer>
                            </Link>

                            <Link onClick={() => setDeleteModal("delete")}>
                                <MapContainer>
                                    <LeftWord>Delete your Account</LeftWord>
                                    <RightArrow>
                                        {" "}
                                        <img
                                            className="image"
                                            src={green_arrow}
                                            alt="green_button"
                                        />
                                    </RightArrow>
                                </MapContainer>
                            </Link>
                            {windowWidth <= 768 && (
                                <Link
                                    onClick={() => {
                                        setModalOpen("Logout");
                                    }}
                                >
                                    <MapContainer>
                                        <LeftWord>Logout</LeftWord>
                                        <RightArrow>
                                            {" "}
                                            <img
                                                className="image"
                                                src={green_arrow}
                                                alt="green_button"
                                            />
                                        </RightArrow>
                                    </MapContainer>
                                </Link>
                            )}
                        </Bottom>
                    </ProfileDiv>
                    <VideoCard>
                        <VideoPlayer
                            item={videoContents}
                            setVideoDuration={setDuration}
                            Icon={playIcon}
                        />
                        {/* <VideoImageDiv>
                            <PlayIcon src={playIcon} alt="icon" />
                        </VideoImageDiv> */}
                        <VideoBottomLine>
                            <ProfilePic src={girl} alt="icon" />
                            <VideoBottomRight>
                                <VideoTitle>{videoContents.title}</VideoTitle>
                                <VideoTime>{Duration}</VideoTime>
                            </VideoBottomRight>
                        </VideoBottomLine>
                    </VideoCard>
                </ContentDiv>
                <BottomNavigation />
            </MainContainer>
        </>
    );
}

export default MyAccount;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    /* z-index: -1; */
    @media all and (max-width: 768px) {
        width: 100%;
        padding: 0 0 120px;
    }
    @media all and (max-width: 360px) {
        width: 100%;
        padding: 0 0 90px;
    }
`;
const BG = styled.div`
    background: ${THEME_COLORS.gradient_3};
    width: 100%;
    height: 134px;
    position: absolute;
    /* z-index: 10;   */
`;
const ContentDiv = styled.div`
    width: 453px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* z-index: 20; */
    gap: 24px;
    padding: 20px 0px;
    @media all and (max-width: 768px) {
        width: 410px;
    }
    @media all and (max-width: 480px) {
        width: 360px;
        padding: 20px 14px;
    }
    @media all and (max-width: 360px) {
        width: 324px;
    }
`;
const Heading = styled.h3`
    font-size: 20px;
    text-align: start;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.black};
    line-height: 26px;
`;
const ProfileDiv = styled.div`
    box-sizing: border-box;
    padding: 24px;
    /* height: 420px; */
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_300};
    box-shadow: 2px 2px 16px ${THEME_COLORS.elevation_user_u4};
    border-radius: 16px;
    @media all and (max-width: 480px) {
        padding: 12px;
    }
    /* @media all and (max-width: 360px) {
        height: 394px; */
    /* } */
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 0px 16px;
    justify-content: space-between;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
    margin-bottom: 24px;
`;
const Profile = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
`;
const Avathar = styled.span`
    display: inline-block;
    width: 72px;
`;
const Details = styled.div`
    h5 {
        font-size: 16px;
        line-height: 20px;
        font-family: "DM_sans_medium";
        color: ${THEME_COLORS.chips_blue_on_container};
    }
    p {
        font-size: 14px;
        font-family: "DM_sans";
        color: ${THEME_COLORS.text_para};
    }
`;
const Edit = styled.div`
    width: 32px;
    cursor: pointer;
`;
const Middle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    @media all and (max-width: 768px) {
        gap: 16px;
    }
    @media all and (max-width: 480px) {
        gap: 6px;
    }
`;
const ButtonDiv = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    /* width: 194.5px; */
    width: 100%;
    height: 44px;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.chips_blue_container};
    box-shadow: 2px 2px 16px ${THEME_COLORS.elevation_user_u4};
    border-radius: 10px;
    cursor: pointer;
    @media all and (max-width: 360px) {
        padding: 8px 5px;
        height: 36px;
    }
`;
const BtnLeft = styled.div`
    display: flex;
    gap: 10px;
    @media all and (max-width: 480px) {
        gap: 6px;
    }
    @media all and (max-width: 360px) {
        align-items: center;
    }
    h6 {
        font-size: 14px;
        line-height: 20px;
        font-family: "DM_sans_medium";
        color: ${THEME_COLORS.text_title};
        @media all and (max-width: 480px) {
            font-size: 13px;
        }
        @media all and (max-width: 360px) {
            font-size: 12px;
        }
    }
`;
const BtnImg = styled.span`
    display: inline-block;
    width: 20px;
    @media all and (max-width: 360px) {
        width: 16px;
    }
`;
const BtnRight = styled.span`
    display: inline-block;
    width: 8px;
    @media all and (max-width: 360px) {
        width: 6px;
    }
`;
const Bottom = styled.div`
    width: 405px;
    /* height: 212px; */
    @media all and (max-width: 768px) {
        width: 360px;
    }
    @media all and (max-width: 480px) {
        width: 302px;
    }
    @media all and (max-width: 360px) {
        width: 270px;
    }
`;
const MapContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px;
`;
const LeftWord = styled.p`
    font-size: 14px;
    line-height: 20px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_green_on_container};
`;
const RightArrow = styled.span`
    font-size: 20px;
`;
const VideoCard = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 16px;
    padding: 12px;
    margin-top: 32px;
    overflow: hidden;
    /* box-shadow: ${THEME_COLORS.elevation_user_u3}; */

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        border-radius: inherit;
        box-shadow: ${THEME_COLORS.elevation_user_u3};
    }
`;
const VideoBottomLine = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
`;
const ProfilePic = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
`;
const VideoBottomRight = styled.div`
    margin-left: 13px;
`;
const VideoTitle = styled.h6`
    font-family: "DM_sans_Medium";
    font-size: 18px;
    color: ${THEME_COLORS.black};
`;
const VideoTime = styled.p`
    font-size: 14px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_para};
    margin-top: 6px;
`;

import React, { useContext } from "react";

// axios
import { PatientConfig } from "../../../axiosConfig";

// store
import { Context } from "../../../contexts/Store";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// package
import styled, { keyframes } from "styled-components";

// media
import nextimg from "../../../assets/images/next.svg";

export default function ConfirmDeleteModal({ onClose, setDeleteModal, phone }) {
    const { state } = useContext(Context);

    let accessToken = state.user_details.access_token;

    const handleDeleteOtpSubmit = () => {
        const formData = new FormData();
        formData.append("country", "IN");
        formData.append("phone", phone);
        PatientConfig.post(
            "api/v1/patients/patient/profile-delete/generate-email-otp/",
            formData,
            {
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: "Bearer " + accessToken,
                },
            }
        )
            .then((response) => {
                // console.log('OTP Sent Successfully', response.data)
            })
            .catch((error) => {
                // console.error('An error occurred:', error)
            });
    };

    return (
        <Modal onClick={() => onClose()}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <Card>
                    <Title>Delete Your Account</Title>
                    <Description>
                        Are you sure you want to delete your account. All
                        registerd informaion will be removed and you will not be
                        able to access the system.
                    </Description>
                    <NavButtons>
                        <Cancel onClick={() => onClose()}>Cancel</Cancel>
                        <Delete
                            onClick={() => {
                                setDeleteModal("otp-verification");
                                handleDeleteOtpSubmit();
                            }}
                        >
                            Next
                            <img src={nextimg} alt="arrow" />
                        </Delete>
                    </NavButtons>
                </Card>
            </ModalContent>
        </Modal>
    );
}
const popup = keyframes`
    0%{
        scale: 0.5;
    }
    100%{
        scale: 1;
    }
`;
const Modal = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${THEME_COLORS.modal_bg};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 305;
`;
const ModalContent = styled.div`
    width: 450px;
    border-radius: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    padding-top: 5px;
    animation: ${popup} 0.4s ease-in-out;

    @media all and (max-width: 480px) {
        width: 100%;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        animation-name: modal;
        animation-duration: 0.3s;

        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
`;
const Card = styled.div`
    border-radius: 24px;
    background: ${THEME_COLORS.white};
    padding: 32px;
    @media all and (max-width: 480px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const Title = styled.h4`
    font-family: "DM_sans_medium";
    font-size: 20px;
    margin-bottom: 20px;
    color: ${THEME_COLORS.text_title};
`;
const Description = styled.p`
    font-family: "DM_sans";
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    margin-bottom: 20px;
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Cancel = styled.div`
    border-radius: 32px;
    padding: 8px 20px;
    border: 2px solid ${THEME_COLORS.light_400};
    color: ${THEME_COLORS.dark_500};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;
const Delete = styled.div`
    gap: 15px;
    border-radius: 32px;
    padding: 10px 20px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.secondary_gradient};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
        width: 6px;
    }
`;

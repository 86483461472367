import React, { useRef, useState } from "react";

// media
import PlayButtonImage from "../../../assets/images/play-icon-white.svg";

// package
import moment from "moment";
import styled from "styled-components";
import "video-react/dist/video-react.css";
import { Player, BigPlayButton } from "video-react";

export default function VideoPlayer({ item, setVideoDuration, Icon }) {
  const [showPlayButton, setShowPlayButton] = useState(true);
  const videoRef = useRef(null);
  // function pauseOtherVideos() {
  //     // Get all the video elements on the page.
  //     const videos = document.querySelectorAll("video");
  //     videos.forEach((video) => {
  //         console.log(video, "==index");

  //         // Check if the video is the same as the one you started
  //         if (video !== videoRef.current && !video.paused) {
  //             video.pause();
  //         }
  //     });
  // }

  // handle video play
  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setShowPlayButton(true);
    }
  };

  return (
    <VideoContainer>
      <Player
        ref={videoRef}
        poster={item?.thumbnail}
        preload="auto"
        src={item.video_file}
        onLoadedMetadata={(e) =>
          setVideoDuration(
            moment.utc(e.target.duration * 1000).format("HH:mm:ss")
          )
        }
        onPlay={() => {
          setShowPlayButton(false);
          // pauseOtherVideos();
        }}
        onPause={() => {
          setShowPlayButton(true);
        }}
      >
        <BigPlayButton disabled />
        {showPlayButton && (
          <PlayButton onClick={() => handlePlay()}>
            <VideoPlayButton
              style={
                Icon && {
                  width: "50px",
                  height: "50px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: " translate(-50%, -50%)",
                }
              }
              src={Icon ? Icon : PlayButtonImage}
            />
          </PlayButton>
        )}
      </Player>
    </VideoContainer>
  );
}
const VideoContainer = styled.div`
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
`;
const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 1;
  @media all and (max-width: 640px) {
    width: 60px;
    height: 60px;
  }
`;
const VideoPlayButton = styled.img``;

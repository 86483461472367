import React from "react";

// Styles //
import styled, { keyframes } from "styled-components";

// colors //
import { THEME_COLORS } from "../../../ThemeConfig";

// Images & Icons
import Tick from "../../../assets/images/bluetick.svg";

const PackageInfoModal = ({ item, closeModal }) => {
    return (
        <Wrapper onClick={closeModal}>
            <Content onClick={(e) => e.stopPropagation()}>
                <TopContainer>
                    <TopLeft>
                        <TopLeftTopContainer>
                            <Title>{item.package_type}</Title>
                            {item?.is_popular && <Popular>Popular</Popular>}
                        </TopLeftTopContainer>
                        <Text>
                            {item.no_of_sessions}{" "}
                            {item.no_of_sessions == "One"
                                ? `session`
                                : `sessions`}{" "}
                            with a {item.expert_type} expert.
                        </Text>
                    </TopLeft>
                    <TopRight>
                        <ActualPrice>₹{item?.actual_price}</ActualPrice>
                        <TopRightSubContainer>
                            <DiscountedPrice>₹{item?.price}</DiscountedPrice>
                            <Offer> ({item?.discount})% OFF</Offer>
                        </TopRightSubContainer>
                    </TopRight>
                </TopContainer>

                <FeaturesContainer>
                    <FeatureHeading>FEATURES</FeatureHeading>
                    <FeatureContainer>
                        {item.feature_points.map((feature) => (
                            <Feature>
                                <TickIconContainer>
                                    <TickIcon src={Tick} alt="Icon" />
                                </TickIconContainer>
                                <FeatureTitle>{feature}</FeatureTitle>
                            </Feature>
                        ))}
                    </FeatureContainer>
                </FeaturesContainer>

                <Button onClick={closeModal}>Okay</Button>
            </Content>
        </Wrapper>
    );
};

export default PackageInfoModal;

const popup = keyframes`
  0%{
      scale: 0.3;
  }
  100%{
      scale: 1;
  }
`;
const Wrapper = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: ${THEME_COLORS.white};
    width: 614px;
    /* height: 469px; */
    padding: 32px;
    z-index: 200;
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: 27px 27px 48px ${THEME_COLORS.elevation_user_u2};
    border-radius: 24px;
    animation: ${popup} 0.4s ease-in-out;
    @media all and (max-width: 780px) {
        padding: 20px 30px;
    }
    @media all and (max-width: 480px) {
        padding: 25px;
        width: 400px;
    }
    @media all and (max-width: 420px) {
        padding: 20px;
        width: 360px;
    }
    @media all and (max-width: 370px) {
        width: 330px;
        padding: 15px;
    }
`;
const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;
const TopLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;
const TopLeftTopContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const Title = styled.h2`
    font-size: 24px;
    text-transform: capitalize;
    font-family: "DM_sans_bold";
    color: #3e3e53;
`;
const Popular = styled.span`
    font-size: 12px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.chips_blue_on_container_2};
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    border-radius: 53px;
    background: ${THEME_COLORS.chips_blue_container};
    padding: 2px 6px;
`;
const Text = styled.p`
    color: #818181;
    font-family: "DM_sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    @media all and (max-width: 370px) {
        font-size: 15px;
    }
`;
const TopRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;
const ActualPrice = styled.span`
    font-size: 40px;
    display: block;
    font-family: "DM_sans_bold";
    color: ${THEME_COLORS.black};
`;
const TopRightSubContainer = styled.div``;
const DiscountedPrice = styled.span`
    font-size: 14px;
    display: block;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_blue_on_container_2};
    text-decoration: line-through;
`;
const Offer = styled.span`
    font-size: 14px;
    display: block;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_blue_on_container_2};
`;

const FeaturesContainer = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid#D3D3D3;
    background: #f0f5ff;
`;
const FeatureHeading = styled.h5`
    color: #3e3e53;
    font-family: "DM_sans_medium";
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
`;
const FeatureContainer = styled.ul`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    flex-wrap: wrap;
`;
const Feature = styled.li`
    display: flex;
    align-items: center;
    gap: 10px;
    /* align-self: stretch; */
    width: 242px;
`;
const TickIconContainer = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
`;
const TickIcon = styled.img``;
const FeatureTitle = styled.span`
    display: flex;
    width: 206px;
    height: 29px;
    flex-direction: column;
    justify-content: center;
    color: #3e3e53;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
`;

const Button = styled.button`
    background: ${THEME_COLORS.secondary_gradient};
    color: ${THEME_COLORS.white};
    cursor: pointer;
    margin: 0 auto;
    display: block;
    font-size: 14px;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    font-family: "DM_sans_medium";
    @media all and (max-width: 780px) {
        margin: 15px auto 0 auto;
    }
`;

import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Context } from "../../../contexts/Store";

export default function AuthRoute({ children }) {
    const { state } = useContext(Context);
    const auth = state.user_details.is_verified;

    return !auth ? children : <Navigate to="/" />;
}

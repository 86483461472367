import React, { useContext, useState } from "react";

// packages
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// components
import NextButton from "../../includes/buttons/NextButton";
import PreviousButton from "../../includes/buttons/PreviousButton";

// store
import { Context } from "../../../contexts/Store";

export default function OnBoardingPersonAge() {
    const { state, dispatch } = useContext(Context);
    const navigate = useNavigate();

    const [isFocused, setIsFocused] = useState("");
    const [error, setError] = useState(false);
    const [age, setAge] = useState(state.user_details.user_age || "");

    // error  validation, dispatching data and navigation
    const handleSubmit = () => {
        if (age === "") {
            // console.log("===ttt");
            setError(true);
        } else {
            setError(false);
            dispatch({
                type: "UPDATE_USER",
                user_details: {
                    user_age: age,
                },
            });
            navigate("/onboarding-person-gender");
        }
    };

    return (
        <>
            <MainContainer>
                <ContentContainer>
                    <Content>
                        <div>
                            <SkipButton to="/onboarding-completed">
                                Skip for now
                            </SkipButton>
                            <LogoContainer>
                                <Logo
                                    src={
                                        require("../../../assets/images/logo.svg")
                                            .default
                                    }
                                    alt="logo"
                                />
                            </LogoContainer>
                            <Detail>
                                <Text>
                                    Hello{" "}
                                    {state.user_details.user_name.first_name} 👋🏻
                                    <br />
                                    How old are you?
                                </Text>
                                <ButtonContainer
                                    className={
                                        isFocused === "age-focus"
                                            ? "input-focus"
                                            : ""
                                    }
                                    onFocus={() => {
                                        setIsFocused("age-focus");
                                    }}
                                    onBlur={() => {
                                        setIsFocused("");
                                        setError(age === "");
                                    }}
                                >
                                    <AgeContainer
                                        className={
                                            isFocused === "age-focus"
                                                ? "input-focus"
                                                : ""
                                        }
                                        onFocus={() => {
                                            setIsFocused("age-focus");
                                        }}
                                        onBlur={() => {
                                            setIsFocused("");
                                            setError(age === "");
                                        }}
                                    >
                                        <AgeLabel>Age*</AgeLabel>
                                        <Age
                                            type="number"
                                            value={age}
                                            onChange={(e) => {
                                                setAge(e.target.value);
                                                setError(age === "");
                                            }}
                                        />
                                    </AgeContainer>
                                    <ErrorMessage
                                        className={error ? "active" : ""}
                                    >
                                        Age field is required
                                    </ErrorMessage>
                                </ButtonContainer>
                            </Detail>
                        </div>
                        <div>
                            <NavButtons>
                                <PreviousButton url="/onboarding-person-name" />
                                <NextButton
                                    onClick={handleSubmit}
                                    // data={{
                                    //     type: "UPDATE_USER",
                                    //     user_details: {
                                    //         user_age: age,
                                    //     },
                                    // }}
                                    // url="/onboarding-person-gender"
                                    // disabled={age === ""}
                                />
                            </NavButtons>
                        </div>
                    </Content>
                </ContentContainer>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.section`
    height: calc(100vh - 70px);
    background: ${THEME_COLORS.light_100};
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        background: ${THEME_COLORS.white};
        display: block;
    }
`;
const ContentContainer = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: 468px;

    /* @media all and (max-width: 1440px) {
        width: 35%;
    }
    @media all and (max-width: 1280px) {
        width: 40%;
    }
    @media all and (max-width: 1080px) {
        width: 45%;
    }
    @media all and (max-width: 980px) {
        width: 60%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 640px) {
        width: 85%;
    } */
    @media all and (max-width: 480px) {
        width: 100%;
        border: none;
        box-shadow: none;
        height: 100%;
    }
`;
const Content = styled.div`
    padding: 30px 30px 80px 30px;
    @media all and (max-width: 480px) {
        padding: 20px 20px 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`;
const SkipButton = styled(Link)`
    font-size: 14px;
    color: ${THEME_COLORS.chips_green_on_container};
    font-family: "DM_sans_medium";
    margin-bottom: 30px;
    text-align: right;
    display: block;
`;
const LogoContainer = styled.div`
    width: 56px;
    margin: 0 auto 60px auto;
`;
const Logo = styled.img``;
const Detail = styled.div`
    padding: 0 30px;
    @media all and (max-width: 640px) {
        padding: 0 15px;
    }
    @media all and (max-width: 480px) {
        padding: 0 5px;
    }
`;

const Text = styled.h3`
    font-family: "DM_sans_bold";
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
`;
const ButtonContainer = styled.div`
    border-radius: 25px;
    margin-bottom: 60px;
    padding: 2px;
    &.input-focus {
        padding: 2px;
        border-radius: 25px;
        background: ${THEME_COLORS.secondary_gradient};
    }
`;
const AgeContainer = styled.div`
    background: ${THEME_COLORS.light_100};
    border-radius: 22px;
    border: 1px solid ${THEME_COLORS.light_100};
    padding: 8px 24px;
    &.input-focus {
        background: ${THEME_COLORS.white};
    }
`;
const AgeLabel = styled.span`
    display: block;
    color: ${THEME_COLORS.text_para};
    font-size: 12px;
    font-family: "DM_Sans";
`;
const Age = styled.input`
    font-size: 16px;
    font-family: "DM_sans_medium";
`;
const ErrorMessage = styled.span`
    display: none;
    &.active {
        position: absolute;
        color: red;
        font-size: 12px;
        display: block;
        top: 63%;
    }
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    @media all and (max-width: 640px) {
        padding: 0 20px;
    }
    @media all and (max-width: 480px) {
        padding: 0 10px;
    }
    @media all and (max-width: 360px) {
        padding: 0;
    }
`;

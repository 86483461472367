import React, { useEffect, useState } from "react";
import styled from "styled-components";

// import share from "../../../assets/image/sessionsinglepage/share.svg";
import copyIcon from "../../../assets/icons/copy-icon.svg";

function UpcomingTimerComplete({ meet_link, timeData }) {
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const handleCopyClick = async (linkToCopy) => {
        try {
            await navigator.clipboard.writeText(linkToCopy);
            setTooltipVisible(true);

            setTimeout(() => {
                setTooltipVisible(false);
            }, 3000);
            //   setCopySuccess('Copied!');
        } catch (err) {
            // console.error("Unable to copy text: ", err);
            //   setCopySuccess('Copy failed');
        }
    };
    const link = meet_link;

    const [countdown, setCountdown] = useState(timeData);
    // const [timerZero, setTimerZero] = useState(false)

    // useEffect(() => {
    //     // Check if hours is not null before starting the countdown
    //     if (timeData !== null) {
    //         const interval = setInterval(() => {
    //             // Decrement the seconds
    //             setCountdown((prevCountdown) => {
    //                 const { hours, minutes, seconds } = prevCountdown;
    //                 if (hours === 0 && minutes < 2) {
    //                     // Timer reached zero, set the state to true
    //                     // setTimerZero(true);
    //                     // console.log(timerZero, 'Timer')
    //                     clearInterval(interval);
    //                     return prevCountdown;
    //                 }

    //                 let newSeconds = seconds - 1;
    //                 let newMinutes = minutes;
    //                 let newHours = hours;

    //                 if (newSeconds < 0) {
    //                     newSeconds = 59;
    //                     newMinutes -= 1;
    //                 }
    //                 if (newMinutes < 0) {
    //                     newMinutes = 59;
    //                     newHours -= 1;
    //                 }

    //                 return {
    //                     hours: newHours,
    //                     minutes: newMinutes,
    //                     seconds: newSeconds,
    //                 };
    //             });
    //         }, 1000);

    //         // Cleanup function to clear the interval when the component unmounts
    //         return () => clearInterval(interval);
    //     }
    // }, [timeData]);

    // const handleCopyLink = () => {
    //   navigator.clipboard
    //     .writeText(`${link}`)
    //     .then(() => {
    //       alert("Link copied to clipboard!");
    //     })
    //     .catch((error) => {
    //       console.error("Error copying link:", error);
    //     });
    // };

    return (
        <Container>
            <Text>Ready to join?</Text>
            <RightContainer>
                {/* <CopyIconContainer
                    onClick={() => {
                        handleCopyClick(link);
                        // navigator.clipboard.writeText(
                        //     "Copy this text to clipboard"
                        // );
                    }}
                >
                    <CopyIcon
                        onClick={() => {
                            handleCopyClick("hii");
                        }}
                        src={copyIcon}
                        alt="Icon"
                    />
                </CopyIconContainer> */}
                <Print
                    onClick={() => {
                        handleCopyClick(link);
                    }}
                >
                    <img src={copyIcon} alt="Icon" />
                    {tooltipVisible && <ToolTip>Copied!</ToolTip>}
                </Print>
                <Button href={link} target="blank">
                    Join
                </Button>
            </RightContainer>
        </Container>
    );
}

export default UpcomingTimerComplete;
const Print = styled.div`
    position: relative;

    display: flex;
    padding: 4px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.15);
    cursor: pointer;
`;
const ToolTip = styled.div`
    position: absolute;
    top: 40px;
    /* bottom: 0px; */
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 14px;
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
    &.hidden {
        opacity: 0;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Text = styled.span`
    color: #f4f4f4;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
`;
const RightContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
`;
const CopyIconContainer = styled.button`
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.15);
    cursor: pointer;
`;
const CopyIcon = styled.img``;
const Button = styled.a`
    display: flex;
    padding: 6px 26px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #e8effe;
    color: #366ee3;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import styled, { keyframes } from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// components
import BottomNavigation from "../../general/BottomNavigation";

// icons
import calendarIcon from "../../../assets/icons/session-calendar-icon.svg";
import callIcon from "../../../assets/icons/session-call-icon.svg";
import clockIcon from "../../../assets/icons/session-clock-icon.svg";
import meetIcon from "../../../assets/icons/session-meet-icon.svg";
import star from "../../../assets/images/star-white.svg";
import greenStar from "../../../assets/images/star-green.svg";

// Lottie
import Lottie from "lottie-react";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";

// store
import { Context } from "../../../contexts/Store";

// axios
import { PatientConfig } from "../../../axiosConfig";

function SessionFeedbackModal({
    id,
    closeModal,
    setIsModalOpen,
    setIsSuccessModalOpen,
    setIsFeedbackModalOpen,
    getSessionDetails,
}) {
    const [error, setError] = useState(false);
    const [feedbackError, setFeedbackError] = useState(false);
    const [descriptionError, setDescriptionError] = useState(false);
    const [value, setValue] = useState("");
    const [isFocus, setIsFocus] = useState(false);
    const [sessionDetial, setSessionDetial] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [rating, setRating] = useState(0);

    const { state } = useContext(Context);

    let accessToken = state.user_details.access_token;

    const slug = id;

    const getSessionDetail = () => {
        setIsLoading(true);
        PatientConfig.get(
            `api/v1/patients/patient/add-session-feedback/${slug}`,
            {
                headers: {
                    Authorization: `Bearer  ${accessToken}`,
                },
            }
        )
            .then((response) => {
                setSessionDetial(response.data.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getSessionDetail();
    }, []);

    // initializing rating picture
    const stars = [
        {
            id: 1,
            image: { star },
            green_image: { greenStar },
        },
        {
            id: 2,
            image: { star },
            green_image: { greenStar },
        },
        {
            id: 3,
            image: { star },
            green_image: { greenStar },
        },
        {
            id: 4,
            image: { star },
            green_image: { greenStar },
        },
        {
            id: 5,
            image: { star },
            green_image: { greenStar },
        },
    ];

    const handleSubmit = () => {
        if (rating === 0) {
            setError(true);
        } else if (value === "") {
            setDescriptionError(true);
        } else {
            setError(false);
            setDescriptionError(false);
            PatientConfig.post(
                `/api/v1/patients/patient/add-session-feedback/${slug}/`,
                {
                    session: sessionDetial.session,
                    rating: rating,
                    review: value,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
                .then((response) => {
                    const StatusCode = response.data.StatusCode;
                    if (StatusCode === 6000) {
                        if (feedbackError) {
                            setFeedbackError(false);
                        }
                        setIsModalOpen(false);
                        setIsSuccessModalOpen(true);
                        setIsFeedbackModalOpen(false);
                        getSessionDetails();
                    } else if (StatusCode === 6001) {
                        setFeedbackError(true);
                        setTimeout(() => {
                            closeModal();
                            setFeedbackError(false);
                        }, 2000);
                    } else {
                        // console.log("Sorry, some error occured");
                    }
                })
                .catch((error) => {
                    const StatusCode = error.response.data.StatusCode;

                    setFeedbackError(error?.response?.data?.data?.message);
                    setTimeout(() => {
                        closeModal();
                        setFeedbackError(false);
                    }, 5000);

                    // alert(error?.response?.data?.data?.message);
                    // console.error("An error occurred:", error);
                });
        }
    };

    // mobile screen
    const isMobile = window.innerWidth <= 480;
    return (
        <>
            {isLoading ? (
                <Lottie
                    animationData={LoaderLottie}
                    style={{
                        width: 150,
                        margin: "auto",
                        position: "absolute",
                    }}
                />
            ) : (
                <Wrapper onClick={closeModal}>
                    <Overlay></Overlay>
                    <ContentsDiv onClick={(e) => e.stopPropagation()}>
                        <TopContainer>
                            <HeadingDiv>
                                <Heading>Session Feedback</Heading>

                                <CancelBtn onClick={closeModal}>
                                    <CloseSvg>
                                        <CloseSvgImg
                                            src={
                                                require("../../../assets/images/close.svg")
                                                    .default
                                            }
                                            alt="cancel-button"
                                        />
                                    </CloseSvg>
                                    <Cancel>Cancel</Cancel>
                                </CancelBtn>
                            </HeadingDiv>

                            <TopSubContainer>
                                <TopUpContainer>
                                    <Title>{sessionDetial.concern}</Title>
                                    <SessionChip>
                                        {sessionDetial.current_session_number}{" "}
                                        of
                                        {sessionDetial.total_sessions} Session
                                    </SessionChip>
                                </TopUpContainer>
                                <TopDownContainer>
                                    <GreyChip>
                                        <ChipIcon
                                            src={calendarIcon}
                                            alt="icon"
                                        />
                                        <ChipText>
                                            {sessionDetial.case_date}
                                        </ChipText>
                                    </GreyChip>
                                    <GreyChip>
                                        <ChipIcon src={clockIcon} alt="icon" />
                                        <ChipText>
                                            {sessionDetial.start_time} -{" "}
                                            {sessionDetial.end_time}
                                        </ChipText>
                                    </GreyChip>
                                    <GreyChip>
                                        <ChipIcon
                                            src={
                                                sessionDetial.meet_mode ==
                                                "Video"
                                                    ? meetIcon
                                                    : callIcon
                                            }
                                            alt="icon"
                                        />
                                    </GreyChip>
                                </TopDownContainer>
                            </TopSubContainer>
                        </TopContainer>
                        <ContentDiv>
                            <RatingContainer>
                                <SessionSubtitle>
                                    THERAPIST RATING
                                </SessionSubtitle>
                                <SessionTitle>
                                    How was your therapist?
                                </SessionTitle>
                                <Rate>
                                    {stars.map((star) => {
                                        // Render rating stars
                                        return (
                                            <ImageContainer>
                                                <ImageTag
                                                    key={star.id}
                                                    src={
                                                        rating >= star.id
                                                            ? star.green_image
                                                                  .greenStar
                                                            : star.image.star
                                                    }
                                                    onClick={() =>
                                                        setRating(star.id)
                                                    }
                                                    alt="rating-star"
                                                />
                                            </ImageContainer>
                                        );
                                    })}
                                </Rate>
                            </RatingContainer>
                            <ReviewContainer>
                                <SessionSubtitle>
                                    THERAPIST REVIEW
                                </SessionSubtitle>
                                <SessionTitle>
                                    Write a short review on your therapist.
                                </SessionTitle>
                                <FormContainer
                                    className={isFocus ? "active" : ""}
                                >
                                    <Input
                                        type="text"
                                        id="input"
                                        placeholder="Enter your answer"
                                        value={value}
                                        onChange={(e) =>
                                            setValue(e.target.value)
                                        }
                                        onFocus={() => setIsFocus(true)}
                                        onBlur={() => setIsFocus(false)}
                                        //   required
                                    />
                                </FormContainer>
                            </ReviewContainer>
                        </ContentDiv>
                        <BottomDiv>
                            <ErrorMessage
                                className={
                                    error || descriptionError || feedbackError
                                        ? "display"
                                        : ""
                                }
                            >
                                {(error && "Please rate your expert!") ||
                                    (descriptionError &&
                                        "Please fill the description!") ||
                                    (feedbackError &&
                                        "Feedback has already been submitted for this session")}
                            </ErrorMessage>
                            <SubmitButton
                                onClick={() => {
                                    handleSubmit();
                                    // handleModal();
                                }}
                            >
                                Submit
                            </SubmitButton>
                        </BottomDiv>
                    </ContentsDiv>
                </Wrapper>
            )}
            {/* Bottom navigation component */}
            <BottomNavigation />
        </>
    );
}

export default SessionFeedbackModal;

const popup = keyframes`
    0%{
        scale: 0.3;
    }
    100%{
        scale: 1;
    }
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;
const Overlay = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.32;
    background: var(--Black, #000);
`;
const HeadingDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;
const Heading = styled.h4`
    font-weight: 500;
    font-size: 18px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    line-height: 26px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const CancelBtn = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    background: ${THEME_COLORS.white};
    border-radius: 32px;
    cursor: pointer;
`;
const CloseSvg = styled.div``;
const CloseSvgImg = styled.img``;
const Cancel = styled.span`
    font-size: 14px;
    font-family: "DM_Sans";
    color: ${THEME_COLORS.chips_green_on_container};
    margin-left: 8px;
`;

const ContentsDiv = styled.div`
    display: flex;
    width: 474px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    border-radius: 24px;
    border: 1px solid #d3d3d3;
    background: #fff;
    box-shadow: 27px 27px 48px 0px rgba(7, 11, 36, 0.1);
    z-index: 101;
    animation: ${popup} 0.4s ease-in-out;
    max-height: 95vh;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 480px) {
        width: 400px;
        padding: 20px;
    }
    @media all and (max-width: 420px) {
        width: 350px;
    }
    @media all and (max-width: 360px) {
        width: 330px;
    }
`;
const ContentDiv = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #fafafa;
    background: #fff;
    box-shadow: 2px 2px 16px 0px rgba(7, 11, 36, 0.06);
    /* @media all and (max-width: 480px) {
    padding: 10px;
  } */
`;
const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;
const TopSubContainer = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #e0f8f2;
`;
const TopUpContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`;
const Title = styled.div`
    color: #2b3990;
    font-family: "DM_sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
const SessionChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    /* gap: 6px; */
    background: #fff;
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-family: "DM_sans";
    font-size: 12px;
`;

const TopDownContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
`;

const GreyChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    background: #fff;
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
`;
const ChipIcon = styled.img`
    width: auto;
`;
const ChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.dark_300};
`;
const RatingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
`;
const ReviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
`;
const SessionSubtitle = styled.h6`
    color: #969696;
    font-family: "DM_Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const SessionTitle = styled.h3`
    color: #3e3e53;
    font-family: "DM_Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const Rate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`;
const ImageContainer = styled.div`
    width: 35px;
    height: 35px;
    cursor: pointer;
`;
const ImageTag = styled.img``;
const ErrorMessage = styled.span`
    font-size: 12px;
    color: red;
    margin-left: 10px;
    visibility: hidden;
    &.display {
        visibility: visible;
    }
`;
const FormContainer = styled.form`
    display: flex;
    padding: 6px 10px;
    align-items: flex-start;
    border: 2px solid #f4f4f4;
    background-color: #f4f4f4;
    transition: all 0.2s ease-in-out 0s;
    border-radius: 8px;
    width: 100%;
    /* height: 86px; */
    &.active {
        border: 2px solid ${THEME_COLORS.border_blue};
        background-color: #fff;
    }
`;
const Input = styled.textarea`
    font-size: 16px;
    width: 100%;
    height: 66px;
    line-height: 1.4em;
    font-family: "DM_sans";
    color: ${THEME_COLORS.black};
`;

const BottomDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
`;
const SubmitButton = styled.button`
    width: 100%;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    border-radius: 32px;
    background: linear-gradient(311deg, #2b3990 11.44%, #4f67f8 87.61%);
    color: #fff;
    text-align: center;
    font-family: "DM_Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
`;

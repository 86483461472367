import React, { useEffect } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

export default function TabbedSelector({
    tabOptions,
    selectedTab,
    setSelectedTab,
}) {
    useEffect(() => {
        setSelectedTab(tabOptions[0].value);
    }, []);

    return (
        <MainBox>
            {tabOptions.map((tabOption) => (
                <TabBox
                    key={tabOption.value}
                    selected={tabOption.value === selectedTab}
                    onClick={() => {
                        setSelectedTab(tabOption.value);
                    }}
                >
                    {tabOption.name}
                </TabBox>
            ))}
        </MainBox>
    );
}
const MainBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px;
    gap: 10px;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 10px;
    /* height: 40px; */
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }

    @media all and (max-width: 480px) {
        /* width: auto; */
        width: auto;
        max-width: fit-content;
        overflow-x: scroll;
    }
`;
const TabBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 6px 12px;
    /* min-width: 92px; */
    width: fit-content;
    height: 32px;
    box-sizing: border-box;
    font-family: "DM_sans_medium";
    font-size: 14px;
    border-radius: 6px;
    color: ${(props) =>
        props.selected ? THEME_COLORS.black : THEME_COLORS.text_para};
    /* border: ${(props) =>
        props.selected
            ? `1px solid ${THEME_COLORS.black}`
            : `1px solid ${THEME_COLORS.white}`}; */
    box-shadow: ${(props) =>
        props.selected ? `${THEME_COLORS.elevation_user_u4}` : ``};
    border: ${(props) =>
        props.selected
            ? ` 2px solid ${THEME_COLORS.chips_blue_on_container_2}`
            : `2px solid transparent`};
`;

import React from "react";

// packages
import styled from "styled-components";
import { Link } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../ThemeConfig";

export default function Head() {
  return (
    <>
      <Header>
        <Wrapper>
          <Nav>
            <NavLeft>
              <LogoContainer to="/onboarding-person">
                <Logo
                  src={
                    require("../../assets/images/logo-with-name.svg").default
                  }
                  alt="logo-image"
                />
              </LogoContainer>
            </NavLeft>
            {/* <NavRight>
                            <ProfilePic
                                src={require("../../assets/images/profile-pic.svg").default}
                                alt="profile-picture"
                            />
                        </NavRight> */}
          </Nav>
        </Wrapper>
      </Header>
    </>
  );
}
const Header = styled.header`
  height: 70px;
  border-bottom: 1px solid ${THEME_COLORS.light_300};
  background: ${THEME_COLORS.light_green};
`;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 100%;
`;
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const NavLeft = styled.h1`
  width: 10%;
  @media all and (max-width: 1440px) {
    width: 15%;
  }
  @media all and (max-width: 980px) {
    width: 25%;
  }
  @media all and (max-width: 640px) {
    width: 38%;
  }
  @media all and (max-width: 480px) {
    width: 60%;
  }
`;
const LogoContainer = styled(Link)`
  display: block;
`;
const Logo = styled.img``;
const NavRight = styled.div`
  width: 3%;
  @media all and (max-width: 1440px) {
    width: 4%;
  }
  @media all and (max-width: 980px) {
    width: 6%;
  }
  @media all and (max-width: 640px) {
    width: 10%;
  }
  @media all and (max-width: 480px) {
    width: 13%;
  }
`;
const ProfilePic = styled.img``;

import React, { useContext, useEffect } from "react";

// packages
import styled, { keyframes } from "styled-components";
import $ from "jquery";
import { useNavigate } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";
import { Context } from "../../../contexts/Store";

export default function LogoutModal({ onClose, modalOpen }) {
    const navigate = useNavigate();
    const { dispatch } = useContext(Context);

    // Function to disable scroll when modal opened
    useEffect(() => {
        if (modalOpen === "Logout") {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [modalOpen]);

    const handleLogout = () => {
        // localStorage.removeItem("user_details");
        dispatch({
            type: "LOGOUT",
        });
        dispatch({
            type: "UPDATE_USER",
            user_details: {
                is_verified: false,
            },
        });

        navigate("/auth/login/");
    };
    return (
        <Modal
            className={modalOpen === "Logout" ? "open" : ""}
            onClick={() => onClose()}
        >
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <Card>
                    <Title>Logout</Title>
                    <Description>
                        Are you sure you wanted to Logout ?
                    </Description>
                    <NavButtons>
                        <Cancel onClick={() => onClose()}>Cancel</Cancel>
                        <Logout onClick={() => handleLogout()}>Logout</Logout>
                    </NavButtons>
                </Card>
            </ModalContent>
        </Modal>
    );
}
const popup = keyframes`
    0%{
        scale: 0;
    }
    100%{
        scale: 1;
    }
`;
const Modal = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 305;
    display: none;
    &.open {
        display: flex;
    }
`;
const ModalContent = styled.div`
    width: 450px;
    border-radius: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    padding-top: 6px;
    /* position: absolute;
  left: 35%;
  top: 35%; */
    /* transform: translate(-50%, -50%); */
    animation: ${popup} 0.4s ease;
    @media all and (max-width: 480px) {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        height: fit-content;
        top: auto;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        animation-name: modal;
        animation-duration: 0.3s;
        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
`;
const Card = styled.div`
    border-radius: 24px;
    background: ${THEME_COLORS.white};
    padding: 32px;
    @media all and (max-width: 480px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const Title = styled.h4`
    font-family: "DM_sans_medium";
    font-size: 20px;
    margin-bottom: 20px;
    color: ${THEME_COLORS.text_title};
`;
const Description = styled.p`
    font-family: "DM_sans";
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 20px;
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Cancel = styled.div`
    border-radius: 32px;
    padding: 8px 20px;
    border: 2px solid ${THEME_COLORS.light_400};
    color: ${THEME_COLORS.dark_500};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;
const Logout = styled.div`
    border-radius: 32px;
    padding: 10px 20px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.secondary_gradient};
    font-size: 14px;
    width: 48%;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;

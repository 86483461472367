import React, { useContext, useState } from "react";

// packages
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// buttons
import NextButton from "../../includes/buttons/NextButton";
import PreviousButton from "../../includes/buttons/PreviousButton";

// store
import { Context } from "../../../contexts/Store";

export default function OnBoardingPersonName() {
    const { state, dispatch } = useContext(Context);
    const navigate = useNavigate();

    const [isFocused, setIsFocused] = useState("");
    const [error, setError] = useState(false);
    const [name, setName] = useState({
        first_name: state.user_details.user_name.first_name || "",
        last_name: state.user_details.user_name.last_name || "",
    });
    // console.log("==error", error);

    // error  validation, dispatching data and navigation
    const handleSubmit = () => {
        if (name.first_name === "") {
            setError(true);
        } else {
            setError(false);
            dispatch({
                type: "UPDATE_USER",
                user_details: {
                    user_name: name,
                },
            });
            navigate("/onboarding-person-age");
        }
    };

    return (
        <>
            <MainContainer>
                <ContentContainer>
                    <Content>
                        <div>
                            <SkipButton to="/onboarding-completed">
                                Skip for now
                            </SkipButton>
                            <LogoContainer>
                                <Logo
                                    src={
                                        require("../../../assets/images/logo.svg")
                                            .default
                                    }
                                    alt="logo"
                                />
                            </LogoContainer>

                            <Detail>
                                <Text>
                                    Hi there :) <br /> What should we call you?
                                </Text>
                                <ButtonContainer
                                    className={
                                        isFocused === "first-name-focus"
                                            ? "input-focus"
                                            : ""
                                    }
                                    onFocus={() => {
                                        setIsFocused("first-name-focus");
                                    }}
                                    onBlur={() => {
                                        setIsFocused("");
                                        setError(name.first_name === "");
                                    }}
                                >
                                    <NameContainer
                                        className={
                                            isFocused === "first-name-focus"
                                                ? "input-focus"
                                                : ""
                                        }
                                        onFocus={() => {
                                            setIsFocused("first-name-focus");
                                        }}
                                        onBlur={() => {
                                            setIsFocused("");
                                            setError(name.first_name === "");
                                        }}
                                    >
                                        <NameLabel>First Name *</NameLabel>
                                        <Name
                                            type="text"
                                            value={name["first_name"]}
                                            onChange={(e) => {
                                                setName({
                                                    first_name: e.target.value,
                                                    last_name: name.last_name,
                                                });
                                                setError(e.target.value === "");
                                            }}
                                        />
                                    </NameContainer>
                                    <ErrorMessage
                                        className={error ? "active" : ""}
                                    >
                                        first name is required
                                    </ErrorMessage>
                                </ButtonContainer>
                                <ButtonContainer
                                    className={
                                        isFocused === "last-name-focus"
                                            ? "input-focus last-name"
                                            : "last-name"
                                    }
                                    onFocus={() => {
                                        setIsFocused("last-name-focus");
                                    }}
                                    onBlur={() => {
                                        setIsFocused("last-name");
                                    }}
                                >
                                    <NameContainer>
                                        <NameLabel>Last Name </NameLabel>
                                        <Name
                                            type="text"
                                            value={name["last_name"]}
                                            onChange={(e) => {
                                                setName({
                                                    first_name: name.first_name,
                                                    last_name: e.target.value,
                                                });
                                            }}
                                        />
                                    </NameContainer>
                                </ButtonContainer>
                            </Detail>
                        </div>
                        <div>
                            <NavButtons>
                                <PreviousButton url="/onboarding-person" />
                                <NextButton
                                    onClick={handleSubmit}
                                    // data={{
                                    //     type: "UPDATE_USER",
                                    //     user_details: {
                                    //         user_name: name,
                                    //     },
                                    // }}
                                    // url="/onboarding-person-age"
                                    // disabled={name.first_name === ""}
                                    // onClick={() => {
                                    //     name.first_name === "" ? setError(true) : setError(false);
                                    // }}
                                />
                            </NavButtons>
                        </div>
                    </Content>
                </ContentContainer>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.section`
    height: calc(100vh - 70px);
    background: ${THEME_COLORS.light_100};
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        background: ${THEME_COLORS.white};
        display: block;
    }
`;
const ContentContainer = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: 468px;
    max-height: 80vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    /* @media all and (max-width: 1440px) {
    width: 35%;
  }
  @media all and (max-width: 1280px) {
    width: 43%;
  }
  @media all and (max-width: 1080px) {
    width: 45%;
  }
  @media all and (max-width: 980px) {
    width: 60%;
  }
  @media all and (max-width: 768px) {
    width: 70%;
  }
  @media all and (max-width: 640px) {
    width: 90%;
  } */
    @media all and (max-width: 480px) {
        width: 100%;
        border: none;
        box-shadow: none;
        height: 100% !important;
    }
`;
const Content = styled.div`
    padding: 30px 30px 60px;
    @media all and (max-width: 640px) {
        padding: 30px 20px 60px;
    }
    @media all and (max-width: 480px) {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
    @media all and (max-width: 360px) {
        padding: 20px 10px;
    }
`;
const SkipButton = styled(Link)`
    font-size: 14px;
    color: ${THEME_COLORS.chips_green_on_container};
    font-family: "DM_sans_medium";
    margin-bottom: 30px;
    text-align: right;
    display: block;
`;
const LogoContainer = styled.div`
    width: 56px;
    margin: 0 auto 60px auto;
`;
const Logo = styled.img``;
const Detail = styled.div`
    padding: 0 30px;
    position: relative;
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const Text = styled.h3`
    font-family: "DM_sans_bold";
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
    @media all and (max-width: 360px) {
        font-size: 23px;
    }
`;
const ButtonContainer = styled.div`
    border: 1px solid ${THEME_COLORS.light_100};
    border-radius: 25px;
    margin-bottom: 30px;
    padding: 2px;
    background: ${THEME_COLORS.light_100};

    &.input-focus {
        padding: 2px;
        border-radius: 25px;
        background: ${THEME_COLORS.secondary_gradient};
    }
    &.last-name {
        margin-bottom: 60px;
        margin-top: 30px;
        @media all and (max-width: 480px) {
            margin-bottom: 50%;
        }
    }
`;
const NameContainer = styled.div`
    background: ${THEME_COLORS.light_100};
    border-radius: 22px;
    padding: 8px 24px;
    border: 1px solid ${THEME_COLORS.light_100};
    &.input-focus {
        background: ${THEME_COLORS.white};
    }
`;
const ErrorMessage = styled.span`
    display: none;
    &.active {
        position: absolute;
        color: red;
        font-size: 12px;
        display: block;
        top: 65%;
    }
`;
const NameLabel = styled.span`
    display: block;
    color: ${THEME_COLORS.text_para};
    font-size: 12px;
    font-family: "DM_Sans";
`;
const Name = styled.input`
    font-size: 16px;
    font-family: "DM_sans_medium";
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    @media all and (max-width: 640px) {
        padding: 0 20px;
    }
    @media all and (max-width: 480px) {
        padding: 0 10px;
    }
`;

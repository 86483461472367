import React, { useEffect, useRef } from "react";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// packages
import styled from "styled-components";

export default function CategoryModal({ onClose, setValue }) {
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                onClose();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);
    return (
        <Modal ref={wrapperRef}>
            <ModalContent>
                <Category>
                    <Menu
                        onClick={() => {
                            setValue("All");
                            onClose();
                        }}
                    >
                        <IconContainer>
                            <Icon
                                src={
                                    require("../../../assets/images/all-media.svg")
                                        .default
                                }
                            ></Icon>
                        </IconContainer>
                        <Text>All Media</Text>
                    </Menu>
                    <Menu
                        onClick={() => {
                            setValue("Videos");
                            onClose();
                        }}
                    >
                        <IconContainer>
                            <Icon
                                src={
                                    require("../../../assets/images/video-icon.svg")
                                        .default
                                }
                            ></Icon>
                        </IconContainer>
                        <Text>Videos</Text>
                    </Menu>
                    <Menu
                        onClick={() => {
                            setValue("Podcasts");
                            onClose();
                        }}
                    >
                        <IconContainer>
                            <Icon
                                src={
                                    require("../../../assets/images/podcast.svg")
                                        .default
                                }
                            ></Icon>
                        </IconContainer>
                        <Text>Podcasts</Text>
                    </Menu>
                    <Menu
                        onClick={() => {
                            setValue("Articles");
                            onClose();
                        }}
                    >
                        <IconContainer>
                            <Icon
                                src={
                                    require("../../../assets/images/article.svg")
                                        .default
                                }
                            ></Icon>
                        </IconContainer>
                        <Text>Articles</Text>
                    </Menu>
                    <Menu
                        onClick={() => {
                            setValue("Images");
                            onClose();
                        }}
                    >
                        <IconContainer>
                            <Icon
                                src={
                                    require("../../../assets/images/image-icon.svg")
                                        .default
                                }
                            ></Icon>
                        </IconContainer>
                        <Text>Images</Text>
                    </Menu>
                    <Menu
                        className="last-child"
                        onClick={() => {
                            setValue("History");
                            onClose();
                        }}
                    >
                        <IconContainer>
                            <Icon
                                src={
                                    require("../../../assets/images/history.svg")
                                        .default
                                }
                            ></Icon>
                        </IconContainer>
                        <Text>History</Text>
                    </Menu>
                </Category>
            </ModalContent>
        </Modal>
    );
}
const Modal = styled.section`
    width: 180px;
    position: absolute;
    z-index: 2;
    right: 0;
    top: 40px;
`;
const ModalContent = styled.div`
    width: 100%;
    background: ${THEME_COLORS.white};
    border-radius: 12px;
    padding: 18px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const Category = styled.div``;
const Menu = styled.div`
    display: flex;
    gap: 25px;
    padding: 8px 10px;
    cursor: pointer;
    &:hover {
        background: ${THEME_COLORS.light_200};
        border-radius: 8px;
        padding: 8px 10px;
    }
    &.last-child {
        margin-bottom: 0;
    }
`;
const Text = styled.div`
    font-size: 14px;
    font-family: "DM_sans";
`;
const IconContainer = styled.div`
    width: 18px;
`;
const Icon = styled.img``;

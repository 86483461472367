import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../../ThemeConfig";
// import successIcon from "../../../../assets/icons/success-tick.svg";
import successIcon from "../../../../assets/icons/payment-success-icon.svg";
import failedIcon from "../../../../assets/icons/payment-faliled.svg";
import { PatientConfig } from "../../../../axiosConfig";
import { Context } from "../../../../contexts/Store";
import BottomNavigation from "../../../general/BottomNavigation";

export default function Transactions() {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const [transactions, setTransactions] = useState([]);

    function capitalizeFirstLetter(sentence) {
        return sentence.replace(/(^\w{1})|(\.\s+\w{1})/g, (match) =>
            match.toUpperCase()
        );
    }

    const getTransactions = () => {
        PatientConfig.get("/api/v1/patients/patient/payment-history/", {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            params: {
                // filter: selectedFilterType,
            },
        })
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode == 6000) {
                    setTransactions(data);
                } else if (StatusCode == 6001) {
                    // console.log(error, "error");
                }
            })
            .catch((error) => {
                if (error.response.data.data.message) {
                    alert(error?.response?.data?.data?.message);
                }
            });
    };

    useEffect(() => {
        getTransactions();
    }, []);

    return (
        <>
            <MainContainer>
                <ContentDiv>
                    <Heading>Transaction History</Heading>
                    {transactions.length > 0 ? (
                        <TransactionCards>
                            {transactions.map((transaction) => (
                                <TransactionCard
                                // status={
                                //     transaction?.is_refund
                                //         ? "refund"
                                //         : transaction?.success
                                //         ? "success"
                                //         : "failed"
                                // }
                                >
                                    <HeadContainer>
                                        <HeadLeft>
                                            <StatusIconContainer
                                                isSuccess={transaction?.success}
                                            >
                                                <StatusIcon
                                                    src={
                                                        transaction?.success
                                                            ? successIcon
                                                            : failedIcon
                                                    }
                                                    alt="icon"
                                                />
                                            </StatusIconContainer>
                                            <ConcernHeadContainer>
                                                <ConcernTitle>
                                                    {transaction?.session_name}
                                                </ConcernTitle>
                                                <ConcernSubTitle>
                                                    {transaction?.case_id}
                                                </ConcernSubTitle>
                                            </ConcernHeadContainer>
                                        </HeadLeft>
                                        {transaction?.is_refund && (
                                            <RefundStatus>Refund</RefundStatus>
                                        )}
                                    </HeadContainer>
                                    <DetailsBox>
                                        <DetailItem>
                                            <DetailGreyText>
                                                Amount:
                                            </DetailGreyText>
                                            <DetailBlackText>
                                                ₹ {transaction?.amount}
                                            </DetailBlackText>
                                        </DetailItem>
                                        <DetailItem>
                                            <DetailGreyText>
                                                Txn ID:
                                            </DetailGreyText>
                                            <DetailBlackText>
                                                {transaction?.transaction_id}
                                            </DetailBlackText>
                                        </DetailItem>
                                        {!transaction.is_refund && (
                                            <DetailItem>
                                                <DetailGreyText>
                                                    Payment type:
                                                </DetailGreyText>
                                                <DetailBlackText>
                                                    {transaction?.payment_type}
                                                </DetailBlackText>
                                            </DetailItem>
                                        )}
                                        <DetailItem>
                                            <DetailGreyText>
                                                Date:
                                            </DetailGreyText>
                                            <DetailBlackText>
                                                {transaction?.date}
                                            </DetailBlackText>
                                        </DetailItem>
                                    </DetailsBox>
                                </TransactionCard>
                            ))}
                        </TransactionCards>
                    ) : (
                        <NoItem>
                            No transactions found. You haven't made any
                            transactions yet.
                        </NoItem>
                    )}
                </ContentDiv>
                <BottomNavigation />
            </MainContainer>
        </>
    );
}

const MainContainer = styled.div`
    width: 100%;
    padding: 20px 0px;
    background: ${THEME_COLORS.light_200};
    overflow: auto;
    display: flex;
    flex-direction: column;

    align-items: center;
    /* position: relative; */
    /* z-index: -1; */
    @media all and (max-width: 768px) {
        width: 100%;
        padding-bottom: 120px;
    }
    @media all and (max-width: 360px) {
        width: 100%;
        padding-bottom: 90px;
    }
`;
const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 24px;
    width: 453px;
    background: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 16px;
    padding: 20px;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    @media all and (max-width: 768px) {
        width: 410px;
    }
    @media all and (max-width: 480px) {
        width: 360px;
        padding: 20px 14px;
        gap: 20px;
    }
    @media all and (max-width: 360px) {
        width: 324px;
    }
`;
const Heading = styled.h3`
    font-size: 20px;
    text-align: start;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
`;
const TransactionCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const TransactionCard = styled.div`
    /* border: 1px solid #e2e2e2; */
    border: ${(props) =>
        props.status == "refund"
            ? "2px solid #366ee3"
            : props.status == "success"
            ? "2px solid #1DA687"
            : props.status == "failed"
            ? "2px solid #FE6666"
            : "2px solid #e2e2e2"};
    border-radius: 16px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;
const HeadContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    /* align-items: center; */
    gap: 8px;
`;
const HeadLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
const RefundStatus = styled.span`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 14px;
    background: #e8effe;
    color: #366ee3;
    padding: 8px 12px;
    border-radius: 16px;
`;
const ConcernHeadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
`;
const ConcernTitle = styled.span`
    display: inline-block;
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
`;
const ConcernSubTitle = styled.span`
    font-size: 14px;
    color: #818181;
    font-family: "DM_sans";
    text-align: center;
    /* text-transform: lowercase; */
`;

const TransactionId = styled.span`
    display: inline-block;
`;
const StatusText = styled.span`
    display: inline-block;
`;
const StatusIconContainer = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${(props) => (props.isSuccess ? "#e0f8f2" : "#fceded")};
`;

const StatusIcon = styled.img`
    width: 70%;
    display: block;
`;
const DateText = styled.span`
    display: inline-block;
`;
const DetailsBox = styled.div`
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    display: flex;
    flex-direction: column;
    gap: 12px;
`;
const DetailItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    /* justify-content: space-between; */
`;
const DetailGreyText = styled.div`
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #686868;
`;
const DetailBlackText = styled.div`
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #333333;
`;
const NoItem = styled.div`
    border: 1px dashed #e2e2e2;
    /* height: 60px; */
    height: 100%;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: #686868;
    padding: 20px;
    text-align: center;
`;

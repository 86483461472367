import React, { useContext } from "react";

// packages
import styled from "styled-components";
import { Link } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";
import { Context } from "../../../contexts/Store";

export default function OnBoardingCompleted() {
    const { state } = useContext(Context);
    // console.log(state);

    return (
        <MainContainer>
            <ContentContainer>
                <Content>
                    <LogoContainer>
                        <Logo
                            src={
                                require("../../../assets/images/logo.svg")
                                    .default
                            }
                            alt="logo"
                        />
                    </LogoContainer>
                    <Text>Congratulations 🎉</Text>
                    <SubText>
                        You completed onboarding, Let's get <br /> Started
                    </SubText>
                    <ButtonContainer to="/">
                        <ButtonText>Get Started</ButtonText>
                        <ArrowContainer>
                            <Arrow
                                src={
                                    require("../../../assets/images/right-arrow.svg")
                                        .default
                                }
                                alt="arrow-image"
                            />
                        </ArrowContainer>
                    </ButtonContainer>
                </Content>
            </ContentContainer>
        </MainContainer>
    );
}
const MainContainer = styled.section`
    height: calc(100vh - 70px);
    background: ${THEME_COLORS.light_100};
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        background: ${THEME_COLORS.white};
        display: block;
    }
`;
const ContentContainer = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: 468px;
    /* @media all and (max-width: 1440px) {
        width: 38%;
    }
    @media all and (max-width: 1280px) {
        width: 45%;
    }
    @media all and (max-width: 1080px) {
        width: 50%;
    }
    @media all and (max-width: 980px) {
        width: 60%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 640px) {
        width: 85%;
    } */
    @media all and (max-width: 480px) {
        width: 100%;
        border: none;
        box-shadow: none;
        height: 100%;
    }
`;
const Content = styled.div`
    padding: 80px 50px;
    @media all and (max-width: 360px) {
        padding: 80px 30px;
    }
`;
const LogoContainer = styled.div`
    width: 56px;
    margin: 0 auto 60px auto;
`;
const Logo = styled.img``;
const Text = styled.h3`
    font-family: "DM_sans_bold";
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
`;
const SubText = styled.h4`
    font-size: 14px;
    color: ${THEME_COLORS.dark_300};
    font-family: "DM_sans_medium";
    text-align: center;
    margin-bottom: 60px;
`;
const ButtonContainer = styled(Link)`
    display: flex;
    justify-content: space-around;
    background: ${THEME_COLORS.secondary_gradient};
    padding: 15px 34%;
    border-radius: 24px;
    align-items: center;
    cursor: pointer;
    @media all and (max-width: 768px) {
        padding: 15px 30%;
    }
    @media all and (max-width: 480px) {
        width: 65%;
        padding: 15px 10%;
        margin: 0 auto;
    }
`;
const ButtonText = styled.span`
    font-size: 16px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.white};
    font-family: "DM_sans_medium";
`;
const ArrowContainer = styled.div`
    width: 7px;
`;
const Arrow = styled.img``;

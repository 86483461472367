import React, { useContext, useEffect, useRef } from "react";
import { styled } from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";
import { Context } from "../../../contexts/Store";

export default function CountModal({ setOpen, setPatient }) {
    const wrapperRef = useRef(null);
    const { dispatch, state } = useContext(Context);

    // useEffect(() => {
    //   function handleClickOutside(event) {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       setOpen(false);
    //     }
    //   }
    //   // Bind the event listener
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     // Unbind the event listener on clean up
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [wrapperRef, setOpen]);
    return (
        <Modal ref={wrapperRef}>
            <ModalContent>
                <div>
                    <Content
                        onClick={() => {
                            dispatch({
                                type: "ADD_SESSION",
                                new_session: {
                                    session_count: "Individual",
                                },
                            });
                            setPatient("Individual");
                            setOpen(false);
                        }}
                    >
                        Individual
                    </Content>
                    <Content
                        onClick={() => {
                            dispatch({
                                type: "ADD_SESSION",
                                new_session: {
                                    session_count: "Family",
                                },
                            });
                            setPatient("Family");
                            setOpen(false);
                        }}
                    >
                        Family
                    </Content>
                </div>
            </ModalContent>
        </Modal>
    );
}
const Modal = styled.div`
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 55px;
    left: 0;
    box-shadow: 10px 12px 48px 0px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    background: ${THEME_COLORS.white};
    padding: 8px 10px;
`;
const ModalContent = styled.div`
    /* box-shadow: 2px 2px 16px rgba(7, 11, 36, 0.06); */
`;
const Content = styled.div`
    font-family: "DM_sans";
    font-size: 14px;
    padding: 8px 10px;
    color: ${THEME_COLORS.black};
    cursor: pointer;
    &:hover {
        background-color: ${THEME_COLORS.light_200};
        padding: 8px 10px;
        border-radius: 6px;
    }
`;

import React, { useState, useRef, useEffect } from "react";

import styled, { keyframes } from "styled-components";

// icons
import cancelledIcon from "../../../assets/icons/session-cancelled-icon.svg";
import feedbackIcon from "../../../assets/icons/add-feedback.svg";
// import rescheduleIcon from "../../../assets/icons/reshedule-black.svg";

// modal
import CancelModal from "./CancelModal";
import SessionFeedbackModal from "./SessionFeedbackModal";

const SessionCardMenu = ({
    closeModal,
    id,
    idForFeedback,
    selectedTab,
    setIsModalOpen,
    setIsSuccessModalOpen,
    isModalOpen,
    handleCloseMenuPopover,
    getSessionDetails,
    usedAt,
}) => {
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

    // Menu click
    const handleCancelClick = () => {
        setIsCancelModalOpen((prev) => !prev);
    };

    // Menu click
    const handleFeedbackClick = () => {
        setIsFeedbackModalOpen((prev) => !prev);
        if (isFeedbackModalOpen == true) {
            handleCloseMenuPopover();
        }
    };

    return (
        // <Wrapper>
        <MenuItems
            usedAt={usedAt}
            // onClick={(e) => e.stopPropagation()}
        >
            {selectedTab == "upcoming" || selectedTab == "pending" ? (
                <>
                    {/* Reschedule not in first phase, coomented to use in next phase */}
                    {/* <MenuItem>
          <MenuIconContainer>
            <MenuIcon src={rescheduleIcon} alt="Icon" />
          </MenuIconContainer>
          <MenuTitle>Reschedule</MenuTitle>
        </MenuItem> */}
                    <MenuItem
                        onClick={() => {
                            handleCancelClick();
                        }}
                    >
                        <MenuIconContainer>
                            <MenuIcon src={cancelledIcon} alt="Icon" />
                        </MenuIconContainer>
                        <MenuTitle className="red">Cancel</MenuTitle>
                    </MenuItem>
                </>
            ) : selectedTab == "completed" ? (
                <>
                    <MenuItem
                        onClick={() => {
                            handleFeedbackClick();
                        }}
                    >
                        <MenuIconContainer>
                            <MenuIcon src={feedbackIcon} alt="Icon" />
                        </MenuIconContainer>
                        <MenuTitle>Add Feedback</MenuTitle>
                    </MenuItem>
                </>
            ) : (
                ""
            )}

            {isCancelModalOpen && (
                <CancelModal id={id} closeModal={() => handleCancelClick()} />
            )}
            {isFeedbackModalOpen && (
                <SessionFeedbackModal
                    id={id}
                    idForFeedback={idForFeedback}
                    closeModal={() => handleFeedbackClick()}
                    setIsModalOpen={setIsModalOpen}
                    setIsSuccessModalOpen={setIsSuccessModalOpen}
                    handleCloseMenuPopover={handleCloseMenuPopover}
                    setIsFeedbackModalOpen={setIsFeedbackModalOpen}
                    getSessionDetails={getSessionDetails}
                />
            )}
        </MenuItems>
        // </Wrapper>
    );
};

export default SessionCardMenu;

const popup = keyframes`
  0%{
      scale: 0.5;
  }
  100%{
      scale: 1;
  }
`;
const Wrapper = styled.div`
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const MenuItems = styled.div`
    /* animation: ${popup} 0.3s ease-in-out; */
    position: ${(props) =>
        props.usedAt == "individual_page" ? "absolute" : "unset"};
    bottom: ${(props) =>
        props.usedAt == "individual_page" ? "-60px" : "unset"};
    right: ${(props) => (props.usedAt == "individual_page" ? "0" : "unset")};
    box-shadow: ${(props) =>
        props.usedAt == "individual_page"
            ? "10px 12px 48px 0px rgba(0, 0, 0, 0.25)"
            : "unset"};

    display: flex;
    width: auto;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    /* position: absolute; */
    /* right: 15px;
    top: 50px; */
    border-radius: 12px;
    border: 0px solid #1da687;
    background: #fff;

    /* box-shadow: 10px 12px 48px 0px rgba(0, 0, 0, 0.25); */
`;
const MenuItem = styled.li`
    cursor: pointer;
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    &:hover {
        background: #f4f4f4;
    }
`;
const MenuIconContainer = styled.div`
    width: 20px;
    height: 20px;
`;
const MenuIcon = styled.img``;
const MenuTitle = styled.span`
    color: #000;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-wrap: nowrap;
    &.red {
        color: #fe6666;
    }
`;

import React, { useContext } from "react";

// package
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

// store
import { Context } from "../../../../contexts/Store";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";
import CancelButton from "../../../includes/buttons/CancelButton";

export default function SessionConfirmed() {
    const { state } = useContext(Context);
    // console.log(state);

    // conversion of date to day,date,month and year
    const datetimeString = state.user_details.fixture;
    const formattedDate = moment(datetimeString).format("dddd, DD MMM, YYYY");

    // adding 45 minute to given time
    const timeString = state.user_details.time;
    const timeMoment = moment(timeString, "h:mm A").add(45, "minutes");
    const formattedTime = timeMoment.format("h:mm A");

    return (
        <>
            <ContentContainer>
                <Content>
                    <Head>
                        <Heading>Create a Session</Heading>
                        <CancelButton
                            path="/"
                            style={{ padding: "6px 20px" }}
                        />
                    </Head>
                    <ContentBox>
                        <ConfirmedBox>
                            <HeadText>Confirmed ✅</HeadText>
                            <SessionMenu>
                                <SessionId>Session 1</SessionId>
                                <SessionName>I need a talk!</SessionName>
                                <SessionDescription>
                                    Feeling really unmotivated and lazy lately.
                                    I find myself procrastinating and putting
                                    things off.
                                </SessionDescription>
                                <SessionDate>
                                    <CalenderContainer>
                                        <Calender
                                            src={
                                                require("../../../../assets/images/calender.svg")
                                                    .default
                                            }
                                            alt="calender-image"
                                        />
                                    </CalenderContainer>
                                    <Day>{formattedDate}</Day>
                                </SessionDate>
                                <SessionDiv>
                                    <SessionTime>
                                        <ClockContainer>
                                            <Clock
                                                src={
                                                    require("../../../../assets/images/alarm_on.svg")
                                                        .default
                                                }
                                                alt="clock"
                                            />
                                        </ClockContainer>
                                        <Time>
                                            {timeString} - {formattedTime}
                                        </Time>
                                    </SessionTime>
                                    <SessionMode>
                                        <MeetIcon>
                                            <Icon
                                                src={
                                                    require("../../../../assets/images/videocam.svg")
                                                        .default
                                                }
                                                alt="google-meet-icon"
                                            />
                                        </MeetIcon>
                                        <Gmeet>Google Meet</Gmeet>
                                    </SessionMode>
                                </SessionDiv>
                            </SessionMenu>
                        </ConfirmedBox>
                        <TagLine>
                            {" "}
                            <ImpContainer>
                                <Imp
                                    src={
                                        require("../../../../assets/images/important-icon.svg")
                                            .default
                                    }
                                    alt="important icon"
                                />
                            </ImpContainer>
                            <Notify>
                                {" "}
                                We will notify you the time and date as
                                scheduled.
                            </Notify>
                        </TagLine>
                        <NavButtons>
                            <HideButton to="/">Hide</HideButton>
                            <DoneButton to="/">Done</DoneButton>
                        </NavButtons>
                    </ContentBox>
                </Content>
            </ContentContainer>
        </>
    );
}

const ContentContainer = styled.div`
    background: ${THEME_COLORS.light_200};
    width: 100%;
    height: calc(100vh - 70px);
`;
const Content = styled.div`
    width: 452px;
    margin: 0 auto;
    padding-top: 50px;
    max-height: 90vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 480px) {
        width: 90%;
        padding-top: 30px;
    }
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        margin-bottom: 50px;
    }
`;
const Heading = styled.h3`
    font-size: 20px;
    font-family: "DM_sans";
`;
const ContentBox = styled.div`
    background: white;
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_400};
    padding: 20px;
`;
const ConfirmedBox = styled.div`
    background: ${THEME_COLORS.chips_green_container};
    border-radius: 24px;
    padding: 20px;
    margin-bottom: 30px;
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;

const HeadText = styled.h2`
    font-size: 20px;
    text-align: center;
    font-family: "DM_sans";
    margin-bottom: 20px;

    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const SessionMenu = styled.div`
    background: ${THEME_COLORS.white};
    border-radius: 8px;
    padding: 16px;
    @media all and (max-width: 640px) {
        padding: 20px;
    }
    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const SessionId = styled.span`
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-size: 12px;
    background: ${THEME_COLORS.chips_blue_container};
    padding: 4px 10px;
    border-radius: 4px;
    display: inline-block;
    margin-bottom: 5px;
`;
const SessionName = styled.div`
    font-size: 16px;
    font-family: "DM_sans_medium";
    margin-bottom: 20px;
`;
const SessionDescription = styled.p`
    font-size: 14px;
    font-family: "DM_sans";
    margin-bottom: 20px;
    color: ${THEME_COLORS.text_para};
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 20px;
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 12px;
    }
`;
const SessionDate = styled.div`
    display: flex;
    align-items: center;
    background: ${THEME_COLORS.chips_green_container};
    padding: 10px 18px;
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 8px;
    margin-bottom: 10px;
    width: fit-content;
    @media all and (max-width: 360px) {
        padding: 10px 12px;
    }
`;
const CalenderContainer = styled.div`
    width: 20px;
    margin-right: 10px;
`;
const Calender = styled.img``;
const Day = styled.span`
    font-size: 14px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.dark_400};
    @media all and (max-width: 360px) {
        font-size: 12px;
    }
`;
const SessionDiv = styled.div`
    display: flex;
    justify-content: space-between;
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const SessionTime = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
    width: fit-content;
    background: ${THEME_COLORS.chips_green_container};
    padding: 8px 12px;
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 8px;
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
`;
const ClockContainer = styled.div`
    width: 20px;
`;
const Clock = styled.img``;
const Time = styled.span`
    font-size: 14px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.dark_400};
`;

const SessionMode = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    width: fit-content;
    background: ${THEME_COLORS.chips_green_container};
    padding: 8px 15px;
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 8px;
`;
const MeetIcon = styled.div`
    width: 20px;
`;
const Icon = styled.img``;
const Gmeet = styled.span`
    font-size: 14px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.dark_400};
`;
const TagLine = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 100px;
    background: ${THEME_COLORS.menu_blue};
    padding: 12px;
    border-radius: 8px;
    align-items: center;
`;
const ImpContainer = styled.div`
    width: 22px;
`;
const Imp = styled.img``;
const Notify = styled.span`
    font-size: 14px;
    color: ${THEME_COLORS.dark_500};
    @media all and (max-width: 480px) {
        font-size: 12px;
    }
`;

const NavButtons = styled.div`
    display: flex;
    gap: 10px;
    width: 50%;
    margin-left: 50%;
    @media all and (max-width: 480px) {
        width: 60%;
        margin-left: 40%;
    }
    @media all and (max-width: 360px) {
        width: 65%;
        margin-left: 35%;
    }
`;
const HideButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "DM_sans_medium";
    border: 2px solid ${THEME_COLORS.light_400};
    border-radius: 32px;
    padding: 10px 20px;
    width: 48%;
    cursor: pointer;
`;
const DoneButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "DM_sans_medium";
    width: 48%;
    background: ${THEME_COLORS.secondary_gradient};
    border-radius: 32px;
    color: ${THEME_COLORS.white};
    padding: 10px 20px;
    cursor: pointer;
`;

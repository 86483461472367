import React, { useContext, useEffect, useRef } from "react";

//package
import styled from "styled-components";
import { Link } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";
import { Context } from "../../../contexts/Store";

export default function MoreModal({ setOpen, setModalOpen, additionalData }) {
    const wrapperRef = useRef(null);
    const { state } = useContext(Context);
    const auth = state.user_details.is_verified;

    // outside click modal closes function
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setOpen("");
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, setOpen]);

    // initializing data of the modal
    const data = [
        // {
        //     id: 1,
        //     value: "Tickets",
        //     image: require("../../../assets/images/ticket-green.svg").default,
        //     path: "/coming-soon",
        // },
        // {
        //     id: 2,
        //     value: "Settings",
        //     image: require("../../../assets/images/settings-green.svg").default,
        //     path: "/coming-soon",
        // },
        {
            id: 3,
            value: "Logout",
            image: require("../../../assets/images/logout-green.svg").default,
        },
    ];
    return (
        <>
            <Modal ref={wrapperRef} auth={auth}>
                <ModalContent>
                    {/* rendering data */}
                    {additionalData?.map((item) => {
                        return (
                            <OptionContainer
                                to={item?.path}
                                key={item?.id}
                                onClick={() => {
                                    setModalOpen(item?.value);
                                    setOpen("");
                                }}
                            >
                                <ImageContainer>
                                    <Image src={item?.image} />
                                </ImageContainer>
                                <Option>{item?.value}</Option>
                            </OptionContainer>
                        );
                    })}
                    {auth &&
                        data.map((item) => {
                            return (
                                <OptionContainer
                                    to={item?.path}
                                    key={item.id}
                                    onClick={() => {
                                        setModalOpen(item.value);
                                        setOpen("");
                                    }}
                                >
                                    <ImageContainer>
                                        <Image src={item.image} />
                                    </ImageContainer>
                                    <Option>{item.value}</Option>
                                </OptionContainer>
                            );
                        })}
                </ModalContent>
            </Modal>
        </>
    );
}
const Modal = styled.section`
    width: 100%;
`;
const ModalContent = styled.div`
    width: 100%;
    background: ${THEME_COLORS.white};
    border-radius: 12px;
    padding: 10px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    position: absolute;
    z-index: 2;
    right: 0;
    /* top: -180px; */
    /* top: -75px; */
    top: ${(props) => (props.auth ? "-75px" : "-25px")};
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        animation-name: modal;
        animation-duration: 0.3s;
        align-items: center;
        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
    @media all and (max-width: 360px) {
        top: -90px;
    }
`;
const OptionContainer = styled(Link)`
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
        color: ${THEME_COLORS.black};
        background-color: ${THEME_COLORS.menu_blue};
        border-radius: 6px;
    }
    @media all and (max-width: 768px) {
        width: 25%;
        margin: 0 auto 10px auto;
    }
    @media all and (max-width: 640px) {
        width: 40%;
    }
    @media all and (max-width: 480px) {
        width: 50%;
    }
`;
const ImageContainer = styled.div`
    width: 20px;
`;
const Image = styled.img``;
const Option = styled.span`
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
`;

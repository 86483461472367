import React, { useContext, useEffect, useState } from "react";
import "../../../assets/css/style.css";

// packages
import styled from "styled-components";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useParams,
} from "react-router-dom";

// components
import Fulfill from "../../screens/dashboard/home/Fulfill";
import FindingTherapist from "../../screens/dashboard/FindingTherapist";
import SessionConfirmed from "../../screens/dashboard/home/SessionConfirmed";
import Challenge from "../../screens/dashboard/home/Challenge";
import Explore from "../../screens/dashboard/Explore";
import Statistics from "../../screens/dashboard/Statistics";
import Myaccount from "../../screens/dashboard/account/MyAccount";
import PrivacyPolicy from "../../screens/dashboard/account/PrivacyPolicy";
import TermsAndCondition from "../../screens/dashboard/account/TermsAndCondition";
import Faq from "../../screens/dashboard/account/Faq";
import VideoPlayer from "../../includes/modals/Video";
import Describe from "../../screens/dashboard/home/Describe";
import Schedule from "../../screens/dashboard/home/Schedule";
import MoreModal from "../../includes/modals/MoreModal";
import Header from "../../general/Header";
import Sidebar from "../../general/Sidebar";
import MeditateHome from "../../screens/dashboard/meditate/MeditateHome";
import Page404 from "../../general/Page404";
import Homepage from "../../screens/homescreens/Homepage";
import PrivateRoute from "../routes/PrivateRoute";
import SessionRoute from "../routes/SessionRoute";
import SessionIndividualPage from "../../includes/homescreens/SessionIndividualPage";
import { Context } from "../../../contexts/Store";
import CancelSession from "../../screens/dashboard/home/CancelSession";
import About from "../../screens/dashboard/account/About";
import Notification from "../../screens/dashboard/notification/Notification";
import ComingSoon from "../../general/ComingSoon";
import SummaryReport from "../../includes/homescreens/SummaryReport";
import RefundPolicy from "../../screens/dashboard/account/RefundPolicy";
import { PatientConfig, socketUrl } from "../../../axiosConfig";
import Transactions from "../../screens/dashboard/account/Transactions";
import CreateSessionSuccessModal from "../../includes/modals/CreateSessionSuccessModal";

export default function HomeRouter() {
    const { state, dispatch } = useContext(Context);
    const userId = state.user_details.user_id;
    const access_token = state.user_details.access_token;
    const location = useLocation();
    const isPaymentPending = state.user_details.ipp;

    // console.log(location, "location");

    // notifactions
    const [unreadNotifictaionsCount, setUnreadNotificationsCount] = useState(0);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const [isExistingSession, setIsExistingSession] = useState(false);

    const [acceptedSessionData, setAcceptedSessionData] = useState([]);
    const [acceptedSessionIds, setAcceptedSessionIds] = useState([]);
    const [isAcceptedModalOpen, setIsAcceptedModalOpen] = useState(false);
    const [isASessionAccepted, setIsASessionAccepted] = useState(false);

    useEffect(() => {
        let sessionDetails = localStorage.getItem("session_details");
        let new_session = localStorage.getItem("new_session");

        sessionDetails = JSON.parse(sessionDetails);
        new_session = JSON.parse(new_session);

        dispatch({
            type: "UPDATE_SESSION_DETAILS",
            new_session: sessionDetails,
        });
        dispatch({
            type: "ADD_SESSION",
            new_session: new_session,
        });
    }, []);

    const getNotifications = () => {
        PatientConfig.get("api/v1/general/notifications/", {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        })
            .then((response) => {
                const {
                    StatusCode,
                    data,
                    existing_session, //to check if it is new user
                    unread_notifications_count,
                } = response.data;
                if (StatusCode == 6000) {
                    setIsExistingSession(existing_session); //check new user

                    setUnreadNotificationsCount(unread_notifications_count);
                    setUnreadNotifications(
                        data.filter(
                            (notification) => notification.read === false
                        )
                    );
                    setNotifications(data);

                    // for displaying session accepted modal
                    const unreadAcceptedSessions = data.filter(
                        (notification) =>
                            notification.read === false &&
                            notification.notification_type ===
                                "Session accepted"
                    );
                    // console.log(
                    //     unreadAcceptedSessions,
                    //     "unreadAcceptedSessions"
                    // );
                    if (unreadAcceptedSessions.length > 0) {
                        // console.log("unreadAcceptedSessions.length > 0");
                        const currentAcceptedSessionIds =
                            unreadAcceptedSessions.map(
                                (notification) => notification.id
                            );
                        // console.log(
                        //     currentAcceptedSessionIds,
                        //     "currentAcceptedSessionIds"
                        // );
                        const acceptedSessionExtraData =
                            unreadAcceptedSessions.map(
                                (notification) => notification.extra_data
                            );
                        // console.log(
                        //     acceptedSessionExtraData,
                        //     "acceptedSessionExtraData"
                        // );
                        // Update state with the new data
                        setAcceptedSessionIds(currentAcceptedSessionIds);
                        setAcceptedSessionData(acceptedSessionExtraData);
                        setIsAcceptedModalOpen(true);
                        setIsASessionAccepted(true);
                    }
                    // console.log(response.data.data, 'notify')
                } else if (StatusCode == 6001) {
                    // SetError(response);
                    // console.log(error, 'error')
                }
            })
            .catch((error) => {
                // console.error('Error fetching data:', error)
                // SetError(error.message);
            });
    };
    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        const socket = new WebSocket(`${socketUrl}/notifications/${userId}/`);

        socket.onopen = () => {
            console.log("WebSocket connected");
        };

        socket.onmessage = (event) => {
            // alert("new new new");
            // console.log("on message:", event.data); //event.data
            // console.log("on message:");
            getNotifications();
        };

        socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        socket.onclose = () => {
            console.log("WebSocket connection closed");
        };

        return () => {
            socket.close();
        };
    });

    // const extractIdFromPath = (path) => {
    //     const parts = path.split("/");
    //     const lastPart = parts[parts.length - 1];
    //     return lastPart ? lastPart : null;
    // };
    const extractIdFromPath = (path) => {
        // Remove any trailing slashes from the path
        const cleanedPath = path.replace(/\/+$/, "");
        // console.log(cleanedPath, "cleanedPath");
        const parts = cleanedPath.split("/");
        const lastPart = parts[parts.length - 1];
        return lastPart ? lastPart : null;
    };

    const idFromPath = extractIdFromPath(location.pathname);
    // console.log(isExistingSession, "isExistingSession");

    const path = [
        "/",
        "/describe",
        "/schedule",
        "/fulfill",
        "/finding-therapist",
        "/session-confirmed",
        "/home",
        "/meditate",
        "/explore",
        "/statistics",
        "/account",
        "/privacy-policy",
        "/terms-and-conditions",
        "/transaction-history",
        "/refund-policy",
        "/faq",
        "/about-us",
        "/video",
        "/more",
        "/homepage",
        "/create-session",
        "/notifications",
        `/session/${idFromPath}`,
        `/view-summary-report/${idFromPath}`,
        `/cancel-session/${idFromPath}`,
        `/payment/${idFromPath}`,
    ];
    // Check if the current location is included in the defined paths
    // const renderComponent = path.includes(location.pathname);
    const renderComponent = path.some((p) => {
        const trimmedPathname = location.pathname.replace(/\/+$/, ""); // Trim trailing slashes
        const trimmedPath = p.replace(/\/+$/, ""); // Trim trailing slashes
        return trimmedPathname === trimmedPath;
    });
    return (
        <>
            {renderComponent && (
                <Header
                    getHeaderNotifications={getNotifications}
                    unreadNotifictaionsCount={unreadNotifictaionsCount}
                    unreadNotifications={unreadNotifications}
                />
            )}{" "}
            {/* Render Header component if the current location is included */}
            <Container className={renderComponent ? "" : "none"}>
                {renderComponent && <Sidebar />}{" "}
                {/* Render Sidebar component if the current location is included */}
                <Routes>
                    {/* Define the routes with their respective components */}
                    <Route
                        exact
                        path="/"
                        element={
                            <SessionRoute>
                                <Homepage
                                    isExistingSession={isExistingSession}
                                    isASessionAccepted={isASessionAccepted}
                                    setIsASessionAccepted={
                                        setIsASessionAccepted
                                    }
                                />
                            </SessionRoute>
                        }
                    />

                    <Route
                        exact
                        path="/payment/:paymentId" //payment id
                        element={
                            <SessionRoute>
                                <Homepage
                                    isASessionAccepted={isASessionAccepted}
                                    setIsASessionAccepted={
                                        setIsASessionAccepted
                                    }
                                />
                            </SessionRoute>
                        }
                    />
                    <Route
                        exact
                        path="/create-session"
                        element={
                            <PrivateRoute>
                                <Challenge />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        exact
                        path="/describe"
                        element={
                            <PrivateRoute>
                                <Describe />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/schedule"
                        element={
                            <PrivateRoute>
                                <Schedule />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/fulfill"
                        element={
                            <PrivateRoute>
                                <Fulfill />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/transaction-history"
                        element={
                            <PrivateRoute>
                                <Transactions />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/cancel-session/:id"
                        element={
                            <PrivateRoute>
                                <CancelSession />
                            </PrivateRoute>
                        }
                    />

                    <Route
                        exact
                        path="/notifications"
                        element={
                            <PrivateRoute>
                                <Notification
                                    getHeaderNotifications={getNotifications}
                                    // isExistingSession={isExistingSession}
                                />
                            </PrivateRoute>
                        }
                    />
                    {/* <Route
                        exact
                        path="/finding-therapist"
                        element={<FindingTherapist />}
                    /> */}
                    <Route
                        exact
                        path="/session-confirmed"
                        element={<SessionConfirmed />}
                    />
                    <Route exact path="/meditate" element={<MeditateHome />} />
                    <Route exact path="/explore" element={<Explore />} />
                    <Route
                        exact
                        path="/session/:id"
                        element={
                            <PrivateRoute>
                                <SessionIndividualPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/view-summary-report/:id"
                        element={
                            <PrivateRoute>
                                <SummaryReport />
                            </PrivateRoute>
                        }
                    />
                    {/* <Route
                        exact
                        path="/statistics"
                        element={
                            <PrivateRoute>
                                <Statistics />
                            </PrivateRoute>
                        }
                    /> */}
                    <Route
                        exact
                        path="/account"
                        element={
                            <PrivateRoute>
                                <Myaccount />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        exact
                        path="/privacy-policy"
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        exact
                        path="/terms-and-conditions"
                        element={<TermsAndCondition />}
                    />
                    <Route
                        exact
                        path="/refund-policy"
                        element={<RefundPolicy />}
                    />
                    <Route exact path="/faq" element={<Faq />} />
                    <Route exact path="/about-us" element={<About />} />
                    <Route exact path="/video" element={<VideoPlayer />} />
                    <Route exact path="/more" element={<MoreModal />} />
                    <Route exact path="/404" element={[<Page404 />]} />
                    <Route exact path="/coming-soon" element={<ComingSoon />} />
                    <Route exact path="*" element={<Navigate to="/404" />} />
                </Routes>
                {!isPaymentPending &&
                    acceptedSessionData.length > 0 &&
                    isAcceptedModalOpen && (
                        <>
                            {/* // acceptedSessionData.map((data) => ( */}
                            <CreateSessionSuccessModal
                                data={acceptedSessionData[0]}
                                setIsModalOpen={setIsAcceptedModalOpen}
                                id={acceptedSessionIds[0]}
                                getHeaderNotifications={getNotifications}
                            />
                            {/* // ))} */}
                        </>
                    )}
            </Container>
        </>
    );
}

const Container = styled.section`
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 70px);
    &.none {
        display: block;
    }
`;

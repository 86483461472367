import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { THEME_COLORS } from "../../../ThemeConfig";

// store
import { Context } from "../../../contexts/Store";

// styled
import styled, { keyframes } from "styled-components";

// icons
import profileIcon from "../../../assets/icons/profile-picture.svg";
import menuIcon from "../../../assets/icons/menu-icon.svg";
import upcomingIcon from "../../../assets/icons/session-upcoming-icon.svg";
import completedIcon from "../../../assets/icons/session-completed-icon.svg";
import cancelledIcon from "../../../assets/icons/session-cancelled-icon.svg";
import pendingIcon from "../../../assets/icons/pending.svg";
import calendarIcon from "../../../assets/icons/session-calendar-icon.svg";
import callIcon from "../../../assets/icons/session-call-icon.svg";
import clockIcon from "../../../assets/icons/session-clock-icon.svg";
import meetIcon from "../../../assets/icons/session-meet-icon.svg";
import emergency from "../../../assets/icons/emergency-call.svg";
import pendingExpert from "../../../assets/icons/expert-pending.svg";
import Info from "../../../assets/icons/black-important.svg";

// Modals
import SessionProgress from "../modals/SessionProgress";
import UpcomingTimerComplete from "../modals/UpcomingTimerComplete";
import SessionCardMenu from "../modals/SessionCardMenu";
import FeedBackSuccessModal from "../modals/FeedBackSuccessModal";
import { Popover } from "@mui/material";
import ExpertDetails from "../modals/ExpertDetails";

export default function SessionDetailedCard({
    selectedTab,
    setMenu,
    menu,
    sessionDetail,
    getSessionDetails,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const { dispatch } = useContext(Context);

    const [isSessionOngoing, setIsSessionOngoing] = useState(false);
    const [countdown, setCountdown] = useState(
        sessionDetail?.meet_time_stamp?.pending_time_for_next_session
    );

    const meeting_date = sessionDetail.event_time_stamp;
    const meetingDateTime = new Date(meeting_date);
    const timeDifference = meetingDateTime - new Date();

    const timeDifferenceInHours = timeDifference / (1000 * 60 * 60); // Convert milliseconds to hours

    // Convert the time difference to hours, minutes, and seconds
    const initialHours = Math.floor(timeDifference / (1000 * 60 * 60));
    const initialMinutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const initialSeconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    const [hours, setHours] = useState(timeDifference > 0 ? initialHours : 0);
    const [minutes, setMinutes] = useState(
        timeDifference > 0 ? initialMinutes : 0
    );
    const [seconds, setSeconds] = useState(
        timeDifference > 0 ? initialSeconds : 0
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            // if (timeDifference > 0) {
            if (hours === 0 && minutes === 0 && seconds === 0) {
                // Timer has reached 0, you can add your logic here
            } else if (minutes === 0 && seconds === 0) {
                setHours(hours - 1);
                setMinutes(59);
                setSeconds(59);
            } else if (seconds === 0) {
                setMinutes(minutes - 1);
                setSeconds(59);
            } else {
                setSeconds(seconds - 1);
            }
            // }
        }, 1000);

        // Cleanup the timer on unmount
        return () => clearTimeout(timer);
    }, [hours, minutes, seconds]);

    useEffect(() => {
        function handleResize() {
            const windowWidth = window.innerWidth;
            if (windowWidth < 1080) {
                setMenu("sessions");
            }
        }

        handleResize(); // Set initial state on component mount

        window.addEventListener("resize", handleResize); // Add event listener

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
        };
    }, []);

    // Menu popover onClick
    // const [menuPopoverAnchorEl, setMenuPopoverAnchorEl] = useState(null);
    // const menuPopoverButtonRef = useRef(null);
    // const handleClickMenuPopover = () => {
    //     setMenuPopoverAnchorEl(menuPopoverButtonRef.current);
    // };
    // const handleCloseMenuPopover = () => {
    //     setMenuPopoverAnchorEl(null);
    // };
    // const openMenuPopoverFilter = Boolean(menuPopoverAnchorEl);
    // const menuPopoverStyle = {
    //     position: "absolute",
    //     top: 20,
    //     left: -100,
    //     borderRadius: "12px",
    //     //   @media screen and (max-width: 980px) {
    //     //     left:0;
    //     // }
    // };

    const [menuPopoverAnchorEl, setMenuPopoverAnchorEl] = useState(null);
    const handleClickMenuPopover = (event) => {
        setMenuPopoverAnchorEl(event.currentTarget);
    };
    const handleCloseMenuPopover = () => {
        setMenuPopoverAnchorEl(null);
    };

    const openMenuPopoverFilter = Boolean(menuPopoverAnchorEl);
    const menuPopoverId = openMenuPopoverFilter ? "simple-popover" : undefined;

    // Menu click
    const handleMenuClick = () => {
        setIsModalOpen((prev) => !prev);
    };

    // useEffect(() => {
    //     // console.log(isModalOpen, "isModalOpen");
    // }, [isModalOpen]);

    // Expert detail modal
    const [detailModal, setDetailModal] = useState(false);
    const [selectedExpertData, setSelectedExpertData] = useState(null);
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setDetailModal(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, detailModal]);
    const openExpertModal = (item) => {
        setSelectedExpertData(item);
        setDetailModal(true);
    };

    const handleSessionClick = (sessionDetail) => {
        dispatch({
            type: "UPDATE_SESSION_DETAILS",
            session_details: {
                id: sessionDetail.slug,
            },
        });
    };

    const classNameFn = () => {
        if (menu === "sessions") {
            if (selectedTab === "upcoming" && timeDifference > 86400000) {
                return "not-active" || selectedTab || "active";
            } else {
                return "" || selectedTab;
            }
        } else {
            if (selectedTab === "upcoming" && timeDifference > 86400000) {
                return "not-active" || selectedTab;
            } else {
                return "" || selectedTab;
            }
        }
    };

    const handleFeedbackSuccessModal = () => {
        setIsSuccessModalOpen((prev) => !prev);
    };

    useEffect(() => {
        if (sessionDetail.is_ongoing) {
            setIsSessionOngoing(true);
        }
    }, [sessionDetail]);

    return (
        <MainBox
            selectedTab={selectedTab}
            //  className={classNameFn()}
        >
            <WhiteBox>
                <FirstRow>
                    <FirstRowLeft>
                        <ProfilePic
                            src={
                                sessionDetail?.session_record_status?.toLowerCase() ==
                                "pending"
                                    ? pendingExpert
                                    : sessionDetail.expert_pic
                                    ? sessionDetail.expert_pic
                                    : profileIcon
                            }
                            alt="icon"
                        />
                        <FirstMiddleDiv>
                            <NameLine>
                                <NameText>
                                    {sessionDetail?.session_record_status?.toLowerCase() ==
                                    "pending"
                                        ? "Awaiting Expert"
                                        : sessionDetail.expert_name}
                                </NameText>
                                <SessionChip>
                                    {sessionDetail.current_session_number} of{" "}
                                    {sessionDetail.total_sessions} Session
                                </SessionChip>
                                {sessionDetail.is_emergency ? (
                                    <EmergencyContainer>
                                        <EmergencyIconContainer>
                                            <EmergencyIcon
                                                src={emergency}
                                                alt="Icon"
                                            />
                                        </EmergencyIconContainer>
                                        <EmergencyText>Emergency</EmergencyText>
                                    </EmergencyContainer>
                                ) : (
                                    ""
                                )}
                            </NameLine>
                            <SpecializationWrapper>
                                <SpecializationText>
                                    {sessionDetail.specialization}
                                </SpecializationText>
                                {sessionDetail?.session_record_status?.toLowerCase() !==
                                    "pending" && (
                                    <NoteIcon
                                        onClick={() => {
                                            setDetailModal(true);
                                        }}
                                    >
                                        <img src={Info} alt="icon" />
                                    </NoteIcon>
                                )}
                            </SpecializationWrapper>
                        </FirstMiddleDiv>
                    </FirstRowLeft>
                    {selectedTab == "upcoming" ||
                    selectedTab == "pending" ||
                    selectedTab == "completed"
                        ? // <MenuIcon
                          //     onClick={() => {
                          //         handleMenuClick();
                          //         // setIsModalOpen(true);
                          //         handleSessionClick(sessionDetail);
                          //     }}
                          //     src={menuIcon}
                          //     alt="icon"
                          // />
                          !sessionDetail.feedback_added && (
                              <MenuIcon
                                  // ref={menuPopoverButtonRef}
                                  aria-describedby={menuPopoverId}
                                  variant="contained"
                                  onClick={handleClickMenuPopover}
                                  // onClick={() => {
                                  //     handleClickMenuPopover();
                                  //     // handleMenuClick();
                                  //     // // setIsModalOpen(true);
                                  //     // handleSessionClick(sessionDetail);
                                  // }}
                                  src={menuIcon}
                                  alt="icon"
                              />
                          )
                        : ""}
                </FirstRow>

                {/* {isModalOpen && (
                    <SessionCardMenu
                        id={sessionDetail.slug}
                        closeModal={() => handleMenuClick()}
                        selectedTab={selectedTab}
                        setIsModalOpen={setIsModalOpen}
                        isModalOpen={isModalOpen}
                        setIsSuccessModalOpen={setIsSuccessModalOpen}
                    />
                )} */}
                <Popover
                    // style={menuPopoverStyle}
                    // open={openMenuPopoverFilter}
                    // anchorEl={menuPopoverAnchorEl}
                    // onClose={handleCloseMenuPopover}
                    id={menuPopoverId}
                    open={openMenuPopoverFilter}
                    anchorEl={menuPopoverAnchorEl}
                    onClose={handleCloseMenuPopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        "&& .MuiPopover-paper": {
                            // border: "1px solid #e7e7e7",
                            boxShadow: "10px 12px 48px 0px rgba(0, 0, 0, 0.25)",
                            // boxShadow:
                            //     "8px 16px 44px rgba(0, 0, 0, 0.05)",
                            borderRadius: "12px",
                        },
                    }}
                >
                    <SessionCardMenu
                        id={sessionDetail.slug}
                        closeModal={() => handleMenuClick()}
                        selectedTab={selectedTab}
                        setIsModalOpen={setIsModalOpen}
                        isModalOpen={isModalOpen}
                        setIsSuccessModalOpen={setIsSuccessModalOpen}
                        handleCloseMenuPopover={handleCloseMenuPopover}
                        getSessionDetails={getSessionDetails}
                    />
                    {/* <h1>hiiii</h1> */}
                </Popover>

                <NavLink
                    onClick={() => handleSessionClick(sessionDetail)}
                    to={`/session/${sessionDetail.slug}`}
                >
                    <SecondRow>
                        <SubTitle>{sessionDetail.concern}</SubTitle>
                        <CaseIdText>
                            Case ID : #{sessionDetail.session_record_id}
                        </CaseIdText>
                    </SecondRow>
                </NavLink>
                <ThirdRow>
                    <StatusChip
                        selectedTab={sessionDetail.session_record_status.toLowerCase()}
                    >
                        <ChipIcon
                            src={
                                sessionDetail.session_record_status.toLowerCase() ==
                                "upcoming"
                                    ? upcomingIcon
                                    : sessionDetail.session_record_status.toLowerCase() ==
                                      "completed"
                                    ? completedIcon
                                    : sessionDetail.session_record_status.toLowerCase() ==
                                      "cancelled"
                                    ? cancelledIcon
                                    : pendingIcon
                            }
                            alt="icon"
                        />
                        <StatusChipText
                            selectedTab={sessionDetail.session_record_status.toLowerCase()}
                        >
                            {sessionDetail.session_record_status}
                        </StatusChipText>
                    </StatusChip>
                    <GreyChip>
                        <ChipIcon src={calendarIcon} alt="icon" />
                        <ChipText>{sessionDetail.case_date}</ChipText>
                    </GreyChip>
                    <GreyChip>
                        <ChipIcon src={clockIcon} alt="icon" />
                        <ChipText>
                            {sessionDetail.start_time} -{" "}
                            {sessionDetail.end_time}
                        </ChipText>
                    </GreyChip>
                    <GreyChip>
                        <ChipIcon
                            src={
                                sessionDetail.meet_mode == "Video"
                                    ? meetIcon
                                    : callIcon
                            }
                            alt="icon"
                        />
                    </GreyChip>
                </ThirdRow>
            </WhiteBox>

            {sessionDetail?.session_record_status?.toLowerCase() ===
                "upcoming" && (
                <>
                    {isSessionOngoing ? (
                        <MainBottomPart
                            className={sessionDetail?.session_record_status?.toLowerCase()}
                        >
                            <UpcomingTimerComplete
                                meet_link={sessionDetail.meet_link}
                                timeData={
                                    sessionDetail?.meet_time_stamp
                                        ?.pending_time_for_next_session
                                }
                                setIsSessionOngoing={setIsSessionOngoing}
                                isSessionOngoing={isSessionOngoing}
                                countdown={countdown}
                                setCountdown={setCountdown}
                            />
                        </MainBottomPart>
                    ) : (
                        <>
                            {sessionDetail?.meet_time_stamp
                                ?.pending_time_for_next_session && (
                                <MainBottomPart
                                    className={sessionDetail?.session_record_status?.toLowerCase()}
                                >
                                    <SessionProgress
                                        isSessionOngoing={isSessionOngoing}
                                        setIsSessionOngoing={
                                            setIsSessionOngoing
                                        }
                                        countdown={countdown}
                                        setCountdown={setCountdown}
                                        summary_report={
                                            sessionDetail.summary_report
                                        }
                                        selectedTab={sessionDetail.session_record_status.toLowerCase()}
                                        slug={sessionDetail.slug}
                                        hours={
                                            sessionDetail?.meet_time_stamp
                                                ?.pending_time_for_next_session
                                                ?.hours
                                        }
                                        minutes={
                                            sessionDetail?.meet_time_stamp
                                                ?.pending_time_for_next_session
                                                ?.minutes
                                        }
                                        seconds={
                                            sessionDetail?.meet_time_stamp
                                                ?.pending_time_for_next_session
                                                ?.seconds
                                        }
                                        timeData={
                                            sessionDetail?.meet_time_stamp
                                                ?.pending_time_for_next_session
                                        }
                                    />
                                </MainBottomPart>
                            )}
                        </>
                    )}
                </>
            )}

            {sessionDetail?.session_record_status?.toLowerCase() ===
                "completed" && (
                <MainBottomPart
                    className={sessionDetail?.session_record_status?.toLowerCase()}
                >
                    <SessionProgress
                        isSessionOngoing={isSessionOngoing}
                        setIsSessionOngoing={setIsSessionOngoing}
                        countdown={countdown}
                        setCountdown={setCountdown}
                        summary_report={sessionDetail.summary_report}
                        selectedTab={sessionDetail.session_record_status.toLowerCase()}
                        slug={sessionDetail.slug}
                        hours={
                            sessionDetail?.meet_time_stamp
                                ?.pending_time_for_next_session?.hours
                        }
                        minutes={
                            sessionDetail?.meet_time_stamp
                                ?.pending_time_for_next_session?.minutes
                        }
                        seconds={
                            sessionDetail?.meet_time_stamp
                                ?.pending_time_for_next_session?.seconds
                        }
                        timeData={
                            sessionDetail?.meet_time_stamp
                                ?.pending_time_for_next_session
                        }
                    />
                </MainBottomPart>
            )}

            {/* {isSessionOngoing?(
                            <>
                            {selectedTab == "upcoming" &&
                            meeting_date &&
                            seconds == 0 &&
                            minutes == 0 &&
                            hours == 0 && (
                                <MainBottomPart
                                    className={sessionDetail?.session_record_status?.toLowerCase()}
                                >
                                    <UpcomingTimerComplete
                                        meet_link={sessionDetail.meet_link}
                                        timeData={
                                            sessionDetail?.meet_time_stamp
                                                ?.pending_time_for_next_session
                                        }
                                        setIsSessionOngoing={setIsSessionOngoing}
                                        isSessionOngoing={isSessionOngoing}
                                    />
                                </MainBottomPart>)}
                                </>
                        ):(
                            <>
                            

{sessionDetail?.meet_time_stamp?.pending_time_for_next_session && (
<MainBottomPart
    className={sessionDetail?.session_record_status?.toLowerCase()}
>
    <SessionProgress
        isSessionOngoing={isSessionOngoing}
        setIsSessionOngoing={
            setIsSessionOngoing
        }
        summary_report={
            sessionDetail.summary_report
        }
        selectedTab={sessionDetail.session_record_status.toLowerCase()}
        slug={sessionDetail.slug}
        hours={
            sessionDetail?.meet_time_stamp
                ?.pending_time_for_next_session
                ?.hours
        }
        minutes={
            sessionDetail?.meet_time_stamp
                ?.pending_time_for_next_session
                ?.minutes
        }
        seconds={
            sessionDetail?.meet_time_stamp
                ?.pending_time_for_next_session
                ?.seconds
        }
        timeData={
            sessionDetail?.meet_time_stamp
                ?.pending_time_for_next_session
        }
    />

    {isSuccessModalOpen && (
        <FeedBackSuccessModal
            closeModal={() =>
                handleFeedbackSuccessModal()
            }
        />
    )}
</MainBottomPart>)} */}

            {isSuccessModalOpen && (
                <FeedBackSuccessModal
                    closeModal={() => handleFeedbackSuccessModal()}
                />
            )}
            {detailModal && (
                <ExpertDetailModal>
                    <ExpertModalContent ref={wrapperRef}>
                        <DetailedContentSection>
                            <ExpertNameText>
                                {sessionDetail.expert_name}
                            </ExpertNameText>
                            <ExpertDetailText>
                                Specialization: {sessionDetail.specialization}
                            </ExpertDetailText>
                            <ExpertDetailText>
                                Experience:{" "}
                                {sessionDetail.expert_data.experience}
                            </ExpertDetailText>

                            {sessionDetail?.expert_data?.expertises.length >
                                0 && (
                                <QualificationsContainer>
                                    <ExpertDetailText style={{ marginTop: 0 }}>
                                        Expertises:
                                    </ExpertDetailText>
                                    {sessionDetail?.expert_data?.expertises.map(
                                        (item) => (
                                            <Qualification>
                                                {item}
                                            </Qualification>
                                        )
                                    )}
                                </QualificationsContainer>
                            )}

                            {sessionDetail?.expert_data?.qualifications.length >
                                0 && (
                                <QualificationsContainer>
                                    <ExpertDetailText style={{ marginTop: 0 }}>
                                        Qualifications:
                                    </ExpertDetailText>
                                    {sessionDetail?.expert_data?.qualifications.map(
                                        (item) => (
                                            <Qualification>
                                                {item}
                                            </Qualification>
                                        )
                                    )}
                                </QualificationsContainer>
                            )}
                            {/* <ExpertDetailsText>
                                Anxiety involves excessive worry, fear, or
                                nervousness that can disrupt daily life. It may
                                manifest with physical symptoms and impact
                                overall well-being.
                            </ExpertDetailsText> */}
                        </DetailedContentSection>
                    </ExpertModalContent>
                </ExpertDetailModal>
            )}
        </MainBox>
    );
}

const MainBox = styled.div`
    background: ${(props) =>
        props.selectedTab == "upcoming"
            ? THEME_COLORS.secondary
            : props.selectedTab == "completed"
            ? THEME_COLORS.chips_green_on_container
            : props.selectedTab == "cancelled"
            ? THEME_COLORS.chips_red_on_container
            : THEME_COLORS.null};
    &.not-active {
        background: #e2e2e2;
    }

    border-radius: 12px;
    padding: 2px;
    margin-bottom: 22px;
    &.active {
        display: block;
    }
    @media all and (max-width: 1080px) {
        display: block;
    }
`;
const WhiteBox = styled.div`
    position: relative;
    padding: 16px;
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    border-radius: 10px;
`;
const FirstRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1.5px solid #e2e2e2;
    padding-bottom: 10px;
`;
const ProfilePic = styled.img`
    width: 36px;
    height: 36px;
    display: block;
    object-fit: cover;
`;
const FirstRowLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const FirstMiddleDiv = styled.div`
    margin-left: 16px;
`;
const MenuIcon = styled.img`
    width: 20px;
    height: 20px;
    display: block;
    object-fit: cover;
    cursor: pointer;
`;
const NameLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }
`;
const NameText = styled.h6`
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: ${THEME_COLORS.secondary};
`;
const SessionChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    /* gap: 6px; */
    background: ${THEME_COLORS.chips_blue_container};
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-family: "DM_sans";
    font-size: 12px;
`;
const EmergencyContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 4px 8px 4px 3px;
    align-items: center;
    border-radius: 4px;
    background: #ffe6d7;
    gap: 8px;
`;
const EmergencyIconContainer = styled.div`
    width: 16px;
    height: 16px;
    padding: 2px;
`;
const EmergencyIcon = styled.img``;
const EmergencyText = styled.span`
    color: #f28b4d;
    font-family: "DM_sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const SpecializationText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 13px;
    color: ${THEME_COLORS.text_para};
`;
const CaseIdText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`;
const SecondRow = styled.div`
    margin-top: 12px;
`;
const SubTitle = styled.h6`
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    font-size: 16px;
`;
const ThirdRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    margin-top: 12px;
    @media all and (max-width: 640px) {
        flex-wrap: wrap;
    }
`;
const StatusChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    height: 24px;
    box-sizing: border-box;
    background: ${(props) =>
        props.selectedTab == "upcoming"
            ? THEME_COLORS.chips_green_container
            : props.selectedTab == "completed"
            ? THEME_COLORS.chips_blue_container
            : props.selectedTab == "cancelled"
            ? THEME_COLORS.chips_red_container
            : THEME_COLORS.chips_orange_container};
    border-radius: 4px;
`;
const GreyChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    background: ${THEME_COLORS.light_200};
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
`;
const ChipIcon = styled.img`
    width: auto;
`;
const StatusChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    text-transform: capitalize;
    color: ${(props) =>
        props.selectedTab == "upcoming"
            ? THEME_COLORS.chips_green_on_container
            : props.selectedTab == "completed"
            ? THEME_COLORS.chips_blue_on_container_2
            : props.selectedTab == "cancelled"
            ? THEME_COLORS.chips_red_on_container
            : THEME_COLORS.chips_orange_on_container};
`;
const ChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.dark_300};
`;

const MainBottomPart = styled.div`
    padding: 12px 12px;
    &.not-active {
        display: none;
        border: none;
    }
    &.upcoming {
        background: ${THEME_COLORS.secondary};
        border-radius: 10px;
    }
    &.pending {
        padding: 0;
    }
    &.completed {
        background: ${THEME_COLORS.chips_green_on_container};
        border-radius: 10px;
    }
    &.cancelled {
        background: ${THEME_COLORS.chips_red_on_container};
        border-radius: 10px;
    }
`;
const SpecializationWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
`;
const NoteIcon = styled.div`
    cursor: pointer;
    width: 16px;
    height: 16px;
    opacity: 0.8;
`;
const popup = keyframes`
    0%{
        scale: 0;
    }
    100%{       
        scale: 1;
    }
`;
const ExpertDetailModal = styled.section`
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: ${THEME_COLORS.modal_bg};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 305;
    display: flex;
    /* background: yellow; */
`;
const ExpertModalContent = styled.div`
    width: 480px;
    max-height: 80vh;
    overflow-y: scroll;
    /* transform: translateX(130px); */
    border-radius: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-top: 4px solid #1da687;
    background: ${THEME_COLORS.white};
    /* background: ${THEME_COLORS.chips_green_on_container}; */
    animation: ${popup} 0.4s ease-in-out;
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
    /* @media all and (max-width: 480px) {
        width: 100%;
        position: absolute;
        bottom: 90px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        animation-name: modal;
        animation-duration: 0.3s;
        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
    @media all and (max-width: 360px) {
        bottom: 70px;
    } */
`;

const DetailedContentSection = styled.div`
    /* background: #fff; */

    padding: 20px;
    @media all and (max-width: 480px) {
        padding: 16px;
    }
`;
const ExpertNameText = styled.h6`
    font-family: "DM_sans_medium";
    font-size: 20px;
`;
const ExpertDetailText = styled.p`
    font-size: 15px;
    font-family: "DM_sans";
    margin-top: 12px;
    color: #3e3e53;
    /* margin-top: 8px; */
`;
const ExpertDetailsText = styled.p`
    font-size: 14px;
    font-family: "DM_sans";
    margin-top: 16px;
    color: #3e3e53;
`;
const QualificationsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 16px;
`;
const Qualification = styled.div`
    padding: 8px;
    border: 1px solid #e2e2e2;
    border-radius: 53px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "DM_sans";
    color: #3e3e53;
`;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../contexts/Store";

// Lottie file
import lottie from "../../../assets/lottie/Lottie-animation.json";
import Lottie from "lottie-react";

// Styles //
import styled, { keyframes } from "styled-components";

// colors //
import { THEME_COLORS } from "../../../ThemeConfig";

// Images & Icons
import expert from "../../../assets/icons/find-expert-icon.svg";

const FindExpertModal = ({ closeModal }) => {
    const { dispatch } = useContext(Context);
    const navigate = useNavigate();

    const handleClick = () => {
        dispatch({
            type: "UPDATE_USER",
            user_details: {
                concern_id: "",
                concern: "",
                description: "",
                case_date: "",
                specialization: "",
                time_slot: "",
                package: "",
                fixture: "",
            },
        });
        sessionStorage.clear();
        navigate("/");
    };
    return (
        <Wrapper>
            <Content onClick={(e) => e.stopPropagation()}>
                <ExpertIconContainer>
                    <ExpertIcon src={expert} alt="Icon" />
                </ExpertIconContainer>
                <Title>Finding your Expert</Title>
                <Container>
                    <Lottie
                        animationData={lottie}
                        style={{
                            height: 150,
                            width: 150,
                            margin: "auto",
                        }}
                    />
                </Container>
                <Text>
                    Stay tuned as we search for the perfect expert and provide
                    you with updates
                </Text>
                <Button onClick={() => handleClick()}>Back to Home</Button>
            </Content>
        </Wrapper>
    );
};

export default FindExpertModal;

const popup = keyframes`
    0%{
        scale: 0.3;
    }
    100%{
        scale: 1;
    }
`;
const Wrapper = styled.div`
    position: fixed;
    z-index: 350;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${THEME_COLORS.breath_model};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: ${THEME_COLORS.white};
    width: 440px;
    padding: 32px;
    z-index: 200;
    border-radius: 24px;
    animation: ${popup} 0.4s ease-in-out;
    @media all and (max-width: 780px) {
        padding: 20px 30px;
    }
    @media all and (max-width: 480px) {
        padding: 25px;
        width: 400px;
    }
    @media all and (max-width: 420px) {
        padding: 20px;
        width: 360px;
    }
    @media all and (max-width: 370px) {
        width: 330px;
        padding: 15px;
    }
`;
const ExpertIconContainer = styled.div`
    display: flex;
    width: 50px;
    height: 50px;
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    border: 8px solid #ecfdf3;
    background: #e0f8f2;
    margin-bottom: 10px;
`;
const ExpertIcon = styled.img``;
const Title = styled.h2`
    font-size: 20px;
    /* font-style: normal; */
    /* font-weight: 500; */
    line-height: normal;
    font-family: "DM_sans_medium";
    color: #3e3e53;
`;
const Text = styled.p`
    color: #818181;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    @media all and (max-width: 370px) {
        font-size: 13px;
    }
`;
const Container = styled.div``;
const Button = styled.button`
    background: ${THEME_COLORS.secondary_gradient};
    color: ${THEME_COLORS.white};
    cursor: pointer;
    margin: 32px auto 0 auto;
    display: block;
    font-size: 16px;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    font-family: "DM_sans_medium";
    @media all and (max-width: 780px) {
        margin: 15px auto 0 auto;
    }
`;

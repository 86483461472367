import React, { useContext, useEffect, useState } from "react";

// Packages //
import { NavLink, useLocation } from "react-router-dom";

// Style //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../ThemeConfig";

// modal
import MoreModal from "../includes/modals/MoreModal";
import LogoutModal from "../includes/modals/LogoutModal";

// Media-Images //
import more from "../../assets/images/more-green.svg";
import home from "../../assets/images/home-green.svg";
import explore from "../../assets/images/explore-green.svg";
import meditate from "../../assets/images/meditate-green.svg";
import account_white from "../../assets/images/account-white.svg";
import home_white from "../../assets/images/home-white.svg";

import stats from "../../assets/images/stats-green.svg";
import { Context } from "../../contexts/Store";

function BottomNavigation() {
    // State variables
    const [isHovered, setIsHovered] = useState(""); // State variable to track the hovered item
    const [activeItem, setActiveItem] = useState(""); // Currently active sidebar item
    const [isOpen, setOpen] = useState(""); // Open state for the more modal
    const [modalOpen, setModalOpen] = useState(""); // Open state for the logout modal
    const { pathname } = useLocation(); // Get the current pathname
    const { state } = useContext(Context);
    const auth = state.user_details.is_verified;
    let id = state.session_details.id;

    // Data to reuse more modal
    const additionalData = [
        {
            id: 4,
            value: "My Account",
            image: require("../../assets/images/account-green.svg").default,
            path: "/account",
        },
        {
            id: 5,
            value: "Notifications",
            image: require("../../assets/images/notification-green.svg")
                .default,
            path: "/notifications",
        },
    ];

    // Sidebar items
    const SIDEBAR_ITEMS = [
        { id: "home", icon: home, text: "Home", path: "/", image: home_white },
        {
            id: "account",
            icon: require("../../assets/images/account-green.svg").default,
            text: "My Account",
            path: "/account",
            image: account_white,
        },
        // { id: "explore", icon: explore, text: "Explore", path: "/explore" },
        // { id: "meditate", icon: meditate, text: "Meditate", path: "/meditate" },
        // { id: "stats", icon: stats, text: "Stats", path: "/statistics" },
        // { id: "more", icon: more, text: "More" },
    ];

    useEffect(() => {
        // Update the active item based on the current pathname
        const match = SIDEBAR_ITEMS.find((item) => {
            if (item.path === "/") {
                return (
                    pathname === "/" ||
                    pathname === "/describe" ||
                    pathname === "/schedule" ||
                    pathname === "/fulfill" ||
                    pathname === "/notifications"
                    // pathname === "/notifications"
                );
            }
            return item.path === pathname;
        });
        if (match) {
            setActiveItem(match.id);
        }
    }, [pathname]);

    return (
        <>
            <LogoutModal
                modalOpen={modalOpen}
                onClose={() => setModalOpen("")}
            />
            <Container>
                {isOpen === "more" && (
                    <MoreModal
                        additionalData={additionalData}
                        setOpen={setOpen}
                        setModalOpen={setModalOpen}
                    />
                )}
                <Cover>
                    {/* Render sidebar items */}
                    {SIDEBAR_ITEMS.map((item) => (
                        <>
                            <NavItem
                                key={item.id}
                                to={item.path}
                                activeclassname="active"
                                onClick={() => {
                                    setActiveItem(item.id);
                                    setOpen(item?.text);
                                }}
                                onMouseEnter={() => setIsHovered(item.id)}
                                onMouseLeave={() => setIsHovered("")}
                                className={
                                    // Add "active" class to the home item if on any related pages
                                    (item.path === "/" &&
                                        (pathname === "/" ||
                                            pathname === "/describe" ||
                                            pathname === "/schedule" ||
                                            pathname === "/create-session" ||
                                            // pathname ===
                                            //     "/finding-therapist" ||
                                            pathname === "/session-confirmed" ||
                                            pathname === "/fulfill" ||
                                            pathname === "/notifications" ||
                                            pathname === `/session/${id}` ||
                                            pathname ===
                                                `/view-summary-report/${id}` ||
                                            pathname ===
                                                `/cancel-session/${id}`)) ||
                                    // Add "active" class to the account item if on any related pages
                                    (item.path === "/account" &&
                                        (pathname === "/privacy-policy" ||
                                            pathname ===
                                                "/terms-and-conditions" ||
                                            pathname === "/refund-policy" ||
                                            pathname === "/about-us" ||
                                            pathname === "/faq" ||
                                            pathname ===
                                                "/transaction-history"))
                                        ? "active"
                                        : ""
                                }
                            >
                                <Icon
                                    // src={item.icon}
                                    active={activeItem === item.id}
                                    src={
                                        isHovered === item.id
                                            ? item.image
                                            : item.icon
                                    }
                                    className={
                                        // Add "active" class to the home item if on any related pages
                                        (item.path === "/" &&
                                            (pathname === "/" ||
                                                pathname === "/describe" ||
                                                pathname === "/schedule" ||
                                                pathname ===
                                                    "/create-session" ||
                                                // pathname ===
                                                //     "/finding-therapist" ||
                                                pathname ===
                                                    "/session-confirmed" ||
                                                pathname === "/fulfill" ||
                                                pathname === "/notifications" ||
                                                pathname === `/session/${id}` ||
                                                pathname ===
                                                    `/view-summary-report/${id}` ||
                                                pathname ===
                                                    `/cancel-session/${id}`)) ||
                                        // Add "active" class to the account item if on any related pages
                                        (item.path === "/account" &&
                                            (pathname === "/privacy-policy" ||
                                                pathname ===
                                                    "/terms-and-conditions" ||
                                                pathname === "/refund-policy" ||
                                                pathname === "/about-us" ||
                                                pathname === "/faq" ||
                                                pathname ===
                                                    "/transaction-history"))
                                            ? "active"
                                            : ""
                                    }
                                    // active={activeItem === item.id}
                                    is_hovered={isHovered === item.id}
                                />
                                <Text
                                    active={activeItem === item.id}
                                    is_hovered={isHovered === item.id}
                                    className={
                                        // Add "active" class to the home item if on any related pages
                                        (item.path === "/" &&
                                            (pathname === "/" ||
                                                pathname === "/describe" ||
                                                pathname === "/schedule" ||
                                                pathname ===
                                                    "/create-session" ||
                                                // pathname ===
                                                //     "/finding-therapist" ||
                                                pathname ===
                                                    "/session-confirmed" ||
                                                pathname === "/fulfill" ||
                                                pathname === "/notifications" ||
                                                pathname === `/session/${id}` ||
                                                pathname ===
                                                    `/view-summary-report/${id}` ||
                                                pathname ===
                                                    `/cancel-session/${id}`)) ||
                                        // Add "active" class to the account item if on any related pages
                                        (item.path === "/account" &&
                                            (pathname === "/privacy-policy" ||
                                                pathname ===
                                                    "/terms-and-conditions" ||
                                                pathname === "/refund-policy" ||
                                                pathname === "/about-us" ||
                                                pathname === "/faq" ||
                                                pathname ===
                                                    "/transaction-history"))
                                            ? "active"
                                            : ""
                                    }
                                >
                                    {item.text}
                                </Text>
                            </NavItem>
                        </>
                    ))}
                    {/* Render 'More' button */}
                    {/* <More onClick={() => setOpen("more")}>
                        <Icon src={more} />
                        <Text>More</Text>
                    </More> */}
                </Cover>
            </Container>
        </>
    );
}

export default BottomNavigation;

const Container = styled.section`
    width: 100%;
    padding: 9px;
    display: none;
    height: 96px;
    border-top: 1px solid ${THEME_COLORS.light_300};
    background: ${THEME_COLORS.white};
    z-index: 300;
    position: fixed;
    bottom: 0px;
    @media all and (max-width: 768px) {
        display: block;
    }
    @media all and (max-width: 640px) {
    }
    @media all and (max-width: 480px) {
        /* padding: 4px 8px;
        bottom: 0;
        position: absolute; */
    }
    @media all and (max-width: 360px) {
        height: 77px;
        padding: 10px 8px;
    }
`;
const Cover = styled.div`
    width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const NavItem = styled(NavLink)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 30px;
    border-radius: 6px;
    color: ${(props) =>
        props.active ? THEME_COLORS.black : THEME_COLORS.light_500};
    background-color: ${(props) =>
        props.active ? THEME_COLORS.menu_blue : "transparent"};
    transition: all 0.2s ease-in-out;
    NavMenu &:hover {
        color: ${THEME_COLORS.black};
        background-color: ${THEME_COLORS.menu_blue};
    }
    &.active {
        color: ${THEME_COLORS.black};
        background-color: ${THEME_COLORS.menu_blue};
    }
    &:hover {
        color: ${THEME_COLORS.white};
        background-color: ${THEME_COLORS.primary};
    }
    @media all and (max-width: 360px) {
        padding: 9px;
    }
`;
const Icon = styled.img`
    width: 30px;
    /* height: 22px; */
    margin-bottom: 6px;
    /* filter: ${(props) => (props.active ? "none" : "grayscale(100%)")}; */
    @media all and (max-width: 480px) {
        width: 26px;
    }
    @media all and (max-width: 360px) {
        width: 22px;
    }
`;
const Text = styled.p`
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: ${(props) =>
        props.active ? THEME_COLORS.black : THEME_COLORS.light_500};
    color: ${(props) =>
        props.is_hovered ? THEME_COLORS.white : THEME_COLORS.light_500};
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 12px;
    }
    &.active {
        color: ${THEME_COLORS.black};
    }
`;
const More = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
`;

import React, { useContext, useState } from "react";

// Styles //
import styled from "styled-components";

// colors //
import { THEME_COLORS } from "../../../../ThemeConfig";

// store //
import { Context } from "../../../../contexts/Store";

// Media-Images //
import tick from "../../../../assets/images/tick.svg";

// components //
import BottomNavigation from "../../../general/BottomNavigation";
import PreviousButton from "../../../includes/buttons/PreviousButton";
import NextButton from "../../../includes/buttons/NextButton";
import CancelButton from "../../../includes/buttons/CancelButton";

// packages //
import { useNavigate } from "react-router-dom";

function Describe() {
    // State for selected emotions
    const [checked, setChecked] = useState("");

    const [describe, setDescribe] = useState("");

    // Context and navigation hooks
    const { dispatch } = useContext(Context);
    const navigate = useNavigate();

    // State for the buttons representing emotions
    const [button, setButton] = useState([
        {
            id: 1,
            label: "Fear",
            selected: false,
        },
        {
            id: 2,
            label: "Worried",
            selected: false,
        },
        {
            id: 3,
            label: "Anxious",
            selected: false,
        },
        {
            id: 4,
            label: "Nervous",
            selected: false,
        },
        {
            id: 5,
            label: "Stressed",
            selected: false,
        },
        {
            id: 6,
            label: "Disappointed",
            selected: false,
        },
        {
            id: 7,
            label: "Isolated",
            selected: false,
        },
        {
            id: 8,
            label: "Hopeless",
            selected: false,
        },
        {
            id: 9,
            label: "Shame",
            selected: false,
        },
        {
            id: 10,
            label: "Helpless",
            selected: false,
        },
        {
            id: 11,
            label: "Ashamed",
            selected: false,
        },
        {
            id: 12,
            label: "Irritable",
            selected: false,
        },
        {
            id: 13,
            label: "Lonely",
            selected: false,
        },
        {
            id: 14,
            label: "Bored",
            selected: false,
        },
        {
            id: 15,
            label: "Resentment",
            selected: false,
        },
        {
            id: 16,
            label: "Overwhelmed",
            selected: false,
        },
        {
            id: 17,
            label: "Sadness",
            selected: false,
        },
        {
            id: 18,
            label: "Restless",
            selected: false,
        },
        {
            id: 19,
            label: "Guilt",
            selected: false,
        },
        {
            id: 20,
            label: "Envy",
            selected: false,
        },
        {
            id: 22,
            label: "Overwhelmed",
            selected: false,
        },
        {
            id: 23,
            label: "Overwhelmed",
            selected: false,
        },
        {
            id: 24,
            label: "Overwhelmed",
            selected: false,
        },
        {
            id: 25,
            label: "Overwhelmed",
            selected: false,
        },
        {
            id: 26,
            label: "Overwhelmed",
            selected: false,
        },
    ]);

    // Function to handle button click and update selected state
    const handleClick = (item) => {
        item.selected = !item.selected;
        setButton([...button]);
        setChecked(
            button
                .filter((item) => item.selected === true)
                .map((item) => item.label)
        );
    };

    // Map buttons and render them
    const buttons = button.map((item) => (
        <Button
            key={item.id}
            className={item.selected ? "active" : ""}
            onClick={() => handleClick(item)}
        >
            <Btnlabel className={item.selected ? "active" : ""}>
                {item.label}
            </Btnlabel>
        </Button>
    ));

    // State for describing feelings in user's own words
    const [value, setValue] = useState("");

    // State for error message
    const [error, setError] = useState(false);

    // Handle form submission
    const handleSubmit = () => {
        if (checked === "") {
            setError(true);
        } else {
            setError(false);
            // Update user details in the store
            dispatch({
                type: "UPDATE_USER",
                user_details: {
                    emotion: checked,
                    describe: value,
                },
            });
            navigate("/schedule"); // Navigate to the next step
        }
    };

    // Check if the screen is mobile
    const isMobile = window.innerWidth <= 480;

    return (
        <>
            {/* Main container */}
            <MainContainer>
                <HeadingDiv>
                    <Heading>Create a Session</Heading>
                    <CancelButton path="/" style={{ padding: "6px 20px" }} />
                </HeadingDiv>
                <ContentDiv>
                    {/* Steps */}
                    <TopDiv>
                        <Section>
                            <Tick>
                                <TickImg src={tick} alt="Tick image" />
                            </Tick>
                            <Title>Challenge</Title>
                        </Section>
                        <Section>
                            <Count isFirst>2</Count>
                            <Title isFirst>Describe</Title>
                        </Section>
                        <Section>
                            <Count>3</Count>
                            <Title>Schedule</Title>
                        </Section>
                        <Section>
                            <Count>4</Count>
                            <Title>Fulfill</Title>
                        </Section>
                    </TopDiv>
                    {/* Emotions and description */}
                    <MiddleDiv>
                        <ItemContainer>
                            <MidHeading>Related Emotions</MidHeading>
                            {!describe && (
                                <ButtonContainer>
                                    {buttons}
                                    <Button onClick={() => setDescribe(true)}>
                                        <Btnlabel>Others</Btnlabel>
                                    </Button>
                                </ButtonContainer>
                            )}
                            {describe && (
                                <>
                                    <MidHeading>
                                        Describe in your words
                                    </MidHeading>
                                    <FormContainer>
                                        <Label>Describe</Label>
                                        <Input
                                            type="text"
                                            id="input"
                                            placeholder="I've been feeling really unmotivated and ..."
                                            value={value}
                                            onChange={(e) =>
                                                setValue(e.target.value)
                                            }
                                        />
                                    </FormContainer>
                                </>
                            )}
                        </ItemContainer>
                    </MiddleDiv>
                    {/* Bottom section */}
                    <BottomDiv>
                        {/* Display error message if no emotion is selected */}
                        {error && <ErrorMessage>Please pick one</ErrorMessage>}
                        {/* Previous and Next buttons */}
                        <PreviousButton
                            size="14px"
                            style={{
                                padding: "10px 20px",
                                height: "40px",
                                width: isMobile ? "50%" : "fit-content",
                            }}
                            url="/create-session"
                        />
                        <NextButton
                            size="14px"
                            style={{
                                padding: "10px 20px",
                                height: "40px",
                                width: isMobile ? "50%" : "fit-content",
                            }}
                            onClick={handleSubmit}
                        />
                    </BottomDiv>
                </ContentDiv>
            </MainContainer>
            {/* Bottom navigation component */}
            <BottomNavigation />
        </>
    );
}

export default Describe;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    padding: 31px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media all and (max-width: 768px) {
        width: 100%;
        overflow-y: auto;
        padding: 50px 0px 120px;
    }
    @media all and (max-width: 480px) {
        padding: 20px 0px 90px;
    }
`;
const HeadingDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 380px;
    margin-bottom: 14px;
    @media all and (max-width: 1280px) {
        width: 370px;
    }
    @media all and (max-width: 480px) {
        width: 320px;
    }
    @media all and (max-width: 360px) {
        width: 300px;
    }
`;
const Heading = styled.h4`
    font-size: 18px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    line-height: 26px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;

const ContentDiv = styled.div`
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_400};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    width: 380px;
    margin: 0 auto;
    border-radius: 24px;
    padding: 16px;
    @media all and (max-width: 480px) {
        width: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px;
    margin-bottom: 18px;
`;
const Section = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
`;
const Count = styled.span`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 9px;
    border-radius: 50%;
    border: 1px solid ${THEME_COLORS.chips_green_on_container};
    padding: 3px 6px;
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.white : THEME_COLORS.black};
    background: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.chips_green_on_container : THEME_COLORS.white};
    span:first-child {
        width: 54px;
        padding: 6px;
    }
    @media all and (max-width: 1440px) {
        font-size: 10px;
    }
`;
const Tick = styled.span`
    display: inline-block;
    width: 18px;
`;
const TickImg = styled.img`
    display: block;
    width: 100%;
`;
const Title = styled.label`
    display: inline-block;
    font-weight: 700;
    font-size: 10px;
    color: ${THEME_COLORS.text_title};
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.black : THEME_COLORS.text_title};
`;
const MiddleDiv = styled.div`
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 10px;
    padding: 14px;
    margin-bottom: 40px;
    height: 418px;
    width: 346px;
    @media all and (max-width: 1440px) {
        margin-bottom: 110px;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 80px;
    }
    @media all and (max-width: 480px) {
        width: 328px;
        margin-bottom: 20px;
    }
    @media all and (max-width: 360px) {
        width: 286px;
        padding: 8px;
    }
`;
const MidHeading = styled.h6`
    font-family: "DM_sans_bold";
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
`;
const ItemContainer = styled.div``;
const ButtonContainer = styled.div`
    /* overflow-y: auto;
  height: 238px; */
    width: 310px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 14px;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${THEME_COLORS.light_200};
    }
    @media all and (max-width: 360px) {
        width: 274px;
        padding: 2px;
    }
`;
const Button = styled.button`
    border: 2px solid ${THEME_COLORS.light_300};
    border-radius: 53px;
    padding: 6px 12px;
    &.active {
        background: ${THEME_COLORS.chips_green_container};
        color: ${THEME_COLORS.chips_green_on_container};
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
    }
`;
const Btnlabel = styled.a`
    font-size: 12px;
    font-family: "DM_sans_medium";
    color: 1px solid ${THEME_COLORS.text_title};
    &.active {
        color: ${THEME_COLORS.chips_green_on_container};
    }
`;
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    padding: 6px;
    border: 2px solid ${THEME_COLORS.border_blue};
    transition: all 0.2s ease-in-out 0s;
    border-radius: 16px;
`;
const Label = styled.label`
    font-size: 12px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_para};
    margin-bottom: 2px;
    @media all and (max-width: 360px) {
        font-size: 11px;
    }
`;
const Input = styled.textarea`
    padding: 2px;
    font-size: 12px;
    width: 290px;
    height: 50px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    ::placeholder {
        color: ${THEME_COLORS.text_title};
    }
    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-resizer {
        display: none;
    }
`;
const ErrorMessage = styled.span`
    font-size: 12px;
    color: red;
`;
const BottomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
    @media all and (max-width: 480px) {
        justify-content: center;
    }
`;

import React from "react";

// styled
import styled from "styled-components";

// icons
import icon from "../../../assets/icons/no-content.svg";

function NoSessionCard(selectedTab) {
  return (
    <MainContainer>
      <SubContainer>
        <IconContainer>
          <Icon src={icon} alt="Icon" />
        </IconContainer>
        <Title>No {selectedTab.selectedTab} sessions to show.</Title>
      </SubContainer>
    </MainContainer>
  );
}

export default NoSessionCard;

const MainContainer = styled.div`
  display: flex;
  padding: 200px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: #f4f4f4;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;
const IconContainer = styled.div`
  width: 40px;
  height: 40px;
`;
const Icon = styled.img``;
const Title = styled.span`
  color: #818181;
  text-align: center;
  font-family: "DM_Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

import React, { useContext } from "react";

// packages
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// store
import { Context } from "../../../contexts/Store";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function OnBoardingPerson() {
    const navigate = useNavigate();
    const { dispatch } = useContext(Context);

    // dispatch data and navigation
    const onSubmit = (option) => {
        dispatch({
            type: "UPDATE_USER",
            user_details: {
                user_type: option,
            },
        });
        navigate("/onboarding-person-name");
    };

    return (
        <>
            <MainContainer>
                <ContentContainer>
                    <Content>
                        <LogoContainer>
                            <Logo
                                src={
                                    require("../../../assets/images/logo.svg")
                                        .default
                                }
                                alt="logo"
                            />
                        </LogoContainer>
                        <Text>Hello! Are you an</Text>
                        <IndividualContainer
                            onClick={() => {
                                onSubmit("Individual");
                            }}
                        >
                            Individual
                        </IndividualContainer>
                        <Parent
                            onClick={() => {
                                onSubmit("Parent Behalf of minor child");
                            }}
                        >
                            Parent Behalf of minor child
                        </Parent>
                        <SkipButton to="/onboarding-completed">
                            Skip for now
                        </SkipButton>
                    </Content>
                </ContentContainer>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.section`
    height: calc(100vh - 70px);

    background: ${THEME_COLORS.light_100};
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        background: ${THEME_COLORS.white};
    }
`;
const ContentContainer = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: 468px;

    /* @media all and (max-width: 1440px) {
        width: 35%;
    }
    @media all and (max-width: 1280px) {
        width: 40%;
    }
    @media all and (max-width: 1080px) {
        width: 45%;
    }
    @media all and (max-width: 980px) {
        width: 55%;
    }
    @media all and (max-width: 768px) {
        width: 65%;
    }
    @media all and (max-width: 640px) {
        width: 75%;
    } */
    @media all and (max-width: 480px) {
        width: 100%;
        border: none;
        box-shadow: none;
    }
`;
const Content = styled.div`
    padding: 100px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 80vh;
    @media all and (max-width: 640px) {
        padding: 50px;
    }
    @media all and (max-width: 480px) {
        padding: 0 20px;
    }
`;
const LogoContainer = styled.div`
    width: 18%;
    margin: 0 auto 80px auto;
    @media all and (max-width: 640px) {
        margin-bottom: 40px;
    }
    @media all and (max-width: 480px) {
        width: 56px;
    }
`;
const Logo = styled.img``;
const Text = styled.h3`
    font-size: 24px;
    text-align: center;
    font-family: "DM_sans_bold";
    margin-bottom: 30px;
`;
const IndividualContainer = styled.button`
    font-size: 16px;
    font-family: "DM_sans";
    border: 1px solid ${THEME_COLORS.light_300};
    display: block;
    margin-bottom: 20px;
    border-radius: 24px;
    min-height: 50px;
    cursor: pointer;
    :hover {
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
        background: ${THEME_COLORS.chips_green_container};
    }
`;
const Parent = styled.button`
    font-size: 16px;
    border: 1px solid ${THEME_COLORS.light_300};
    margin-bottom: 30px;
    border-radius: 24px;
    font-family: "DM_sans";
    min-height: 50px;
    cursor: pointer;

    :hover {
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
        background: ${THEME_COLORS.chips_green_container};
    }
`;
const SkipButton = styled(Link)`
    font-size: 14px;
    color: ${THEME_COLORS.chips_green_on_container};
    font-family: "DM_sans_medium";
    cursor: pointer;
    text-align: center;
`;

import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import embraceImage from "../../../assets/images/homescreens/embrace-card-image.svg";
import { THEME_COLORS } from "../../../ThemeConfig";
import { Link } from "react-router-dom";
import { Context } from "../../../contexts/Store";

export default function EmbraceCard() {
    const { dispatch } = useContext(Context);

    useEffect(() => {
        dispatch({
            type: "CANCEL_ADD_SESSION",
        });
    }, []);

    return (
        <ImageCardDiv>
            <ImageTitleText>
                Embrace{" "}
                <ImageTitleSecondaryColor>
                    Mindfulness,
                </ImageTitleSecondaryColor>{" "}
                Elevate Your{" "}
                <ImageTitleSecondaryColor>Well-being</ImageTitleSecondaryColor>
            </ImageTitleText>
            <CardImage src={embraceImage} alt="image" />
            <ImageDivButton to="/create-session">Book a Session</ImageDivButton>
        </ImageCardDiv>
    );
}
const ImageCardDiv = styled.div`
    background: #faf1e8;
    border-radius: 12px;
    padding: 26px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    text-align: center;
    @media all and (max-width: 1080px) {
        display: block;
    }
`;
const ImageTitleText = styled.h6`
    font-weight: 500;
    font-size: 20px;
    color: ${THEME_COLORS.black};
    width: 100%;
    text-align: center;
`;
const ImageTitleSecondaryColor = styled.span`
    font-weight: 500;
    font-size: 20px;
    color: #1da687;
`;
const CardImage = styled.img`
    margin-top: 12px;
    display: block;
`;
const ImageDivButton = styled(Link)`
    display: inline-block;
    margin-top: 26px;
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 32px;
    padding: 6px 18px;
    height: 32px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.white};
    width: fit-content;
    cursor: pointer;
`;

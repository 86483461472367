import React, { useContext, useEffect, useState } from "react";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// store
import { Context } from "../../../../contexts/Store";

// Media-Images //
import arrowimg from "../../../../assets/images/expand_more.svg";
import arrowimg2 from "../../../../assets/images/expand_less.svg";
import BottomNavigation from "../../../general/BottomNavigation";

// components
import NextButton from "../../../includes/buttons/NextButton";
import CancelButton from "../../../includes/buttons/CancelButton";

// packages
import { useNavigate } from "react-router-dom";

// axios
import { PatientConfig } from "../../../../axiosConfig";
import CreateSessionCancelModal from "../../../includes/modals/CreateSessionCancelModal";

function Challenge() {
    // Context and navigation hooks
    const { state, dispatch } = useContext(Context);
    const new_session = state.new_session;
    // console.log(new_session, "new_session");
    // console.log(state, "state");

    const navigate = useNavigate();

    let accessToken = state.user_details.access_token;
    let Token = state.new_session?.concern;
    let description = state.user_details.description;

    // State variables
    const [checked, setChecked] = useState(""); // Selected option
    const [isdown, setIsdown] = useState(""); // Expanded option description
    const [error, setError] = useState(false); // Error message flag
    const [value, setValue] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    const [concernList, setConcernList] = useState([]);
    const [isFocus, setIsFocus] = useState(false);
    const [ischeck, setischeck] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [otherData, setOtherData] = useState();

    // const getConcernList = () => {
    //     PatientConfig.get("api/v1/experts/area-of-expertises/", {
    //         headers: {
    //             // "Content-Type": "application/x-www-form-urlencoded",
    //             // Authorization: `Bearer  ${accessToken}`,
    //         },
    //     })
    //         .then((response) => {
    //             setConcernList(
    //                 response.data.data.filter((item) => {
    //                     return item.name !== "Other"
    //                         ? item
    //                         : setOtherData(item);
    //                 })
    //             );
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching data:", error);
    //         });
    // };
    const getConcernList = () => {
        const headers = {};
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        PatientConfig.get("api/v1/experts/area-of-expertises/", {
            headers: headers,
        })
            .then((response) => {
                setConcernList(
                    response.data.data.filter((item) => {
                        return item.name !== "Other"
                            ? item
                            : setOtherData(item);
                    })
                );
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getConcernList();
    }, []);

    // navigation,dispatch data to store
    const handleSubmit = () => {
        if (checked === "") {
            setError(true);
        } else if (checked === otherData?.id && value === "") {
            setDescriptionError(true);
        } else {
            setError(false);
            setDescriptionError(false);
            // dispatch({
            //     type: "UPDATE_USER",
            //     user_details: {
            //         concern: checked,
            //         description: value,
            //     },
            // });

            dispatch({
                type: "ADD_SESSION",
                new_session: {
                    concern: checked,
                    description: value,
                },
            });

            navigate("/schedule");
        }
    };

    // mobile screen
    const isMobile = window.innerWidth <= 480;

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        setChecked(event.target.value);
        // setischeck(true)
        dispatch({
            type: "ADD_SESSION",
            new_session: {
                concern: event.target.value,
            },
        });
        setError(false);
        setDescriptionError(false);
    };

    const handleDescriptionChange = (e) => {
        setDescriptionError();
        const input = e.target.value;
        setValue(input);
    };

    const handleCancelClick = () => {
        setIsCancelModalOpen((prev) => !prev);
    };

    const handleCancelAddSession = () => {
        dispatch({
            type: "CANCEL_ADD_SESSION",
        });
        navigate("/");
    };

    // console.log(Boolean(new_session), "Boolean of new_session");
    // useEffect(() => {
    //     let new_session = localStorage.getItem("new_session");
    //     new_session = JSON.parse(new_session);
    //     dispatch({
    //         type: "ADD_SESSION",
    //         new_session: new_session,
    //     });
    // }, [new_session]);

    useEffect(() => {
        if (Boolean(new_session)) {
            if (new_session.concern) {
                setChecked(new_session.concern);
            }
            if (new_session.description) {
                setValue(new_session.description);
            }
        }
    }, [new_session]);

    // Render each option
    const renderBox = concernList.map((concern, index) => {
        return (
            <ItemDiv
                className={isdown === concern.name && "active"}
                key={concern.id}
            >
                <ItemContent key={index}>
                    <LeftContainer>
                        {concern.id == Token ? (
                            <RadioBtn>
                                <input
                                    checked={true}
                                    type="radio"
                                    name="session"
                                    value={concern.id}
                                    // onLoad={() => {
                                    //   setChecked(Token)
                                    // }}
                                />
                            </RadioBtn>
                        ) : checked == concern.id ? (
                            <RadioBtn>
                                <input
                                    checked={true}
                                    type="radio"
                                    name="session"
                                    value={concern.id}
                                    // onClick={(e) => {
                                    //   handleCheckboxChange(e)
                                    // }}
                                />
                            </RadioBtn>
                        ) : (
                            <RadioBtn>
                                <input
                                    checked={false}
                                    type="radio"
                                    name="session"
                                    value={concern.id}
                                    onClick={(e) => {
                                        handleCheckboxChange(e);
                                    }}
                                />
                            </RadioBtn>
                        )}
                        <Label>{concern.name}</Label>
                    </LeftContainer>
                    {isdown === concern.name ? (
                        <RightContainer onClick={() => setIsdown("")}>
                            <ArrowImage src={arrowimg2} alt="Arrow" />
                        </RightContainer>
                    ) : (
                        <RightContainer onClick={() => setIsdown(concern.name)}>
                            <ArrowImage src={arrowimg} alt="Arrow" />
                        </RightContainer>
                    )}
                </ItemContent>
                {isdown === concern.name && <Para>{concern.description}</Para>}
            </ItemDiv>
        );
    });

    return (
        <>
            <MainContainer>
                <HeadingDiv>
                    <Heading>Create a Session</Heading>
                    <CancelBtn onClick={() => handleCancelClick()}>
                        <CloseSvg>
                            <CloseSvgImg
                                src={
                                    require("../../../../assets/images/close.svg")
                                        .default
                                }
                                alt="cancel-button"
                            />
                        </CloseSvg>
                        <Cancel>Cancel</Cancel>
                    </CancelBtn>
                </HeadingDiv>
                <ContentDiv>
                    <TopDiv>
                        <Section>
                            <Count isFirst>1</Count>
                            <Title isFirst>Challenge</Title>
                        </Section>
                        <Section>
                            <Count>2</Count>
                            <Title>Schedule</Title>
                        </Section>
                        <Section>
                            <Count>3</Count>
                            <Title>Fulfill</Title>
                        </Section>
                    </TopDiv>
                    <MiddleDiv>
                        <MidHeading>Select your concern</MidHeading>
                        <ItemContainer>{renderBox}</ItemContainer>
                        {otherData && (
                            <OtherOption>
                                <ItemDiv>
                                    <ItemContent>
                                        <LeftContainer>
                                            <RadioBtn>
                                                <input
                                                    type="radio"
                                                    name="session"
                                                    value={otherData?.id}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                />
                                            </RadioBtn>
                                            <Label className="other">
                                                {otherData?.name}
                                            </Label>
                                        </LeftContainer>
                                    </ItemContent>
                                </ItemDiv>
                            </OtherOption>
                        )}
                    </MiddleDiv>
                    <MiddleDiv>
                        <MidHeading>Describe in your words</MidHeading>
                        <FormContainer className={isFocus ? "active" : ""}>
                            {/* <DescribeLabel
                onClick={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
              >
                Describe
              </DescribeLabel> */}
                            <Input
                                type="text"
                                id="input"
                                placeholder="Enter here"
                                value={value}
                                onChange={handleDescriptionChange}
                                onFocus={() => setIsFocus(true)}
                                onBlur={() => setIsFocus(false)}
                                // required
                            />
                        </FormContainer>
                    </MiddleDiv>
                    <BottomDiv>
                        <ErrorMessage
                            className={
                                error || descriptionError ? "display" : ""
                            }
                        >
                            {(error && "Please select a concern") ||
                                (descriptionError &&
                                    "Please fill the description")}
                        </ErrorMessage>
                        <NextButton
                            size="14px"
                            style={{
                                padding: "10px 20px",
                                height: "40px",
                                width: isMobile ? "50%" : "fit-content",
                            }}
                            onClick={handleSubmit}
                        />
                    </BottomDiv>
                </ContentDiv>

                {isCancelModalOpen && (
                    <CreateSessionCancelModal
                        closeModal={() => handleCancelClick()}
                    />
                )}
            </MainContainer>
            {/* Bottom navigation component */}
            <BottomNavigation />
        </>
    );
}

export default Challenge;
const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    padding: 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    @media all and (max-width: 768px) {
        width: 100%;
        /* overflow-y: auto; */
        padding: 50px 0px 120px;
    }
    @media all and (max-width: 480px) {
        /* padding-bottom: 120px; */
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const HeadingDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 452px;
    margin-bottom: 14px;

    @media all and (max-width: 480px) {
        width: 95%;
    }
`;
const Heading = styled.h4`
    /* font-weight: 500; */
    font-size: 18px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    line-height: 26px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const CancelBtn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 18px;
    background: ${THEME_COLORS.white};
    border-radius: 32px;
    width: 107px;
    cursor: pointer;
`;
const CloseSvg = styled.div``;
const CloseSvgImg = styled.img``;
const Cancel = styled.span`
    font-size: 14px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_green_on_container};
    margin-left: 8px;
`;
const ContentDiv = styled.div`
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_400};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    height: fit-content;
    width: 452px;
    border-radius: 24px;
    padding: 16px;
    /* max-height: 100vh;
    overflow-x: auto; */
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 480px) {
        width: 95%;
    }
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    margin-bottom: 18px;
`;
const Section = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
`;
const Count = styled.span`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 9px;
    border-radius: 50%;
    border: 1px solid ${THEME_COLORS.chips_green_on_container};
    padding: 2px 6px;
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.white : THEME_COLORS.black};
    background: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.chips_green_on_container : THEME_COLORS.white};
    @media all and (max-width: 1440px) {
        font-size: 10px;
    }
`;
const Title = styled.label`
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.black : THEME_COLORS.text_title};
`;
const MiddleDiv = styled.div`
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 10px;
    padding: 14px 14px 10px 14px;
    margin-bottom: 18px;
`;
const MidHeading = styled.h6`
    font-family: "DM_sans_bold";
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
`;
const ItemDiv = styled.div`
    padding: 5px;
    margin-bottom: 2px;
    border-radius: 8px;
    &.active {
        background: ${THEME_COLORS.chips_green_container};
        transition: all 0.5s ease;
    }
`;
const Para = styled.p`
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
    padding: 0px 30px;
`;
const OtherOption = styled.div`
    border-top: 1px solid #f5f5f5;
`;
const ItemContainer = styled.div`
    /* overflow-y: scroll;
    height: 280px; */
    padding-right: 10px;
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${THEME_COLORS.light_400};
        border-radius: 1px;
        height: 2px;
    }
    /* @media all and (max-width: 1440px) {
    height: 446px;
  }
  @media all and (max-width: 768px) {
    height: 424px;
  }
  @media all and (max-width: 480px) {
    height: 380px;
  } */
`;
const ItemContent = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
`;
const LeftContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 12px;
    input[type="radio"] {
        width: 16px;
        height: 16px;
        accent-color: ${THEME_COLORS.chips_green_on_container};
    }
`;
const Label = styled.label`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 14px;
    line-height: 20px;
    width: 315px;
    &.other {
        text-transform: capitalize;
    }
`;
const RightContainer = styled.span`
    display: inline-block;
    width: 10px;
    :hover {
        cursor: pointer;
    }
`;
const ArrowImage = styled.img`
    width: 100%;
    display: block;
`;
const RadioBtn = styled.div`
    display: flex;
    align-items: center;
    padding-top: 3px;
    :hover {
        cursor: pointer;
    }
`;
const ErrorMessage = styled.span`
    font-size: 12px;
    color: red;
    margin-left: 10px;
    visibility: hidden;
    &.display {
        visibility: visible;
    }
`;
const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 2px solid #f4f4f4;
    background-color: #f4f4f4;
    transition: all 0.2s ease-in-out 0s;
    border-radius: 10px;
    &.active {
        border: 2px solid ${THEME_COLORS.border_blue};
    }
`;
const Input = styled.textarea`
    font-size: 14px;
    width: 100%;
    height: 18px;
    line-height: 1.4em;
    font-family: "DM_sans";
    color: ${THEME_COLORS.black};
    height: 59px;
    &:focus {
    }
    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-resizer {
        display: none;
    }
`;
const BottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

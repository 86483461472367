import React, { useContext, useEffect } from "react";

// components
import banner from "../../../assets/images/home-banner.svg";

// packages
import styled from "styled-components";
import { Link } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";
import { Context } from "../../../contexts/Store";

export default function HomeScreenBanner({ isExistingSession }) {
    const { dispatch } = useContext(Context);

    useEffect(() => {
        dispatch({
            type: "CANCEL_ADD_SESSION",
        });
    }, []);
    return (
        <ImageContainer>
            <Title>
                Embrace <span>Mindfulness</span>, <br />
                Elevate Your <span>Well-being</span>
            </Title>
            <Button to="/create-session">Book a Session</Button>
        </ImageContainer>
    );
}
const ImageContainer = styled.div`
    background-image: url(${banner});
    background-size: contain;
    background-repeat: no-repeat;
    padding: 30px;
    width: 100%;
    height: 190px;
    @media all and (max-width: 1080px) {
        display: ${({ isExistingSession }) =>
            isExistingSession ? "block" : "none"};
        height: 152px;
        padding: 18px;
    }
`;
const Title = styled.h2`
    font-size: 24px;
    margin-bottom: 10px;
    span {
        font-size: 24px;
        color: #1da687;
    }
`;
const Button = styled(Link)`
    display: inline-block;
    padding: 8px 18px;
    background: ${THEME_COLORS.chips_green_on_container};
    border-radius: 32px;
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.white};
    cursor: pointer;
`;

import React, { useContext, useEffect, useState, useRef } from "react";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// Media-Images //
import tick from "../../../../assets/images/tick.svg";
import info from "../../../../assets/icons/alert-red.svg";
import callBlue from "../../../../assets/icons/call-blue.svg";
import profileBlue from "../../../../assets/icons/profile-blue.svg";
import sessionBlue from "../../../../assets/icons/session-blue.svg";
import durationBlue from "../../../../assets/icons/duration-blue.svg";
import dropDown from "../../../../assets/icons/dropdown-arrow.svg";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";
import PaymentButton from "../../../includes/buttons/PaymentButton";
import PreviousButton from "../../../includes/buttons/PreviousButton";
import CancelButton from "../../../includes/buttons/CancelButton";
import CountModal from "../../../includes/modals/CountModal";
import PackageInfoModal from "../../../includes/modals/PackageInfoModal";

// axios
import { PatientConfig } from "../../../../axiosConfig";

// store
import { Context } from "../../../../contexts/Store";
import CreateSessionCancelModal from "../../../includes/modals/CreateSessionCancelModal";
import { useNavigate } from "react-router-dom";

function Fulfill() {
    const [selectedPackageId, setSelectedPackageId] = useState("");
    const [isOpen, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // Modal open //
    const navigate = useNavigate();
    const [isSuccess, setIsSuccess] = useState(false);

    const [patient, setPatient] = useState("");
    const [isFocused, setIsFocused] = useState("");
    const [packages, setPackages] = useState([]);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [error, setError] = useState("");

    // State for Single Session and Package Session
    const [SingleSession, setSingleSession] = useState(true);
    const [PackageSession, setPackageSession] = useState(false);
    const [planType, setPlanType] = useState("Single");

    const { state, dispatch } = useContext(Context);
    const new_session = state.new_session;

    let accessToken = state.user_details.access_token;

    const getPackages = () => {
        PatientConfig.get("api/v1/cases/packages/", {
            headers: {
                // "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer  ${accessToken}`,
            },
            params: {
                count:
                    new_session?.session_count == "Individual"
                        ? "1"
                        : new_session?.session_count == "Family"
                        ? "2"
                        : null,
                session_type:
                    new_session?.plan_type == "Single"
                        ? "1"
                        : new_session?.plan_type == "Package"
                        ? "2"
                        : null,
            },
        })
            .then((response) => {
                setPackages(response.data.data.data);
                // Handle success as needed
            })
            .catch((error) => {
                // console.error("An error occurred:", error);
                // Handle errors as needed
            });
    };

    useEffect(() => {
        getPackages();
    }, [new_session?.session_count, new_session?.plan_type]);

    // Check if the screen is mobile
    const isMobile = window.innerWidth <= 480;

    // Info click
    const handleInfoClick = () => {
        setIsModalOpen((prev) => !prev);
    };

    //   handle selected package
    const handleItemClick = (itemId) => {
        setSelectedPackageId(itemId);
        // dispatch({
        //     type: "UPDATE_USER",
        //     user_details: {
        //         package: itemId,
        //         session_count: patient,
        //     },
        // });
    };

    const handleCancelClick = () => {
        setIsCancelModalOpen((prev) => !prev);
    };
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, setOpen]);
    useEffect(() => {
        // console.log("isOpen state changed:", isOpen);
    }, [isOpen]);

    useEffect(() => {
        if (Boolean(new_session)) {
            // console.log("new session is there");
            if (new_session?.session_count) {
                setPatient(new_session.session_count);
            }
            if (new_session?.plan_type) {
                setPlanType(new_session.plan_type);
            }
        }
    }, [new_session]);
    useEffect(() => {
        if (!new_session?.plan_type || new_session?.plan_type == "") {
            dispatch({
                type: "ADD_SESSION",
                new_session: {
                    plan_type: "Single",
                },
            });
        }
        if (!new_session?.session_count || new_session?.session_count == "") {
            dispatch({
                type: "ADD_SESSION",
                new_session: {
                    session_count: "Individual",
                },
            });
        }
    }, [new_session]);

    useEffect(() => {
        if (planType && selectedPackageId) {
            if (
                !packages.some(
                    (item) =>
                        item.session_type === planType &&
                        item.id === selectedPackageId
                )
            ) {
                setSelectedPackageId("");
                // console.log("---Cleared---");
            }
        }
    }, [packages, new_session, planType]);

    useEffect(() => {
        if (isSuccess) {
            if (!isSuccessModalOpen) {
                navigate("/");
            }
        }
    }, [isSuccess, isSuccessModalOpen]);

    useEffect(() => {
        if (error) {
            if (patient && planType && selectedPackageId) {
                setError("");
            }
            setTimeout(() => {
                setError("");
            }, 3000);
        }
    }, [error, patient, planType, selectedPackageId]);

    useEffect(() => {
        // console.log(Boolean(new_session), "Boolean(new_session)");
        if (!isSuccess) {
            if (new_session) {
                if (Boolean(new_session) == true) {
                    if (Boolean(new_session.concern) == false) {
                        navigate("/create-session");
                    } else if (Boolean(new_session.expert) == false) {
                        navigate("/schedule");
                    } else if (Boolean(new_session.case_date) == false) {
                        navigate("/schedule");
                    } else if (Boolean(new_session.time_slot) == false) {
                        navigate("/schedule");
                    }
                } else if (Boolean(new_session) == false) {
                    navigate("/create-session");
                }
            }
        }
    }, [isSuccess, new_session]);

    return (
        <>
            {/* Main container */}
            <MainContainer>
                <HeadingDiv>
                    <Heading>Create a Session</Heading>
                    <CancelBtn onClick={() => handleCancelClick()}>
                        <CloseSvg>
                            <CloseSvgImg
                                src={
                                    require("../../../../assets/images/close.svg")
                                        .default
                                }
                                alt="cancel-button"
                            />
                        </CloseSvg>
                        <Cancel>Cancel</Cancel>
                    </CancelBtn>
                </HeadingDiv>
                <ContentDiv>
                    <TopDiv>
                        <Section>
                            <Tick>
                                <TickImg src={tick} alt="Tick image" />
                            </Tick>
                            <Title>Challenge</Title>
                        </Section>
                        <Section>
                            <Tick>
                                <TickImg src={tick} alt="Tick image" />
                            </Tick>
                            <Title>Schedule</Title>
                        </Section>
                        <Section>
                            <Count isFirst>3</Count>
                            <Title isFirst>Fulfill</Title>
                        </Section>
                    </TopDiv>
                    {/* Select plan */}
                    <MiddleDiv>
                        <ItemContainer>
                            <div ref={wrapperRef}>
                                <MidHeading>Select your count</MidHeading>
                                <Contents
                                    onClick={() => setOpen(!isOpen)}
                                    onFocus={() => setIsFocused("active")}
                                    onBlur={() => setIsFocused("")}
                                    className={
                                        isFocused === "active" || isOpen
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <div>
                                        <LabelCount>Choose Count*</LabelCount>
                                        <Patient>{patient}</Patient>
                                    </div>
                                    <DropDown
                                        src={dropDown}
                                        alt="dropdown-arrow"
                                        isOpen={isOpen ? true : false}
                                    />
                                    {isOpen && (
                                        <CountModal
                                            setPatient={setPatient}
                                            setOpen={setOpen}
                                        />
                                    )}
                                </Contents>
                            </div>
                            <MidHeading>Select your plan</MidHeading>
                            {/* Toggle between Single Session and Package Session */}
                            <OptionDIv>
                                <OptionBtn
                                    className={
                                        planType == "Single" ? "active" : ""
                                    }
                                    onClick={() => {
                                        dispatch({
                                            type: "ADD_SESSION",
                                            new_session: {
                                                plan_type: "Single",
                                            },
                                        });
                                        setPlanType("Single");
                                    }}
                                >
                                    Single
                                </OptionBtn>
                                <OptionBtn
                                    className={
                                        planType == "Package" ? "active" : ""
                                    }
                                    onClick={() => {
                                        dispatch({
                                            type: "ADD_SESSION",
                                            new_session: {
                                                plan_type: "Package",
                                            },
                                        });
                                        setPlanType("Package");
                                    }}
                                >
                                    Package
                                </OptionBtn>
                            </OptionDIv>
                            {/* Render Single Session details */}
                            {planType == "Single" &&
                                packages.map((item) => {
                                    if (item.session_type === "Single") {
                                        return (
                                            <PriceDiv
                                                key={item?.pk}
                                                onClick={() =>
                                                    handleItemClick(item.pk)
                                                }
                                                className={
                                                    selectedPackageId ===
                                                    item.pk
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <HeadContainer>
                                                    <HeadTitle>
                                                        {item?.package_type}
                                                    </HeadTitle>
                                                    <SubContainer>
                                                        {item?.is_popular && (
                                                            <Popular>
                                                                Popular
                                                            </Popular>
                                                        )}
                                                        <Alert
                                                            src={info}
                                                            onClick={() => {
                                                                handleInfoClick();
                                                                setSelectedItem(
                                                                    item
                                                                );
                                                            }}
                                                            alt="red-alert"
                                                        />
                                                        {isModalOpen && (
                                                            <PackageInfoModal
                                                                item={
                                                                    selectedItem
                                                                }
                                                                closeModal={() =>
                                                                    handleInfoClick()
                                                                }
                                                            />
                                                        )}
                                                    </SubContainer>
                                                </HeadContainer>
                                                <Price>
                                                    <ActualPrice>
                                                        ₹{item?.actual_price}
                                                    </ActualPrice>{" "}
                                                    <DiscountedPrice>
                                                        ₹{item?.price}
                                                    </DiscountedPrice>
                                                    <Offer>
                                                        {" "}
                                                        ({item?.discount})% OFF
                                                    </Offer>
                                                </Price>
                                                <Inclusive>
                                                    inclusive of all taxes
                                                </Inclusive>
                                                <ContentContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={callBlue}
                                                            alt="call-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>Mode</Mode>
                                                            <ModeType>
                                                                {
                                                                    item?.meet_mode
                                                                }
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={durationBlue}
                                                            alt="call-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>
                                                                Duration
                                                            </Mode>
                                                            <ModeType>
                                                                {item?.duration}{" "}
                                                                Minutes
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={sessionBlue}
                                                            alt="call-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>
                                                                No. of Sessions
                                                            </Mode>
                                                            <ModeType>
                                                                {
                                                                    item?.no_of_sessions
                                                                }
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={profileBlue}
                                                            alt="profile-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>
                                                                Expert Type
                                                            </Mode>
                                                            <ModeType>
                                                                {
                                                                    item?.expert_type
                                                                }
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                </ContentContainer>
                                            </PriceDiv>
                                        );
                                    }
                                })}

                            {/* Render Package Session details */}
                            {planType == "Package" &&
                                packages.map((item) => {
                                    if (item.session_type === "Package") {
                                        return (
                                            <PriceDiv
                                                key={item?.pk}
                                                onClick={() =>
                                                    handleItemClick(item.pk)
                                                }
                                                className={
                                                    selectedPackageId ===
                                                    item.pk
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                <HeadContainer>
                                                    <HeadTitle>
                                                        {item?.package_type}
                                                    </HeadTitle>
                                                    <SubContainer>
                                                        {item?.is_popular && (
                                                            <Popular>
                                                                Popular
                                                            </Popular>
                                                        )}
                                                        <Alert
                                                            src={info}
                                                            onClick={() => {
                                                                handleInfoClick();
                                                                setSelectedItem(
                                                                    item
                                                                );
                                                            }}
                                                            alt="red-alert"
                                                        />
                                                        {isModalOpen && (
                                                            <PackageInfoModal
                                                                item={
                                                                    selectedItem
                                                                }
                                                                closeModal={() =>
                                                                    handleInfoClick()
                                                                }
                                                            />
                                                        )}
                                                    </SubContainer>
                                                </HeadContainer>
                                                <Price>
                                                    <ActualPrice>
                                                        ₹{item?.actual_price}
                                                    </ActualPrice>
                                                    <DiscountedPrice>
                                                        ₹{item?.price}
                                                    </DiscountedPrice>
                                                    <Offer>
                                                        {" "}
                                                        ({item?.discount})% OFF
                                                    </Offer>
                                                </Price>
                                                <Inclusive>
                                                    inclusive of all taxes
                                                </Inclusive>
                                                <ContentContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={callBlue}
                                                            alt="call-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>Mode</Mode>
                                                            <ModeType>
                                                                {
                                                                    item?.meet_mode
                                                                }
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={durationBlue}
                                                            alt="call-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>
                                                                Duration
                                                            </Mode>
                                                            <ModeType>
                                                                {item?.duration}{" "}
                                                                Minutes
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={sessionBlue}
                                                            alt="call-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>
                                                                No. of Sessions
                                                            </Mode>
                                                            <ModeType>
                                                                {
                                                                    item?.no_of_sessions
                                                                }
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                    <ModeContainer>
                                                        <CallBlue
                                                            src={profileBlue}
                                                            alt="profile-blue-icon"
                                                        />
                                                        <div>
                                                            <Mode>
                                                                Expert Type
                                                            </Mode>
                                                            <ModeType>
                                                                {
                                                                    item?.expert_type
                                                                }
                                                            </ModeType>
                                                        </div>
                                                    </ModeContainer>
                                                </ContentContainer>
                                            </PriceDiv>
                                        );
                                    }
                                })}
                        </ItemContainer>
                        <span style={{ color: "red", fontSize: "12px" }}>
                            {error}
                        </span>
                    </MiddleDiv>
                    {/* Bottom section */}
                    <BottomDiv>
                        {/* Previous and Payment buttons */}
                        <PreviousButton
                            size="14px"
                            style={{
                                padding: "10px 20px",
                                height: "40px",
                                width: isMobile ? "50%" : "fit-content",
                            }}
                            url="/schedule"
                        />
                        <PaymentButton
                            setError={setError}
                            style={{
                                width: isMobile && "50%",
                            }}
                            url="/"
                            usageAt="fullfill"
                            selectedPackageId={selectedPackageId}
                            isModalOpen={isSuccessModalOpen}
                            setIsModalOpen={setIsSuccessModalOpen}
                            setIsSuccess={setIsSuccess}
                            // handleFullfillSubmit={handleFullfillSubmit}
                        />
                    </BottomDiv>
                </ContentDiv>
                {isCancelModalOpen && (
                    <CreateSessionCancelModal
                        closeModal={() => handleCancelClick()}
                    />
                )}
            </MainContainer>
            {/* Bottom navigation component */}
            <BottomNavigation />
        </>
    );
}

export default Fulfill;

const MainContainer = styled.div`
    /* position: relative; */
    width: 100%;
    background: ${THEME_COLORS.light_200};
    padding: 26px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: (100vh - 70px);
    overflow-y: scroll;
    @media all and (max-width: 768px) {
        width: 100%;
        /* overflow-y: scroll; */
        padding: 26px 0 120px;
    }
    @media all and (max-width: 480px) {
        padding-bottom: 90px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const HeadingDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 412px;
    margin-bottom: 14px;

    @media all and (max-width: 480px) {
        /* width: 360px; */
        width: 95%;
    }
    /* @media all and (max-width: 360px) {
        width: 98%;
    } */
`;
const Heading = styled.h4`
    font-size: 18px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    line-height: 26px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const CancelBtn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 18px;
    background: ${THEME_COLORS.white};
    border-radius: 32px;
    width: 107px;
    cursor: pointer;
`;
const CloseSvg = styled.div``;
const CloseSvgImg = styled.img``;
const Cancel = styled.span`
    font-size: 14px;
    font-family: "DM_sans_medium";

    color: ${THEME_COLORS.chips_green_on_container};
    margin-left: 8px;
`;
const ContentDiv = styled.div`
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_400};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    width: 412px;

    margin: 0 auto;
    border-radius: 24px;
    padding: 16px;

    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 480px) {
        /* width: 360px; */
        width: 95%;
    }
    /* @media all and (max-width: 360px) {
        /* width: 320px; */
    /* width: 98%; */
    /* }  */
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    margin-bottom: 18px;
`;
const Section = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
`;
const Count = styled.span`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 9px;
    border-radius: 50%;
    border: 1px solid ${THEME_COLORS.chips_green_on_container};
    padding: 3px 6px;
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.white : THEME_COLORS.black};
    background: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.chips_green_on_container : THEME_COLORS.white};
    span:first-child {
        width: 54px;
        padding: 6px;
    }
    @media all and (max-width: 1440px) {
        font-size: 10px;
    }
`;
const Tick = styled.span`
    display: inline-block;
    width: 18px;
`;
const TickImg = styled.img`
    display: block;
    width: 100%;
`;
const Title = styled.label`
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    color: ${THEME_COLORS.text_title};
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.black : THEME_COLORS.text_title};
`;
const MiddleDiv = styled.div`
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 10px;
    padding: 14px;
    margin-bottom: 18px;

    @media all and (max-width: 1440px) {
        margin-bottom: 20px;
    }
    @media all and (max-width: 768px) {
        margin-bottom: 22px;
    }
    @media all and (max-width: 360px) {
        padding: 8px;
    }
`;
const MidHeading = styled.h6`
    font-family: "DM_sans_bold";
    font-size: 14px;
    line-height: 18px;

    margin: 10px 0 8px 0;
`;
const ItemContainer = styled.div`
    /* height: 57vh; */
    /* height: calc(100vh - 350px);
    overflow-y: scroll; */
    position: relative;
`;
const BottomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 14px;
`;
const OptionDIv = styled.div`
    border: 1px solid ${THEME_COLORS.light_400};
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    padding: 4px;
    margin-bottom: 12px;
`;
const OptionBtn = styled.button`
    display: inline-block;
    width: 146px;
    height: 25px;
    border-radius: 6px;
    cursor: pointer;
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #818181;
    width: 49%;
    /* ${(props) => (props.isOpen ? "rotate(180deg)" : null)} */
    &.active {
        border: 2px solid ${THEME_COLORS.border_blue};
        color: #000;
        &:hover {
            opacity: 1;
        }
    }
    &:hover {
        color: #000;
        opacity: 0.8;
    }
`;
const PriceDiv = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 2px solid ${THEME_COLORS.light_400};
    background: ${THEME_COLORS.menu_blue};
    padding: 16px;
    margin-bottom: 20px;
    &.active {
        border: 2px solid ${THEME_COLORS.border_blue};
    }
`;

const Price = styled.p`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
`;
const ActualPrice = styled.span`
    font-size: 32px;
    display: block;
    font-family: "DM_sans_bold";
    color: ${THEME_COLORS.black};
`;
const DiscountedPrice = styled.span`
    font-size: 14px;
    display: block;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_blue_on_container_2};
    text-decoration: line-through;
`;
const Offer = styled.span`
    font-size: 14px;
    display: block;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_blue_on_container_2};
`;

const HeadContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;
const HeadTitle = styled.h4`
    font-family: "DM_sans_medium";
    font-size: 14px;
    text-transform: uppercase;
`;
const SubContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;
const Popular = styled.span`
    font-size: 12px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.chips_blue_on_container_2};
    border: 1px solid ${THEME_COLORS.chips_blue_on_container_2};
    border-radius: 53px;
    background: ${THEME_COLORS.chips_blue_container};
    padding: 2px 6px;
`;
const Alert = styled.img`
    width: 20px;
    height: 20px;
`;
const Inclusive = styled.span`
    display: block;
    font-size: 12px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_para};
    margin-bottom: 10px;
`;
const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
const ModeContainer = styled.div`
    display: flex;
    gap: 13px;
    width: 50%;
    margin-bottom: 10px;
`;
const CallBlue = styled.img`
    width: 32px;
`;
const Mode = styled.span`
    display: block;
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: ${THEME_COLORS.dark_400};
`;
const ModeType = styled.span`
    display: block;
    font-size: 16px;
    font-family: "DM_sans_medium";
`;
const Contents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${THEME_COLORS.light_200};
    border-radius: 25px;
    margin-bottom: 10px;
    padding: 6px 24px;
    position: relative;
    border: 2px solid transparent;
    height: 50px;
    cursor: pointer;
    &.active {
        border: 2px solid #2b3990;
    }
`;
const LabelCount = styled.span`
    display: block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`;
const Patient = styled.span`
    display: block;
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: ${THEME_COLORS.black};
`;
const DropDown = styled.img`
    width: 20px;
    height: 20px;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : null)};
    transition: 0.1s;
`;

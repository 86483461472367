import React, { useEffect, useRef } from "react";

// Styles //
import styled from "styled-components";

// colors //
import { THEME_COLORS } from "../../../ThemeConfig";
import videoSource from "../../../assets/video/pex.mp4";

const Video = ({ closeModal, setDuration }) => {
    const videoRef = useRef();
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.addEventListener("loadedmetadata", () => {
                const duration = video.duration;
                setDuration(duration);
                // console.log("Video duration:", duration);
            });
        }
    }, []);

    return (
        <Wrapper onClick={closeModal}>
            <Content onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={closeModal}>&times;</CloseButton>
                <VideoContainer>
                    <Videos
                        ref={videoRef}
                        src={videoSource}
                        controls
                        autoPlay
                    />
                </VideoContainer>
            </Content>
        </Wrapper>
    );
};

export default Video;

const CloseButton = styled.span`
    position: absolute;
    top: 1%;
    right: 1%;
    font-size: 20px;
    font-weight: bold;
    color: #ccc;
    cursor: pointer;
`;
const Wrapper = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${THEME_COLORS.breath_model};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-out;
`;
const Content = styled.div`
    width: 70%;
    z-index: 200;
    border-radius: 10px;
`;
const VideoContainer = styled.div`
    width: 100%;
    height: 80%;
    background: #fff;
    border-radius: 10px;
    overflow-y: hidden; /* Hide the scrollbars */
`;

const Videos = styled.video`
    width: 100%;
    display: block;
`;

import React, { useEffect } from "react";

// package
import styled, { keyframes } from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function FeedBackSuccessModal({ closeModal }) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      closeModal();
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Modal onClick={closeModal}>
      <ModalContent>
        <Card>
          <CardTitle>✅ Feedback submitted successfully</CardTitle>
          <CardDescription>
            Your changes have been successfully saved.
          </CardDescription>
        </Card>
      </ModalContent>
    </Modal>
  );
}

const popup = keyframes`
    0%{
        scale: 0.3;
    }
    100%{
        scale: 1;
    }
`;

const Modal = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  left: 0;
  top: 0;
  @media all and (max-width: 480px) {
    justify-content: center;
  }
`;
const ModalContent = styled.div`
  border-radius: 12px;
  box-shadow: ${THEME_COLORS.elevation_expert_e2};
  background: ${THEME_COLORS.chips_green_on_container};
  position: absolute;
  padding-top: 6px;
  bottom: 5%;
  left: 3%;
  z-index: 100;
  animation: ${popup} 0.5s ease-in-out;
  @media all and (max-width: 480px) {
    left: auto;
    bottom: 80%;
  }
`;
const Card = styled.div`
  border-radius: 12px;
  background: ${THEME_COLORS.white};
  padding: 24px;
  @media all and (max-width: 480px) {
    padding: 16px;
  }
  @media all and (max-width: 360px) {
    padding: 14px;
  }
`;
const CardTitle = styled.h3`
  font-family: "DM_sans_medium";
  font-size: 20px;
  color: ${THEME_COLORS.text_title};
  margin-bottom: 5px;
  @media all and (max-width: 480px) {
    font-size: 16px;
  }
  @media all and (max-width: 360px) {
    font-size: 15px;
  }
`;
const CardDescription = styled.p`
  font-family: "DM_sans";
  font-size: 14px;
  color: ${THEME_COLORS.text_title};
  @media all and (max-width: 480px) {
    font-size: 12px;
  }
  @media all and (max-width: 360px) {
    font-size: 11px;
  }
`;

import React, { useState } from "react";

// packages
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import { CountdownCircleTimer } from "react-countdown-circle-timer";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function OnBoardingPersonMeditate() {
    const navigate = useNavigate();
    const [isPlaying, setIsPlaying] = useState(false);
    // console.log(isPlaying);

    return (
        <MainContainer>
            <ContentContainer>
                <Content>
                    <TopContent>
                        <LogoContainer>
                            <Logo
                                src={
                                    require("../../../assets/images/logo.svg")
                                        .default
                                }
                                alt="logo"
                            />
                        </LogoContainer>
                        <Text>Just Breath for a 30 sec.</Text>
                        <SubText>
                            Meditate, You are Stronger <br />
                            than you think
                        </SubText>
                        <Counter>
                            <svg style={{ position: "absolute", left: 0 }}>
                                <defs>
                                    <linearGradient
                                        id="countdown"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="0"
                                    >
                                        <stop
                                            offset="36%"
                                            stopColor="#21A789"
                                        />
                                        <stop
                                            offset="100%"
                                            stopColor="rgba(29, 166, 135, 0.36)"
                                        />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <CountdownCircleTimer
                                isPlaying={isPlaying}
                                duration={30}
                                initialRemainingTime={30}
                                colors={"url(#countdown)"}
                                strokeWidth={28}
                                size={200}
                                strokeLinecap={"square"}
                                trailColor={THEME_COLORS.white}
                                onComplete={() => {
                                    navigate("/onboarding-completed");
                                    return { delay: 3 };
                                }}
                            >
                                {({ remainingTime }) => (
                                    <div className="timer">
                                        <div
                                            className="value-container"
                                            style={{
                                                border: "6px solid #ECE7E7",
                                                borderRadius: "50%",
                                                padding: "45px 21px",
                                                overflow: "hidden",
                                            }}
                                        >
                                            <div
                                                className="value timer-text"
                                                style={{
                                                    fontFamily: "DM_sans_bold",
                                                    fontSize: "32px",
                                                    minWidth: "100px",
                                                }}
                                            >
                                                00:
                                                {remainingTime < 10
                                                    ? `0${remainingTime}`
                                                    : remainingTime}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </CountdownCircleTimer>
                        </Counter>
                    </TopContent>
                    <BottomContent>
                        <StartButton onClick={() => setIsPlaying(true)}>
                            Start Now
                        </StartButton>
                        <SkipButton to="/onboarding-completed">
                            {" "}
                            Skip for now
                        </SkipButton>
                    </BottomContent>
                </Content>
            </ContentContainer>
        </MainContainer>
    );
}
const MainContainer = styled.section`
    height: calc(100vh - 70px);
    background: ${THEME_COLORS.light_100};
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        background: ${THEME_COLORS.white};
        display: block;
    }
`;
const ContentContainer = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: 468px;
    max-height: 80vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    /* @media all and (max-width: 1440px) {
    width: 38%;
  }
  @media all and (max-width: 1280px) {
    width: 45%;
  }
  @media all and (max-width: 1080px) {
    width: 50%;
  }
  @media all and (max-width: 980px) {
    width: 62%;
  }
  @media all and (max-width: 768px) {
    width: 75%;
  }
  @media all and (max-width: 640px) {
    width: 90%;
  } */
    @media all and (max-width: 480px) {
        width: 100%;
        border: none;
        box-shadow: none;
        height: 100%;
    }
`;
const Content = styled.div`
    padding: 50px;
    @media all and (max-width: 480px) {
        padding: 50px 15px 20px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`;
const TopContent = styled.div``;
const LogoContainer = styled.div`
    width: 56px;
    margin: 0 auto 60px auto;
`;
const Logo = styled.img``;
const Text = styled.h3`
    font-family: "DM_sans_bold";
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
`;
const SubText = styled.h4`
    font-size: 14px;
    color: ${THEME_COLORS.dark_300};
    font-family: "DM_sans_medium";
    text-align: center;
    margin-bottom: 30px;
`;
const Counter = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
`;
const BottomContent = styled.div``;
const StartButton = styled.div`
    display: flex;
    justify-content: space-around;
    background: ${THEME_COLORS.secondary_gradient};
    padding: 15px 34%;
    border-radius: 24px;
    align-items: center;
    color: ${THEME_COLORS.white};
    font-size: 16px;
    margin-bottom: 20px;
    cursor: pointer;
`;
const SkipButton = styled(Link)`
    display: flex;
    justify-content: space-around;
    padding: 14px 34%;
    border-radius: 24px;
    align-items: center;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_400};
    font-size: 16px;
`;

import React, { useEffect, useRef } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import styled, { keyframes } from "styled-components";
import moment from "moment";
import $ from "jquery";

import { THEME_COLORS } from "../../../ThemeConfig";

export default function DatePick({
    selected,
    setSelected,
    showDatePicker,
    setShowDatePicker,
}) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);

    const availableDates = []; // Add your logic to generate available dates

    const modifiers = {
        available: (date) =>
            date >= dayAfterTomorrow && availableDates.includes(date),
        disabled: (date) => disablePastDates(date) || date <= tomorrow,
    };

    const disabledDates = [
        moment().startOf("day").toDate(), // Today
        moment().add(1, "days").startOf("day").toDate(), // Tomorrow
    ];

    const disablePastDates = (date) => {
        return (
            date < moment().startOf("day").toDate() ||
            disabledDates.includes(date) ||
            date === moment().add(1, "days").startOf("day").toDate()
        );
    };

    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setShowDatePicker(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, setShowDatePicker]);

    useEffect(() => {
        if (showDatePicker) {
            $("html").addClass("modal-enabled");
        } else {
            $("html").removeClass("modal-enabled");
        }
    }, [showDatePicker]);

    useEffect(() => {
        const headerCells = document.querySelectorAll(".rdp-head_cell");
        headerCells.forEach((cell) => {
            const textContent = cell.textContent;
            cell.textContent = textContent.charAt(0);
        });
    }, [showDatePicker]);

    useEffect(() => {
        setSelected(moment().add(2, "days").startOf("day").toDate()); // Set to day after tomorrow's date
    }, []);

    return (
        <>
            {showDatePicker && (
                <>
                    <RdpContainer ref={wrapperRef}>
                        <DayPicker
                            selected={moment()
                                .add(2, "days")
                                .startOf("day")
                                .toDate()}
                            onDayClick={(date) => {
                                setSelected(date);
                                setShowDatePicker(false);
                            }}
                            defaultMonth={moment().toDate()}
                            fromDate={moment().startOf("day").toDate()}
                            weekStartsOn={1}
                            fixedWeeks
                            modifiers={modifiers}
                            renderDay={(date, modifiers) => {
                                const day = moment(date).date();
                                const isAvailable = modifiers.available;
                                return (
                                    <div
                                        className={`${
                                            isAvailable ? "available" : ""
                                        } ${
                                            modifiers.disabled ? "disabled" : ""
                                        }`}
                                    >
                                        {day}
                                    </div>
                                );
                            }}
                        />
                    </RdpContainer>
                </>
            )}
        </>
    );
}

const popup = keyframes`
    0%{
        scale: 0;
    }
    100%{       
        scale: 1;
    }
`;

const RdpContainer = styled.div`
    @media all and (max-width: 480px) {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${THEME_COLORS.modal_bg};
        position: fixed;
        left: 0;
        top: 0;
        z-index: 303;
    }

    .rdp {
        box-shadow: ${THEME_COLORS.elevation_user_u2};
        --rdp-cell-size: 45px;
        position: absolute;
        right: 25px;
        z-index: 2;
        background-color: white;
        border-radius: 10px;
        margin: auto;
        @media all and (max-width: 480px) {
            animation: ${popup} 0.3s ease-in-out;
            position: unset;
        }
    }

    .rdp-cell {
        height: 15px;
    }

    .rdp-caption {
        padding: 10px 10px 0 10px;
    }

    @media all and (max-width: 480px) {
    }
    @media all and (max-width: 360px) {
        .rdp-button {
            font-size: 14px;
            width: 40px !important;
            height: 40px !important;
        }
        .rdp-head_cell {
            font-size: 12px;
        }
        .rdp-caption_label {
            font-size: 14px !important;
        }
        .rdp-caption {
            padding: 6px 6px 0px 6px;
        }
        .rdp-cell:first-child,
        .rdp-head_cell:first-child {
            padding-left: 6px;
        }
        .rdp-cell:last-child {
            padding-right: 6px;
        }
        .rdp-head_cell:last-child {
            padding-right: 6px;
        }
        .jWjUBb .rdp-cell:last-child {
            padding-right: 6px;
        }
    }
    @media all and (max-width: 330px) {
        .rdp-button {
            font-size: 13px;
            width: 35px !important;
            height: 35px !important;
        }
        .rdp-cell {
            height: 10px;
            width: 35px;
        }
        /* .rdp-head_cell {
            width: 35px;
        } */
    }
`;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";

// axios
import { PatientConfig } from "../../../../axiosConfig";

function TermsAndCondition() {
    // const [data, setData] = useState([]);
    // const [contents, setContents] = useState([]);

    // const termsAndCondition = () => {
    //   PatientConfig.get("api/v1/patients/patient/terms-and-conditions/")
    //     .then((response) => {
    //       setData(response.data.data);
    //       setContents(response.data.data.contents);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching data:", error);
    //     });
    // };

    // useEffect(() => {
    //   termsAndCondition();
    // }, []);

    return (
        <>
            {/* <MainContainer>
        <Wrapper>
          <Terms>
            <>
              <Heading>{data.introduction_title}</Heading>
              <Date>Last Updated {data.date_updated}</Date>
              <ContentDiv>
                {contents.map((item, index) => (
                  <Contents key={index}>
                    <SubHeading>{item.title}</SubHeading>
                    <Para>{item.descriptions}</Para>
                  </Contents>
                ))}
              </ContentDiv>
            </>
          </Terms>
          <ButtonDiv>
            <CancelAcceptBtn />
          </ButtonDiv>
        </Wrapper>
      </MainContainer> */}

            <MainContainer>
                <Wrapper>
                    <Terms>
                        <>
                            <Heading>Terms and Conditions</Heading>
                            <Date>Last Updated December 15, 2023</Date>
                            <ContentDiv>
                                <Contents>
                                    <Para>
                                        This document is an electronic record in
                                        terms of: (i) the Information Technology
                                        Act, 2000; (ii) the rules framed
                                        thereunder, as applicable; and (iii) the
                                        amended provisions pertaining to
                                        electronic records in various statutes,
                                        as amended by the Information Technology
                                        Act, 2000. This electronic record is
                                        generated by a computer system and does
                                        not require any physical or digital
                                        signatures.
                                    </Para>
                                    <Para>
                                        This document is published in accordance
                                        with the provisions of Rule 3 (1) of the
                                        Information Technology (Intermediaries
                                        guidelines) Rules, 2011 that require
                                        publishing the rules and regulations,
                                        privacy policy and Terms for access or
                                        usage of{" "}
                                        <Url to="https://www.mymindmitra.com">
                                            www.mymindmitra.com
                                        </Url>{" "}
                                        (the “Website”).
                                    </Para>
                                    <Para>
                                        The Website is owned and operated by
                                        MIND MITRA PRIVATE LIMITED (“Company”),
                                        a private limited company, incorporated
                                        under the provisions of the Companies
                                        Act, 2013 and having its registered
                                        office at DOOR NO13/135, ERUPPAKKIL,
                                        KADAMPAZHIPPURAM ,PALAKKAD,KL-678633
                                        IN.These terms of usage (“Terms”) govern
                                        your use of the Website and Services
                                        provided therein (“Services”). By using
                                        or visiting the Website, or by using any
                                        content or information provided as part
                                        of the Website/ Services, you shall be
                                        deemed to have read, understood, and
                                        accepted to be bound by these Terms. If
                                        you do not agree to these Terms, do not
                                        use this Website/ Services. Because
                                        these Terms are a legal contract between
                                        you and MindMitra, it is important that
                                        you review the Terms carefully before
                                        accessing or using the Website. These
                                        Terms are not intended to alter in any
                                        way or limit the terms or conditions of
                                        any other agreement that you may have
                                        with MindMitra, including without
                                        limitation the privacy policy (“Privacy
                                        Policy”).
                                    </Para>
                                    <Para>
                                        For the purpose of these Terms, wherever
                                        the context so requires “You”, “Your” or
                                        “User” shall mean any natural person who
                                        uses the Website for availing the
                                        Services. The term “We”, “Us”, “Our”,
                                        “MindMitra” shall mean the Company, its
                                        employees, and authorized agents that
                                        perform any services on the Company’s
                                        behalf.
                                    </Para>
                                    <Para>
                                        If you are not an individual and are an
                                        incorporated/registered
                                        entity/institute/organization seeking to
                                        avail the services of MindMitra for your
                                        members/employees/students or any
                                        individuals registered with you for any
                                        services, you shall enter into an
                                        agreement/memorandum of understanding
                                        with MindMitra for this purpose. Each
                                        individual availing the services of
                                        MindMitra under such
                                        agreement/memorandum of understanding
                                        shall be bound by these Terms and
                                        Privacy Policy available here.
                                    </Para>
                                    <Para>
                                        We reserve the right to make changes to
                                        these Terms at any time. Any such
                                        modifications will become effective
                                        immediately upon posting to the Website
                                        and your continued use of the Website,
                                        and/or the Services constitutes your
                                        agreement to such modifications. You
                                        agree to periodically review the current
                                        version of these Terms of Use as posted
                                        on the Website.
                                    </Para>
                                    <Para>
                                        The Website allows Users to connect with
                                        counsellors or other individuals
                                        (“Expert(s)”) who provide counselling
                                        and therapy services. We provide the
                                        following online/offline counselling and
                                        therapy services:{" "}
                                        <Url to="https://www.mymindmitra.com/">
                                            https://www.mymindmitra.com/
                                        </Url>
                                    </Para>
                                    <Para>
                                        Know more about MindMitra here on this
                                        link{" "}
                                        <Url to="https://www.mymindmitra.com/about-us">
                                            {" "}
                                            https://www.mymindmitra.com/about
                                        </Url>
                                    </Para>
                                    <Para>
                                        Direct use of the Website is available
                                        only to persons over the age of eighteen
                                        (18). If you are a minor i.e. under the
                                        age of eighteen (18) years, you shall
                                        not register as a member of the Website
                                        and shall not sell or purchase any items
                                        on the Website, unless you are accessing
                                        this Website with parental guidance or
                                        guidance of a legal guardian (Register
                                        as parent/guardian on behalf of minor).
                                        We reserve the right to terminate your
                                        membership and refuse to provide you
                                        with access to the Website if it is
                                        brought to our notice or if it is
                                        discovered that you are under the age of
                                        eighteen (18) years and are accessing
                                        this Website without parental consent
                                        and guidance or consent and guidance of
                                        a legal guardian. We will not be liable
                                        for any harm caused to you on account of
                                        any use of the Website without
                                        appropriate parental consent.
                                    </Para>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>1.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>Payment</SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                1.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                {" "}
                                                We may charge for the audio,
                                                and/or video-based counselling/
                                                therapy sessions. Before
                                                availing any such Service, we
                                                will appraise the User of the
                                                applicable charges for the said
                                                Service. In addition to these
                                                Terms, a User shall also be
                                                bound by the terms (if any)
                                                mentioned for specific
                                                Service(s). <br /> <br /> Please
                                                note the specific refund policy
                                                in the event of the following
                                                circumstances:
                                            </Para>
                                            <DescriptionHeading>
                                                Refund Policy
                                            </DescriptionHeading>
                                            <Para>
                                                MindMitra offers it’s paid
                                                services under two categories.{" "}
                                                <br /> &nbsp; 1. Single sessions{" "}
                                                <br />
                                                &nbsp; 2. Packages with multiple
                                                sessions <br />
                                                Refund policy is different for
                                                both cases and its given below
                                                for your reference.
                                            </Para>
                                            <DescriptionHeading className="sub-heading">
                                                For single sessions
                                            </DescriptionHeading>
                                            <Para className="heading">
                                                Scenarios where the user will
                                                get a full refund
                                            </Para>
                                            <ListContainer>
                                                <ListItem>
                                                    <Para>
                                                        If Mindmitra is unable
                                                        to find a therapist
                                                        within 4 working days
                                                        for single session, we
                                                        will let you know the
                                                        same and will initiate
                                                        full refund after you
                                                        confirm. You may try to
                                                        make a fresh booking for
                                                        another date. Mindmitra
                                                        team will assist you to
                                                        find a session.
                                                    </Para>
                                                </ListItem>
                                            </ListContainer>
                                            <Para className="heading">
                                                Scenarios where the user will
                                                get a partial refund
                                            </Para>
                                            <ListContainer>
                                                <ListItem>
                                                    <Para>
                                                        User has an option to
                                                        cancel the booked
                                                        session 48 hours prior
                                                        to the session time. We
                                                        suggest you to be very
                                                        sure about your need to
                                                        undergo a therapy as we
                                                        will refund only the 50%
                                                        of the original payment
                                                        upon cancellation. You
                                                        may choose to reschedule
                                                        your session on
                                                        unavoidable emergency,
                                                        rather than cancelling,
                                                        by paying additional 20%
                                                        of the original payment
                                                        fees. (Reschedule also
                                                        need to be done minimum
                                                        48 hours prior)
                                                    </Para>
                                                </ListItem>
                                            </ListContainer>
                                            <Para className="heading">
                                                Scenarios where the user will
                                                NOT get any refund
                                            </Para>
                                            <ListContainer>
                                                <ListItem>
                                                    <Para>
                                                        If the user cancelled
                                                        the session within 48
                                                        hours prior to the
                                                        session time
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        Mindmitra put lot of
                                                        efforts in bringing in
                                                        qualified therapists to
                                                        the platform. We won’t
                                                        be able to accept any
                                                        request to change the
                                                        therapist once assigned
                                                        and we won’t entertain a
                                                        refund on this matter.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        User need to turn up on
                                                        time for the session.
                                                        Your allotted time will
                                                        start as per the
                                                        schedule and if you join
                                                        late, you will be able
                                                        to attend only the
                                                        remaining time as per
                                                        your session plan. There
                                                        won’t be any refund or
                                                        partial refund of any
                                                        kind in this scenario.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        We are happy to hear
                                                        your feedback about the
                                                        session. But we won’t be
                                                        able to process any
                                                        refund for the session
                                                        if you are not happy
                                                        with session experience.
                                                        We will pass on the
                                                        feedback to the
                                                        therapist and you may
                                                        make fresh booking. We
                                                        will try our best to
                                                        assign you another
                                                        therapist.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        If there is any
                                                        connectivity or network
                                                        issues from the user’s
                                                        side, we won’t able to
                                                        issue any refund. Please
                                                        make sure you have
                                                        access to internet with
                                                        proper network coverage
                                                        before the session. If
                                                        there are any genuine
                                                        issues on the platform
                                                        used, we will consider
                                                        it case by case basis
                                                        and reschedule the
                                                        session without any
                                                        additional payment.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        Therapist may choose to
                                                        cancel the session on an
                                                        unavoidable situation.
                                                        Mindmitra will try to
                                                        find another expert for
                                                        the original session
                                                        time. In case of
                                                        unavailability of any
                                                        suitable expert for the
                                                        original session time,
                                                        We will share the
                                                        nearest available slots
                                                        to the user for
                                                        selection. No refund
                                                        will be given on this
                                                        scenario.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        Therapist may choose to
                                                        reschedule the session
                                                        on an unavoidable
                                                        situation. User will be
                                                        notified about the new
                                                        session time and the
                                                        user can accept or
                                                        reject the new time
                                                        slot. If the user
                                                        chooses to reject the
                                                        new time slot, user will
                                                        be given the available
                                                        other time slots of the
                                                        same therapist. He/she
                                                        can choose from the
                                                        options and confirm. No
                                                        refund will be given in
                                                        this scenario.
                                                    </Para>
                                                </ListItem>
                                            </ListContainer>

                                            <DescriptionHeading className="sub-heading">
                                                For Packages
                                            </DescriptionHeading>
                                            <Para className="heading">
                                                Scenarios where the user will
                                                get a full refund
                                            </Para>
                                            <ListContainer>
                                                <ListItem>
                                                    <Para>
                                                        If Mindmitra is unable
                                                        to find a therapist
                                                        within 5 working days
                                                        for package sessions, we
                                                        will let you know the
                                                        same and will initiate
                                                        full refund after you
                                                        confirm. You may try to
                                                        make a fresh booking for
                                                        another date. Mindmitra
                                                        team will assist you to
                                                        find a session.
                                                    </Para>
                                                </ListItem>
                                            </ListContainer>
                                            <Para className="heading">
                                                Scenarios where the user will
                                                get a partial refund
                                            </Para>
                                            <ListContainer>
                                                <ListItem>
                                                    <Para>
                                                        There is no option to
                                                        cancel individual
                                                        session under a package.
                                                        If the user is not happy
                                                        with the therapy
                                                        experience after few
                                                        sessions, user can
                                                        cancel the package and
                                                        we will refund you 50%
                                                        of the proportionate
                                                        amount of the remaining
                                                        sessions.
                                                    </Para>
                                                </ListItem>
                                            </ListContainer>
                                            <Para className="heading">
                                                Scenarios where the user will
                                                NOT get any refund
                                            </Para>
                                            <ListContainer>
                                                <ListItem>
                                                    <Para>
                                                        User can’t cancel a
                                                        package even before the
                                                        first session. Hence
                                                        please be sure about
                                                        your requirement before
                                                        making payment for a
                                                        package session. There
                                                        won’t be any refund
                                                        under any circumstances
                                                        for this scenario.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        Mindmitra put lot of
                                                        efforts in bringing in
                                                        qualified therapists to
                                                        the platform. We won’t
                                                        be able to accept any
                                                        request to change the
                                                        therapist once assigned
                                                        (before starting the
                                                        package or during the
                                                        package) and we won’t
                                                        entertain a refund on
                                                        this matter. You may
                                                        make fresh booking next
                                                        time and we will try our
                                                        best to assign another
                                                        therapist to you.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        User need to turn up on
                                                        time for the session.
                                                        Your allotted time will
                                                        start as per the
                                                        schedule and if you join
                                                        late, you will be able
                                                        to attend only the
                                                        remaining time as per
                                                        your session plan. There
                                                        won’t be any refund or
                                                        partial refund of any
                                                        kind in this scenario.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        We are happy to hear
                                                        your feedback about the
                                                        session. But we won’t be
                                                        able to process any
                                                        refund for the sessions
                                                        which got over in case
                                                        you are not happy with
                                                        session experience. We
                                                        will pass on the
                                                        feedback to the
                                                        therapist.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        If there is any
                                                        connectivity or network
                                                        issues from the user’s
                                                        side, we won’t able to
                                                        issue any refund. Please
                                                        make sure you have
                                                        access to internet with
                                                        proper network coverage
                                                        before the session. If
                                                        there are any genuine
                                                        issues on the platform
                                                        used, we will consider
                                                        it case by case basis
                                                        and reschedule the
                                                        session without any
                                                        additional payment.
                                                    </Para>
                                                </ListItem>
                                                <ListItem>
                                                    <Para>
                                                        User may choose to
                                                        reschedule your session
                                                        on an unavoidable
                                                        emergency, by paying
                                                        additional 20% of the
                                                        original payment fees at
                                                        least 48 hours prior to
                                                        the session. There won’t
                                                        be any refund if there
                                                        is no action from user
                                                        and he/ she was absent
                                                        for the session.
                                                    </Para>
                                                </ListItem>
                                            </ListContainer>
                                            <Para>
                                                You can find the same at{" "}
                                                <Url to="https://www.mymindmitra.com/refund-policy">
                                                    www.mymindmitra.com/refund-policy
                                                </Url>
                                            </Para>
                                        </Right>
                                    </SubContainer>

                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                1.2.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <DescriptionHeading>
                                                Appointments (Audio/Video){" "}
                                            </DescriptionHeading>
                                            <Para className="less-margin">
                                                1.2.2.1 For any services
                                                purchased, the MindMitra will
                                                notify the about the appointment
                                                status via email and the
                                                notification on the website. The
                                                appointment will be added to the
                                                user’s Google Calendar as a
                                                meeting. Customer should join
                                                the meeting as per the timings
                                                mentioned. There will be
                                                reminders sent by the system to
                                                help you. In case the customer
                                                didn’t attend the session, the
                                                service will be treated as
                                                consumed and no refunds shall be
                                                provided by MindMitra.
                                            </Para>
                                            <Para className="less-margin">
                                                1.2.2.2 In case, of technical
                                                difficulties during or at the
                                                time of the session, MindMitra
                                                will check with their service
                                                providers. If the case is
                                                adjudged to be genuine, the
                                                appointment shall be rescheduled
                                                without additional payment.
                                            </Para>
                                            <Para className="less-margin">
                                                1.2.2.3 For Audio/Video
                                                Sessions, in case, the customer
                                                fails to turn up at the time of
                                                the appointment, appointment
                                                will be treated as consumed and
                                                no refunds shall be provided by
                                                MindMitra.
                                            </Para>
                                            <Para className="less-margin">
                                                1.2.2.4 Any rescheduling, if
                                                any, can be done by the customer
                                                from the website at least 48
                                                hours in advance with an
                                                additional payment of 20% of the
                                                session fees.
                                            </Para>
                                            <Para className="less-margin">
                                                1.2.2.5 Any cancelation if any,
                                                can be done by the customer from
                                                the website at least 48 hours in
                                                advance. MindMitra will refund
                                                50% of the paid amount.
                                            </Para>
                                            <Para className="less-margin">
                                                **Please refer refund policy for
                                                further details
                                            </Para>

                                            <DescriptionHeading>
                                                Validity of Packages
                                            </DescriptionHeading>

                                            <TableContainer>
                                                <TableColumn>
                                                    <TableHeadCell>
                                                        No. of Sessions
                                                    </TableHeadCell>
                                                    <TableCell>
                                                        1 Session
                                                    </TableCell>
                                                    <TableCell>
                                                        4 Sessions
                                                    </TableCell>
                                                    <TableCell className="last-child">
                                                        7 Sessions
                                                    </TableCell>
                                                </TableColumn>
                                                <TableColumn>
                                                    <TableHeadCell>
                                                        Essential Packages
                                                    </TableHeadCell>
                                                    <TableCell>
                                                        10 Days
                                                    </TableCell>
                                                    <TableCell>
                                                        40 Days
                                                    </TableCell>
                                                    <TableCell className="last-child">
                                                        70 Days
                                                    </TableCell>
                                                </TableColumn>
                                                <TableColumn>
                                                    <TableHeadCell>
                                                        Prime Packages
                                                    </TableHeadCell>
                                                    <TableCell>
                                                        15 Days
                                                    </TableCell>
                                                    <TableCell>
                                                        60 Days
                                                    </TableCell>
                                                    <TableCell className="last-child">
                                                        120 Days
                                                    </TableCell>
                                                </TableColumn>
                                            </TableContainer>

                                            <DescriptionHeading className="sub-heading">
                                                Legal Actions
                                            </DescriptionHeading>
                                            <Para>
                                                If you are found degrading,
                                                tarnishing, maligning the
                                                Image/reputation of
                                                MindMitra.com by spreading
                                                hatred, false/fake reviews,
                                                wrong actions against the
                                                platform or against any other
                                                associates, strong legal actions
                                                will be taken immediately.
                                            </Para>
                                        </Right>
                                    </SubContainer>

                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                1.3
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                User(s) can make payments
                                                through any of the following
                                                available options: <br /> 1.3.1
                                                Internet Banking <br /> 1.3.2
                                                Debit/ Credit Cards <br /> 1.3.3
                                                UPI
                                            </Para>
                                        </Right>
                                    </SubContainer>

                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                1.4
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                The User agrees and accepts that
                                                all nuances and modalities
                                                relating to making payment using
                                                Internet Banking/ Debit/Credit
                                                Cards/UPI (“Virtual Payment
                                                Mode”) shall be separately
                                                governed by arrangement(s) /
                                                terms and conditions between the
                                                User and the relevant banks. We
                                                shall not be responsible, in any
                                                manner whatsoever, for any
                                                liability that may arise in
                                                relation to the Virtual Payment
                                                Modes (including any fraudulent
                                                transaction).
                                            </Para>
                                        </Right>
                                    </SubContainer>

                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                1.5
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                While availing any of the
                                                payment method(s) available on
                                                the Website, we will not be
                                                responsible or assume any
                                                liability, whatsoever in respect
                                                of any loss or damage arising
                                                directly or indirectly to You
                                                due to (a) lack of authorization
                                                for any transactions; (b) any
                                                payment issues arising out of
                                                the transaction or (c) decline
                                                of such transaction for any
                                                reason.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                1.6
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                You understand, accept and agree
                                                that the payment facility
                                                provided us, is neither a
                                                banking nor financial service.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                1.7
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                MindMitra reserves its right to
                                                change the charges for Services,
                                                at any time, without the
                                                requirement of any prior
                                                intimation to the User. Any such
                                                change shall be binding and
                                                effective on the User.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>2.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Ownership of the website
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                2.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                The Website contains content
                                                owned or licensed by MindMitra
                                                Private Limited (“MindMitra
                                                Private Limited Content”).
                                                MindMitra Private Limited owns
                                                and retains all rights in the
                                                MindMitra Private Limited
                                                Content. You will not remove,
                                                alter or conceal any copyright,
                                                trademark, service mark or other
                                                proprietary rights notices
                                                incorporated in or accompanying
                                                the MindMitra Content and you
                                                will not reproduce, modify,
                                                adapt, prepare derivative works
                                                based on, perform, display,
                                                publish, distribute, transmit,
                                                broadcast, sell, license or
                                                otherwise exploit the MindMitra
                                                Content.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                2.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                The MindMitra name and logo are
                                                trademarks of MindMitra Private
                                                Limited, and may not be copied,
                                                imitated or used, in whole or in
                                                part, without the prior written
                                                permission of MindMitra Private
                                                Limited, except with prior
                                                consent. In addition, all custom
                                                graphics, button icons and
                                                scripts are service marks,
                                                trademarks and/or trade dress of
                                                MindMitra, and may not be
                                                copied, imitated or used, in
                                                whole or in part, without prior
                                                written permission from
                                                MindMitra Private Limited.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>

                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>3.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Use of the website and conduct
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                3.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <DescriptionHeading className="sub-heading">
                                                Use of the Website
                                            </DescriptionHeading>
                                            <Para>
                                                3.1.1 You may use the Website
                                                for lawful purposes only. You
                                                shall not post or transmit
                                                through the Website any material
                                                which violates or infringes the
                                                rights of others, or which is
                                                threatening, abusive,
                                                defamatory, libelous, invasive
                                                of privacy or publicity rights,
                                                vulgar, obscene, profane or
                                                otherwise objectionable,
                                                contains injurious formulas,
                                                recipes, or instructions, which
                                                encourages conduct that would
                                                constitute a criminal offense,
                                                give rise to civil liability or
                                                otherwise violate any law.{" "}
                                                <br /> <br /> 3.1.2 You agree
                                                not to engage in any of the
                                                following prohibited activities:
                                                (i) copying, distributing, or
                                                disclosing any part of the
                                                Website in any medium; (ii)
                                                transmitting spam, chain
                                                letters, or other unsolicited
                                                email; (iv) attempting to
                                                interfere with, compromise the
                                                system integrity or security or
                                                decipher any transmissions to or
                                                from the servers running the
                                                Website; (iii) taking any action
                                                that imposes, or may impose at
                                                our sole discretion an
                                                unreasonable or
                                                disproportionately large load on
                                                our infrastructure; (iv)
                                                uploading invalid data, viruses,
                                                worms, or other software agents
                                                through the Website; (v)
                                                collecting or harvesting any
                                                personally identifiable
                                                information, including account
                                                names, from the Website; (vi)
                                                using the Website for any
                                                commercial purposes without
                                                having all necessary rights and
                                                licenses to the User Content;
                                                (vii) impersonating another
                                                person or otherwise
                                                misrepresenting your affiliation
                                                with a person or entity,
                                                conducting fraud, hiding or
                                                attempting to hide your
                                                identity; (viii) interfering
                                                with the proper working of the
                                                Website; (ix) accessing any
                                                content on the Website through
                                                any technology or means other
                                                than those capabilities provided
                                                by the Website; or (x) bypassing
                                                the measures we may use to
                                                prevent or restrict access to
                                                the Website, including without
                                                limitation features that prevent
                                                or restrict use or copying of
                                                any content or enforce
                                                limitations on use of the
                                                Website or the content therein.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                3.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <DescriptionHeading className="sub-heading">
                                                User Content Guidelines
                                            </DescriptionHeading>
                                            <Para>
                                                3.2.1 You are solely responsible
                                                for your conduct and any data
                                                that you submit, post or display
                                                on or via the Website. MindMitra
                                                shall have no liability for
                                                conduct in relation to your use
                                                of our Website. Violations of
                                                these Terms may result in legal
                                                consequences prescribed by the
                                                applicable laws. <br /> <br />{" "}
                                                3.2.2 The Website provides Users
                                                the facility to create, share
                                                and post content (together,
                                                “User Content”). MindMitra
                                                claims no ownership rights over
                                                User Content created by you.
                                                MindMitra has the right (but not
                                                the obligation) in its sole
                                                discretion to remove any User
                                                Content that is stored via the
                                                Website. <br /> <br /> 3.2.3
                                                MindMitra takes no
                                                responsibility and assumes no
                                                liability for any User Content
                                                that you or any other User or
                                                third party creates, stores,
                                                shares, posts or sends through
                                                the Website. You shall be solely
                                                responsible for your User
                                                Content and the consequences of
                                                posting, publishing it, or
                                                sharing it and you agree that we
                                                are only acting as a passive
                                                conduit for your online
                                                distribution and publication of
                                                your User Content. If your
                                                Content violates these Terms,
                                                you may bear legal
                                                responsibility for that content.{" "}
                                                <br /> <br /> 3.2.4 You agree
                                                not to post, store, transmit,
                                                create or share any User Content
                                                that: (i) may create a risk of
                                                harm, loss, physical or mental
                                                injury, emotional distress,
                                                death, disability,
                                                disfigurement, or physical or
                                                mental illness to you, to any
                                                other person, or to any animal;
                                                (ii) may create a risk of any
                                                other loss or damage to any
                                                person or property; (iii) seeks
                                                to harm or exploit children by
                                                exposing them to inappropriate
                                                content, asking for personally
                                                identifiable details or
                                                otherwise; (iv) may constitute
                                                or contribute to a crime or
                                                tort; (v) contains any
                                                information or content that we
                                                deem to be unlawful, harmful,
                                                abusive, racially or ethnically
                                                offensive, defamatory,
                                                infringing, invasive of personal
                                                privacy or publicity rights,
                                                harassing, humiliating to other
                                                people (publicly or otherwise),
                                                libelous, threatening, profane,
                                                or otherwise objectionable; (vi)
                                                contains any information or
                                                content that is illegal
                                                (including, without limitation,
                                                the disclosure of insider
                                                information under securities law
                                                or of another party’s trade
                                                secrets); or (vii) contains any
                                                information or content that you
                                                do not have a right to make
                                                available under any law or under
                                                contractual or fiduciary
                                                relationships. You agree that
                                                any User Content that you
                                                create, store, or share does not
                                                and will not violate third-party
                                                rights of any kind, including
                                                without limitation any
                                                Intellectual Property Rights (as
                                                defined below) or rights of
                                                privacy. MindMitra reserves the
                                                right, but is not obligated, to
                                                reject and/or remove any User
                                                Content that MindMitra believes,
                                                in its sole discretion, violates
                                                these provisions. For the
                                                purposes of these Terms,
                                                “Intellectual Property Rights”
                                                means all patent rights,
                                                copyright rights, mask work
                                                rights, moral rights, rights of
                                                publicity, trademark, trade
                                                dress and service mark rights,
                                                goodwill, trade secret rights
                                                and other intellectual property
                                                rights as may now exist or
                                                hereafter come into existence,
                                                and all Website therefore and
                                                registrations, renewals and
                                                extensions thereof, under the
                                                laws of any state, country,
                                                territory or other jurisdiction.{" "}
                                                <br /> <br /> 3.2.5 In
                                                connection with your User
                                                Content, you affirm, represent
                                                and warrant the following:
                                            </Para>
                                            <Para className="left-blank">
                                                3.2.5.1 Your User Content and
                                                your use thereof as contemplated
                                                by these Terms and the Website
                                                will not violate any law or
                                                infringe any rights of any third
                                                party, including but not limited
                                                to any Intellectual Property
                                                Rights or privacy rights. <br />{" "}
                                                <br /> 3.2.5.2 To the extent
                                                that you use the Website for any
                                                commercial purpose and your User
                                                Content contains any copyrighted
                                                material that is not your
                                                original work or in which you do
                                                not otherwise own the copyright,
                                                you have obtained all rights,
                                                licenses, consents, and
                                                permissions necessary in order
                                                to use that copyrighted material
                                                in connection with the creation
                                                and/or dissemination of that
                                                User Content using the Website.{" "}
                                                <br /> <br /> 3.2.5.3 MindMitra
                                                may exercise the rights to your
                                                User Content granted under these
                                                Terms without liability for
                                                payment of any guild fees,
                                                residuals, payments, fees, or
                                                royalties payable under any
                                                collective bargaining agreement
                                                or otherwise. <br /> <br />{" "}
                                                3.2.5.4 MindMitra takes no
                                                responsibility and assumes no
                                                liability for any User Content
                                                that you or any other User or
                                                third party creates, stores,
                                                shares, posts, sends or
                                                otherwise makes available
                                                through the Website, whether
                                                directly or indirectly. You
                                                shall be solely responsible for
                                                your User Content and the
                                                consequences of posting,
                                                publishing it, or sharing it and
                                                you agree that MindMitra is only
                                                acting a passive conduit for the
                                                distribution and publication of
                                                your User Content. If your
                                                Content violates these Terms,
                                                you may bear legal
                                                responsibility for that content.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                3.3
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <DescriptionHeading className="sub-heading">
                                                Consent for the assessment and
                                                treatment
                                            </DescriptionHeading>
                                            <Para>
                                                This is an e-agreement and
                                                obliged to agree the consent
                                                terms and no need of any further
                                                signature for making these
                                                policies into practice before
                                                the consultation. I, Client
                                                understands that the type and
                                                extent of psychological
                                                intervention, which includes
                                                assessment and treatment that I
                                                will receive at the online
                                                platform will be determined
                                                following an initial clinical
                                                assessment of my problem/s
                                                through an interview with me,
                                                and/or my family members. The
                                                goal of this assessment process
                                                is to determine the best course
                                                of treatment for me <br />{" "}
                                                <br /> I understand that all
                                                information shared with my
                                                therapist at the time of
                                                assessment and treatment is
                                                confidential and no information
                                                will be released without my
                                                consent. During the course of
                                                assessment and treatment, at
                                                times it may be necessary for my
                                                therapist to communicate with
                                                other health services providers
                                                at the Institute. <br /> <br />{" "}
                                                I further understand that there
                                                are specific and limited
                                                exceptions to this
                                                confidentiality which include
                                                the following:
                                            </Para>
                                            <Para className="left-blank">
                                                a. When there is risk of
                                                imminent danger to myself or to
                                                another person, my therapist is
                                                ethically bound to take
                                                necessary steps to prevent such
                                                danger. <br /> <br /> b. When
                                                there is suspicion that a child
                                                or elder is being sexually or
                                                physically abused or is at risk
                                                of such abuse, my therapist is
                                                legally required to take steps
                                                to protect the child, and to
                                                inform the proper authorities.{" "}
                                                <br /> <br /> c. When a valid
                                                court order is issued for
                                                medical records; my therapist
                                                and the Institute are bound by
                                                law to comply with such
                                                requests.
                                            </Para>
                                            <Para>
                                                I understand that some of the
                                                professionals involved in my
                                                assessment or treatment may be
                                                under training and in such cases
                                                all professionals-in-training
                                                are supervised by qualified and
                                                licensed staff. Typically,
                                                psychological treatment is
                                                provided over the course of
                                                several weeks. <br />
                                                I understand that while
                                                psychological intervention or
                                                psychotherapy, may provide
                                                significant benefits, it may
                                                also pose risks. Psychotherapy
                                                may elicit uncomfortable
                                                thoughts and feelings, or may
                                                lead to the recall of troubling
                                                memories. <br /> <br /> If I
                                                have any questions regarding
                                                this consent form or about the
                                                services offered at the
                                                hospital/clinic, I may discuss
                                                them with my therapist. My
                                                therapist has explained all the
                                                above in a language that I
                                                understand, and I consent hereby
                                                to participate in the assessment
                                                and treatment offered to me by
                                                the therapist. Also I understand
                                                that I may stop treatment at any
                                                time.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>4.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Rights to User content
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                4.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                If you share your User Content
                                                with MindMitra and/or link your
                                                User Content to MindMitra on a
                                                third party service and/or post
                                                content on any social media page
                                                owned and operated by MindMitra
                                                you expressly grant, and you
                                                represent and warrant that you
                                                have all rights necessary to
                                                grant, to MindMitra a
                                                royalty-free, sublicensable,
                                                transferable, perpetual,
                                                irrevocable, non-exclusive,
                                                worldwide license to use,
                                                reproduce, modify, publish, list
                                                information regarding, edit,
                                                translate, distribute,
                                                syndicate, publicly perform,
                                                publicly display, and make
                                                derivative works of all such
                                                User Content and your name,
                                                voice, and/or likeness as
                                                contained in your User Content,
                                                in whole or in part, and in any
                                                form, media or technology,
                                                whether now known or hereafter
                                                developed, for use in connection
                                                with the Website and MindMitra’s
                                                (and its successors’ and
                                                affiliates’) business, including
                                                without limitation for promoting
                                                and redistributing part or all
                                                of our Website (and derivative
                                                works thereof) in any media
                                                formats and through any media
                                                channels.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>5.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>Disclaimers</SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                5.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                The advice or information
                                                provided by experts via the
                                                website is provided for
                                                informational purposes only and
                                                cannot be considered a
                                                substitute for examination by a
                                                doctor or other mental health
                                                professional. You are advised
                                                strongly against relying solely
                                                on, or making decisions based
                                                solely on, advice provided by
                                                any expert.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                5.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                You understand and agree that
                                                although a Expert may be a
                                                counsellor, therapist or other
                                                mental health professional.
                                                MindMitra disclaims any and all
                                                liability for any consultation
                                                and therapy services rendered by
                                                an Expert to you through the
                                                Website. You further acknowledge
                                                and agree that you take full
                                                responsibility for the decision
                                                to access an Expert through the
                                                Website and to continue to
                                                interact with such
                                                individual(s), and that the role
                                                of MindMitra is strictly limited
                                                to providing access to such
                                                Experts to you.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                5.3
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Use of the services is not for
                                                emergencies. If you think you
                                                have a medical or mental health
                                                emergency, or if at any time you
                                                are concerned about your care or
                                                treatment, please go to the
                                                nearest hospital or health care
                                                provider.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                5.4
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                The website is not a suicide
                                                helpline platform. If you are
                                                considering or contemplating
                                                suicide or feel that you are a
                                                danger to yourself or to others,
                                                you may discontinue use of the
                                                services immediately at your
                                                discretion and please notify
                                                appropriate police or emergency
                                                medical personnel. If you are
                                                thinking about suicide,
                                                immediately call a suicide
                                                prevention helpline.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                5.5
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Your use of information provided
                                                on the website and availing of
                                                services on the website is
                                                solely at your own risk.
                                                MindMitra is not, and will not
                                                in any manner be involved in the
                                                practice of medicine or the
                                                provision of medical care.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>6.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Privacy & Security
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                6.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                You understand that by using the
                                                Website you consent to the
                                                collection, use and disclosure
                                                of your personally identifiable
                                                information and aggregate data
                                                as set forth in our Privacy
                                                Policy.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                6.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                You understand that MindMitra
                                                cannot guarantee that
                                                unauthorized third parties will
                                                never be able to defeat our
                                                security measures or use your
                                                personal information for
                                                improper purposes. You
                                                acknowledge that you provide
                                                your personal information at
                                                your own risk.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>7.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>Warranty </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                7.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                The website is provided on an
                                                "as is" basis, and use of the
                                                website is at the user’s risk.
                                                To the maximum extent permitted
                                                by applicable law, the website
                                                is provided without warranties
                                                of any kind, whether express or
                                                implied, including, but not
                                                limited to, implied warranties
                                                of merchantability, fitness for
                                                a particular purpose, or
                                                non-infringement. No advice or
                                                information, whether oral or
                                                written, obtained by you from
                                                MindMitra or through the website
                                                will create any warranty not
                                                expressly stated herein. <br />{" "}
                                                <br /> Without limiting the
                                                foregoing, MindMitra, its
                                                subsidiaries, its affiliates,
                                                and its licensors do not warrant
                                                that the content found on the
                                                website is accurate, reliable,
                                                or correct; that the website
                                                will meet your requirements;
                                                that the website will be
                                                available at any particular time
                                                or location, uninterrupted or
                                                secure; that any defects or
                                                errors will be corrected; or
                                                that the website is free of
                                                viruses or other harmful
                                                components. Any content
                                                downloaded or otherwise obtained
                                                through the use of the website
                                                is downloaded at your own risk,
                                                and you will be solely
                                                responsible for any damage to
                                                your computer system or mobile
                                                device or loss of data that
                                                results from such download or
                                                your use of the website.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                7.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                MindMitra does not warrant,
                                                endorse, guarantee, or assume
                                                responsibility for any product
                                                or service advertised or offered
                                                by a third party through the
                                                website or any hyperlinked
                                                website or service, and
                                                MindMitra will not be a party to
                                                or in any way monitor any
                                                transaction between you and
                                                third-party providers of
                                                products or services.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>8.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Expectations and Limitations{" "}
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                8.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                MindMitra does not make any
                                                representation or warranty as to
                                                the quality or value of the
                                                services offered on the Website,
                                                or availability of Expert(s).
                                                MindMitra does not implicitly or
                                                explicitly support or endorse
                                                any services on the Website.
                                                MindMitra shall not be liable
                                                for any errors or omissions,
                                                whether on behalf of itself or
                                                third parties.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                8.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                While MindMitra carries out
                                                background checks and
                                                verifications on all Experts,
                                                you understand and acknowledge
                                                that MindMitra does not endorse,
                                                recommend, warrant or guarantee
                                                to qualifications, expertise,
                                                claims or background of any
                                                Expert, or any service, advice,
                                                opinion, recommendation provided
                                                by an Expert. Nothing contained
                                                in these Terms, the Website or
                                                on any third-party site shall be
                                                considered as an endorsement,
                                                recommendation, referral,
                                                verification, warranty or
                                                guarantee with respect to (a)
                                                any Expert; (b) the Website or
                                                (c) any service, advice,
                                                opinion, recommendation made
                                                available via the Website or (d)
                                                the validity, accuracy,
                                                availability, completeness,
                                                safety, legality, quality or
                                                applicability of any information
                                                made available via the Website.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                8.3
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                You acknowledge that there will
                                                be occasions when the Website
                                                may be interrupted, including,
                                                without limitation, for
                                                scheduled maintenance or
                                                upgrades, for emergency repairs,
                                                or due to failure of
                                                telecommunications links and/or
                                                equipment.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                8.4
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                You agree that MindMitra is not
                                                responsible for, and does not
                                                endorse, User Content posted
                                                within the Website. MindMitra
                                                does not have any obligation to
                                                pre-screen, monitor, edit, or
                                                remove any User Content. If your
                                                User Content violates these
                                                Terms, you shall be solely
                                                responsible for any legal
                                                consequences with respect to
                                                such User Content.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                8.5
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                MindMitra reserves the right to
                                                remove any User Content from the
                                                Website for any reason, without
                                                prior notice. User Content
                                                removed from the Website may
                                                continue to be stored by
                                                MindMitra, including, without
                                                limitation, in order to comply
                                                with certain legal obligations,
                                                but may not be retrievable
                                                without a valid court order.
                                                MindMitra will not be liable to
                                                you for any modification,
                                                suspension, or discontinuation
                                                of the Website, or the loss of
                                                any User Content.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                8.6
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                We may, without prior notice,
                                                change the Website, stop
                                                providing the Website or
                                                features of the Website, to you
                                                or to Users generally, or create
                                                usage limits for the Website. We
                                                may permanently or temporarily
                                                terminate or suspend your access
                                                to the Website, or delete any
                                                User Content without notice and
                                                liability for any reason,
                                                including if in our sole
                                                determination you violate any
                                                provision of these Terms, or for
                                                no reason. Upon termination for
                                                any reason or no reason, you
                                                continue to be bound by these
                                                Terms.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>9.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Termination{" "}
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                9.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                MindMitra may terminate these
                                                Terms for any reason at any
                                                time. MindMitra reserves the
                                                right, in its sole discretion,
                                                to restrict, suspend, or
                                                terminate your access to and use
                                                of the Website, with or without
                                                prior notice. Otherwise
                                                applicable sections of the Terms
                                                shall survive termination. In
                                                addition to any termination
                                                rights, we reserve the right to
                                                enforce and prosecute any
                                                violations of these Terms.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>10.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>Links </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                10.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                MindMitra may terminate these
                                                Terms for any reason at any
                                                time. MindMitra reserves the
                                                right, in its sole discretion,
                                                to restrict, suspend, or
                                                terminate your access to and use
                                                of the Website, The Website may
                                                contain links to other Websites
                                                (“Linked Sites”). The Linked
                                                Sites are not under the control
                                                of the Company. We are not
                                                responsible for the content of
                                                any Linked Site, including,
                                                without limitation to, any link
                                                contained in a Linked Site, or
                                                any changes or updates to a
                                                Linked Site. or without prior
                                                notice. Otherwise applicable
                                                sections of the Terms shall
                                                survive termination. In addition
                                                to any termination rights, we
                                                reserve the right to enforce and
                                                prosecute any violations of
                                                these Terms.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                10.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                We are not responsible for any
                                                form of transmission,
                                                whatsoever, received by the User
                                                from any Linked Site. We are
                                                providing these links only for
                                                convenience, and the inclusion
                                                of any such link does not imply
                                                endorsement by the Website, of
                                                the Linked Sites or any
                                                association with its operators
                                                or owners including the legal
                                                heirs or assigns thereof.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                10.3
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                On accessing the Linked Sites,
                                                you shall be governed by the
                                                terms of use, privacy policy and
                                                such other additional policies
                                                of the Linked Sites. You further
                                                acknowledge and agree that we
                                                shall not be responsible or
                                                liable, directly or indirectly,
                                                for any damage or loss caused or
                                                alleged to be caused by or in
                                                connection with the use of or
                                                reliance on any such content,
                                                advertising, products, services
                                                or other materials available on
                                                or through any Linked Sites or
                                                for any errors, defamatory
                                                content, libel, slander,
                                                omissions, falsehoods, obscene
                                                content, pornographic material,
                                                or any profanity contained
                                                therein.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>11.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Indemnification{" "}
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                11.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                You shall indemnify and hold us
                                                harmless from and against any
                                                and all losses, damages,
                                                settlements, liabilities, costs,
                                                charges, assessments and
                                                expenses, as well as third party
                                                claims and causes of action,
                                                including, without limitation,
                                                attorneys’ fees, arising out of
                                                any breach by you of any of
                                                these Terms, or any use by you
                                                of the Website. You shall
                                                provide us with such assistance,
                                                without charge, as we may
                                                request in connection with any
                                                such defence, including, without
                                                limitation, providing us with
                                                such information, documents,
                                                records and reasonable access to
                                                you, as we deem necessary. You
                                                shall not settle any third party
                                                claim or waive any defence
                                                without our prior written
                                                consent.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                11.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                In the event of a dispute
                                                regarding any transaction
                                                conducted via the website, you
                                                hereby relieve MindMitra, its
                                                affiliates, their respective
                                                officers, directors,
                                                shareholders, employees,
                                                sub-contractors, and agents from
                                                all manner of actions, claims,
                                                or demands and from any and all
                                                losses (direct, indirect,
                                                incidental, or consequential),
                                                damages, costs, or expenses,
                                                including, without limitation,
                                                court costs and attorneys’ fees,
                                                which member may have against
                                                one or more of the above.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>12.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Limitation of Liability{" "}
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                12.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                In no event will MindMitra or
                                                its affiliates or any party
                                                involved in creating, producing,
                                                or delivering the website be
                                                liable for any direct,
                                                incidental, consequential,
                                                indirect, special, or punitive
                                                damages arising out of your
                                                access, use, misuse, or
                                                inability to use the website or
                                                any linked sites, or in
                                                connection with any failure of
                                                performance. These limitations
                                                apply whether the alleged
                                                liability is based on contract,
                                                tort, negligence, strict
                                                liability, or any other basis,
                                                even if MindMitra has been
                                                advised of the possibility of
                                                such damage.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                12.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                By using the website and the
                                                services, the user acknowledges
                                                that he/she is solely
                                                responsible for any and all
                                                actions, liabilities,
                                                consequences, decisions,
                                                behaviors ("conduct") arising
                                                out of or in connection with the
                                                use of the website and/or
                                                services and shall in no way
                                                hold MindMitra and/or its
                                                affiliates responsible for such
                                                conduct.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>13.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                {" "}
                                                Governing Law
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                13.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                This Agreement shall be governed
                                                by the laws of India, and the
                                                courts of Delhi shall have
                                                exclusive jurisdiction with
                                                respect to any dispute arising
                                                hereunder.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>14.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Grievance Officer{" "}
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                14.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                In accordance with Information
                                                Technology Act, 2000 and rules
                                                made there under, the name and
                                                contact details of the
                                                ‘Grievance Officer’ are provided
                                                below: <br /> <br /> Name: Ms.
                                                Greeshma E <br /> <br /> Phone
                                                No: +91 9188386292 <br /> E-mail
                                                Address: mindmitrapsy@gmail.com{" "}
                                                <br /> <br /> Postal Address:
                                                DOOR NO13/135, ERUPPAKKIL,
                                                KADAMPAZHIPPURAM
                                                ,PALAKKAD,KL-678633 IN.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>15.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>
                                                Miscellaneous Provisions{" "}
                                            </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                15.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Survival: In the event of
                                                termination or expiration of
                                                these Terms for any reason, any
                                                provisions of these Terms that
                                                by their nature should survive
                                                termination of these Terms will
                                                survive termination of these
                                                Terms, unless contrary to the
                                                pertinent provisions herein
                                                stated.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                15.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Severability: If any term or
                                                provision in these Terms is held
                                                to be either illegal or
                                                unenforceable, in whole or in
                                                part, under any enactment or
                                                rule of law, such term or
                                                provision or part shall to that
                                                extent be deemed not to form
                                                part of these Terms, but the
                                                validity and enforceability of
                                                the remainder of these Terms
                                                shall not be affected.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                15.3
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Unenforceability: If any
                                                provision of these Terms or any
                                                word, phrase, clause, sentence,
                                                or other portion thereof should
                                                be held to be unenforceable or
                                                invalid for any reason, then
                                                provided that the essential
                                                consideration for entering into
                                                these Terms on the part of any
                                                Party is not unreasonably
                                                impaired, such provision or
                                                portion thereof shall be
                                                modified or deleted in such
                                                manner as to render these Terms
                                                as modified legal and
                                                enforceable to the maximum
                                                extent permitted under
                                                applicable laws.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                15.4
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                No Waiver: No delay or omission
                                                by either Party hereto to
                                                exercise any right or power
                                                occurring upon any noncompliance
                                                or default by the other party
                                                with respect to any of the terms
                                                of these Terms shall impair any
                                                such right or power or be
                                                construed to be a waiver
                                                thereof. The terms and
                                                conditions of these Terms may be
                                                waived or amended only in
                                                writing or mutual agreement of
                                                the Parties. A waiver by either
                                                of the Parties hereto of any of
                                                the covenants, conditions, or
                                                agreements to be performed by
                                                the other shall not be construed
                                                to be a waiver of any succeeding
                                                breach thereof or of any
                                                covenant, condition, or
                                                agreement herein contained
                                                (whether or not the provision is
                                                similar).
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                15.5
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Notices: Any notice required or
                                                permitted to be given to
                                                MindMitra hereunder shall be in
                                                writing and sent or transmitted
                                                by (i) registered or certified
                                                mail; (ii) hand-delivery; (iii)
                                                email; or (iv) internationally
                                                recognized courier service,
                                                provided its receipt is
                                                acknowledged and, dispatched or
                                                sent or transmitted to the
                                                address specified MindMitra. All
                                                notice required to be given
                                                under these Terms shall be
                                                addressed to: <br />
                                                Name: MindMitra Private Limited{" "}
                                                <br /> <br />
                                                {/* Contact us: www.mymindmitra.com/contact-us */}
                                                Contact us <br />
                                                Postal Address : DOOR NO13/135,
                                                ERUPPAKKIL, KADAMPAZHIPPURAM,
                                                <br /> PALAKKAD,KL-678633 IN.{" "}
                                                {/* <br /> */}
                                                <br />
                                                E-mail Address:
                                                mindmitrapsy@gmail.com
                                                {/* mindmitrapsy@gmail.com */}
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <SubContainer>
                                        <Left>
                                            <HeadingList>16.</HeadingList>
                                        </Left>
                                        <Right>
                                            <SubHeading>Data </SubHeading>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                16.1
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Account Deletion: Upon account
                                                deletion or logout, all
                                                user-related cache, including
                                                their credentials and token, is
                                                promptly purged.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                16.2
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Account Termination: Users have
                                                the option to delete their
                                                account through their profile.
                                                This action results in the
                                                removal of local data stored in
                                                our database.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                    <SubContainer>
                                        <Left>
                                            <DescriptionList>
                                                16.3
                                            </DescriptionList>
                                        </Left>
                                        <Right>
                                            <Para>
                                                Data Utilization: User data is
                                                exclusively stored and employed
                                                within the confines of the
                                                application, rendering users
                                                incapable of sharing any form of
                                                data.
                                            </Para>
                                        </Right>
                                    </SubContainer>
                                </Contents>
                                <Contents>
                                    <Para>
                                        NOTE: We encourage you to periodically
                                        review this page for the latest
                                        information on our Privacy Policy and
                                        practices. MINDMITRA PRIVATE LIMITED
                                        reserves the right to change these terms
                                        and conditions at any time without prior
                                        notice. In the event that any changes
                                        are made, the revised terms and
                                        conditions shall be posted on this
                                        website immediately.
                                    </Para>
                                </Contents>
                            </ContentDiv>
                        </>
                    </Terms>
                    {/* <ButtonDiv>
            <CancelAcceptBtn />
          </ButtonDiv> */}
                </Wrapper>
            </MainContainer>

            <BottomNavigation />
        </>
    );
}

export default TermsAndCondition;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 30px 0;
    @media all and (max-width: 768px) {
        width: 100%;
        padding-bottom: 60px;
    }
`;
const Wrapper = styled.div`
    width: 57%;
    display: flex;
    flex-direction: column;
    @media all and (max-width: 1350px) {
        width: 65%;
    }
    @media all and (max-width: 1250px) {
        width: 70%;
    }
    @media all and (max-width: 1150px) {
        width: 75%;
    }
    @media all and (max-width: 1050px) {
        width: 80%;
    }
    @media all and (max-width: 950px) {
        width: 85%;
    }
    @media all and (max-width: 900px) {
        width: 90%;
    }
    @media all and (max-width: 780px) {
        /* width: 80%; */
        padding-bottom: 50px;
    }
    @media all and (max-width: 1440px) {
        /* width: 80%; */
    }
    @media all and (max-width: 1440px) {
        /* width: 80%; */
    }
    @media all and (max-width: 1440px) {
        /* width: 80%; */
    }
`;
const Terms = styled.div`
    align-items: start;
    display: flex;
    flex-direction: column;
`;
const Heading = styled.h3`
    font-size: 20px;
    text-align: start;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.black};
    line-height: 26px;
    margin-bottom: 1px;
`;
const Date = styled.label`
    display: inline-block;
    font-size: 12px;
    text-align: start;
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_label};
    margin-bottom: 44px;
`;
const ContentDiv = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
`;
const Contents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;
const SubContainer = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`;
const HeadingList = styled.span`
    color: #3e3e53;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
const DescriptionList = styled.span`
    color: #3e3e53;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
`;
const SubHeading = styled.h5`
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color: ${THEME_COLORS.text_title};
    line-height: 20px;
`;
const Para = styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${THEME_COLORS.text_para};
    &.heading {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
    &.less-margin {
        margin-bottom: -8px;
    }
    &.left-blank {
        margin-top: -10px;
        margin-left: 10px;
    }
`;
const DescriptionHeading = styled.span`
    color: #3e3e53;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: -8px;
    &.sub-heading {
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        margin-top: 3px;
    }
`;
const ListContainer = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: ${THEME_COLORS.text_para};
    margin-top: -8px;
`;
const ListItem = styled.li`
    list-style: disc;
    list-style-position: outside;
    width: 97%;
`;
const Url = styled(Link)`
    color: #366ee3;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
const ButtonDiv = styled.div`
    width: 92%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;

const TableContainer = styled.div`
    display: flex;
    width: 100%;
    margin: 20px 0;
    background: #fff;
    border-radius: 10px;
    border: 1px solid var(--Light-300, #e2e2e2);
`;

const TableColumn = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
`;

const TableHeadCell = styled.div`
    display: flex;
    height: 56px;
    padding: 12px 14px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid #e2e2e2;
    color: #aaa;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;

const TableCell = styled.div`
    display: flex;
    height: 56px;
    padding: 12px 14px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-bottom: 1px solid #e2e2e2;
    color: #3e3e53;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &.last-child {
        border-bottom: 0;
    }
`;

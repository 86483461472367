import React from "react";

// Styles //
import styled, { keyframes } from "styled-components";

// colors //
import { THEME_COLORS } from "../../../ThemeConfig";

const BreathStatus = ({ closeModal }) => {
    // Mapping benifits items //
    const items = [
        { id: 1, name: "Decreases stress, increases calm." },
        {
            id: 2,
            name: "Relieves pain.",
        },
        {
            id: 3,
            name: "Stimulates the lymphatic system (Detoxifies the body).",
        },
        { id: 4, name: "Improves immunity. Increases energy." },
        { id: 5, name: "Lowers blood pressure." },
        { id: 6, name: "Improves digestion." },
        { id: 7, name: "Helps support correct posture." },
    ];

    return (
        <Wrapper onClick={closeModal}>
            <Content onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={closeModal}>&times;</CloseButton>
                <Title>
                    Breath in... <br />
                    Breath out...
                </Title>
                <Text>
                    Deep breathing slows down your heart rate, allows the body
                    to take in more oxygen and ultimately signals the brain to
                    wind down.
                </Text>
                <SubTitle>Benefits</SubTitle>
                <Container>
                    {items.map((item, index) => (
                        <Item key={item.name}>
                            <Name>{index + 1}.</Name>
                            <Name>{item.name}</Name>
                        </Item>
                    ))}
                </Container>
                <Button onClick={closeModal}>Done</Button>
            </Content>
        </Wrapper>
    );
};

export default BreathStatus;

const popup = keyframes`
    0%{
        scale: 0.3;
    }
    100%{
        scale: 1;
    }
`;
const CloseButton = styled.span`
    position: absolute;
    top: 1%;
    right: 1%;
    font-size: 20px;
    font-weight: bold;
    color: #ccc;
    cursor: pointer;
`;
const Wrapper = styled.div`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${THEME_COLORS.breath_model};
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Content = styled.div`
    background-color: ${THEME_COLORS.white};
    width: 450px;
    height: 469px;
    padding: 32px;
    z-index: 200;
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    box-shadow: 27px 27px 48px ${THEME_COLORS.elevation_user_u2};
    border-radius: 24px;
    animation: ${popup} 0.4s ease-in-out;

    @media all and (max-width: 480px) {
        padding: 16px;
    }
    @media all and (max-width: 360px) {
        width: 321px;
        height: 471px;
        padding: 15px;
    }
`;
const Title = styled.h2`
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 10px;
    font-family: "DM_sans_bold";
    color: ${THEME_COLORS.text_title};
`;
const Text = styled.p`
    color: ${THEME_COLORS.text_title};
    font-family: "DM_sans";
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
`;
const SubTitle = styled.p`
    color: ${THEME_COLORS.text_title};
    font-family: "DM_sans_medium";
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
`;
const Container = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 6px;
    margin-bottom: 20px;
`;
const Item = styled.div`
    display: flex;
    align-items: center;
`;
const Name = styled.div`
    color: ${THEME_COLORS.text_title};
    font-size: 14px;
    font-family: "DM_sans";
    margin-bottom: 3px;
    :last-child {
        margin-bottom: 0;
    }
    @media all and (max-width: 480px) {
        margin-right: 4px;
    }
`;
const Button = styled.button`
    background: ${THEME_COLORS.secondary_gradient};
    color: ${THEME_COLORS.white};
    cursor: pointer;
    margin: 0 auto;
    display: block;
    font-size: 16px;
    border-radius: 20px;
    width: 100%;
    height: 40px;
    font-family: "DM_sans_medium";
`;

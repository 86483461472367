import React from "react";

// packages
import { keyframes, styled } from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function ExpertDetails({ data, onClose }) {
    return (
        <Modal onClick={() => onClose()}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <Card>
                    <Title>About {data?.name}</Title>
                    <Description>
                        {/* Counseling psychology is a psychological specialty that began with a
            focus on vocational counseling, but later moved its emphasis to
            adjustment counseling, and then expanded to cover all normal
            psychology psychotherapy. */}
                        {data?.description}
                    </Description>
                    <Okay onClick={() => onClose()}>Okay</Okay>
                </Card>
            </ModalContent>
        </Modal>
    );
}
const popup = keyframes`
    0%{
        scale: 0;
    }
    100%{       
        scale: 1;
    }
`;
const Modal = styled.section`
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: ${THEME_COLORS.modal_bg};
    position: fixed;
    left: 0;
    top: 0;
    z-index: 305;
    display: flex;
    /* background: yellow; */
`;
const ModalContent = styled.div`
    width: 350px;
    transform: translateX(130px);
    border-radius: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    padding-top: 6px;
    animation: ${popup} 0.4s ease-in-out;
    @media all and (max-width: 768px) {
        transform: translateX(0px);
    }
    @media all and (max-width: 480px) {
        width: 100%;
        position: absolute;
        bottom: 90px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        animation-name: modal;
        animation-duration: 0.3s;
        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
    @media all and (max-width: 360px) {
        bottom: 70px;
    }
`;
const Card = styled.div`
    border-radius: 24px;
    background: ${THEME_COLORS.white};
    padding: 32px;
    @media all and (max-width: 480px) {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const Title = styled.h4`
    font-family: "DM_sans_bold";
    font-size: 24px;
    margin-bottom: 20px;
    color: ${THEME_COLORS.text_title};
`;
const Description = styled.p`
    font-family: "DM_sans";
    font-size: 14px;
    color: ${THEME_COLORS.text_para};
    margin-bottom: 20px;
`;

const Okay = styled.div`
    font-family: "DM_sans_medium";
    border-radius: 32px;
    padding: 8px 20px;
    color: ${THEME_COLORS.white};
    background: ${THEME_COLORS.secondary_gradient};

    font-size: 14px;
    display: flex;
    justify-content: center;
    cursor: pointer;
`;

import React from "react";

// package
import styled, { css } from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function NextButton({ onClick, size, style }) {
    return (
        <>
            <NextContainer style={style} onClick={onClick}>
                <Parent>
                    <Next size={size}>Next</Next>
                    <ArrowContainer>
                        <Arrow
                            src={
                                require("../../../assets/images/right-arrow.svg")
                                    .default
                            }
                            alt="arrow-image"
                        />
                    </ArrowContainer>
                </Parent>
            </NextContainer>
        </>
    );
}

const NextContainer = styled.a`
    background: ${THEME_COLORS.secondary_gradient};
    width: 46%;
    border-radius: 32px;
    height: 50px;
    display: flex;
    justify-content: center;
    ${(props) =>
        props.style &&
        css`
            background-color: ${props.style.backgroundColor};
            font-size: ${props.style.fontSize};
        `}

    @media (max-width: 480px) {
        padding: 0 15px !important;
    }
    @media (max-width: 360px) {
        padding: 0 10px !important;
    }
`;
const Parent = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;
const ArrowContainer = styled.div`
    width: 6.25 px;
`;
const Arrow = styled.img``;
const Next = styled.span`
    display: inline-block;
    font-size: ${(props) => (props.size ? props.size : "16px")};
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.white};
    margin-right: 10px;
`;

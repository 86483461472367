import React, { useContext, useEffect, useState } from "react";

// package
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// media
import plusIcon from "../../../assets/icons/rounded-plus.svg";
import playIcon from "../../../assets/icons/rounded-play-button.svg";
import profileIcon from "../../../assets/icons/rounded-profile-icon.png";

// components
import EmbraceCard from "../../includes/homescreens/EmbraceCard";
import TabbedSelector from "../../includes/homescreens/TabbedSelector";
import SessionDetailedCard from "../../includes/homescreens/SessionDetailedCard";
import BottomNavigation from "../../general/BottomNavigation";
import VideoPlayer from "../../includes/chip/VideoPlayer";
import HomeScreenBanner from "../../includes/homescreens/HomeScreenBanner";

// store
import { Context } from "../../../contexts/Store";
import { PatientConfig } from "../../../axiosConfig";

// Lottie
import Lottie from "lottie-react";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";
import NoSessionCard from "../../includes/homescreens/NoSessionCard";
import CreateSessionCancelModal from "../../includes/modals/CreateSessionCancelModal";
import CreateSessionSuccessModal from "../../includes/modals/CreateSessionSuccessModal";
import PaymentStatusModal from "../../includes/modals/PaymentStatusModal";

export default function Homepage({
    isExistingSession,
    isASessionAccepted,
    setIsASessionAccepted,
}) {
    // console.log(isExistingSession, "isExistingSession");
    // const [isExistingSession, setIsInitialPage] = useState(false);
    const [menu, setMenu] = useState("sessions");
    const { paymentId } = useParams();
    const [VideoDuration, setVideoDuration] = useState(0);
    const [sessionDetails, setSessionDetails] = useState([]);
    const [popularSessions, setPopularSessions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [welcomeMessage, setWelcomeMessage] = useState(""); // to dispaly welcome message
    const [videoContents, setVideoContents] = useState([]);
    const [isDataBoolean, setIsDataBoolean] = useState(false);
    const [isDataCount, setIsDataCount] = useState(0);
    // const [acceptedSessionData, setAcceptedSessionData] = useState([]);
    // const [acceptedSessionIds, setAcceptedSessionIds] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const [isPaymentDataLoading, setIsPaymentDataLoading] = useState(false);
    const [isPaymentModal, setIsPaymentModal] = useState(false);
    const [paymentData, setPaymentData] = useState({});
    const [paymentDataFetched, setPaymentDataFetched] = useState(false); // to prevent api getting called multiple times

    const [dependency, setDependency] = useState(""); // to dispaly welcome message

    const { state, dispatch } = useContext(Context);
    let accessToken = state.user_details.access_token;
    const userId = state.user_details.user_id;
    const auth = state.user_details.is_verified;
    const isPaymentPending = state.user_details.ipp;
    const navigate = useNavigate();

    // useEffect(() => {
    //     const socket = new WebSocket(
    //         `wss://api.mindmitra.talrop.works/notifications/${userId}/`
    //     );
    //     setDependency(socket.onopen);
    //     socket.onopen = () => {
    //         // console.log("WebSocket connected");
    //     };

    //     socket.onmessage = (event) => {
    //         // console.log("Received message:", event.data);
    //     };

    //     socket.onerror = (error) => {
    //         // console.error("WebSocket error:", error);
    //     };

    //     socket.onclose = () => {
    //         // console.log("WebSocket connection closed");
    //     };

    //     return () => {
    //         socket.close();
    //     };
    // }, [dependency]);

    // useEffect(() => {
    //     PatientConfig.get("api/v1/general/notifications", {
    //         headers: {
    //             Authorization: `Bearer ${accessToken}`,
    //         },
    //     })
    //         .then((response) => {
    //             const { StatusCode, data } = response.data;
    //             if (StatusCode === 6000) {
    //                 setIsInitialPage(response.data.existing_session); //check new user
    //                 const notifications = response.data.data; //setting ntifications array
    //                 const unreadAcceptedSessions = notifications.filter(
    //                     (notification) =>
    //                         notification.read === false &&
    //                         notification.notification_type ===
    //                             "Session accepted"
    //                 );
    //                 if (unreadAcceptedSessions.length > 0) {
    //                     const currentAcceptedSessionIds =
    //                         unreadAcceptedSessions.map(
    //                             (notification) => notification.id
    //                         );
    //                     const acceptedSessionExtraData =
    //                         unreadAcceptedSessions.map(
    //                             (notification) => notification.extra_data
    //                         );
    //                     // Update state with the new data
    //                     setAcceptedSessionIds(currentAcceptedSessionIds);
    //                     setAcceptedSessionData(acceptedSessionExtraData);
    //                     setIsModalOpen(true);
    //                 }
    //             } else if (StatusCode === 6001) {
    //                 // console.log("error");
    //             }
    //         })
    //         .catch((error) => {
    //             // console.error("Error fetching data:", error);
    //         });
    // }, []);

    // useEffect(() => {
    //   if (state.user_details.is_new_user || isDataBoolean) {
    //     setIsInitialPage(true)
    //   } else {
    //     setIsInitialPage(false)
    //   }
    // }, [state.user_details.is_new_user, isDataBoolean])

    const tabOptions = [
        {
            id: 1,
            name: "Upcoming",
            value: "upcoming",
        },
        {
            id: 2,
            name: "Completed",
            value: "completed",
        },
        {
            id: 3,
            name: "Cancelled",
            value: "cancelled",
        },
        {
            id: 4,
            name: "Pending",
            value: "pending",
        },
        {
            id: 5,
            name: "Missed/Failed",
            value: "failed",
        },
    ];

    const [selectedTab, setSelectedTab] = useState([tabOptions[0].value]);

    // video API
    const getVideo = () => {
        PatientConfig.get("api/v1/general/videos?purpose=2")
            .then((response) => {
                setVideoContents(response.data.data);
            })
            .catch((error) => {
                // console.error("Error", error);
            });
    };

    useEffect(() => {
        getVideo();
    }, []);

    // session API
    const getSessionDetails = () => {
        setIsLoading(true);
        PatientConfig.get(`api/v1/cases/sessions?type=${selectedTab}`, {
            headers: {
                Authorization: `Bearer  ${accessToken}`,
            },
        })
            .then((response) => {
                setSessionDetails(response.data.data.data);
                setIsLoading(false);
                if (isASessionAccepted) {
                    setIsASessionAccepted(false);
                }
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getSessionDetails();
    }, [selectedTab]);

    // popular session API
    const getConcernList = () => {
        const headers = {};
        if (accessToken) {
            headers.Authorization = `Bearer ${accessToken}`;
        }

        PatientConfig.get("api/v1/experts/area-of-expertises/", {
            headers: headers,
        })
            .then((response) => {
                setPopularSessions(
                    response.data.data.filter((item) => {
                        return item.name !== "Other" ? item : "";
                    })
                );
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    };
    // const getConcernList = () => {
    //     PatientConfig.get("api/v1/experts/area-of-expertises/", {
    //         headers: {
    //             if(accessToken){

    //                 Authorization: `Bearer  ${accessToken}`,
    //             }
    //         },
    //     })
    //         .then((response) => {
    //             setPopularSessions(
    //                 response.data.data.filter((item) => {
    //                     return item.name !== "Other" ? item : "";
    //                 })
    //             );
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching data:", error);
    //         });
    // };

    useEffect(() => {
        getConcernList();
    }, []);

    useEffect(() => {
        if (selectedTab == "upcoming" && isASessionAccepted == true) {
            getSessionDetails();
        }
    }, [selectedTab, isASessionAccepted]);

    // session API
    const getPaymentData = () => {
        setIsPaymentDataLoading(true);
        // payments / phonepe - payment - data / <id>/</id>;
        PatientConfig.get(
            `/api/v1/payments/phonepe-payment-data/${paymentId}/`,
            {
                headers: {
                    Authorization: `Bearer  ${accessToken}`,
                },
            }
        )
            .then((response) => {
                // setSessionDetails(response.data.data.data);
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    setPaymentData(data);
                    setIsPaymentDataLoading(false);
                    setPaymentDataFetched(true);
                } else {
                    setIsPaymentDataLoading(false);
                }
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
                setIsPaymentDataLoading(false);
            });
    };

    // Implementing load more
    const [displayCount, setDisplayCount] = useState(4);
    const limitedSessions = popularSessions.slice(0, displayCount);

    const getTimeBasedGreeting = () => {
        const myDate = new Date();
        const hrs = myDate.getHours();
        if (hrs < 12) {
            setWelcomeMessage("Good Morning");
        } else if (hrs >= 12 && hrs < 16) {
            setWelcomeMessage("Good Afternoon");
        } else if (hrs >= 16 && hrs <= 24) {
            setWelcomeMessage("Good Evening");
        }
    };

    useEffect(() => {
        getTimeBasedGreeting();
    }, []);

    const handleClick = (item) => {
        dispatch({
            type: "ADD_SESSION",
            new_session: {
                concern: item,
            },
        });
        navigate("/create-session");
    };

    useEffect(() => {
        if (sessionDetails.length > 0) {
            if (sessionDetails.length == 1) {
                setDisplayCount(2);
            } else if (sessionDetails.length == 2) {
                setDisplayCount(3);
            } else {
                setDisplayCount(4);
            }
        } else {
            if (sessionDetails.length == 0) {
                setDisplayCount(2);
            }
        }
    }, [sessionDetails]);

    // to manage payment status modal
    useEffect(() => {
        if (paymentId && isPaymentPending && !paymentDataFetched) {
            // Check if data hasn't been fetched yet
            getPaymentData();
        }
        if (
            paymentId &&
            isPaymentPending &&
            paymentDataFetched &&
            !isPaymentDataLoading
        ) {
            setIsPaymentModal(true);
        }
    }, [paymentId, isPaymentPending, isPaymentDataLoading, paymentDataFetched]);

    return (
        <>
            <SectionBox>
                <Container>
                    <WelcomeBox>
                        <WelcomeText>
                            {welcomeMessage}{" "}
                            <NameText>
                                {state.user_details.user_name || ""},{" "}
                            </NameText>
                            😃
                        </WelcomeText>
                    </WelcomeBox>
                    {/* {!isExistingSession && ( */}
                    <HomeScreenBanner isExistingSession={!isExistingSession} />
                    {/* )} */}
                    <MainContainer
                        className={menu === "get-started" ? "flex-none" : ""}
                    >
                        <LeftSide isExistingSession={isExistingSession}>
                            {auth && isExistingSession && (
                                <HeadNav>
                                    <Menu
                                        className={
                                            menu === "sessions" ? "active" : ""
                                        }
                                        onClick={() => setMenu("sessions")}
                                    >
                                        Sessions
                                    </Menu>
                                    <Menu
                                        className={
                                            menu === "get-started"
                                                ? "active"
                                                : ""
                                        }
                                        onClick={() => setMenu("get-started")}
                                    >
                                        Get Started
                                    </Menu>
                                </HeadNav>
                            )}
                            {!isExistingSession && (
                                <div>
                                    <PopularSessionsContainer>
                                        <SubTitle>Popular Sessions</SubTitle>
                                        <SessionsContainer>
                                            {popularSessions.map((session) => (
                                                <SessionCard key={session.id}>
                                                    <SessionCardLeft>
                                                        <SessionCardTitle>
                                                            {session.title}
                                                        </SessionCardTitle>
                                                        <SessionCardSubTitle>
                                                            {
                                                                session.description
                                                            }
                                                        </SessionCardSubTitle>
                                                    </SessionCardLeft>
                                                    <SessionCardPlusIconContainer
                                                        onClick={() => {
                                                            handleClick(
                                                                session.id
                                                            );
                                                        }}
                                                    >
                                                        <SessionCardPlusIcon
                                                            src={plusIcon}
                                                            alt="icon"
                                                        />
                                                    </SessionCardPlusIconContainer>
                                                </SessionCard>
                                            ))}
                                        </SessionsContainer>
                                    </PopularSessionsContainer>
                                </div>
                            )}
                            {isExistingSession && (
                                <AllSessionsContainer
                                    className={
                                        menu === "get-started" ? "none" : ""
                                    }
                                >
                                    <TabbedSelector
                                        tabOptions={tabOptions}
                                        selectedTab={selectedTab}
                                        setSelectedTab={setSelectedTab}
                                    />

                                    <DetailedSessionsContainer>
                                        {isLoading ? (
                                            <Lottie
                                                animationData={LoaderLottie}
                                                style={{
                                                    width: 150,
                                                    margin: "auto",
                                                }}
                                            />
                                        ) : sessionDetails.length > 0 ? (
                                            sessionDetails.map(
                                                (sessionDetail) => (
                                                    <SessionDetailedCard
                                                        selectedTab={
                                                            selectedTab
                                                        }
                                                        menu={menu}
                                                        setMenu={setMenu}
                                                        sessionDetail={
                                                            sessionDetail
                                                        }
                                                        getSessionDetails={
                                                            getSessionDetails
                                                        }
                                                        key={sessionDetail?.id}
                                                    />
                                                )
                                            )
                                        ) : (
                                            <NoSessionCard
                                                selectedTab={selectedTab}
                                            />
                                        )}
                                    </DetailedSessionsContainer>
                                </AllSessionsContainer>
                            )}
                        </LeftSide>
                        <RightSide
                            className={menu === "get-started" ? "active" : ""}
                        >
                            <EmbraceCard />
                            {isExistingSession && (
                                <PopularSessionsContainer>
                                    <SubTitle>Popular Sessions</SubTitle>
                                    <SessionsContainer>
                                        {limitedSessions.map((session, i) => (
                                            <SessionCard
                                                key={session.id}
                                                lastChild={
                                                    i ==
                                                    limitedSessions.length - 1
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <SessionCardLeft>
                                                    <SessionCardTitle>
                                                        {session.title}
                                                    </SessionCardTitle>
                                                    <SessionCardSubTitle>
                                                        {session.description}
                                                    </SessionCardSubTitle>
                                                </SessionCardLeft>
                                                <SessionCardPlusIconContainer
                                                    onClick={() => {
                                                        handleClick(session.id);
                                                    }}
                                                >
                                                    <SessionCardPlusIcon
                                                        src={plusIcon}
                                                        alt="icon"
                                                    />
                                                </SessionCardPlusIconContainer>
                                            </SessionCard>
                                        ))}
                                        {/* {limitedSessions.length < sessions.length && ( */}
                                        <LoadMoreButton
                                            onClick={() => {
                                                if (
                                                    limitedSessions.length !==
                                                    popularSessions.length
                                                ) {
                                                    setDisplayCount(
                                                        popularSessions.length
                                                    );
                                                } else {
                                                    if (
                                                        sessionDetails.length ==
                                                            0 ||
                                                        sessionDetails.length ==
                                                            1
                                                    ) {
                                                        setDisplayCount(2);
                                                    } else if (
                                                        sessionDetails.length ==
                                                        2
                                                    ) {
                                                        setDisplayCount(3);
                                                    } else {
                                                        setDisplayCount(4);
                                                    }
                                                }
                                            }}
                                        >
                                            {limitedSessions.length !==
                                            popularSessions.length
                                                ? `Load More`
                                                : `View Less`}
                                        </LoadMoreButton>
                                        {/* )} */}
                                    </SessionsContainer>
                                </PopularSessionsContainer>
                            )}
                            <VideoCard>
                                <VideoPlayer
                                    item={videoContents}
                                    setVideoDuration={setVideoDuration}
                                    Icon={playIcon}
                                />
                                <VideoBottomLine>
                                    <ProfilePic src={profileIcon} alt="icon" />
                                    <VideoBottomRight>
                                        <VideoTitle>
                                            {videoContents.title}
                                        </VideoTitle>
                                        <VideoTime>{VideoDuration}</VideoTime>
                                    </VideoBottomRight>
                                </VideoBottomLine>
                            </VideoCard>
                        </RightSide>
                    </MainContainer>
                </Container>
            </SectionBox>

            <BottomNavigation />
            {isPaymentModal && (
                <PaymentStatusModal
                    isPaymentModal={isPaymentModal}
                    setIsPaymentModal={setIsPaymentModal}
                    paymentData={paymentData}
                />
            )}
        </>
    );
}
const HeadNav = styled.div`
    display: none;
    @media all and (max-width: 1080px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        padding: 4px;
        background: ${THEME_COLORS.light_200};
        margin-bottom: 20px;
    }
`;
const Menu = styled.div`
    width: 49%;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: "DM_sans_medium";
    font-size: 16px;
    cursor: pointer;
    color: ${THEME_COLORS.text_para};
    &.active {
        background: ${THEME_COLORS.white};
        gap: 10px;
        border-radius: 8px;
        color: ${THEME_COLORS.black};
    }
`;
const SectionBox = styled.div`
    background: #fafafa;
    padding: 30px 0 30px 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    @media all and (max-width: 1080px) {
        display: block;
        overflow-y: scroll;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        padding-bottom: 120px;
    }
`;
const Container = styled.div`
    width: 68%;
    margin: 0 auto;
    @media all and (max-width: 640px) {
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;
const MainContainer = styled.div`
    display: flex;
    /* flex-direction: row; */
    /* justify-content: center; */
    /* align-items: flex-start; */
    gap: 40px;
    margin-top: 32px;
    &.flex-none {
        display: block;
    }
`;
const LeftSide = styled.div`
    width: 60%;
    max-width: 460px;
    min-width: 430px;
    /* max-height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  } */
    @media all and (max-width: 1080px) {
        margin: 0 auto;
    }
    @media all and (max-width: 980px) {
        width: 80%;
    }
    @media all and (max-width: 640px) {
        width: 90%;
        min-width: auto;
    }
    @media all and (max-width: 480px) {
        max-width: auto;
        min-width: auto;
    }
    div.initial {
        display: flex;
        /* flex-direction: row; */
        /* justify-content: center; */
        /* align-items: flex-start; */
        gap: 40px;
    }
`;
const RightSide = styled.div`
    /* margin-left: 32px; */
    width: 315px;
    /* max-height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  } */
    &.active {
        display: block;
    }
    @media all and (max-width: 1080px) {
        display: none;
        width: 60%;
        margin: 0 auto;
    }
    @media all and (max-width: 980px) {
        width: 80%;
    }
    @media all and (max-width: 768px) {
        width: 70%;
    }
    @media all and (max-width: 640px) {
        width: 90%;
    }
`;
const WelcomeBox = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.none {
        display: none;
    }
    @media screen and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const WelcomeText = styled.h6`
    color: ${THEME_COLORS.black};
    font-family: "DM_sans";
    font-size: 20px;
`;
const NameText = styled.span`
    color: ${THEME_COLORS.black};
    /* font-weight: 400; */
    font-family: "DM_sans_medium";
    font-size: 20px;
    /* margin-left: 4px; */
`;
const PopularSessionsContainer = styled.div`
    /* margin-top: 20px; */
`;
const SubTitle = styled.span`
    display: inline-block;
    color: ${THEME_COLORS.text_title};
    /* font-weight: 500; */
    font-family: "DM_sans_medium";

    font-size: 16px;
`;
const SessionsContainer = styled.div`
    margin-top: 12px;
    background: ${THEME_COLORS.light_200};
    border-radius: 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* gap: 12px; */
    /* height: calc(100vh - 216px);
    overflow-y: scroll; */
`;
const SessionCard = styled.div`
    width: 100%;
    padding: 12px;
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_user_u3};
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const SessionCardLeft = styled.div``;
const SessionCardTitle = styled.h6`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.text_title};
`;
const SessionCardSubTitle = styled.p`
    margin-top: 2px;
    font-weight: 400;
    font-size: 12px;
    color: ${THEME_COLORS.text_label};
`;
const SessionCardPlusIconContainer = styled.div`
    cursor: pointer;
`;
const SessionCardPlusIcon = styled.img`
    width: auto;
`;
const VideoCard = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 16px;
    padding: 12px;
    margin-top: 32px;
    overflow: hidden;
    /* box-shadow: ${THEME_COLORS.elevation_user_u3}; */

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        border-radius: inherit;
        box-shadow: ${THEME_COLORS.elevation_user_u3};
    }
    @media all and (max-width: 768px) {
        margin-bottom: 30px;
    }
`;
// const VideoImageDiv = styled.div`
//     background-image: url(${videoBG});
//     width: 100%;
//     background-repeat: no-repeat;
//     background-size: cover;
//     height: 180px;
//     border-radius: 16px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// `;
// const PlayIcon = styled.img`
//     width: 50px;
//     display: block;
//     object-fit: cover;
//     cursor: pointer;
// `;
const VideoBottomLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
`;
const ProfilePic = styled.img`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: block;
    object-fit: cover;
`;
const VideoBottomRight = styled.div`
    margin-left: 13px;
`;
const VideoTitle = styled.h6`
    font-weight: 500;
    font-size: 14px;
    color: ${THEME_COLORS.black};
`;
const VideoTime = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
    margin-top: 6px;
`;
const AllSessionsContainer = styled.div`
    /* margin-top: 32px; */
    &.none {
        display: none;
    }
`;
const DetailedSessionsContainer = styled.div`
    margin-top: 34px;
`;
const LoadMoreButton = styled.button`
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    display: inline-block;
    margin-top: 16px;
    background: ${THEME_COLORS.white};
    border: 2px solid ${THEME_COLORS.light_400};
    border-radius: 32px;
    cursor: pointer;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.dark_500};
`;

// const VideoCard=styled.div``;

import React from "react";

// Styles //
import styled from "styled-components";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";

// images & icons
import about from "../../../../assets/images/about-group.png";
import aboutVision from "../../../../assets/images/about-mission.png";
import aboutMission from "../../../../assets/images/about-vission.png";
import Header from "../../../general/Header";

function About() {
    return (
        <>
            {/* <Header /> */}
            <Container>
                <TopMainContainer>
                    {/* <Heading className="heading">
            About <span>Us</span>{' '}
          </Heading> */}
                    <TopSubContainer>
                        <TopLeftContainer>
                            <Heading>
                                Enbrace Mindfulness Elevate your well-being
                            </Heading>
                            <LeftBottomContainer>
                                <SubHeading>About Mind Mitra</SubHeading>
                                <Description>
                                    Mindmitra is a patient centric company
                                    working to provide easy and affordable
                                    access to mental wellness by leveraging
                                    technology.
                                    <br></br> Mindmitra is a team of qualified
                                    multi intervention professionals dedicated
                                    to providing technology supported mental
                                    health consultation, and assist to
                                    individuals and families with psychological
                                    needs. We realised during our professional
                                    journey that majority of the society still
                                    shy away from taking help when they are
                                    mentally disturbed and often try to avoid
                                    importance of mental health. There are
                                    difficulties like lack of quality mental
                                    health support at reasonable price,
                                    accessibility to good care at remote areas
                                    etc. We are trying to solve these issues
                                    with the help of our online counselling and
                                    therapy platform (One step at a time!).
                                </Description>
                            </LeftBottomContainer>
                        </TopLeftContainer>
                        <TopRightContainer>
                            <ImageContainer>
                                <Image src={about} alt="Icon" />
                            </ImageContainer>
                        </TopRightContainer>
                    </TopSubContainer>
                </TopMainContainer>
                <BottomMainContainer>
                    <BottomSubContainer>
                        <MissionContainer>
                            <MissionLeftContainer>
                                <MissionHeading>Mission</MissionHeading>
                                <MissionDescription>
                                    To be the companion for every Indian to
                                    achieve mental wellness.
                                </MissionDescription>
                            </MissionLeftContainer>

                            <MissionImageContainer>
                                <MissionImage src={aboutMission} alt="Image" />
                            </MissionImageContainer>
                        </MissionContainer>
                        <VisionContainer>
                            <VisionImageContainer>
                                <VisionImage src={aboutVision} alt="Image" />
                            </VisionImageContainer>

                            <VisionLeftContainer>
                                <VisionHeading>Vision</VisionHeading>
                                <VisionDescription>
                                    To be the most accessible and trustworthy
                                    wellness provider.
                                </VisionDescription>
                            </VisionLeftContainer>
                        </VisionContainer>
                    </BottomSubContainer>
                </BottomMainContainer>
            </Container>
            <BottomNavigation />
        </>
    );
}

export default About;

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;
const TopMainContainer = styled.div`
    display: inline-flex;
    padding: 30px 0;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
    gap: 8.056px;
    background: #fff;
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;

const TopSubContainer = styled.div`
    display: flex;
    /* width: 80%; */
    justify-content: center;
    align-items: center;
    gap: 18.528px;
    margin: 0 auto;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        width: 95%;
    }
`;
const TopLeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16.111px;
    width: 50%;
    @media all and (max-width: 1080px) {
        width: 90%;
    }
`;
const Heading = styled.h4`
    color: #000;
    font-family: "DM_Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 90%;
    &.heading {
        color: #01a79e;
    }
    span {
        color: #2b3990;
        font-weight: 700;
    }
    @media all and (max-width: 1080px) {
        width: 100%;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        font-size: 26px;
    }
`;
const LeftBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8.056px;
    align-self: stretch;
    border-radius: 13.694px;
    /* backdrop-filter: blur(16.916667938232422px); */
    @media all and (max-width: 1080px) {
        align-items: center;
    }
`;
const SubHeading = styled.h5`
    color: #3e3e53;
    font-family: "DM_Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    @media all and (max-width: 1080px) {
        width: 100%;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
`;
const Description = styled.span`
    color: #7a838a;
    font-family: "DM_Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
    width: 90%;
`;
const TopRightContainer = styled.div`
    display: flex;
    width: 870px;
    justify-content: center;
    align-items: center;
    gap: 18.528px;
    width: 50%;
`;
const ImageContainer = styled.div``;
const Image = styled.img``;
const BottomMainContainer = styled.div`
    display: flex;
    width: 80%;
    padding: 30px 0 50px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8.065px;
    background: #fff;
    margin: 0 auto;
    @media all and (max-width: 480px) {
        width: 95%;
        padding-bottom: 110px;
    }
`;

const BottomSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 29.035px;
`;
const MissionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24.196px;
    flex: 1 0 0;
    align-self: stretch;
    @media all and (max-width: 1080px) {
        flex-wrap: wrap;
    }
`;
const MissionLeftContainer = styled.div`
    display: flex;
    padding: 12.098px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8.065px;
    border-radius: 12.098px;
    width: 50%;
    @media all and (max-width: 1080px) {
        width: 80%;
        align-items: center;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;
const MissionHeading = styled.h4`
    color: #000;
    font-family: "DM_Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
const MissionDescription = styled.span`
    color: #7a838a;
    font-family: "DM_Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
    width: 90%;
`;
const MissionImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
`;
const MissionImage = styled.img``;

const VisionContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24.196px;
    flex: 1 0 0;
    align-self: stretch;

    @media all and (max-width: 1080px) {
        flex-wrap: wrap-reverse;
    }
`;

const VisionLeftContainer = styled.div`
    display: flex;
    padding: 12.098px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8.065px;
    border-radius: 12.098px;
    width: 50%;
    @media all and (max-width: 1080px) {
        width: 80%;
        align-items: center;
        text-align: center;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;
const VisionHeading = styled.h4`
    color: #000;
    font-family: "DM_Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`;
const VisionDescription = styled.span`
    color: #7a838a;
    font-family: "DM_Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: justify;
    width: 90%;
`;
const VisionImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
`;
const VisionImage = styled.img``;

// import React from "react";

// // Styles //
// import styled from "styled-components";

// // Components //
// import BottomNavigation from "../../../general/BottomNavigation";

// // images & icons
// import about from "../../../../assets/images/about-group.png";
// import aboutVision from "../../../../assets/images/about-mission.png";
// import aboutMission from "../../../../assets/images/about-vission.png";

// function About() {
//   return (
//     <>
//       <Container>
//         <TopMainContainer>
//           <TopSubContainer>
//             <TopLeftContainer>
//               <Heading>Enbrace Mindfulness Elevate your well-being</Heading>
//               <LeftBottomContainer>
//                 <SubHeading>About Mind Mitra</SubHeading>
//                 <Description>
//                   Mental health is a significant issue in many countries, with
//                   many individuals struggling to access timely and effective
//                   care due to a variety of barriers. One potential solution to
//                   this issue is the use of online platforms for mental health
//                   appointments. These platforms can provide a convenient and
//                   private way for individuals to access therapy from the comfort
//                   of their own home.
//                 </Description>
//               </LeftBottomContainer>
//             </TopLeftContainer>
//             <TopRightContainer>
//               <ImageContainer>
//                 <Image src={about} alt="Icon" />
//               </ImageContainer>
//             </TopRightContainer>
//           </TopSubContainer>
//         </TopMainContainer>
//         <BottomMainContainer>
//           <BottomSubContainer>
//             <MissionContainer>
//               <MissionLeftContainer>
//                 <MissionHeading>Mission</MissionHeading>
//                 <MissionDescription>
//                   Mental health is a significant issue in many countries, with
//                   many individuals struggling to access timely and effective
//                   care due to a variety of barriers. One potential solution to
//                   this issue is the use of online platforms for mental health
//                   appointments. These platforms can provide a convenient and
//                   private way for individuals to access therapy from the comfort
//                   of their own home.
//                 </MissionDescription>
//               </MissionLeftContainer>

//               <MissionImageContainer>
//                 <MissionImage src={aboutMission} alt="Image" />
//               </MissionImageContainer>
//             </MissionContainer>
//             <VisionContainer>
//               <VisionImageContainer>
//                 <VisionImage src={aboutVision} alt="Image" />
//               </VisionImageContainer>

//               <VisionLeftContainer>
//                 <VisionHeading>Vision</VisionHeading>
//                 <VisionDescription>
//                   Mental health is a significant issue in many countries, with
//                   many individuals struggling to access timely and effective
//                   care due to a variety of barriers. One potential solution to
//                   this issue is the use of online platforms for mental health
//                   appointments. These platforms can provide a convenient and
//                   private way for individuals to access therapy from the comfort
//                   of their own home.
//                 </VisionDescription>
//               </VisionLeftContainer>
//             </VisionContainer>
//           </BottomSubContainer>
//         </BottomMainContainer>
//       </Container>
//       <BottomNavigation />
//     </>
//   );
// }

// export default About;

// const Container = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   overflow: scroll;
// `;
// const TopMainContainer = styled.div`
//   display: inline-flex;
//   padding: 30px 0;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8.056px;
//   background: #fff;
// `;

// const TopSubContainer = styled.div`
//   display: flex;
//   width: 80%;
//   justify-content: center;
//   align-items: center;
//   gap: 18.528px;
//   margin: 0 auto;
//   @media all and (max-width: 1080px) {
//     flex-wrap: wrap;
//     text-align: center;
//   }
//   @media all and (max-width: 480px) {
//     width: 95%;
//   }
// `;
// const TopLeftContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 16.111px;
//   width: 50%;
//   @media all and (max-width: 1080px) {
//     width: 90%;
//   }
// `;
// const Heading = styled.h4`
//   color: #000;
//   font-family: "DM_Sans";
//   font-size: 32px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
//   width: 90%;
//   @media all and (max-width: 1080px) {
//     width: 100%;
//     text-align: center;
//   }
// `;
// const LeftBottomContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8.056px;
//   align-self: stretch;
//   border-radius: 13.694px;
//   backdrop-filter: blur(16.916667938232422px);
//   @media all and (max-width: 1080px) {
//     align-items: center;
//   }
// `;
// const SubHeading = styled.h5`
//   color: #3e3e53;
//   font-family: "DM_Sans";
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
//   @media all and (max-width: 1080px) {
//     width: 100%;
//     text-align: center;
//   }
// `;
// const Description = styled.span`
//   color: #7a838a;
//   font-family: "DM_Sans";
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 20px;
//   width: 90%;
// `;
// const TopRightContainer = styled.div`
//   display: flex;
//   width: 870px;
//   justify-content: center;
//   align-items: center;
//   gap: 18.528px;
//   width: 50%;
// `;
// const ImageContainer = styled.div``;
// const Image = styled.img``;
// const BottomMainContainer = styled.div`
//   display: flex;
//   width: 80%;
//   padding: 30px 0 50px 0;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   gap: 8.065px;
//   background: #fff;
//   margin: 0 auto;
//   @media all and (max-width: 480px) {
//     width: 95%;
//     padding-bottom: 110px;
//   }
// `;

// const BottomSubContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 29.035px;
// `;
// const MissionContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 24.196px;
//   flex: 1 0 0;
//   align-self: stretch;
//   @media all and (max-width: 1080px) {
//     flex-wrap: wrap;
//   }
// `;
// const MissionLeftContainer = styled.div`
//   display: flex;
//   padding: 12.098px;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8.065px;
//   border-radius: 12.098px;
//   width: 50%;
//   @media all and (max-width: 1080px) {
//     width: 80%;
//     align-items: center;
//     text-align: center;
//   }
// `;
// const MissionHeading = styled.h4`
//   color: #000;
//   font-family: "DM_Sans";
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
// `;
// const MissionDescription = styled.span`
//   color: #7a838a;
//   font-family: "DM_Sans";
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 20px;
// `;
// const MissionImageContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 50%;
// `;
// const MissionImage = styled.img``;

// const VisionContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 24.196px;
//   flex: 1 0 0;
//   align-self: stretch;

//   @media all and (max-width: 1080px) {
//     flex-wrap: wrap-reverse;
//   }
// `;

// const VisionLeftContainer = styled.div`
//   display: flex;
//   padding: 12.098px;
//   flex-direction: column;
//   align-items: flex-start;
//   gap: 8.065px;
//   border-radius: 12.098px;
//   width: 50%;
//   @media all and (max-width: 1080px) {
//     width: 80%;
//     align-items: center;
//     text-align: center;
//   }
// `;
// const VisionHeading = styled.h4`
//   color: #000;
//   font-family: "DM_Sans";
//   font-size: 24px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: normal;
// `;
// const VisionDescription = styled.span`
//   color: #7a838a;
//   font-family: "DM_Sans";
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 20px;
// `;
// const VisionImageContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 50%;
// `;
// const VisionImage = styled.img``;

import React, { useEffect, useRef, useState } from "react";

// packages
import { styled } from "styled-components";

// media
import Profile from "../../../assets/icons/person.svg";
import Info from "../../../assets/icons/black-important.svg";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// components
import ExpertDetails from "./ExpertDetails";

export default function ExpertModal({
    setSelectedExpert,
    setOpen,
    expertList,
    // handleChange,
}) {
    // console.log(expertList, "expertList");
    const [detailModal, setDetailModal] = useState(false);
    const [selectedExpertData, setSelectedExpertData] = useState(null);

    const wrapperRef = useRef(null);

    // // outside click modal closes function
    // useEffect(() => {
    //   function handleClickOutside(event) {
    //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
    //       setOpen(false);
    //     }
    //   }
    //   // Bind the event listener
    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     // Unbind the event listener on clean up
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [wrapperRef, setOpen]);

    // to set clicked expert details
    const openExpertModal = (item) => {
        setSelectedExpertData(item);
        setDetailModal(true);
    };
    return (
        <>
            <Modal ref={wrapperRef}>
                <ModalContent>
                    {expertList.map((item, index) => (
                        <Container key={index}>
                            <Content
                                onClick={() => {
                                    setSelectedExpert(item.id);
                                    setOpen(false);
                                    // handleChange();
                                }}
                            >
                                <ProfileIcon>
                                    <img src={Profile} alt="profile-icon" />
                                </ProfileIcon>
                                <Menu>{item.name}</Menu>
                            </Content>

                            <NoteIcon onClick={() => openExpertModal(item)}>
                                <img src={Info} alt="icon" />
                            </NoteIcon>
                        </Container>
                    ))}
                </ModalContent>
                {detailModal && (
                    <ExpertDetails
                        data={selectedExpertData}
                        onClose={() => setDetailModal(false)}
                    />
                )}
            </Modal>
        </>
    );
}
const Modal = styled.div`
    position: absolute;
    width: 100%;
    z-index: 1;
`;
const ModalContent = styled.div`
    background: ${THEME_COLORS.white};
    padding: 16px;
    /* box-shadow: 2px 2px 16px rgba(7, 11, 36, 0.06); */
    box-shadow: 10px 12px 48px 0px rgba(0, 0, 0, 0.25);

    border-radius: 12px;
    @media screen and (max-width: 420px) {
        padding: 10 12px;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &:hover {
        color: ${THEME_COLORS.black};
        background-color: ${THEME_COLORS.gradient_2};
        border-radius: 6px;
    }
    &:last-child {
        margin-bottom: 0;
    }
`;
const Content = styled.div`
    display: flex;
    gap: 10px;
    cursor: pointer;
`;
const ProfileIcon = styled.div``;
const Menu = styled.h6`
    font-size: 16px;
    color: ${THEME_COLORS.black};
    font-family: "DM_sans";
`;
const NoteIcon = styled.div`
    cursor: pointer;
`;

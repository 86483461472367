import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";
import NotificationCard from "./NotificationCard";

import leftIcon from "../../../../assets/icons/left-icon-green.svg";
import tick from "../../../../assets/icons/tick-green.svg";
import { Context } from "../../../../contexts/Store";
import { PatientConfig } from "../../../../axiosConfig";
import BottomNavigation from "../../../general/BottomNavigation";

const Notification = ({ getHeaderNotifications }) => {
    const [notifications, setNotifications] = useState([]);
    const [error, SetError] = useState([]);
    const [query, SetQuery] = useState();
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);

    const filterOptions = [
        {
            id: 1,
            option: "All",
            value: "all",
        },
        {
            id: 2,
            option: "Today",
            value: "today",
        },
        {
            id: 3,
            option: "This Week",
            value: "week_ago",
        },
        {
            id: 4,
            option: "A long time ago",
            value: "long_time_ago",
        },
    ];
    const [selectedFilterType, setSelectedFilterType] = useState(
        filterOptions[0].value
    );

    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const userId = state.user_details.user_id;

    // useEffect(() => {
    //     const socket = new WebSocket(
    //         `wss://api.mindmitra.talrop.works/notifications/${userId}/`
    //     );

    //     socket.onopen = () => {
    //         // console.log("WebSocket connected");
    //     };

    //     socket.onmessage = (event) => {
    //         // console.log("Received message:", event.data);
    //     };

    //     socket.onerror = (error) => {
    //         // console.error("WebSocket error:", error);
    //     };

    //     socket.onclose = () => {
    //         // console.log("WebSocket connection closed");
    //     };

    //     return () => {
    //         socket.close();
    //     };
    // }, []);

    const getNotifications = () => {
        PatientConfig.get("api/v1/general/notifications/", {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            params: {
                filter: selectedFilterType,
            },
        })
            .then((response) => {
                const { StatusCode } = response.data;
                if (StatusCode == 6000) {
                    setNotifications(response.data.data);
                } else if (StatusCode == 6001) {
                    SetError(response);
                    // console.log(error, "error");
                }
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
                SetError(error.message);
            });
    };

    const handleMarkAsRead = (value) => {
        PatientConfig.post(
            "api/v1/general/notifications/",
            {
                mark_as_read_pks: unreadNotifications,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + access_token,
                },
            }
        )
            .then((response) => {
                const { StatusCode, data } = response.data;
                if (StatusCode === 6000) {
                    getNotifications();
                    getHeaderNotifications();
                } else {
                    alert("Sorry, some error occured");
                }
            })
            .catch((error) => {
                alert(error?.respone?.data?.data?.message);
                // console.error("An error occurred:", error);
            });
    };

    useEffect(() => {
        window.scroll(0, 0);
    });

    useEffect(() => {
        getNotifications();
    }, [selectedFilterType]);

    useEffect(() => {
        if (notifications.length > 0) {
            const unreadIds = notifications
                .filter((item) => !item.read)
                .map((item) => item.id);

            setUnreadNotifications(unreadIds);
        }
    }, [notifications]);

    // const handleMarkAsRead = () => {
    //   const newList = list.map((item) =>
    //     item.isread === false ? { ...item, isread: true } : item,
    //   )
    //   setList(newList)
    // }

    return (
        <>
            <MainContainer>
                <Wrapper>
                    <Head>
                        <Title>Notifications</Title>
                        <BackLinkContainer>
                            <BackLink to="/">
                                <img src={leftIcon} alt="left arrow" />
                                <span>Back to Dashboard</span>
                            </BackLink>
                        </BackLinkContainer>
                    </Head>
                    <Content>
                        <FilterSection>
                            <FilterLeft>
                                {filterOptions.map((item) => (
                                    <FilterButton
                                        key={item.id}
                                        onClick={() =>
                                            setSelectedFilterType(item.value)
                                        }
                                        className={
                                            selectedFilterType === item.value &&
                                            "active"
                                        }
                                    >
                                        {item.option}
                                    </FilterButton>
                                ))}
                            </FilterLeft>
                            <FilterRight>
                                {unreadNotifications.length > 0 && (
                                    <MarkAllReadButton
                                        onClick={() => {
                                            handleMarkAsRead();
                                        }}
                                    >
                                        <img src={tick} alt="tick" />
                                        <span>Mark as all read</span>
                                    </MarkAllReadButton>
                                )}
                            </FilterRight>
                        </FilterSection>
                        {/* <Top>
                            <Left>
                                {options.map((item) => (
                                    <Button
                                        key={item.id}
                                        onClick={() => setActiveButton(item.id)}
                                        className={
                                            activeButton === item.id && "active"
                                        }
                                    >
                                        {item.option}
                                    </Button>
                                ))}
                            </Left>
                            <Right
                            //  onClick={handleMarkAsRead}
                            >
                                <img src={tick} alt="tick" />
                                <span>Mark as all read</span>
                            </Right>
                        </Top> */}
                        {}
                        {notifications.length > 0 ? (
                            <Bottom>
                                {notifications?.map((item) => (
                                    <NotificationCard
                                        data={item}
                                        key={item.id}
                                        large
                                    />
                                ))}
                            </Bottom>
                        ) : (
                            <NoItemContainer>
                                <NoItemText>
                                    There are no notifications to display.
                                </NoItemText>
                            </NoItemContainer>
                        )}
                    </Content>
                </Wrapper>
            </MainContainer>
            <BottomNavigation />
        </>
    );
};

export default Notification;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    padding: 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    scrollbar-width: none;
    /* @media all and (max-width: 768px) {
        width: 100%;
        overflow-y: auto;
        padding: 50px 0px 170px;
    }
    @media all and (max-width: 480px) {
        padding-bottom: 90px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    } */
    @media all and (max-width: 769px) {
        padding: 20px 0 120px 0;
    }
`;
const FilterSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
`;

const Wrapper = styled.div`
    width: 60%;
    display: flex;
    padding: 32px 20px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 8px;
    border: 1px solid#F5F5F5;
    background: #fff;

    @media all and (max-width: 1280px) {
        width: 80%;
    }
    @media all and (max-width: 980px) {
        width: 90%;
    }

    /* @media all and (max-width: 1440px) {
        width: 65%;
    }
    @media all and (max-width: 1280px) {
        width: 70%;
    }
    @media all and (max-width: 1280px) {
        width: 80%;
    }
    @media all and (max-width: 540px) {
        gap: 24px;
    } */
`;
const Head = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
    /* @media screen and (max-width: 480px) {
        flex-wrap: wrap;
    } */
`;
const Title = styled.h3`
    color: #3e3e53;
    font-size: 28px;
    font-family: "DM_sans_bold";

    /* @media screen and (max-width: 540px) {
        font-size: 26px;
    } */
`;
const BackLinkContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    @media all and (max-width: 480px) {
        width: 100%;
    }
    /* display: hidden; */
    /* @media screen and (max-width: 480px) {
        width: 100%;

        display: flex;
        justify-content: end;
        display: block;
        text-align: right; 
    } */
`;
const BackLink = styled(Link)`
    display: flex;
    height: 36px;
    padding: 8px 15px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 8px;
    img {
        width: 20px;
    }
    span {
        font-family: "DM_sans_medium";
        font-size: 14px;
        color: #1da687;
        /* line-height: 20px; */
    }
    &:hover {
        background-color: ${THEME_COLORS.light_200};
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`;
const Left = styled.div``;
const Button = styled.button`
    padding: 6px 16px;
    background-color: ${THEME_COLORS.white};
    border: 2px solid ${THEME_COLORS.light_300};
    color: ${THEME_COLORS.text_title};
    border-radius: 53px;
    margin-right: 8px;
    cursor: pointer;
    &:last-child {
        margin-right: 0;
    }
    &.active {
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const FilterLeft = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
`;
const FilterRight = styled.div`
    @media all and (max-width: 980px) {
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
    }
`;

const FilterButton = styled.button`
    padding: 6px 16px;
    background-color: ${THEME_COLORS.white};
    border: 2px solid ${THEME_COLORS.light_300};
    color: ${THEME_COLORS.text_title};
    border-radius: 53px;

    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #3e3e53;
    cursor: pointer;

    &:last-child {
        margin-right: 0;
    }
    &.active {
        border: 2px solid ${THEME_COLORS.chips_blue_on_container_2};
    }
`;
const MarkAllReadButton = styled.div`
    border-bottom: 1px solid #1da687;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 5px;
    cursor: pointer;
    img {
        width: 22px;
        /* height: 24px; */
    }
    span {
        font-family: "DM_sans_medium";
        font-size: 14px;
        color: #1da687;
    }
`;

const Right = styled.div`
    border-bottom: 1px solid #1da687;
    display: flex;
    align-items: center;
    img {
        width: 24px;
        height: 24px;
    }
    span {
        font-weight: 500;
        font-size: 14px;
        margin-left: 5px;
        color: #1da687;
    }
`;
const Bottom = styled.div`
    width: 100%;
`;
const NoItemContainer = styled.div`
    display: flex;
    padding: 40px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px dashed #d3d3d3;
    background: #fff;
`;
const NoItemText = styled.p`
    color: #818181;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
`;

import React, { useState } from "react";
import {
    WhatsappShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    EmailShareButton,
} from "react-share";

// Icons
import {
    WhatsappIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    EmailIcon,
} from "react-share";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// pacakges
import styled, { keyframes } from "styled-components";

export default function ShareReport({ onClose, deleteModal, DownloadLink }) {
    const [copyLinkText, setCopyLinkText] = useState("Copy Link");
    const [copyLinkTextColor, setCopyLinkTextColor] = useState("#333333");
    const [copyLinkBorderColor, setCopyLinkBorderColor] =
        useState("transparent");
    const [isHovered, setIsHovered] = useState(false);

    const handleCopyLink = () => {
        navigator.clipboard
            .writeText({ DownloadLink })
            .then(() => {
                setIsHovered(false);
                setCopyLinkText("Copied!");
                setCopyLinkTextColor("#1DA687");
                setCopyLinkBorderColor("#1DA687");

                setTimeout(() => {
                    setCopyLinkText("Copy Link");
                    setCopyLinkTextColor("#333333");
                    setCopyLinkBorderColor("transparent");
                }, 3000); // Reset to 'Copy Link' after 3 seconds
            })
            .catch((error) => {
                // console.error('Error copying link:', error)
            });
    };

    return (
        <Modal
            className={deleteModal === "share" ? "open" : ""}
            onClick={() => onClose()}
        >
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <Card>
                    <Title>Share</Title>
                    <SubTitle>Share this report via</SubTitle>
                    <SocialMedia>
                        <WhatsappShareButton
                            url={DownloadLink}
                            title="check out this report "
                        >
                            <WhatsappIcon size={45} round={true} />
                        </WhatsappShareButton>
                        <EmailShareButton
                            subject="Checkout this report"
                            url={DownloadLink}
                        >
                            <EmailIcon size={45} round={true} />
                        </EmailShareButton>
                    </SocialMedia>
                </Card>
            </ModalContent>
        </Modal>
    );
}
const popup = keyframes`
    0%{
        scale: 0;
    }
    100%{       
        scale: 1;
    }
`;
const Modal = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${THEME_COLORS.modal_bg};
    position: absolute;
    left: 0;
    top: 0;
    z-index: 305;
    /* display: none; */
    &.open {
        display: flex;
    }
`;
const ModalContent = styled.div`
    width: 350px;
    border-radius: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    background: ${THEME_COLORS.chips_green_on_container};
    padding-top: 5px;
    animation: ${popup} 0.4s ease-in-out;
    @media all and (max-width: 480px) {
        width: 75%;
        position: absolute;
        bottom: 45%;
        animation-name: modal;
        animation-duration: 0.3s;
        @keyframes modal {
            from {
                transform: translateY(100%);
            }
            to {
                transform: translateY(0%);
            }
        }
    }
`;
const Card = styled.div`
    border-radius: 24px;
    background: ${THEME_COLORS.white};
    padding: 20px;
`;
const Title = styled.h4`
    font-family: "DM_sans_medium";
    font-size: 20px;
    margin-bottom: 20px;
    color: ${THEME_COLORS.text_title};
    margin-bottom: 10px;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
`;
const SubTitle = styled.span`
    display: inline-block;
    font-size: 14px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_title};
    margin-bottom: 20px;
`;
const SocialMedia = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 30px;
`;
const CopyLink = styled.span`
    display: inline-block;
    font-family: "DM_sans_medium";
    border-radius: 8px;
    width: 90px;
    text-align: center;
    font-size: 14px;
    border: 2px solid transparent;
    padding: 8px 10px;
    cursor: pointer;
    margin-top: 10px;
`;
const Form = styled.form`
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    background: ${THEME_COLORS.chips_blue_container};
    border-radius: 8px;
    input {
        font-size: 14px;
        font-family: "DM_sans";
        color: ${THEME_COLORS.text_title};
    }
`;
const Button = styled.div`
    cursor: pointer;
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #fff;
    padding: 6px 16px;
    background: ${THEME_COLORS.secondary_gradient};
    border-radius: 8px;
`;

import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_details: {
        is_verified: false,
        is_new_user: true,
        is_login: false,
        role: "",
        user_type: "",
        user_name: "",
        user_id: "",
        user_age: "",
        gender: "",
        email: "",
        feelings: [],
        emotion: "",
        sessionmode: "",
        fixture: [],
        session_count: "",
        phone_number: "",

        concern: "",
        description: "",
        specialization: "",
        case_date: "",
        time_slot: "",
        package: "",
        concern_id: "",

        ipp: false, //is payment pending
    },

    session_details: {
        id: "",
    },
    new_session: null,
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;

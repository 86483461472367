import { Context } from "./Store"; // Use 'Context' instead of 'initalState'

const Reducer = (state, action) => {
    switch (action.type) {
        case "UPDATE_USER":
            const user_details = {
                ...state.user_details,
                ...action.user_details,
            };
            localStorage.setItem("user_details", JSON.stringify(user_details));
            return {
                ...state,
                user_details: user_details,
            };

        case "LOGOUT":
            localStorage.removeItem("user_details"); // Remove user details from localStorage
            // console.log(Context, "==Context");
            return {
                ...state,
                user_details: Context.user_details, // Reset user details to initial values
            };

        case "UPDATE_SESSION_DETAILS":
            const session_details = {
                ...state.session_details,
                ...action.session_details,
            };
            localStorage.setItem(
                "session_details",
                JSON.stringify(session_details)
            );
            return {
                ...state,
                session_details: session_details,
            };

        case "ADD_SESSION":
            const new_session = {
                ...state.new_session,
                ...action.new_session,
            };
            localStorage.setItem("new_session", JSON.stringify(new_session));
            return {
                ...state,
                new_session: new_session,
            };

        case "CANCEL_ADD_SESSION":
            localStorage.removeItem("new_session"); // Remove user details from localStorage
            // console.log(Context, "==Context");
            return {
                ...state,
                new_session: null,
            };

        default:
            return state;
    }

    // async function storeData(user_data) {
    // 	try {
    // 		const jsonValue = JSON.stringify(user_data);
    // 		localStorage.setItem("user_details", jsonValue);
    // 		// localStorage.getItem("user_details", (err, value) => {
    // 		// 	if (err) {
    // 		// 	} else {
    // 		// 		JSON.parse(value); // boolean false
    // 		// 	}
    // 		// });
    // 	} catch (e) {
    // 		// saving error
    // 	}
    // }

    // const user_details = {
    // 	...state.user_details,
    // 	...action.user_details,
    // };

    // switch (action.type) {
    // 	case "UPDATE_USER":
    // 		storeData(user_details);
    // 		return {
    // 			...state,
    // 			user_details: user_details,
    // 		};
    // 	default:
    // 		return state;
    // }
};

export default Reducer;

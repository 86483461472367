import React, { useContext, useEffect, useState } from "react";

// Styles //
import styled from "styled-components";

// Packages //
import { Link, NavLink } from "react-router-dom";

// Media-Images //
import logo from "../../assets/images/header/logoimg.svg";
import logotitle from "../../assets/images/header/logotitle.svg";
import notification from "../../assets/images/notification-green.svg";

// colors
import { THEME_COLORS } from "../../ThemeConfig";

// modal
import NotificationModal from "../includes/modals/NotificationModal";

// store
import { Context } from "../../contexts/Store";

// axios
import { PatientConfig } from "../../axiosConfig";

function Header({
    unreadNotifictaionsCount,
    unreadNotifications,
    getHeaderNotifications,
}) {
    const { state, dispatch } = useContext(Context);
    const access_token = state.user_details.access_token;
    const auth = state.user_details.is_verified;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, SetError] = useState([]);

    // // notifactions
    // const [unreadNotifictaionsCount, setUnreadNotificationsCount] = useState(0);
    // const [unreadNotifications, setUnreadNotifications] = useState([]);
    // const [notifications, setNotifications] = useState([]);

    const handleClick = () => {
        setIsModalOpen((prev) => !prev);
    };

    // useEffect(() => {
    //     const socket = new WebSocket(
    //         `wss://api.mindmitra.talrop.works/notifications/${userId}/`
    //     );

    //     socket.onopen = () => {
    //         // console.log('WebSocket connected')
    //     };

    //     socket.onmessage = (event) => {
    //         alert("new new new");
    //         // console.log('Received message:', event.data)
    //     };

    //     socket.onerror = (error) => {
    //         // console.error('WebSocket error:', error)
    //     };

    //     socket.onclose = () => {
    //         // console.log('WebSocket connection closed')
    //     };

    //     return () => {
    //         socket.close();
    //     };
    // }, []);

    // useEffect(() => {
    //     // if (access_token) {
    //     PatientConfig.get("api/v1/general/notifications/", {
    //         headers: {
    //             Authorization: `Bearer ${access_token}`,
    //         },
    //     })
    //         .then((response) => {
    //             const {
    //                 StatusCode,
    //                 data,
    //                 existing_session, //to check if it is new user
    //                 unread_notifications_count,
    //             } = response.data;
    //             if (StatusCode == 6000) {
    //                 setUnreadNotificationsCount(unread_notifications_count);
    //                 setUnreadNotifications(
    //                     data.filter(
    //                         (notification) => notification.read === false
    //                     )
    //                 );

    //                 setNotifications(data);
    //                 // console.log(response.data.data, 'notify')
    //             } else if (StatusCode == 6001) {
    //                 SetError(response);
    //                 // console.log(error, 'error')
    //             }
    //         })
    //         .catch((error) => {
    //             // console.error('Error fetching data:', error)
    //             SetError(error.message);
    //         });
    //     // }
    // }, []);
    useEffect(() => {
        if (access_token) {
            getHeaderNotifications();
        }
    }, [access_token]);

    return (
        <>
            <HeaderContainer>
                <LeftDiv>
                    <Logo to="/">
                        <LogoImg src={logo} alt="Logo" />
                    </Logo>
                    <LogoTitle to="/">
                        <LogoTitleImg src={logotitle} alt="Logo title" />
                    </LogoTitle>
                </LeftDiv>
                <RightDiv>
                    {auth && (
                        <Notification onClick={handleClick}>
                            <NotificationImage
                                src={notification}
                                alt="Image 1"
                            />
                            {unreadNotifictaionsCount > 0 && (
                                <NotificationRedSignal></NotificationRedSignal>
                            )}
                        </Notification>
                    )}
                </RightDiv>
                {isModalOpen && (
                    <NotificationModal
                        notifications={unreadNotifications}
                        closeModal={() => handleClick()}
                    />
                )}
            </HeaderContainer>
        </>
    );
}

export default Header;

const HeaderContainer = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${THEME_COLORS.light_300};
    background: ${THEME_COLORS.light_green};
    height: 70px;
    padding: 8px 32px;

    /* new changes */
    /* position: fixed;
    top: 0;
    width: 100%; */
    /* end */
    &.none {
        display: none;
    }
    @media all and (max-width: 768px) {
        height: 80px;
    }
    @media all and (max-width: 640px) {
        padding: 8px 20px;
    }
    @media all and (max-width: 360px) {
        padding: 10px;
    }
`;
const LeftDiv = styled.h1`
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
`;
const Logo = styled(Link)`
    display: inline-block;
    width: 35px;
    cursor: poniner;
    @media all and (max-width: 768px) {
        width: 42px;
    }
    @media all and (max-width: 480px) {
        width: 34px;
    }
`;
const LogoImg = styled.img`
    width: 100%;
    display: block;
`;
const LogoTitle = styled(Link)`
    display: inline-block;
    width: 134px;
    cursor: poniner;
`;
const LogoTitleImg = styled.img`
    width: 100%;
    display: block;
`;
const RightDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 40px;
`;
const Notification = styled(NavLink)`
    width: 20px;
    cursor: pointer;
    position: relative;
`;
const NotificationRedSignal = styled.div`
    position: absolute;
    top: 0;
    right: 2px;
    width: 7px;
    height: 7px;
    background: #f00;
    border-radius: 50%;
`;
const NotificationImage = styled.img`
    width: 100%;
    display: block;
`;

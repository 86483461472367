import React, { useContext, useState } from "react";

// packages
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// component
import NextButton from "../../includes/buttons/NextButton";
import PreviousButton from "../../includes/buttons/PreviousButton";

// store
import { Context } from "../../../contexts/Store";

export default function OnBoardingPersonGender() {
    const { state, dispatch } = useContext(Context);
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [gender, setGender] = useState(state.user_details.gender || "");

    // error  validation, dispatching data and navigation
    const handleSubmit = () => {
        if (gender === "") {
            setError(true);
        } else {
            setError(false);
            dispatch({
                type: "UPDATE_USER",
                user_details: {
                    gender: gender,
                },
            });
            navigate("/onboarding-person-feelings");
        }
    };

    return (
        <MainContainer>
            <ContentContainer>
                <Content>
                    <div>
                        <SkipButton to="/onboarding-completed">
                            Skip for now
                        </SkipButton>
                        <LogoContainer>
                            <Logo
                                src={
                                    require("../../../assets/images/logo.svg")
                                        .default
                                }
                                alt="logo"
                            />
                        </LogoContainer>
                        <Detail>
                            <Text>What is your Gender</Text>
                            <Option
                                onClick={() => setGender("Male")}
                                className={gender === "Male" ? "selected" : ""}
                            >
                                Male
                            </Option>
                            <Option
                                onClick={() => setGender("Female")}
                                className={
                                    gender === "Female" ? "selected" : ""
                                }
                            >
                                Female
                            </Option>
                            <Option
                                onClick={() => setGender("Transgender")}
                                className={
                                    gender === "Transgender"
                                        ? "selected last-child"
                                        : "last-child"
                                }
                            >
                                Transgender
                            </Option>
                            <ErrorMessage className={error ? "active" : ""}>
                                Please select a gender
                            </ErrorMessage>
                        </Detail>
                    </div>
                    <div>
                        <NavButtons>
                            <PreviousButton url="/onboarding-person-age" />
                            <NextButton
                                onClick={handleSubmit}
                                // data={{
                                //     type: "UPDATE_USER",
                                //     user_details: {
                                //         gender: gender,
                                //     },
                                // }}
                                // url="/onboarding-person-feelings"
                            />
                        </NavButtons>
                    </div>
                </Content>
            </ContentContainer>
        </MainContainer>
    );
}
const MainContainer = styled.section`
    height: calc(100vh - 70px);
    background: ${THEME_COLORS.light_100};
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (max-width: 480px) {
        background: ${THEME_COLORS.white};
        display: block;
    }
`;
const ContentContainer = styled.div`
    background: ${THEME_COLORS.white};
    box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_300};
    width: 468px;
    max-height: 80vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    /* @media all and (max-width: 1440px) {
    width: 35%;
  }
  @media all and (max-width: 1280px) {
    width: 42%;
  }
  @media all and (max-width: 1080px) {
    width: 48%;
  }
  @media all and (max-width: 980px) {
    width: 62%;
  }
  @media all and (max-width: 768px) {
    width: 70%;
  }
  @media all and (max-width: 640px) {
    width: 90%;
  } */
    @media all and (max-width: 480px) {
        width: 100%;
        border: none;
        box-shadow: none;
        height: 100%;
    }
`;
const Content = styled.div`
    padding: 30px 30px 60px 30px;
    @media all and (max-width: 480px) {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
`;
const SkipButton = styled(Link)`
    font-size: 14px;
    color: ${THEME_COLORS.chips_green_on_container};
    font-family: "DM_sans_medium";
    margin-bottom: 30px;
    text-align: right;
    display: block;
`;
const LogoContainer = styled.div`
    width: 56px;
    margin: 0 auto 60px auto;
`;
const Logo = styled.img``;
const Detail = styled.div`
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media all and (max-width: 640px) {
        padding: 0 28px;
    }
    @media all and (max-width: 480px) {
        padding: 0 10px;
    }
`;
const Text = styled.h3`
    font-family: "DM_sans_bold";
    font-size: 24px;
    margin-bottom: 30px;
    text-align: center;
`;
const Option = styled.button`
    font-size: 16px;
    font-family: "DM_sans";
    border: 1px solid ${THEME_COLORS.light_300};
    display: block;
    margin-bottom: 20px;
    border-radius: 24px;
    height: 50px;
    text-align: center;
    color: ${THEME_COLORS.text_title};
    cursor: pointer;

    :hover {
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
        background: ${THEME_COLORS.chips_green_container};
    }
    &.last-child {
        margin-bottom: 60px;
    }
    &.selected {
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
        background: ${THEME_COLORS.chips_green_container};
    }
`;
const ErrorMessage = styled.span`
    display: none;
    &.active {
        position: absolute;
        color: red;
        font-size: 12px;
        display: block;
        bottom: 2%;
        right: 35%;
    }
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    @media all and (max-width: 640px) {
        padding: 0 20px;
    }
    @media all and (max-width: 480px) {
        padding: 0 10px;
    }
    @media all and (max-width: 360px) {
        padding: 0;
    }
`;

import React from "react";
import { Route, Routes } from "react-router-dom";
import "../../../assets/css/style.css";

// components
import Head from "../../general/Head";
import OnBoardingCompleted from "../../screens/onboarding-screens/OnBoardingCompleted";
import OnboardingFeelToday from "../../screens/onboarding-screens/OnboardingFeelToday";
import OnBoardingPerson from "../../screens/onboarding-screens/OnBoardingPerson";
import OnBoardingPersonAge from "../../screens/onboarding-screens/OnBoardingPersonAge";
import OnBoardingPersonFeelings from "../../screens/onboarding-screens/OnBoardingPersonFeelings";
import OnBoardingPersonGender from "../../screens/onboarding-screens/OnBoardingPersonGender";
import OnBoardingPersonMeditate from "../../screens/onboarding-screens/OnBoardingPersonMeditate";
import OnBoardingPersonName from "../../screens/onboarding-screens/OnBoardingPersonName";
import HomeRouter from "./HomeRouter";
import TermsAndCondition from "../../screens/dashboard/account/TermsAndCondition";
import PrivacyPolicy from "../../screens/dashboard/account/PrivacyPolicy";
import SessionIndividualPage from "../../includes/homescreens/SessionIndividualPage";
import Sidebar from "../../general/Sidebar";

export default function AppRouter() {
  return (
    <>
      <Routes>
        <Route
          path="/onboarding-person"
          element={[<Head />, <OnBoardingPerson />]}
        />
        <Route
          path="/onboarding-person-name"
          element={[<Head />, <OnBoardingPersonName />]}
        />
        <Route
          path="/onboarding-person-age"
          element={[<Head />, <OnBoardingPersonAge />]}
        />
        <Route
          path="/onboarding-person-gender"
          element={[<Head />, <OnBoardingPersonGender />]}
        />
        <Route
          path="/onboarding-person-feelings"
          element={[<Head />, <OnBoardingPersonFeelings />]}
        />
        <Route
          path="/onboarding-person-meditate"
          element={[<Head />, <OnBoardingPersonMeditate />]}
        />
        <Route
          path="/onboarding-completed"
          element={[<Head />, <OnBoardingCompleted />]}
        />
        <Route
          path="/onboarding-person-feeling-today"
          element={[<Head />, <OnboardingFeelToday />]}
        />
        <Route
          path="/terms-n-conditions"
          element={[<Head />, <TermsAndCondition />]}
        />
        <Route
          path="/privacy-policies"
          element={[<Head />, <PrivacyPolicy />]}
        />
        <Route path="/*" element={<HomeRouter />} />
      </Routes>
    </>
  );
}

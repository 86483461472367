import React, { useState, useContext, useEffect, useRef } from "react";

// axios
import { PatientConfig } from "../../../../axiosConfig";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// Media-Images //
import tick from "../../../../assets/images/tick.svg";
import calender from "../../../../assets/images/calender-black.svg";
import greendot from "../../../../assets/images/Ellipse 11.svg";
import dropDown from "../../../../assets/icons/dropdown-arrow.svg";

// Packages //
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-US";
import { format } from "date-fns";

// store
import { Context } from "../../../../contexts/Store";

// Components //
import BottomNavigation from "../../../general/BottomNavigation";
import PreviousButton from "../../../includes/buttons/PreviousButton";
import NextButton from "../../../includes/buttons/NextButton";
import DatePick from "../../../includes/chip/DatePick";
import CancelButton from "../../../includes/buttons/CancelButton";
import ExpertModal from "../../../includes/modals/ExpertModal";

// Lottie
import Lottie from "lottie-react";
import LoaderLottie from "../../../../assets/lottie/LoaderLottie.json";
import CreateSessionCancelModal from "../../../includes/modals/CreateSessionCancelModal";

function Schedule() {
    // Register locale for react-day-picker
    registerLocale("en", en);

    // State for selected date and time slot
    const [selected, setSelected] = useState(new Date());
    const [expert, setExpert] = useState(() => {
        const storedExpert = sessionStorage.getItem("selectedExpert");
        return storedExpert ? JSON.parse(storedExpert) : "";
    });
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(today);
    const [selectedExpert, setSelectedExpert] = useState("");
    const [selectedTime, setSelectedTime] = useState();

    const [timeChecked, setTimeChecked] = useState("");
    const [error, setError] = useState("");
    const [expertList, setExpertList] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false); // State for show/hide date picker

    const [isOpen, setOpen] = useState(false);
    const [isFocused, setIsFocused] = useState("");
    const [timeSlots, setTimeSlots] = useState([]); // State for the buttons that show the emotions
    const [isLoading, setIsLoading] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

    // Context and navigation variables
    const { dispatch, state } = useContext(Context);
    const navigate = useNavigate();
    // console.log(selectedDate, "selectedDate");
    // Check if the screen is mobile
    const isMobile = window.innerWidth <= 480;

    let accessToken = state.user_details.access_token;
    const new_session = state.new_session;
    const concern_id = new_session?.concern;

    // console.log(isLoading, "isLoading");

    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");

    const getExpertsList = () => {
        PatientConfig.get(
            `api/v1/experts/specializations?expertise=${concern_id}`,
            {
                headers: {
                    Authorization: `Bearer  ${accessToken}`,
                },
            }
        )
            .then((response) => {
                setExpertList(response.data.data);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    };

    // dependency for fetching expert lists
    useEffect(() => {
        if (concern_id) {
            getExpertsList();
        }
    }, [concern_id]);

    const getTimeSlot = () => {
        setIsLoading(true);
        PatientConfig.get(
            `api/v1/general/time-slots?specialization=${selectedExpert}&case_date=${formattedDate}`,
            {
                specialization: selectedExpert,
                case_date: formattedDate,
                headers: {
                    Authorization: `Bearer  ${accessToken}`,
                },
            }
        )
            .then((response) => {
                setTimeSlots(response.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        if (selectedExpert) {
            getTimeSlot();
        }
    }, [selectedExpert, selectedDate]);

    const handleCancelClick = () => {
        setIsCancelModalOpen((prev) => !prev);
    };

    // Handle form submission
    const handleSubmit = () => {
        if (!selectedExpert) {
            setError("Please select an expert");
        } else if (!selectedDate) {
            setError("Please select a date");
        } else if (!selectedTime) {
            setError("Please select an available time");
        } else {
            setError("");
            dispatch({
                type: "ADD_SESSION",
                new_session: {
                    case_date: selectedDate,
                    expert: selectedExpert,
                    time_slot: selectedTime,
                },
            });
            // sessionStorage.setItem("selectedDate", selected.toISOString());
            navigate("/fulfill");
        }
    };

    // Handle button click and update selected time slot
    const handleClick = () => {
        setTimeChecked(true);
    };

    const handleChange = () => {
        setError("");
    };

    let buttonsFromAPI;

    // Map the time slot buttonsFromAPI and render them
    if (Array.isArray(timeSlots)) {
        buttonsFromAPI = timeSlots?.map((item) => (
            <TimeSlotButton
                key={item.id}
                className={
                    !item?.active
                        ? "not-available"
                        : item.id === selectedTime
                            ? "active"
                            : ""
                }
                // className={item?.active === true ? "" : "not-available"}
                onClick={() => {
                    if (item?.active) {
                        handleClick(item.id);
                        setSelectedTime(item.id);
                    }
                    // handleChange();
                }}
            >
                <TimeSlotBtnlabel
                    className={item.id === selectedTime ? "active" : ""}
                >
                    {item.start_time}
                </TimeSlotBtnlabel>
            </TimeSlotButton>
        ));
    } else {
        // console.error("button is not an array");
    }

    const [buttonDisabled, setButtonDisabled] = useState([
        // Array of disabled time slot options for static view before api fetching
        {
            id: 1,
            label: "7:00 AM",
        },
        {
            id: 2,
            label: "8:00 AM",
        },
        {
            id: 3,
            label: "9:00 AM",
        },
        {
            id: 4,
            label: "10:00 AM",
        },
        {
            id: 5,
            label: "11:00 AM",
        },
        {
            id: 6,
            label: "12:00 PM",
        },
        {
            id: 7,
            label: "1:00 PM",
        },
        {
            id: 8,
            label: "2:00 PM",
        },
        {
            id: 9,
            label: "3:00 PM",
        },
        {
            id: 10,
            label: "4:00 PM",
        },
        {
            id: 11,
            label: "5:00 PM",
        },
        {
            id: 12,
            label: "6:00 PM",
        },
        {
            id: 13,
            label: "7:00 PM",
        },
        {
            id: 14,
            label: "8:00 PM",
        },
        {
            id: 15,
            label: "9:00 PM",
        },
    ]);
    const wrapperRef = useRef(null);

    // outside click modal closes function
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                setOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, isOpen]);

    // console.log(selectedExpert, "selectedExpert");
    const handleCancelAddSession = () => {
        dispatch({
            type: "CANCEL_ADD_SESSION",
        });
        navigate("/");
    };

    useEffect(() => {
        if (Boolean(new_session)) {
            // console.log("new session is there");
            if (new_session.expert) {
                setSelectedExpert(new_session.expert);
            }
            if (new_session.case_date) {
                setSelectedDate(new_session.case_date);
            }
            if (new_session.time_slot) {
                setSelectedTime(new_session.time_slot);
            }
        }
    }, [new_session]);

    useEffect(() => {
        if (error) {
            if (selectedExpert && selectedDate && selectedTime) {
                setError("");
            }
            setTimeout(() => {
                setError("");
            }, 3000);
        }
    }, [error, selectedExpert, selectedDate, selectedTime]);

    useEffect(() => {
        if (new_session) {
            if (Boolean(new_session) == true) {
                if (Boolean(new_session.concern) == false) {
                    navigate("/create-session");
                }
            } else {
                navigate("/create-session");
            }
        }
    }, [new_session]);

    return (
        <>
            {/* Main container */}
            <MainContainer>
                <HeadingDiv>
                    <Heading>Create a Session</Heading>
                    <CancelBtn onClick={() => handleCancelClick()}>
                        <CloseSvg>
                            <CloseSvgImg
                                src={
                                    require("../../../../assets/images/close.svg")
                                        .default
                                }
                                alt="cancel-button"
                            />
                        </CloseSvg>
                        <Cancel>Cancel</Cancel>
                    </CancelBtn>
                </HeadingDiv>
                <ContentDiv>
                    <TopDiv>
                        <Section>
                            <Tick>
                                <TickImg src={tick} alt="Tick image" />
                            </Tick>
                            <Title>Challenge</Title>
                        </Section>
                        <Section>
                            <Count isFirst>2</Count>
                            <Title isFirst>Schedule</Title>
                        </Section>
                        <Section>
                            <Count>3</Count>
                            <Title>Fulfill</Title>
                        </Section>
                    </TopDiv>
                    {/* Select date and time */}
                    <MiddleDiv>
                        <ItemContainer>
                            <div ref={wrapperRef}>
                                <MidHeading>Select Expert and Date</MidHeading>

                                <Contents
                                    onClick={() => setOpen(!isOpen)}
                                    onFocus={() => setIsFocused("active")}
                                    onBlur={() => setIsFocused("")}
                                    className={
                                        isFocused === "active" || isOpen
                                            ? "active"
                                            : ""
                                    }
                                >
                                    <div>
                                        <Label>Choose Expert*</Label>
                                        <Mode>
                                            {expertList &&
                                                selectedExpert &&
                                                expertList?.find(
                                                    (item) =>
                                                        item.id ==
                                                        selectedExpert
                                                )?.name}
                                        </Mode>
                                    </div>
                                    <DropDown
                                        src={dropDown}
                                        alt="dropdown-arrow"
                                        isOpen={isOpen ? true : false}
                                    />
                                </Contents>
                                {isOpen && (
                                    <ExpertModal
                                        setSelectedExpert={setSelectedExpert}
                                        setOpen={setOpen}
                                        expertList={expertList}
                                    // handleChange={handleChange}
                                    />
                                )}
                            </div>
                            {/* Date selection */}
                            <Content
                                className={
                                    isFocused === "calender" || showDatePicker
                                        ? "active"
                                        : ""
                                }
                                onFocus={() => setIsFocused("calender")}
                                onBlur={() => setIsFocused("")}
                                onClick={() => {
                                    setShowDatePicker(true);
                                }}
                                onChange={handleChange}
                            >
                                <LabelContainer>
                                    <Label>Choose Date*</Label>
                                    <DateSelected>
                                        {formattedDate && formattedDate}
                                    </DateSelected>
                                </LabelContainer>
                                <CalenderBox>
                                    <CalenderContainer>
                                        <CalenderIcon
                                            src={calender}
                                            alt="calender"
                                        />
                                    </CalenderContainer>
                                </CalenderBox>
                            </Content>
                            {/* Date picker */}
                            <DatePick
                                showDatePicker={showDatePicker}
                                selected={selectedDate}
                                setSelected={setSelectedDate}
                                setShowDatePicker={setShowDatePicker}
                                onChange={handleChange}
                            />
                            <MidHeading>Choose Time Slot</MidHeading>
                            {/* Render time slot buttonsFromAPI */}
                            <ButtonContainer>
                                {isLoading ? (
                                    <Lottie
                                        animationData={LoaderLottie}
                                        style={{
                                            width: 150,
                                            margin: "auto",
                                        }}
                                    />
                                ) : Array.isArray(timeSlots) &&
                                    timeSlots.length > 0 ? (
                                    buttonsFromAPI
                                ) : (
                                    buttonDisabled?.map((item) => (
                                        <TimeSlotButton
                                            key={item.id}
                                            className="not-available"
                                        >
                                            <TimeSlotBtnlabel className="not-available">
                                                {item.label}
                                            </TimeSlotBtnlabel>
                                        </TimeSlotButton>
                                    ))
                                )}
                            </ButtonContainer>
                            <Para>
                                You have scheduled a session on <br />
                                <Blue>
                                    {selectedDate
                                        ? formattedDate
                                        : "Select date"}{" "}
                                </Blue>
                                from{" "}
                                {timeSlots.length > 0 && selectedTime && (
                                    <Blue>
                                        {selectedTime
                                            ? `${timeSlots?.find(
                                                (item) =>
                                                    item.id ==
                                                    selectedTime
                                            ).start_time
                                            } to `
                                            : "select a time"}
                                        {selectedTime
                                            ? timeSlots.find(
                                                (item) =>
                                                    item.id == selectedTime
                                            ).end_time
                                            : ""}
                                    </Blue>
                                )}
                            </Para>
                        </ItemContainer>
                    </MiddleDiv>
                    {/* Bottom section */}
                    <BottomDiv>
                        {/* Error message */}
                        <ErrorMessage>{error}</ErrorMessage>
                        {/* Previous and Next buttons */}
                        <PreviousButton
                            size="14px"
                            style={{
                                padding: "10px 20px",
                                height: "40px",
                                width: isMobile ? "50%" : "fit-content",
                            }}
                            url="/create-session"
                        />
                        <NextButton
                            size="14px"
                            style={{
                                padding: "10px 20px",
                                height: "40px",
                                width: isMobile ? "50%" : "fit-content",
                            }}
                            onClick={handleSubmit}
                        />
                    </BottomDiv>
                </ContentDiv>
                {isCancelModalOpen && (
                    <CreateSessionCancelModal
                        closeModal={() => handleCancelClick()}
                    />
                )}
            </MainContainer>
            {/* Bottom navigation component */}
            <BottomNavigation />
        </>
    );
}

export default Schedule;
const MainContainer = styled.div`
    width: 100%;
    overflow-y: scroll;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    background: ${THEME_COLORS.light_200};
    padding: 26px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media all and (max-width: 768px) {
        width: 100%;
        padding: 26px 0px 120px;
    }
`;
const HeadingDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 452px;
    margin-bottom: 14px;

    @media all and (max-width: 480px) {
        width: 95%;
    }
`;
const Heading = styled.h4`
    font-size: 18px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    line-height: 26px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const CancelBtn = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 18px;
    background: ${THEME_COLORS.white};
    border-radius: 32px;
    width: 107px;
    cursor: pointer;
`;
const CloseSvg = styled.div``;
const CloseSvgImg = styled.img``;
const Cancel = styled.span`
    font-size: 14px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.chips_green_on_container};
    margin-left: 8px;
`;
const ContentDiv = styled.div`
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_400};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    height: fit-content;
    width: 452px;
    border-radius: 24px;
    padding: 16px;
    /* margin-bottom: 40px; */
    @media all and (max-width: 480px) {
        width: 95%;
    }
`;
const TopDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    margin-bottom: 18px;
`;
const Count = styled.span`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 9px;
    border-radius: 50%;
    border: 1px solid ${THEME_COLORS.chips_green_on_container};
    padding: 3px 6px;
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.white : THEME_COLORS.black};
    background: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.chips_green_on_container : THEME_COLORS.white};
    span:first-child {
        width: 54px;
        padding: 6px;
    }
    @media all and (max-width: 1440px) {
        font-size: 10px;
    }
`;
const Section = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3px;
`;

const Tick = styled.span`
    display: inline-block;
    width: 18px;
`;
const TickImg = styled.img`
    display: block;
    width: 100%;
`;
const Title = styled.label`
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    color: ${THEME_COLORS.text_title};
    color: ${({ isFirst }) =>
        isFirst ? THEME_COLORS.black : THEME_COLORS.text_title};
`;
const MiddleDiv = styled.div`
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 10px;
    padding: 14px;
    margin-bottom: 50px;
    height: fit-content;
`;
const MidHeading = styled.h6`
    font-family: "DM_sans_bold";
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 8px;
`;
const ItemContainer = styled.div`
    position: relative;
    height: fit-content;
`;
const BottomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 14px;
    position: relative;
    align-items: center;
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Contents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${THEME_COLORS.light_200};
    border-radius: 25px;
    margin-bottom: 10px;
    padding: 6px 24px;
    position: relative;
    border: 2px solid transparent;
    height: 50px;

    cursor: pointer;
    &.active {
        border: 2px solid #2b3990;
    }
`;
const Label = styled.label`
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_para};
    font-size: 12px;
    margin-bottom: 0px;
    display: block;
    @media all and (max-width: 1280px) {
        font-size: 15px;
    }
    @media all and (max-width: 420px) {
        font-size: 12px;
    }
`;

const CalenderBox = styled.div``;
const CalenderContainer = styled.div``;
const CalenderIcon = styled.img`
    width: 100%;
    display: block;
    opacity: 0.6;
`;
const GreenDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 14px;
    background: ${THEME_COLORS.chips_green_container};
    padding: 6px 10px;
    border-radius: 6px;
    margin-bottom: 10px;
`;
const GreenDot = styled.span`
    display: inline-block;
    width: 7px;
`;
const Dotimg = styled.img`
    width: 100%;
    display: block;
`;
const GreenPara = styled.p`
    font-size: 11px;
    font-family: "DM_sans";
    width: 88%;
    line-height: 15px;
`;
const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 4px;
    gap: 12px;
    margin-bottom: 18px;
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${THEME_COLORS.light_400};
    }
    @media all and (max-width: 480px) {
        gap: 10px;
    }
`;
const Button = styled.button`
    border: 2px solid ${THEME_COLORS.light_300};
    border-radius: 53px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    position: relative;
    width: 118px;
    cursor: pointer;
    &.active {
        background: ${THEME_COLORS.chips_green_container};
        color: ${THEME_COLORS.chips_green_on_container};
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
    }
    &.not-available {
        background: ${THEME_COLORS.light_200};
        color: ${THEME_COLORS.light_400};
    }
    @media all and (max-width: 480px) {
        width: 110px;
    }
    @media all and (max-width: 450px) {
        width: 100px;
    }
    @media all and (max-width: 420px) {
        width: 120px;
    }
    @media all and (max-width: 360px) {
        width: 110px;
    }
`;

const TimeSlotButton = styled.button`
    border: 2px solid ${THEME_COLORS.light_300};
    border-radius: 53px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    position: relative;
    width: 118px;

    cursor: pointer;
    &.active {
        background: ${THEME_COLORS.chips_green_container};
        color: ${THEME_COLORS.chips_green_on_container};
        border: 2px solid ${THEME_COLORS.chips_green_on_container};
    }
    &.not-available {
        background: ${THEME_COLORS.light_200};
        color: ${THEME_COLORS.light_400};
        cursor: not-allowed;
        opacity: 0.5;
    }
    @media all and (max-width: 480px) {
        width: 110px;
    }
    @media all and (max-width: 450px) {
        width: 100px;
    }
    @media all and (max-width: 420px) {
        width: 120px;
    }
    @media all and (max-width: 360px) {
        width: 110px;
    }
`;
const Btnlabel = styled.a`
    font-size: 14px;
    width: 70px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    display: block;
    &.active {
        color: ${THEME_COLORS.chips_green_on_container};
    }
    &.not-available {
        color: ${THEME_COLORS.light_400};
    }
`;
const TimeSlotBtnlabel = styled.span`
    font-size: 14px;
    width: 70px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    display: block;
    &.active {
        color: ${THEME_COLORS.chips_green_on_container};
    }
    &.not-available {
        color: ${THEME_COLORS.light_400};
    }
    cursor: inherit;
`;
const Para = styled.p`
    font-size: 13px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.black};

    line-height: 18px;
`;
const Blue = styled.span`
    font-size: 13px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.chips_blue_on_container_2};
`;
const ButtonDot = styled.span`
    display: none;
    &.greenbtn {
        display: inline-block;
        position: absolute;
        width: 6px;
        height: 7px;
        right: 5px;
        bottom: 11px;
    }
    &.not-available {
        display: none;
    }

    @media all and (max-width: 1440px) {
        bottom: 7px;
    }
`;
const ErrorMessage = styled.span`
    font-size: 12px;
    position: absolute;
    left: 0;
    top: -25px;
    color: red;
`;
const Mode = styled.span`
    display: block;
    font-size: 16px;
    color: ${THEME_COLORS.text_title};
    font-family: "DM_sans_medium";
    @media all and (max-width: 420px) {
        font-size: 14px;
    }
`;
const DropDown = styled.img`
    width: 20px;
    height: 20px;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : null)};
    transition: 0.1s;
`;
const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${THEME_COLORS.light_200};
    border-radius: 25px;
    margin-bottom: 10px;
    padding: 6px 24px;
    border: 2px solid transparent;
    cursor: pointer;

    &.active {
        border: 2px solid #2b3990;
    }
`;
const DateSelected = styled.div`
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    @media all and (max-width: 420px) {
        font-size: 14px;
    }
`;
const LabelContainer = styled.div``;

import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Context } from "../../../contexts/Store";

export default function PrivateRoute({ children }) {
    const location = useLocation();
    const { state } = useContext(Context);
    const auth = state.user_details.is_verified;

    return auth ? (
        children
    ) : (
        <Navigate
            to={{
                pathname: "/auth/login/",
                search:
                    location.pathname === "/"
                        ? ""
                        : `?next=${location.pathname}`,
            }}
        />
    );
}

import React from "react";

// package
import styled from "styled-components";
import { Link } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// components
import CancelButton from "../../includes/buttons/CancelButton";

export default function FindingTherapist() {
    return (
        <>
            <ContentContainer>
                <Content>
                    <Head>
                        <Heading>Create a Session</Heading>
                        <CancelButton
                            path="/"
                            style={{ padding: "6px 20px" }}
                        />
                    </Head>
                    <ContentBox>
                        <ImageContainer>
                            <Image
                                src={require("../../../assets/images/therapist.png")}
                                alt="Therapist-photo"
                            />
                        </ImageContainer>
                        <HeadText>Finding Therapist...</HeadText>
                        <Description>
                            Wait a lil bit... while we are finding the <br />{" "}
                            best therapist for you.
                        </Description>
                        <NavButtons>
                            <HideButton>Hide</HideButton>
                            <DoneButton to="/session-confirmed">
                                Done
                            </DoneButton>
                        </NavButtons>
                    </ContentBox>
                </Content>
            </ContentContainer>
        </>
    );
}

const ContentContainer = styled.div`
    background: ${THEME_COLORS.light_200};
    width: 100%;
    height: calc(100vh - 70px);
`;
const Content = styled.div`
    width: 452px;
    margin: 0 auto;
    padding-top: 50px;
    max-height: 90vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: 480px) {
        width: 88%;
        padding-top: 20px;
    }
`;

const Head = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media all and (max-width: 480px) {
        margin-bottom: 100px;
    }
`;
const Heading = styled.h3`
    font-size: 20px;
    font-family: "DM_sans";
`;
const ContentBox = styled.div`
    background: white;
    border-radius: 24px;
    border: 1px solid ${THEME_COLORS.light_400};
    padding: 100px;
    @media all and (max-width: 980px) {
        padding: 65px;
    }
    @media all and (max-width: 480px) {
        padding: 60px 30px;
    }
`;
const ImageContainer = styled.div`
    margin-bottom: 50px;
    @media all and (max-width: 480px) {
        width: 95%;
        margin-bottom: 20px;
    }
`;
const Image = styled.img``;
const HeadText = styled.h4`
    font-size: 20px;
    text-align: center;
    font-family: "DM_sans_medium";
    margin-bottom: 20px;
    color: ${THEME_COLORS.text_title};
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
`;
const Description = styled.p`
    font-size: 14px;
    text-align: center;
    font-family: "DM_sans";
    margin-bottom: 50px;
    color: ${THEME_COLORS.text_para};
    @media all and (max-width: 980px) {
        font-size: 12px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 20px;
    }
`;
const NavButtons = styled.div`
    display: flex;
    justify-content: space-between;
`;
const HideButton = styled.button`
    font-size: 14px;
    font-family: "DM_sans_medium";
    border: 2px solid ${THEME_COLORS.light_400};
    border-radius: 32px;
    padding: 10px 20px;
    width: 48%;
    cursor: pointer;
`;
const DoneButton = styled(Link)`
    font-size: 14px;
    font-family: "DM_sans_medium";
    width: 48%;
    display: flex;
    justify-content: center;
    background: ${THEME_COLORS.secondary_gradient};
    border-radius: 32px;
    color: ${THEME_COLORS.white};
    padding: 10px 20px;
    cursor: pointer;
`;

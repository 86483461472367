import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../contexts/Store";

// package
import styled from "styled-components";

// axios
import { PatientConfig } from "../../../axiosConfig";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// media
import nextimg from "../../../assets/images/next.svg";

// Modal
import CreateSessionSuccessModal from "../modals/CreateSessionSuccessModal";
import FindExpertModal from "../modals/FindExpertModal";

// Lottie
import Lottie from "lottie-react";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";
import moment from "moment";

export default function PaymentButton({
    url,
    style,
    setError,
    usageAt,
    selectedPackageId,
    handleFullfillSubmit,
    isModalOpen,
    setIsModalOpen,
    setIsSuccess,
}) {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const new_session = state.new_session;

    let accessToken = state.user_details.access_token;
    // let concern = state.new_session.concern;
    // let description = state.new_session.description;
    // let specialization = state.new_session.expert;
    // let case_date = state.new_session.case_date;
    // let time_slot = state.new_session.time_slot;
    // let session_count = state.new_session.session_count;
    // let plan_type = state.new_session.plan_type;
    // let packageId = selectedPackageId;

    // Modal click
    const handleClick = () => {
        setIsModalOpen((prev) => !prev);
    };

    // Submit function
    const handleSubmit = () => {
        if (!new_session.session_count) {
            setError("Select a count");
        } else if (!new_session.plan_type) {
            setError("Select a plan");
        } else if (!selectedPackageId) {
            setError("Select a package");
        } else {
            setError("");
            setIsLoading(true);
            PatientConfig.post(
                "api/v1/cases/sessions/create-a-session/",
                {
                    concern: new_session.concern,
                    describe: new_session.description,
                    specialization: new_session.expert,
                    case_date: moment(new_session.case_date).format(
                        "YYYY-MM-DD"
                    ),
                    time_slot: new_session.time_slot,
                    package: selectedPackageId,
                    session_count: new_session.session_count,
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "Bearer " + accessToken,
                    },
                }
            )
                .then((response) => {
                    const { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        dispatch({
                            type: "UPDATE_USER",
                            user_details: {
                                ipp: true,
                            },
                        });

                        setTimeout(() => {
                            window.location.href = data.payment_url;
                            setIsLoading(false);
                            dispatch({
                                type: "CANCEL_ADD_SESSION",
                            });
                        }, 100);

                        // setIsModalOpen(true);
                        // setIsSuccess(true);
                    }
                })
                .catch((error) => {
                    // console.error("An error occurred:", error);
                    setIsLoading(false);
                    alert(error.response.data.data.message);
                });
        }
    };

    //   const handleFullfillSubmit = () => {
    //     if (patient == "") {
    //         setError("Select count");
    //     } else if (selectedItemId == "") {
    //         setError("Select a package");
    //     } else {
    //         setIsLoading(true);
    //         PatientConfig.post(
    //             "api/v1/cases/sessions/create-a-session/",
    //             {
    //                 concern: concern,
    //                 describe: describe,
    //                 specialization: specialization,
    //                 case_date: case_date,
    //                 time_slot: time_slot,
    //                 package: packageId,
    //                 session_count: session_count,
    //             },
    //             {
    //                 headers: {
    //                     "Content-Type": "application/x-www-form-urlencoded",
    //                     Authorization: "Bearer " + accessToken,
    //                 },
    //             }
    //         )
    //             .then((response) => {
    //                 setIsLoading(false);
    //                 setIsModalOpen(true);
    //             })
    //             .catch((error) => {
    //                 console.error("An error occurred:", error);
    //             });
    //     }
    // };

    return (
        <>
            <PaymentBtn
                onClick={() => {
                    if (!isLoading) {
                        handleSubmit();
                    }
                    // else {
                    //     // console.log("Loading, please wait...");
                    // }
                }}
                isLoading={isLoading}
                style={style}
            >
                <Payment>
                    Proceed <Span> to Payment</Span>
                </Payment>
                <PaymentSvg>
                    <PaymentSvgImg src={nextimg} alt="Logo title" />
                </PaymentSvg>
            </PaymentBtn>

            {isLoading
                ? // <Lottie
                  //     animationData={LoaderLottie}
                  //     style={{
                  //         width: 150,
                  //         margin: "auto",
                  //         position: "absolute",
                  //         top: 0,
                  //         bottom: 0,
                  //         right: 0,
                  //         left: 0,
                  //     }}
                  // />
                  null
                : isModalOpen && (
                      <FindExpertModal closeModal={() => handleClick()} />
                  )}
        </>
    );
}
const Span = styled.span`
    display: inline-block;
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const PaymentBtn = styled.div`
    cursor: ${(props) => (props.isLoading ? "progress" : "pointer")};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 20px;
    background: ${THEME_COLORS.secondary_gradient};
    color: ${THEME_COLORS.white};
    padding: 10px 20px;
    width: 180px;
    height: 40px;
    @media all and (max-width: 480px) {
        width: 140px;
    }
`;
const PaymentSvg = styled.span`
    display: inline-block;
    width: 6px;
`;
const PaymentSvgImg = styled.img`
    width: 100%;
    display: block;
`;
const Payment = styled.small`
    display: inline-block;
    font-family: "DM_sans_medium";
    font-size: 13px;
    line-height: 20px;
`;

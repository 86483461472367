import React from "react";
import styled from "styled-components";

import { THEME_COLORS } from "../../../../ThemeConfig";

import bell from "../../../../assets/icons/bell_black.svg";

const NotificationCard = ({ data, large }) => {
    return (
        <Card key={data.id} isRead={data.read} large={large}>
            <Left large={large}>
                <ImageBox>
                    <img src={bell} alt="bell icon" />
                </ImageBox>
            </Left>
            <Right>
                <RightTop>
                    <Title large={large} isRead={data.isread}>
                        {data.title}
                    </Title>
                </RightTop>
                <Content large={large} isRead={data.isread}>
                    {data?.content?.length > 96
                        ? `${data.message.substring(0, 96)}...`
                        : data.message}
                </Content>
                <Time>{data.date}</Time>
            </Right>
        </Card>
    );
};

export default NotificationCard;

const Card = styled.div`
    width: 100%;
    background: ${(props) => !props.isRead && "#E0F8F2"};
    border: ${(props) =>
        props.isRead
            ? `1px solid  ${THEME_COLORS.light_200}`
            : `1px solid ${THEME_COLORS.divider}`};
    /* border-bottom: ${(props) =>
        !props.isRead && `1px solid ${THEME_COLORS.divider}`}; */
    padding: ${(props) => (props.large ? "12px" : "8px")};

    margin-bottom: ${(props) => (props.large ? "12px" : "6px")};

    display: flex;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const Left = styled.div`
    margin-right: ${(props) => (props.large ? "12px" : "10px")};
`;
const ImageBox = styled.div`
    width: 36px;
    height: 36px;
    background: ${THEME_COLORS.white};
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    align-self: stretch;
`;
const Right = styled.div`
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
`;
const RightTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
`;
const Title = styled.h5`
    font-family: "DM_sans_medium";

    font-size: ${(props) => (props.large ? "18px" : "14px")};
    /* color: ${(props) =>
        props.isRead ? THEME_COLORS.text_title : THEME_COLORS.black}; */
    color: #3e3e53;
    @media screen and (max-width: 540px) {
        font-size: ${(props) => (props.large ? "16px" : "14px")};
    }
`;
const Content = styled.p`
    font-family: "DM_sans";
    color: #3e3e53;

    font-size: ${(props) => (props.large ? "14px" : "12px")};
    /* color: ${(props) =>
        props.isRead ? THEME_COLORS.text_para : THEME_COLORS.black}; */
    margin-bottom: 4px;
    @media screen and (max-width: 540px) {
        font-size: ${(props) => (props.large ? "14px" : "12px")};
    }
`;
const Time = styled.p`
    font-weight: 400;
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
    margin: 0 0 0 auto;
`;

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// Styles //
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// store
import { Context } from "../../../contexts/Store";

// media
import profileIcon from "../../../assets/icons/dr-profile.svg";
import menuIcon2 from "../../../assets/icons/menu-icon2.svg";
import upcomingIcon from "../../../assets/icons/session-upcoming-icon.svg";
import completedIcon from "../../../assets/icons/session-completed-icon.svg";
import cancelledIcon from "../../../assets/icons/session-cancelled-icon.svg";
import pendingIcon from "../../../assets/icons/pending.svg";
import calendarIcon from "../../../assets/icons/session-calendar-icon.svg";
import clockIcon from "../../../assets/icons/session-clock-icon.svg";
import callIcon from "../../../assets/icons/session-call-icon.svg";
import meetIcon from "../../../assets/icons/session-meet-icon.svg";
import leftArrowIcon from "../../../assets/icons/leftArrowGrey.svg";
import reviewIcon from "../../../assets/icons/no_review.svg";
import emergency from "../../../assets/icons/emergency-call.svg";
import pendingExpert from "../../../assets/icons/expert-pending.svg";
import profile from "../../../assets/icons/profile-picture.svg";
import greenStar from "../../../assets/images/star-green.svg";

// modals
import SessionProgress from "../modals/SessionProgress";
import UpcomingTimerComplete from "../modals/UpcomingTimerComplete";
import SessionCardMenu from "../modals/SessionCardMenu";
import FeedBackSuccessModal from "../modals/FeedBackSuccessModal";

// axios
import { PatientConfig } from "../../../axiosConfig";

// Lottie
import Lottie from "lottie-react";
import LoaderLottie from "../../../assets/lottie/LoaderLottie.json";
import BottomNavigation from "../../general/BottomNavigation";

function SessionIndividualPage() {
    const [sessionDetail, setSessionDetail] = useState("");
    const [feedback, setFeedback] = useState("");
    const [selectedTab, setSelectedTab] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hours, setHours] = useState();
    const [minutes, setMinutes] = useState();
    const [seconds, setSeconds] = useState();
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const { state } = useContext(Context);

    const [isSessionOngoing, setIsSessionOngoing] = useState(false);
    const [countdown, setCountdown] = useState(
        sessionDetail?.meet_time_stamp?.pending_time_for_next_session
    );

    let accessToken = state.user_details.access_token;

    const slug = useParams();

    const getSessionDetail = () => {
        setIsLoading(true);
        PatientConfig.get(`/api/v1/cases/sessions/single-session/${slug.id}`, {
            headers: {
                Authorization: `Bearer  ${accessToken}`,
            },
        })
            .then((response) => {
                setSessionDetail(response.data.data.data);
                setSelectedTab(
                    response.data.data.data.session_record_status.toLowerCase()
                );

                setFeedback(response.data.data.session_review);
                setIsLoading(false);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getSessionDetail();
    }, []);

    const meeting_date = sessionDetail.event_date;
    const meetingDateTime = new Date(meeting_date);

    // // Calculate the time difference in milliseconds
    const timeDifference = meetingDateTime - new Date();

    // Convert the time difference to hours, minutes, and seconds
    const initialHours = Math.floor(timeDifference / (1000 * 60 * 60));

    const initialMinutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    const initialSeconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // Menu click
    const handleMenuClick = () => {
        setIsModalOpen((prev) => !prev);
    };

    useEffect(() => {
        setHours(timeDifference > 0 ? initialHours : 0);
        setMinutes(timeDifference > 0 ? initialMinutes : 0);
        setSeconds(timeDifference > 0 ? initialSeconds : 0);
    }, [timeDifference]);

    useEffect(() => {
        const timer = setTimeout(() => {
            // if (timeDifference > 0) {
            if (hours === 0 && minutes === 0 && seconds === 0) {
                // Timer has reached 0, you can add your logic here
            } else if (minutes === 0 && seconds === 0) {
                setHours(hours - 1);
                setMinutes(59);
                setSeconds(59);
            } else if (seconds === 0) {
                setMinutes(minutes - 1);
                setSeconds(59);
            } else {
                setSeconds(seconds - 1);
            }
            // }
        }, 1000);

        // Cleanup the timer on unmount
        return () => clearTimeout(timer);
    }, [hours, minutes, seconds]);

    const handleFeedbackSuccessModal = () => {
        setIsSuccessModalOpen((prev) => !prev);
    };

    // useEffect(() => {
    //     // Check if hours is not null before starting the countdown
    //     if (
    //         sessionDetail.pending_time_for_next_session !== null &&
    //         countdown !== null
    //     ) {
    //         const interval = setInterval(() => {
    //             // Decrement the seconds
    //             setCountdown((prevCountdown) => {
    //                 const { hours, minutes, seconds } = prevCountdown;
    //                 if (hours === 0 && minutes < 2) {
    //                     // Timer reached zero, set the state to true
    //                     // setTimerZero(true);
    //                     // console.log(timerZero, 'Timer')
    //                     setIsSessionOngoing(true);
    //                     clearInterval(interval);
    //                     return prevCountdown;
    //                 }

    //                 let newSeconds = seconds - 1;
    //                 let newMinutes = minutes;
    //                 let newHours = hours;

    //                 if (newSeconds < 0) {
    //                     newSeconds = 59;
    //                     newMinutes -= 1;
    //                 }
    //                 if (newMinutes < 0) {
    //                     newMinutes = 59;
    //                     newHours -= 1;
    //                 }

    //                 return {
    //                     hours: newHours,
    //                     minutes: newMinutes,
    //                     seconds: newSeconds,
    //                 };
    //             });
    //         }, 1000);

    //         // Cleanup function to clear the interval when the component unmounts
    //         return () => clearInterval(interval);
    //     }
    // }, [sessionDetail, countdown]);

    useEffect(() => {
        if (sessionDetail.is_ongoing) {
            setIsSessionOngoing(true);
        }
    }, [sessionDetail]);

    return (
        <>
            <MainContainer>
                <SubContainer>
                    {isLoading ? (
                        <Lottie
                            animationData={LoaderLottie}
                            style={{
                                width: 150,
                                margin: "auto",
                            }}
                        />
                    ) : (
                        <>
                            <BackButtonContainer to="/">
                                <LeftArrowContainer>
                                    <LeftArrow src={leftArrowIcon} alt="Icon" />
                                </LeftArrowContainer>
                                <BackButton>Back to Dashboard</BackButton>
                            </BackButtonContainer>

                            <MainBox selectedTab={selectedTab}>
                                <WhiteBox>
                                    {sessionDetail?.session_record_status?.toLowerCase() ===
                                        "upcoming" && (
                                        <>
                                            {isSessionOngoing ? (
                                                <MainBottomPart
                                                    className={sessionDetail?.session_record_status?.toLowerCase()}
                                                >
                                                    <UpcomingTimerComplete
                                                        meet_link={
                                                            sessionDetail.meet_link
                                                        }
                                                        timeData={
                                                            sessionDetail
                                                                ?.meet_time_stamp
                                                                ?.pending_time_for_next_session
                                                        }
                                                        setIsSessionOngoing={
                                                            setIsSessionOngoing
                                                        }
                                                        isSessionOngoing={
                                                            isSessionOngoing
                                                        }
                                                        countdown={countdown}
                                                        setCountdown={
                                                            setCountdown
                                                        }
                                                    />
                                                </MainBottomPart>
                                            ) : (
                                                <>
                                                    {/* {sessionDetail
                                                        ?.meet_time_stamp
                                                        ?.pending_time_for_next_session && (
                                                        <MainBottomPart
                                                            className={sessionDetail?.session_record_status?.toLowerCase()}
                                                        >
                                                            <SessionProgress
                                                                isSessionOngoing={
                                                                    isSessionOngoing
                                                                }
                                                                setIsSessionOngoing={
                                                                    setIsSessionOngoing
                                                                }
                                                                countdown={
                                                                    countdown
                                                                }
                                                                setCountdown={
                                                                    setCountdown
                                                                }
                                                                summary_report={
                                                                    sessionDetail.summary_report
                                                                }
                                                                selectedTab={sessionDetail.session_record_status.toLowerCase()}
                                                                slug={
                                                                    sessionDetail.slug
                                                                }
                                                                hours={
                                                                    sessionDetail
                                                                        ?.meet_time_stamp
                                                                        ?.pending_time_for_next_session
                                                                        ?.hours
                                                                }
                                                                minutes={
                                                                    sessionDetail
                                                                        ?.meet_time_stamp
                                                                        ?.pending_time_for_next_session
                                                                        ?.minutes
                                                                }
                                                                seconds={
                                                                    sessionDetail
                                                                        ?.meet_time_stamp
                                                                        ?.pending_time_for_next_session
                                                                        ?.seconds
                                                                }
                                                                timeData={
                                                                    sessionDetail
                                                                        ?.meet_time_stamp
                                                                        ?.pending_time_for_next_session
                                                                }
                                                            />
                                                        </MainBottomPart>
                                                    )} */}
                                                </>
                                            )}
                                        </>
                                    )}

                                    {/* <MainBottomPart
                                        className={
                                            selectedTab === "upcoming" &&
                                            timeDifference > 86400000
                                                ? "not-active" || selectedTab
                                                : "" || selectedTab
                                        }
                                    >
                                        {selectedTab === "upcoming" &&
                                        meeting_date &&
                                        seconds == 0 &&
                                        minutes == 0 &&
                                        hours == 0 ? (
                                            <UpcomingTimerComplete
                                                meet_link={
                                                    sessionDetail.meet_link
                                                }
                                            />
                                        ) : (
                                            <SessionProgress
                                                summary_report={
                                                    sessionDetail.summary_report
                                                }
                                                selectedTab={selectedTab}
                                                slug={slug.id}
                                                hours={hours}
                                                minutes={minutes}
                                                seconds={seconds}
                                            />
                                        )}
                                    </MainBottomPart> */}

                                    <FirstRow>
                                        <FirstRowLeft>
                                            <ProfilePic
                                                src={
                                                    sessionDetail?.session_record_status?.toLowerCase() ==
                                                    "pending"
                                                        ? pendingExpert
                                                        : sessionDetail.expert_pic
                                                        ? sessionDetail.expert_pic
                                                        : profileIcon
                                                }
                                                alt="icon"
                                            />
                                            <FirstMiddleDiv>
                                                <NameLine>
                                                    <NameText>
                                                        {sessionDetail?.session_record_status?.toLowerCase() ==
                                                        "pending"
                                                            ? "Awaiting Expert"
                                                            : sessionDetail.expert_name}
                                                    </NameText>
                                                    <SessionChip>
                                                        {
                                                            sessionDetail.current_session_number
                                                        }{" "}
                                                        of{" "}
                                                        {
                                                            sessionDetail.total_sessions
                                                        }{" "}
                                                        Session
                                                    </SessionChip>
                                                    {sessionDetail.is_emergency ? (
                                                        <EmergencyContainer>
                                                            <EmergencyIconContainer>
                                                                <EmergencyIcon
                                                                    src={
                                                                        emergency
                                                                    }
                                                                    alt="Icon"
                                                                />
                                                            </EmergencyIconContainer>
                                                            <EmergencyText>
                                                                Emergency
                                                            </EmergencyText>
                                                        </EmergencyContainer>
                                                    ) : (
                                                        ""
                                                    )}
                                                </NameLine>
                                                <SpecializationText>
                                                    {
                                                        sessionDetail.specialization
                                                    }
                                                </SpecializationText>
                                            </FirstMiddleDiv>
                                        </FirstRowLeft>
                                        <FirstRowRight>
                                            {selectedTab == "upcoming" ||
                                            selectedTab == "pending" ||
                                            selectedTab == "completed" ? (
                                                <MenuIcon
                                                    onClick={() => {
                                                        handleMenuClick();
                                                    }}
                                                    src={menuIcon2}
                                                    alt="icon"
                                                />
                                            ) : (
                                                ""
                                            )}
                                            {isModalOpen && (
                                                <SessionCardMenu
                                                    className="cancel_modal"
                                                    id={slug.id}
                                                    selectedTab={selectedTab}
                                                    closeModal={() =>
                                                        handleMenuClick()
                                                    }
                                                    setIsModalOpen={
                                                        setIsModalOpen
                                                    }
                                                    setIsSuccessModalOpen={
                                                        setIsSuccessModalOpen
                                                    }
                                                    handleCloseMenuPopover={
                                                        handleMenuClick
                                                    }
                                                    usedAt="individual_page"
                                                />
                                            )}
                                        </FirstRowRight>
                                    </FirstRow>
                                    {/* {isModalOpen && (
                                        <SessionCardMenu
                                            className="cancel_modal"
                                            id={slug.id}
                                            selectedTab={selectedTab}
                                            closeModal={() => handleMenuClick()}
                                            setIsModalOpen={setIsModalOpen}
                                            setIsSuccessModalOpen={
                                                setIsSuccessModalOpen
                                            }
                                            handleCloseMenuPopover={
                                                handleMenuClick
                                            }
                                        />
                                    )} */}
                                    <SecondRow>
                                        <SubTitle>
                                            {sessionDetail.concern}
                                        </SubTitle>
                                        <SecondRowPara>
                                            {sessionDetail.concern_describe}
                                        </SecondRowPara>
                                        <CaseIdText>
                                            Case ID : #
                                            {sessionDetail.session_record_id}
                                        </CaseIdText>
                                        <br />
                                        {sessionDetail.describe ? (
                                            <>
                                                <DescriptionTitle>
                                                    Description
                                                </DescriptionTitle>
                                                <Description>
                                                    {sessionDetail.describe}
                                                </Description>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </SecondRow>
                                    <ThirdRow>
                                        <ThirdRowLeft>
                                            <SessionCount>
                                                Session{" "}
                                                {
                                                    sessionDetail.current_session_number
                                                }
                                            </SessionCount>
                                        </ThirdRowLeft>
                                        <ThirdRowRight>
                                            <StatusChip
                                                selectedTab={selectedTab}
                                            >
                                                <ChipIcon
                                                    src={
                                                        selectedTab ==
                                                        "upcoming"
                                                            ? upcomingIcon
                                                            : selectedTab ==
                                                              "completed"
                                                            ? completedIcon
                                                            : selectedTab ==
                                                              "cancelled"
                                                            ? cancelledIcon
                                                            : pendingIcon
                                                    }
                                                    alt="icon"
                                                />
                                                <StatusChipText
                                                    selectedTab={selectedTab}
                                                >
                                                    {selectedTab}
                                                </StatusChipText>
                                            </StatusChip>
                                            <GreyChip>
                                                <ChipIcon
                                                    src={calendarIcon}
                                                    alt="icon"
                                                />
                                                <ChipText>
                                                    {sessionDetail.case_date}
                                                </ChipText>
                                            </GreyChip>
                                            <GreyChip>
                                                <ChipIcon
                                                    src={clockIcon}
                                                    alt="icon"
                                                />
                                                <ChipText>
                                                    {sessionDetail.start_time} -{" "}
                                                    {sessionDetail.end_time}
                                                </ChipText>
                                            </GreyChip>
                                            <GreyChip className="green_bg">
                                                <ChipIcon
                                                    src={
                                                        sessionDetail.meet_mode ==
                                                        "Video"
                                                            ? meetIcon
                                                            : callIcon
                                                    }
                                                    alt="icon"
                                                />
                                            </GreyChip>
                                        </ThirdRowRight>
                                    </ThirdRow>
                                </WhiteBox>
                            </MainBox>

                            <BottomContainer>
                                <BottomHeading>Session Review</BottomHeading>
                                {feedback.rating ? (
                                    <ReviewContainer className="feedback">
                                        <ProfileContainer>
                                            <ProfileIconContainer>
                                                <ProfileIcon
                                                    src={
                                                        feedback?.profile_pic
                                                            ? feedback.profile_pic
                                                            : profile
                                                    }
                                                    alt="Profile Icon"
                                                />
                                            </ProfileIconContainer>
                                            <PatientName>
                                                {feedback.full_name}
                                            </PatientName>
                                        </ProfileContainer>
                                        <RatingContainer>
                                            <RatingStarContainer>
                                                {Array.from(
                                                    { length: feedback.rating },
                                                    (_, index) => (
                                                        <RatingStar
                                                            key={index}
                                                            src={greenStar}
                                                            alt="Star Icon"
                                                        />
                                                    )
                                                )}
                                            </RatingStarContainer>
                                            <DateAdded>
                                                {feedback.date_added}
                                            </DateAdded>
                                        </RatingContainer>
                                        <Feedback>{feedback.review}</Feedback>
                                    </ReviewContainer>
                                ) : (
                                    <ReviewContainer>
                                        <ReviewIconContainer>
                                            <ReviewIcon
                                                src={reviewIcon}
                                                alt="Icon"
                                            />
                                        </ReviewIconContainer>
                                        <ReviewText>
                                            No reviews added yet
                                        </ReviewText>
                                    </ReviewContainer>
                                )}
                            </BottomContainer>
                        </>
                    )}
                </SubContainer>

                {isSuccessModalOpen && (
                    <FeedBackSuccessModal
                        closeModal={() => handleFeedbackSuccessModal()}
                    />
                )}
            </MainContainer>
            <BottomNavigation />
        </>
    );
}

export default SessionIndividualPage;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    padding: 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    scrollbar-width: none;
    @media all and (max-width: 768px) {
        width: 100%;
        overflow-y: auto;
        padding: 50px 0px 150px;
    }
    @media all and (max-width: 480px) {
        padding-bottom: 90px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
`;
const SubContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    @media all and (max-width: 1080px) {
        width: 80%;
    }
    @media all and (max-width: 980px) {
        width: 90%;
    }
    @media all and (max-width: 768px) {
        width: 85%;
    }
    @media all and (max-width: 680px) {
        width: 90%;
    }

    @media all and (max-width: 640px) {
        width: 93%;
    }
    @media all and (max-width: 480px) {
        width: 90%;
    }
`;
const BackButtonContainer = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    background: #fff;
    padding: 6px 18px;
    border: 2px solid #e2e2e2;
    border-radius: 20px;
    cursor: pointer;
`;
const LeftArrowContainer = styled.div`
    width: 7px;
`;
const LeftArrow = styled.img``;
const BackButton = styled.span`
    color: #333;
    text-align: center;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const MainBox = styled.div`
    border-radius: 12px;
    padding: 2px;
    width: 100%;
    margin-bottom: 22px;
    color: ${(props) =>
        props.selectedTab == "upcoming"
            ? THEME_COLORS.chips_green_on_container
            : props.selectedTab == "completed"
            ? THEME_COLORS.chips_blue_on_container_2
            : props.selectedTab == "cancelled"
            ? THEME_COLORS.chips_red_on_container
            : null};
    &.active {
        display: block;
    }
    @media all and (max-width: 1080px) {
        display: block;
    }
`;
const WhiteBox = styled.div`
    position: relative;
    padding: 24px;
    background: ${THEME_COLORS.white};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    border-radius: 10px;
`;
const FirstRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ProfilePic = styled.img`
    width: 36px;
    height: 36px;
    display: block;
    object-fit: cover;
`;
const FirstRowLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;
const FirstMiddleDiv = styled.div`
    margin-left: 16px;
`;
const MenuIcon = styled.img`
    width: 20px;
    height: 20px;
    display: block;
    object-fit: cover;
    cursor: pointer;
`;
const NameLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;

    @media all and (max-width: 480px) {
        flex-wrap: wrap;
    }
`;
const NameText = styled.h6`
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: ${THEME_COLORS.secondary};
`;
const SessionChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    background: ${THEME_COLORS.chips_blue_container};
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-family: "DM_sans";
    font-size: 12px;
`;
const EmergencyContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 4px 8px 4px 3px;
    align-items: center;
    border-radius: 4px;
    background: #ffe6d7;
    gap: 8px;
`;
const EmergencyIconContainer = styled.div`
    width: 16px;
    height: 16px;
    padding: 2px;
`;
const EmergencyIcon = styled.img``;
const EmergencyText = styled.span`
    color: #f28b4d;
    font-family: "DM_sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const CaseIdText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.text_para};
`;
const DescriptionTitle = styled.span`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: block;
    margin-top: 16px;
`;
const Description = styled.p`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 15px;
`;
const SecondRow = styled.div`
    margin-top: 12px;
`;
const SubTitle = styled.h6`
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    font-size: 16px;
`;
const SecondRowPara = styled.span`
    display: inline-block;
    font-size: 14px;
    font-family: "DM_sans";
    color: ${THEME_COLORS.text_para};
    margin-top: 2px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 2; /* Change the number of lines as needed */
    text-overflow: ellipsis;
`;

const SpecializationText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 13px;
    color: ${THEME_COLORS.text_para};
`;
const ThirdRow = styled.div`
    border: 1px solid #e2e2e2;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    margin-top: 12px;
    @media all and (max-width: 640px) {
        justify-content: space-between;
    }

    @media all and (max-width: 880px) {
        flex-direction: column;
        align-items: flex-start;
    }
    @media all and (max-width: 768px) {
        flex-direction: row;
        align-items: center;
    }

    @media all and (max-width: 610px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media all and (max-width: 540px) {
        flex-direction: row;
        align-items: center;
    }
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
    }
`;
const ThirdRowLeft = styled.div`
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const SessionCount = styled.h5`
    color: #3e3e53;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.02cm;
    @media all and (max-width: 640px) {
        font-size: 13px;
    }
`;
const ThirdRowRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    @media all and (max-width: 540px) {
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        justify-content: flex-start;
    }
`;
const StatusChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    height: 24px;
    box-sizing: border-box;
    background: ${(props) =>
        props.selectedTab == "upcoming"
            ? THEME_COLORS.chips_green_container
            : props.selectedTab == "completed"
            ? THEME_COLORS.chips_blue_container
            : props.selectedTab == "cancelled"
            ? THEME_COLORS.chips_red_container
            : THEME_COLORS.chips_orange_container};
    border-radius: 4px;
`;
const GreyChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    background: ${THEME_COLORS.light_200};
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
    &.green_bg {
        background: #e0f8f2;
    }
`;
const ChipIcon = styled.img`
    width: auto;
`;
const StatusChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    text-transform: capitalize;
    color: ${(props) =>
        props.selectedTab == "upcoming"
            ? THEME_COLORS.chips_green_on_container
            : props.selectedTab == "completed"
            ? THEME_COLORS.chips_blue_on_container_2
            : props.selectedTab == "cancelled"
            ? THEME_COLORS.chips_red_on_container
            : THEME_COLORS.chips_orange_on_container};
`;
const ChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.dark_300};
`;

const MainBottomPart = styled.div`
    padding: 12px 12px;
    border-radius: 12px;
    margin-bottom: 20px;
    &.upcoming {
        background: ${THEME_COLORS.secondary};
    }
    &.pending {
        background: ${THEME_COLORS.secondary};
    }
    &.completed {
        background: ${THEME_COLORS.chips_green_on_container};
    }
    &.cancelled {
        background: ${THEME_COLORS.chips_red_on_container};
    }
    &.not-active {
        display: none;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 16px;
`;
const BottomHeading = styled.div`
    color: #3e3e53;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
const BottomSubHeading = styled.div`
    border-radius: 53px;
    border: 2px solid #366ee3;
    background: #fff;
    padding: 6px 16px;
    width: fit-content;
    color: #3e3e53;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
`;
const ReviewContainer = styled.div`
    display: flex;
    padding: 40px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 10px;
    border: 1px dashed #d3d3d3;
    background: #fff;
    &.feedback {
        padding: 24px;
        align-items: flex-start;
        gap: 12px;
        border: 1px solid #82dd55;
        box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.04);
    }
`;
const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
const ProfileIconContainer = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 24px;
`;
const ProfileIcon = styled.img``;
const PatientName = styled.span`
    color: #2b3990;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
`;
const RatingContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;
const RatingStarContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 2px;
`;
const RatingStar = styled.img`
    width: 17px;
    height: 16px;
`;
const DateAdded = styled.span`
    color: #969696;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const Feedback = styled.span`
    color: #3e3e53;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
`;

const ReviewIconContainer = styled.div``;
const ReviewIcon = styled.img``;
const ReviewText = styled.p`
    color: #818181;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
`;
const FirstRowRight = styled.div`
    position: relative;
`;

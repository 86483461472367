import React, { useEffect, useRef, useState } from "react";

// styles
import { styled } from "styled-components";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

export default function LanguageModal({
    onClose,
    setGender,
    gender,
    setError,
    setIsGenderInputFocused,
    genderPopoverButtonRef,
    setSelelctedLanguage,
    selectedLanguage
}) {
    const wrapperRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (
                wrapperRef.current &&
                !wrapperRef.current.contains(event.target)
            ) {
                onClose();
                setIsGenderInputFocused(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, onClose]);
    return (
        // <Modal
        // ref={wrapperRef}
        // >
        <ModalContent width={genderPopoverButtonRef.current.clientWidth}>
            {
                gender?.map((item) => (
                    <Option
                        onClick={() => {
                            setError((prev) => ({
                                ...prev,
                                gender: "",
                            }));
                            setSelelctedLanguage(item);
                            onClose();
                            setIsGenderInputFocused(false);
                        }}
                    >
                        {item.name}
                    </Option>
                ))
            }


        </ModalContent>
        // </Modal>
    );
}
const Modal = styled.div`
    position: absolute;
    border-radius: 10px;
    background: ${THEME_COLORS.white};
    width: 170px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    right: 29px;
    bottom: 220px;
    @media screen and (max-width: 1440px) {
        bottom: 382px;
    }
    @media screen and (max-width: 1380px) {
        bottom: 382px;
    }
    @media screen and (max-width: 1280px) {
        bottom: 380px;
    }
    @media screen and (max-width: 1200px) {
        bottom: 345px;
    }
    @media screen and (max-width: 1180px) {
        bottom: 340px;
    }
    @media screen and (max-width: 1125px) {
        bottom: 280px;
    }
    @media screen and (max-width: 1100px) {
        bottom: 280px;
    }
    @media screen and (max-width: 1080px) {
        bottom: 290px;
    }
    @media screen and (max-width: 1024px) {
        bottom: 345px;
    }
    @media screen and (max-width: 980px) {
        bottom: 210px;
    }
    @media screen and (max-width: 768px) {
        bottom: 145px;
    }
    @media screen and (max-width: 640px) {
        bottom: 145px;
    }
    @media screen and (max-width: 480px) {
        bottom: 140px;
    }
    @media screen and (max-width: 360px) {
        bottom: 140px;
    }
`;
const ModalContent = styled.div`
    padding: 10px;
    /* width: 100%; */
    /* width: 170px; */
    width: ${(props) =>
        props.width
            ? `${props.width}px`
            : "auto"}; /* @media screen and (max-width: 1080px) {
        width: 150px;
    }
    @media screen and (max-width: 840px) {
        width: 153px;
    }
    @media screen and (max-width: 768px) {
        width: 135px;
    }
    @media screen and (max-width: 640px) {
        width: 135px;
    }
    @media screen and (max-width: 420px) {
        width: 169px;
    } */
`;
const Option = styled.div`
    font-size: 14px;
    font-family: "DM_sans";
    margin-bottom: 5px;
    padding: 8px 10px;
    cursor: pointer;
    &:hover {
        background: ${THEME_COLORS.light_200};
        border-radius: 12px;
    }
`;

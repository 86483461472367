import React, { useContext, useEffect, useState } from "react";

// Styles
import styled from "styled-components";

// colors
import { THEME_COLORS } from "../../../../ThemeConfig";

// components
import NextButton from "../../../includes/buttons/NextButton";
import CancelButton from "../../../includes/buttons/CancelButton";
import BottomNavigation from "../../../general/BottomNavigation";

// icons
import warning from "../../../../assets/icons/alert-blue.svg";
import calendarIcon from "../../../../assets/icons/session-calendar-icon.svg";
import callIcon from "../../../../assets/icons/session-call-icon.svg";
import clockIcon from "../../../../assets/icons/session-clock-icon.svg";
import meetIcon from "../../../../assets/icons/session-meet-icon.svg";

// modals
import ConfirmCancellationModal from "../../../includes/modals/ConfirmCancellationModal";

// Lottie
import Lottie from "lottie-react";
import LoaderLottie from "../../../../assets/lottie/LoaderLottie.json";

// store
import { Context } from "../../../../contexts/Store";

// axios
import { PatientConfig } from "../../../../axiosConfig";
import { useParams } from "react-router-dom";

function CancelSession() {
    // State variables
    const [checked, setChecked] = useState(""); // Selected option
    const [error, setError] = useState(false); // Error message flag
    const [value, setValue] = useState("");
    const [descriptionError, setDescriptionError] = useState(false);
    const [isFocus, setIsFocus] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sessionDetial, setSessionDetial] = useState("");
    const [cancelReason, setCancelReason] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { state } = useContext(Context);

    let accessToken = state.user_details.access_token;

    const slug = useParams();

    const getSessionDetail = () => {
        setIsLoading(true);
        PatientConfig.get(`/api/v1/cases/sessions-cancellation/${slug.id}`, {
            headers: {
                Authorization: `Bearer  ${accessToken}`,
            },
        })
            .then((response) => {
                setSessionDetial(response.data.data.data.session_detail);
                setCancelReason(
                    response.data.data.data.session_detail.cancellation_reasons
                );
                setIsLoading(false);
            })
            .catch((error) => {
                // console.error("Error fetching data:", error);
            });
    };

    useEffect(() => {
        getSessionDetail();
    }, []);

    // Handle checkbox change
    const handleCheckboxChange = (event) => {
        setChecked(event.target.value);
        setError(false);
        setDescriptionError(false);
    };

    // navigation,dispatch data to store
    const handleSubmit = () => {
        if (checked === "") {
            setError(true);
        } else if (checked === "others" && value === "") {
            setDescriptionError(true);
        } else {
            setError(false);
            setDescriptionError(false);
            setIsModalOpen((prev) => !prev);
        }
    };

    // mobile screen
    const isMobile = window.innerWidth <= 480;

    // Render each option
    const renderBox = cancelReason.map((reason) => {
        return (
            <ItemDiv
                //   className={isdown === reason.name && "active"}
                key={reason.id}
            >
                <ItemContent key={reason.id}>
                    <LeftContainer>
                        <RadioBtn>
                            <input
                                type="radio"
                                name="session"
                                value={reason.id}
                                // checked={checked === reason.name}
                                onChange={handleCheckboxChange}
                            />
                        </RadioBtn>
                        <Label>{reason.title}</Label>
                    </LeftContainer>
                </ItemContent>
            </ItemDiv>
        );
    });

    return (
        <>
            <MainContainer>
                {isLoading ? (
                    <Lottie
                        animationData={LoaderLottie}
                        style={{
                            width: 150,
                            margin: "auto",
                        }}
                    />
                ) : (
                    <>
                        <HeadingDiv>
                            <Heading>Cancel session</Heading>
                            <CancelButton
                                path="/"
                                style={{ padding: "6px 20px" }}
                            />
                        </HeadingDiv>
                        <ContentsDiv>
                            <ContentDiv>
                                <TopContainer>
                                    <TopUpContainer>
                                        <Title>{sessionDetial.concern}</Title>
                                        <SessionChip>
                                            {
                                                sessionDetial.current_session_number
                                            }{" "}
                                            of {sessionDetial.total_sessions}{" "}
                                            Session
                                        </SessionChip>
                                    </TopUpContainer>
                                    <TopDownContainer>
                                        <GreyChip>
                                            <ChipIcon
                                                src={calendarIcon}
                                                alt="icon"
                                            />
                                            <ChipText>
                                                {sessionDetial.case_date}
                                            </ChipText>
                                        </GreyChip>
                                        <GreyChip>
                                            <ChipIcon
                                                src={clockIcon}
                                                alt="icon"
                                            />
                                            <ChipText>
                                                {sessionDetial.start_time} -{" "}
                                                {sessionDetial.end_time}
                                            </ChipText>
                                        </GreyChip>
                                        <GreyChip>
                                            <ChipIcon
                                                src={
                                                    sessionDetial.meet_mode ==
                                                    "Video"
                                                        ? meetIcon
                                                        : callIcon
                                                }
                                                alt="icon"
                                            />
                                        </GreyChip>
                                    </TopDownContainer>
                                </TopContainer>
                                <MidHeading>Reason for cancellation</MidHeading>
                                <ItemContainer>{renderBox}</ItemContainer>
                                {/* <OtherOption>
                                    <ItemDiv>
                                        <ItemContent>
                                            <LeftContainer>
                                                <RadioBtn>
                                                    <input
                                                        type="radio"
                                                        name="session"
                                                        value="others"
                                                        // checked={checked === box.label}
                                                        onChange={
                                                            handleCheckboxChange
                                                        }
                                                    />
                                                </RadioBtn>
                                                <Label>Others</Label>
                                            </LeftContainer>
                                        </ItemContent>
                                    </ItemDiv>
                                </OtherOption> */}
                                <FormContainer
                                    className={isFocus ? "active" : ""}
                                >
                                    <Input
                                        type="text"
                                        id="input"
                                        placeholder="Enter your answer"
                                        value={value}
                                        onChange={(e) =>
                                            setValue(e.target.value)
                                        }
                                        onFocus={() => setIsFocus(true)}
                                        onBlur={() => setIsFocus(false)}
                                        //   required
                                    />
                                </FormContainer>
                            </ContentDiv>
                            <WarningContainer>
                                <WarningIconContainer>
                                    <WarningIcon src={warning} alt="Icon" />
                                </WarningIconContainer>
                                <WarningText>
                                    Only 80% of the current plan is refundable
                                </WarningText>
                            </WarningContainer>
                            <BottomDiv>
                                <ErrorMessage
                                    className={
                                        error || descriptionError
                                            ? "display"
                                            : ""
                                    }
                                >
                                    {(error && "Please pick one") ||
                                        (descriptionError &&
                                            "Please fill the description")}
                                </ErrorMessage>
                                <NextButton
                                    size="14px"
                                    style={{
                                        padding: "10px 20px",
                                        height: "40px",
                                        width: isMobile ? "50%" : "97px",
                                    }}
                                    onClick={handleSubmit}
                                />
                            </BottomDiv>
                            {isModalOpen && (
                                <ConfirmCancellationModal
                                    id={slug.id}
                                    cancellation_reason={checked}
                                    cancellation_describe={value}
                                    closeModal={() => handleSubmit()}
                                />
                            )}
                        </ContentsDiv>
                    </>
                )}
            </MainContainer>
            {/* Bottom navigation component */}
            <BottomNavigation />
        </>
    );
}

export default CancelSession;

const MainContainer = styled.div`
    width: 100%;
    background: ${THEME_COLORS.light_200};
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    @media all and (max-width: 768px) {
        width: 100%;
        overflow-y: auto;
        padding: 50px 0px 150px;
    }
    @media all and (max-width: 480px) {
        padding-bottom: 110px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-top: 15px;
    }
`;
const HeadingDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 452px;
    margin-bottom: 14px;
    @media all and (max-width: 480px) {
        width: 390px;
    }
    @media all and (max-width: 420px) {
        width: 340px;
    }
    @media all and (max-width: 360px) {
        width: 315px;
    }
`;
const Heading = styled.h4`
    font-weight: 500;
    font-size: 18px;
    font-family: "DM_sans_medium";
    color: ${THEME_COLORS.text_title};
    line-height: 26px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const ContentsDiv = styled.div`
    background: ${THEME_COLORS.white};
    border: 1px solid ${THEME_COLORS.light_400};
    box-shadow: ${THEME_COLORS.elevation_user_u2};
    /* height: 645px; */
    width: 452px;
    border-radius: 24px;
    padding: 20px;
    /* max-height: 100vh; */
    ::-webkit-scrollbar {
        display: none;
    }
    @media all and (max-width: 480px) {
        width: 400px;
        padding: 10px;
    }
    @media all and (max-width: 420px) {
        width: 350px;
    }
    @media all and (max-width: 360px) {
        width: 330px;
    }
`;
const ContentDiv = styled.div`
    box-shadow: ${THEME_COLORS.elevation_user_u4};
    border: 1px solid ${THEME_COLORS.light_200};
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;

    @media all and (max-width: 480px) {
        padding: 10px;
    }
`;
const TopContainer = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #e0f8f2;
`;
const TopUpContainer = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
`;
const Title = styled.div`
    color: #2b3990;
    font-family: "DM_sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 60%;
`;
const SessionChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    /* gap: 6px; */
    background: #fff;
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
    color: ${THEME_COLORS.chips_blue_on_container_2};
    font-family: "DM_sans";
    font-size: 12px;
    width: 30%;
`;

const TopDownContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 6px;
    align-self: stretch;
`;

const GreyChip = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    gap: 6px;
    background: #fff;
    border-radius: 4px;
    height: 24px;
    box-sizing: border-box;
`;
const ChipIcon = styled.img`
    width: auto;
`;
const ChipText = styled.span`
    display: inline-block;
    font-family: "DM_sans";
    font-size: 12px;
    color: ${THEME_COLORS.dark_300};
`;

const MidHeading = styled.h6`
    color: #1da687;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 24px;
`;
const ItemDiv = styled.div``;
const OtherOption = styled.div`
    margin: 16px 0;
`;
const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-top: 12px;
`;
const ItemContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
`;
const LeftContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    input[type="radio"] {
        width: 20px;
        height: 20px;
        accent-color: ${THEME_COLORS.chips_green_on_container};
    }
`;
const Label = styled.label`
    color: #3e3e53;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;
const RadioBtn = styled.div`
    display: flex;
    align-items: center;
    padding-top: 3px;
    :hover {
        cursor: pointer;
    }
`;
const ErrorMessage = styled.span`
    font-size: 12px;
    color: red;
    margin-left: 10px;
    visibility: hidden;
    &.display {
        visibility: visible;
    }
`;
const FormContainer = styled.form`
    display: flex;
    padding: 17px 12px;
    align-items: center;
    border: 2px solid #f4f4f4;
    background-color: #f4f4f4;
    transition: all 0.2s ease-in-out 0s;
    border-radius: 8px;
    margin-top: 16px;
    &.active {
        border: 2px solid ${THEME_COLORS.border_blue};
    }
`;
const Input = styled.textarea`
    font-size: 14px;
    width: 100%;
    height: 18px;
    line-height: 1.4em;
    font-family: "DM_sans";
    color: ${THEME_COLORS.black};
`;

const WarningContainer = styled.div`
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #f0f5ff;
    margin-bottom: 22px;
`;
const WarningIconContainer = styled.div`
    width: 24px;
    height: 24px;
`;
const WarningIcon = styled.img``;
const WarningText = styled.span`
    color: #333;
    font-family: "DM_sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    @media all and (max-width: 420px) {
        font-size: 13px;
    }
`;

const BottomDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

import React, { useContext, useState } from "react";

// pacakges
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// components
import NextButton from "../../includes/buttons/NextButton";
import PreviousButton from "../../includes/buttons/PreviousButton";

// store
import { Context } from "../../../contexts/Store";

export default function OnBoardingPersonFeelings() {
  const { state, dispatch } = useContext(Context);
  const navigate = useNavigate();

  const [mood, setMood] = useState(state.user_details.feelings || []);
  const [error, setError] = useState(false);
  const [option, setOption] = useState([
    {
      id: 1,
      value: "Happy",
      selected: mood.includes("Happy"),
    },
    {
      id: 2,
      value: "Sadness",
      selected: mood.includes("Sadness"),
    },
    {
      id: 3,
      value: "Calm",
      selected: mood.includes("Calm"),
    },
    {
      id: 4,
      value: "Anger",
      selected: mood.includes("Anger"),
    },
    {
      id: 5,
      value: "Relaxed",
      selected: mood.includes("Relaxed"),
    },
    {
      id: 6,
      value: "Anxiety",
      selected: mood.includes("Anxiety"),
    },
    {
      id: 7,
      value: "Proud",
      selected: mood.includes("Proud"),
    },
    {
      id: 8,
      value: "Worry",
      selected: mood.includes("Worry"),
    },
  ]);

  // feeling selection functionality
  const handleSelect = (item) => {
    item.selected = !item.selected;
    setMood(
      option
        .filter((item) => item.selected === true)
        .map((item) => {
          return item.value;
        })
    );
  };

  // error  validation, dispatching data and navigation
  const handleSubmit = () => {
    if (mood == []) {
      setError(true);
    } else {
      setError(false);
      dispatch({
        type: "UPDATE_USER",
        user_details: {
          feelings: mood,
        },
      });
      navigate("/onboarding-person-meditate");
    }
  };

  return (
    <>
      <MainContainer>
        <ContentContainer>
          <Content>
            <TopContent>
              <SkipButton to="/onboarding-completed">Skip for now</SkipButton>
              <LogoContainer>
                <Logo
                  src={require("../../../assets/images/logo.svg").default}
                  alt="logo"
                />
              </LogoContainer>
              <Text>What is your feeling now</Text>
              <Feelings>
                {option.map((item) => {
                  return (
                    <Feel
                      key={item.id}
                      className={item.selected ? "selected" : ""}
                      onClick={() => handleSelect(item)}
                    >
                      {item.value}
                    </Feel>
                  );
                })}
              </Feelings>
              <ErrorMessage className={error ? "active" : ""}>
                Please select an option
              </ErrorMessage>
            </TopContent>
            <BottomContent>
              <NavButtons>
                <PreviousButton url="/onboarding-person-gender" />
                <NextButton
                  onClick={handleSubmit}
                  // data={{
                  //     type: "UPDATE_USER",
                  //     user_details: {
                  //         feelings: mood,
                  //     },
                  // }}
                  // url="/onboarding-person-meditate"
                />
              </NavButtons>
            </BottomContent>
          </Content>
        </ContentContainer>
      </MainContainer>
    </>
  );
}
const MainContainer = styled.section`
  height: calc(100vh - 70px);
  background: ${THEME_COLORS.light_100};
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 480px) {
    background: ${THEME_COLORS.white};
    display: block;
  }
`;
const ContentContainer = styled.div`
  background: ${THEME_COLORS.white};
  box-shadow: 27px 27px 48px rgba(7, 11, 36, 0.1);
  border-radius: 24px;
  border: 1px solid ${THEME_COLORS.light_300};
  width: 468px;
  max-height: 80vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  /* @media all and (max-width: 1440px) {
    width: 38%;
  }
  @media all and (max-width: 1280px) {
    width: 45%;
  }
  @media all and (max-width: 1080px) {
    width: 52%;
  }
  @media all and (max-width: 980px) {
    width: 62%;
  }
  @media all and (max-width: 768px) {
    width: 75%;
  }
  @media all and (max-width: 640px) {
    width: 90%;
  } */
  @media all and (max-width: 480px) {
    width: 100%;
    border: none;
    box-shadow: none;
    height: 100%;
  }
`;
const Content = styled.div`
  padding: 30px 50px 50px 50px;
  @media all and (max-width: 640px) {
    padding: 30px 30px 50px;
  }
  @media all and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 25px 30px 20px;
  }
`;

const TopContent = styled.div`
  margin-bottom: 60px;
  position: relative;
`;

const SkipButton = styled(Link)`
  font-size: 14px;
  color: ${THEME_COLORS.chips_green_on_container};
  font-family: "DM_sans_medium";
  margin-bottom: 30px;
  text-align: right;
  display: block;
`;
const LogoContainer = styled.div`
  width: 56px;
  margin: 0 auto 60px auto;
`;
const Logo = styled.img``;

const Text = styled.h3`
  font-family: "DM_sans_bold";
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
  @media all and (max-width: 360px) {
    font-size: 22px;
  }
`;
const Feelings = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;
const Feel = styled.div`
  font-size: 16px;
  font-family: "DM_sans";
  border: 1px solid ${THEME_COLORS.light_300};
  border-radius: 24px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${THEME_COLORS.text_title};
  cursor: pointer;
  &.selected {
    border: 2px solid ${THEME_COLORS.chips_green_on_container};
    background: ${THEME_COLORS.chips_green_container};
  }
`;
const ErrorMessage = styled.span`
  display: none;
  &.active {
    position: absolute;
    color: red;
    font-size: 12px;
    display: block;
    bottom: -10%;
    right: 35%;
  }
`;
const BottomContent = styled.div`
  padding: 0 10px;
  @media all and (max-width: 480px) {
    padding: 0;
  }
`;
const NavButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import { THEME_COLORS } from "../../../ThemeConfig";

import NotificationCard from "../../screens/dashboard/notification/NotificationCard";

import nodata from "../../../assets/icons/no-content.svg";
import { Context } from "../../../contexts/Store";

const NotificationModal = ({ notifications, closeModal }) => {
    return (
        <Wrapper onClick={closeModal}>
            <CardContainer onClick={(e) => e.stopPropagation()}>
                <Head>Notification</Head>
                {notifications.length != 0 ? (
                    <>
                        {notifications?.map((item) => (
                            <NotificationCard data={item} key={item.id} />
                        ))}
                        <ViewLink to="/notifications" onClick={closeModal}>
                            View all notifications
                        </ViewLink>
                    </>
                ) : (
                    <>
                        <Nodata className="nodata">
                            <img src={nodata} style={{ width: "64px" }} />
                            <p style={{ textAlign: "center" }}>
                                You don't have any new notifications
                            </p>
                        </Nodata>
                        <ViewLink to="/notifications" onClick={closeModal}>
                            View all notifications
                        </ViewLink>
                    </>
                )}
            </CardContainer>
        </Wrapper>
    );
};

export default NotificationModal;

const popup = keyframes`
    from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const Wrapper = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 768px) {
        z-index: 999;
    }
`;
const CardContainer = styled.div`
    padding: 24px;
    box-shadow: ${THEME_COLORS.elevation_expert_e2};
    border-top: 4px solid ${THEME_COLORS.chips_green_on_container};
    background: ${THEME_COLORS.white};
    border-radius: 12px;
    width: 420px;
    /* max-height: 700px; */
    max-height: calc(100vh - 120px);
    position: absolute;
    top: 60px;
    right: 25px;
    cursor: default;
    z-index: 1111;
    overflow-y: scroll;
    animation: ${popup} 0.4s linear;
    @media screen and (max-width: 768px) {
        max-height: calc(100vh - 100px);
        overflow-y: scroll;
        position: fixed;
    }
    @media screen and (max-width: 480px) {
        /* padding: 10 12px; */
        width: 90%;
        right: 5%;
    }
`;
const Head = styled.h4`
    margin-bottom: 20px;
    color: #3e3e53;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;
const ViewLink = styled(Link)`
    /* font-weight: 500; */
    font-size: 14px;
    color: #1da687;
    text-align: center;
    line-height: 20px;
    margin-top: 20px;
    display: inline-block;
    padding: 8px 15px;
    border-radius: 8px;
    font-family: "DM_sans_medium";

    &:hover {
        background: ${THEME_COLORS.light_200};
    }
`;
const Nodata = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    img {
        margin-bottom: 10px;
    }
`;

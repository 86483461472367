import React, { useEffect, useRef, useState } from "react";

// packages
import WaveSurfer from "wavesurfer.js";
import styled from "styled-components";
import moment from "moment";

// colors
import { THEME_COLORS } from "../../../ThemeConfig";

// media
import music from "../../../assets/mp3/bird.mp3";

export default function Waveform({ item, setDuration }) {
    const [isPlaying, setIsPlaying] = useState("");
    const [wavesurfer, setWavesurfer] = useState(null);
    const [currentTime, setCurrentTime] = useState("00:00:00");
    const waveformRef = useRef(null);

    // WaveSurfer package settings
    useEffect(() => {
        // console.log("Setting up wavesurfer instance");
        if (!waveformRef.current) {
            return;
        }
        const wavesurferInstance = WaveSurfer.create({
            container: waveformRef.current,
            height: 50,
            width: 500,
            maxCanvasWidth: 30000,
            responsive: true,
            backend: "WebAudio",
            waveColor: "#D3D3D3",
            progressColor: "#366EE3",
            minPxPerSec: 2000,
            barWidth: 2,
            cursorWidth: 0,
            barGap: 3.5,
            barRadius: 3,
        });
        setWavesurfer(wavesurferInstance);
        return () => {
            wavesurferInstance.destroy();
        };
    }, [waveformRef]);

    useEffect(() => {
        // console.log("Loading audio");
        if (!item.src || !wavesurfer) {
            return;
        }
        // wavesurfer.load("https://freesound.org/data/previews/462/462808_8386274-lq.mp3");
        wavesurfer.load(music); // Replace with the actual source of the audio
        wavesurfer.on("ready", () => {
            setDuration(
                moment.utc(wavesurfer.getDuration() * 1000).format("HH:mm:ss")
            );
        });
        wavesurfer.on("finish", function () {
            setIsPlaying("");
        });
        wavesurfer.on("audioprocess", function () {
            setCurrentTime(
                moment
                    .utc(wavesurfer.getCurrentTime() * 1000)
                    .format("HH:mm:ss")
            );
        });
    }, [item.src, wavesurfer, setDuration]);

    //   audio play and pause function
    const handlePlayClick = (item) => {
        if (!isPlaying) {
            wavesurfer.play();
            setIsPlaying(`${item.id}`);
        } else {
            wavesurfer.pause();
            setIsPlaying("");
        }
    };

    return (
        <PodcastDiv>
            <PlayButtonContainer>
                <PlayButtonAudio
                    src={
                        isPlaying === `${item.id}`
                            ? require("../../../assets/images/Pause.svg")
                                  .default
                            : require("../../../assets/images/Play.svg").default
                    }
                    alt={
                        isPlaying === `${item.id}`
                            ? "pause-button"
                            : "play-button"
                    }
                    onClick={() => handlePlayClick(item)}
                />
            </PlayButtonContainer>
            <AudioContainer>
                <AudioPlayer ref={waveformRef}></AudioPlayer>
            </AudioContainer>
            <TimeDuration>{currentTime}</TimeDuration>
        </PodcastDiv>
    );
}
const PodcastDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background: ${THEME_COLORS.chips_blue_container};
    padding: 10px;
    border-radius: 12px;
    @media all and (max-width: 480px) {
        gap: 8px;
    }
`;
const PlayButtonContainer = styled.div`
    width: 45px;
    cursor: pointer;
`;
const PlayButtonAudio = styled.img``;
const AudioContainer = styled.div`
    width: 60%;
`;
const TimeDuration = styled.span`
    width: 15%;
    font-size: 14px;
    color: #000;
    font-family: "DM_sans_medium";
    @media all and (max-width: 480px) {
        font-size: 12px;
        width: 20%;
    }
`;
const AudioPlayer = styled.div`
    wave {
        cursor: pointer;
    }
`;

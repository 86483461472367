import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

// icon
import cancelledIcon from "../../../assets/icons/session-cancelled-icon.svg";
import { Link } from "react-router-dom";
import { Context } from "../../../contexts/Store";

function SessionProgress({
    summary_report,
    slug,
    selectedTab,
    hours,
    minutes,
    seconds,
    timeData,
    isSessionOngoing,
    setIsSessionOngoing,
    countdown,
    setCountdown,
}) {
    // const [timerZero, setTimerZero] = useState(false)

    useEffect(() => {
        // Check if hours is not null before starting the countdown
        if (timeData !== null) {
            const interval = setInterval(() => {
                // Decrement the seconds
                setCountdown((prevCountdown) => {
                    const { hours, minutes, seconds } = prevCountdown;
                    if (hours === 0 && minutes < 2) {
                        // Timer reached zero, set the state to true
                        // setTimerZero(true);
                        // console.log(timerZero, 'Timer')
                        setIsSessionOngoing(true);
                        clearInterval(interval);
                        return prevCountdown;
                    }

                    let newSeconds = seconds - 1;
                    let newMinutes = minutes;
                    let newHours = hours;

                    if (newSeconds < 0) {
                        newSeconds = 59;
                        newMinutes -= 1;
                    }
                    if (newMinutes < 0) {
                        newMinutes = 59;
                        newHours -= 1;
                    }

                    return {
                        hours: newHours,
                        minutes: newMinutes,
                        seconds: newSeconds,
                    };
                });
            }, 1000);

            // Cleanup function to clear the interval when the component unmounts
            return () => clearInterval(interval);
        }
    }, [timeData]);
    const { dispatch } = useContext(Context);

    const handleReportClick = (slug) => {
        dispatch({
            type: "UPDATE_SESSION_DETAILS",
            session_details: {
                id: slug,
            },
        });
    };

    return (
        <>
            {selectedTab == "upcoming" ? (
                hours < 24 && (
                    <UpcomingBottom>
                        <BottomText>
                            You will receive a call within the next
                        </BottomText>
                        <UpcomingBottomRight>
                            <TimeContainer>
                                <TimeText>
                                    {String(countdown.hours).padStart(2, "0")}
                                </TimeText>
                                <TimeFormatText>Hour</TimeFormatText>
                            </TimeContainer>
                            <ColonText>:</ColonText>
                            <TimeContainer>
                                <TimeText>
                                    {String(countdown.minutes).padStart(2, "0")}
                                </TimeText>
                                <TimeFormatText>Min</TimeFormatText>
                            </TimeContainer>
                            <ColonText>:</ColonText>
                            <TimeContainer>
                                <TimeText>
                                    {String(countdown.seconds).padStart(2, "0")}
                                </TimeText>
                                <TimeFormatText>Sec</TimeFormatText>
                            </TimeContainer>
                        </UpcomingBottomRight>
                    </UpcomingBottom>
                )
            ) : // : selectedTab == "pending" ? (
            //     <UpcomingBottom>
            //         <BottomText>
            //             Your session will be confirmed within
            //         </BottomText>
            //         <UpcomingBottomRight>
            //             <TimeContainer>
            //                 <TimeText>
            //                     {hours < 10 ? `0${hours}` : hours}
            //                 </TimeText>
            //                 <TimeFormatText>Hour</TimeFormatText>
            //             </TimeContainer>
            //             <ColonText>:</ColonText>
            //             <TimeContainer>
            //                 <TimeText>
            //                     {minutes < 10 ? `0${minutes}` : minutes}
            //                 </TimeText>
            //                 <TimeFormatText>Min</TimeFormatText>
            //             </TimeContainer>
            //             <ColonText>:</ColonText>
            //             <TimeContainer>
            //                 <TimeText>
            //                     {seconds < 10 ? `0${seconds}` : seconds}
            //                 </TimeText>
            //                 <TimeFormatText>Sec</TimeFormatText>
            //             </TimeContainer>
            //         </UpcomingBottomRight>
            //     </UpcomingBottom>
            // )
            selectedTab == "completed" ? (
                <CompletedBottom>
                    {summary_report ? (
                        <>
                            <BottomText>Case report updated</BottomText>
                            <ViewButton
                                onClick={() => handleReportClick(slug)}
                                to={`/view-summary-report/${slug}`}
                            >
                                View Summary Report
                            </ViewButton>
                        </>
                    ) : (
                        <BottomText className="no_summary">
                            Summary report pending...
                        </BottomText>
                    )}
                </CompletedBottom>
            ) : (
                selectedTab == "cancelled" && (
                    <CancelledBottom>
                        <BottomText>This session has been Cancelled</BottomText>
                    </CancelledBottom>
                )
            )}
        </>
    );
}

export default SessionProgress;

const UpcomingBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;
const BottomText = styled.span`
    display: block;
    font-family: "DM_sans";
    font-size: 14px;
    color: ${THEME_COLORS.light_200};
    &.no_summary {
        text-align: center;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 10px;
    }
`;
const UpcomingBottomRight = styled.div`
    display: flex;
    align-items: baseline;
    gap: 6px;
    width: 100px;
`;
const TimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const TimeText = styled.span`
    display: block;
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: ${THEME_COLORS.white};
`;
const TimeFormatText = styled.span`
    display: block;
    font-family: "DM_sans";
    font-size: 10px;
    color: ${THEME_COLORS.light_200};
`;
const ColonText = styled.span`
    display: block;
    font-family: "DM_sans_medium";
    font-size: 16px;
    color: ${THEME_COLORS.white};
    justify-self: first baseline;
`;
const CompletedBottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 480px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;
const ViewButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    height: 32px;
    box-sizing: border-box;
    padding: 6px 18px;
    background: #fff;
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: ${THEME_COLORS.chips_green_on_container};
    cursor: pointer;
`;
const CancelledBottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import { THEME_COLORS } from "../../../ThemeConfig";

import successIcon from "../../../assets/icons/payment-success-icon.svg";
import failedIcon from "../../../assets/icons/payment-faliled.svg";

import { Context } from "../../../contexts/Store";
import { useNavigate } from "react-router-dom";

export default function PaymentStatusModal({
    // isPaymentModal,
    setIsPaymentModal,
    paymentData,
}) {
    const { dispatch } = useContext(Context);
    const navigate = useNavigate();
    const handleClose = () => {
        setIsPaymentModal(false);
        dispatch({
            type: "UPDATE_USER",
            user_details: {
                ipp: false,
            },
        });
        navigate("/");
    };
    return (
        <Wrapper
            onClick={() => {
                handleClose();
            }}
        >
            <Content onClick={(e) => e.stopPropagation()}>
                <TopBox>
                    <IconDiv isSuccess={paymentData?.success}>
                        <Icon
                            src={
                                paymentData?.success ? successIcon : failedIcon
                            }
                            alt="icon"
                        />
                    </IconDiv>
                    <TitleText>
                        Payment{" "}
                        {paymentData?.success ? `Successfull` : `Failed`}
                    </TitleText>
                    {paymentData?.success ? (
                        <SubTitleText>Thank you for your payment.</SubTitleText>
                    ) : (
                        <SubTitleText>
                            {/* Payment could not be processed. Please try again. */}
                            We apologize, but we are unable to process your
                            payment at the moment. Please try again later.
                        </SubTitleText>
                    )}
                </TopBox>
                <DetailsBox>
                    <TransactionTitle>Transaction details</TransactionTitle>
                    <DetailItem>
                        <DetailGreyText>Amount Paid</DetailGreyText>
                        <DetailBlackText>
                            ₹ {paymentData?.amount}
                        </DetailBlackText>
                    </DetailItem>
                    <DetailItem>
                        <DetailGreyText>Transaction ID</DetailGreyText>
                        <DetailBlackText>
                            {paymentData?.transaction_id}
                        </DetailBlackText>
                    </DetailItem>
                    <DetailItem>
                        <DetailGreyText>Payment Method</DetailGreyText>
                        <DetailBlackText>
                            {paymentData?.payment_type}
                        </DetailBlackText>
                    </DetailItem>
                    <DetailItem>
                        <DetailGreyText>Date</DetailGreyText>
                        <DetailBlackText>{paymentData?.date}</DetailBlackText>
                    </DetailItem>
                </DetailsBox>
                <Button onClick={handleClose}>Continue</Button>
            </Content>
        </Wrapper>
    );
}
const popup = keyframes`
    0%{
        scale: 0.3;
    }
    100%{
        scale: 1;
    }
`;
const Wrapper = styled.div`
    position: absolute;
    z-index: 350;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${THEME_COLORS.breath_model};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: ${THEME_COLORS.white};
    width: 460px;
    max-width: 90vw;
    max-height: 90vh;
    padding: 32px;
    z-index: 200;
    border-radius: 24px;
    overflow-y: scroll;
    animation: ${popup} 0.4s ease-in-out;
    @media all and (max-width: 780px) {
        padding: 20px;
    }
    @media all and (max-width: 480px) {
        /* padding: 25px; */
        /* width: 400px; */
    }
    @media all and (max-width: 420px) {
        padding: 16px;
        /* width: 360px; */
    }
    @media all and (max-width: 370px) {
        /* width: 330px; */
        padding: 12px;
    }
`;
const TopBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
`;
const IconDiv = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: ${(props) => (props.isSuccess ? "#e0f8f2" : "#fceded")};

    display: flex;
    justify-content: center;
    align-items: center;

    @media all and (max-width: 480px) {
        width: 40px;
        height: 40px;
    }
`;
const Icon = styled.img`
    width: 70%;
    height: 70%;
    display: block;
`;
const TitleText = styled.div`
    font-size: 20px;
    color: #3e3e53;
    font-family: "DM_sans_medium";
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
`;
const SubTitleText = styled.div`
    font-size: 14px;
    color: #818181;
    font-family: "DM_sans";
    max-width: 90%;
    text-align: center;
`;
const DetailsBox = styled.div`
    margin-top: 20px;
    border: 1px dashed #e2e2e2;
    padding: 16px;
    background: #fafafa;
    width: 100%;
    border-radius: 8px;
`;
const TransactionTitle = styled.div`
    font-size: 16px;
    font-family: "DM_sans_medium";
    color: #3e3e53;
    margin-bottom: 16px;
`;
const DetailItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
`;
const DetailGreyText = styled.div`
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #686868;
`;
const DetailBlackText = styled.div`
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #333333;
`;
const Button = styled.div`
    background: linear-gradient(310.87deg, #2b3990 11.44%, #4f67f8 87.61%);
    font-family: "DM_sans_medium";
    font-size: 14px;
    color: #fff;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    margin-top: 28px;
    border-radius: 32px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 10px;
`;
